import React from "react"
import { checkboxOutline as icon} from "ionicons/icons"
import { IonIcon } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./CompletedCardView.css"

export const CompletedCardView: React.FC<ReactViewProps> = (props) => {
    return (
        <div className="completed-card">
            <IonIcon icon={icon}></IonIcon>
            <div>Completed</div>
        </div>
    )
}