import { NewsFeedSchemaId } from "./NewsFeedSchemaId"
import { Schema } from "../schema/Schema"

export const NewsFeedSchema: Schema = {

    "$id": NewsFeedSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "News from newsapi.com",
    title: "News Feed",

    properties: {

        "apiKey": {
            type: "string",
            description: "The API key to call the newsapi REST service",
            title: "API Key"
        },

        "category": {
            type: "string",
            description: "The category of news articles",
            enum: [
                "business",
                "entertainment",
                "general",
                "health",
                "science",
                "sports",
                "technology"
            ],
            title: "Category"
        },

        "country": {
            type: "string",
            default: "us",
            description: "The country code",
            enum: [
                "ae",
                "ar",
                "at",
                "au",
                "be",
                "bg",
                "br",
                "ca",
                "ch",
                "cn",
                "co",
                "cu",
                "cz",
                "de",
                "eg",
                "fr",
                "gb",
                "gr",
                "hk",
                "hu",
                "id",
                "ie",
                "il",
                "in",
                "it",
                "jp",
                "kr",
                "lt",
                "lv",
                "ma",
                "mx",
                "my",
                "ng",
                "nl",
                "no",
                "nz",
                "ph",
                "pl",
                "pt",
                "ro",
                "rs",
                "ru",
                "sa",
                "se",
                "sg",
                "si",
                "sk",
                "th",
                "tr",
                "tw",
                "ua",
                "us",
                "ve",
                "za"
            ],
            title: "Country code"
        },
    }
}