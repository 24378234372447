import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { TimespanFormatter } from "./TimespanFormatter"
import { TimespanFormatterContextKey } from "./TimespanFormatterContextKey"
import { TimespanFormatterPluginKey } from "./TimespanFormatterPluginKey"
import { TimespanPluginKey } from "../timespan/TimespanPluginKey"
import { TimespanSchemaId } from "../timespan/TimespanSchemaId"
import { FormatterRegistryEntry } from "../formatter-registry/FormatterRegistryEntry"
import { ManifestContext } from "../../runtime/ManifestContext"

export const TimespanFormatterManifest: Manifest = {
 
    /**
     * A string that uniquely identifies the plugin.
     */
    key: TimespanFormatterPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FormatterPluginKey,
        FormatterRegistryPluginKey,
        TimespanPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // formatter
        {
            key: TimespanFormatterContextKey,
            title: "Timespan Formatter",
            value: new TimespanFormatter()
        } as ManifestEntry<TimespanFormatter>,

        // registration
        {
            key: "TimespanFormatterRegistryEntry",
            registerWith: FormatterRegistryContextKey,
            requires: [TimespanFormatterContextKey],
            value: (context: ManifestContext) => ({
                schemaId: TimespanSchemaId,
                formatter: context[TimespanFormatterContextKey]
            })
        } as ManifestEntry<FormatterRegistryEntry<TimespanFormatter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Timespan Formatter"
}