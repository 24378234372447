import { CueCardView } from "./CueCardView"
import { CuePluginKey } from "../cue/CuePluginKey"
import { CueSchemaId } from "../cue/CueSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const CueCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "cue-card-view",
    
    /**
     * Indicates the plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CuePluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "CueCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Cue Card",
            value: {
                layout: ["card", "preview"],
                match: CueSchemaId,
                fc: CueCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    title: "Cue Card View",
}