import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./DefaultCardView.css"

export const DefaultCardView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;

    let str: string;
    if (payload?.title) {
        str = payload.title;
    }
    else {
        str = String(payload?.data);
    }
    
    return (
        <div className="default-card-view selectable">
            <span>{str}</span>
        </div>
    );
}