import { CancelTaskPluginKey } from "../cancel-task/CancelTaskPluginKey"
import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { CompleteTaskPluginKey } from "../complete-task/CompleteTaskPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReopenTaskPluginKey } from "../reopen-task/ReopenTaskPluginKey"
import { TaskCommander } from "./TaskCommander"
import { TaskPluginKey } from "../task/TaskPluginKey"

export const TaskCommanderManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "task-commander",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CancelTaskPluginKey,
        CommanderPluginKey,
        CommanderRegistryPluginKey,
        CompleteTaskPluginKey,
        ReopenTaskPluginKey,
        TaskPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "TaskCommander",
            registerWith: CommanderArrayContextKey,
            title: "Task Commander",
            value: new TaskCommander()
        } as ManifestEntry<TaskCommander>,        
    ],

    /**
     * The display title of the plugin.
     */
    title: "Task Commander"
}