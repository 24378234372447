import { Payload } from "../payload/Payload"
import { PollerCreatorRegistry } from "../poller-creator-registry/PollerCreatorRegistry"
import { PollerStreamer } from "./PollerStreamer"
import { Store } from "../store/Store"
import { Streamer } from "../streamer/Streamer"
import { StreamerCreator } from "../streamer-creator/StreamerCreator"

const DEFAULT_POLLING_INTERVAL = 1000 * 60 * 60;

/**
 * Creates poller streamer.
 */
export class PollerStreamerCreator implements StreamerCreator {

    private creators: PollerCreatorRegistry;
    private store: Store;

    constructor(creators: PollerCreatorRegistry, store: Store) {

        if (!(this.creators = creators)) {
            throw new Error("creators must be specified");
        }

        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }
    }

    /**
     * Attempts to create a streamer from the specified settings.
     */
    public async create(payload: Payload): Promise<Streamer | undefined> {

        const match = this.creators.match(payload);
        if (!match) {
            return undefined;
        }

        const poller = await match.creator.create(payload);
        if (!poller) {
            return undefined;
        }

        // Get the polling interval
        const interval = DEFAULT_POLLING_INTERVAL;

        // Return the streamer.
        return new PollerStreamer(poller, this.store, payload, interval, true)
    }
}