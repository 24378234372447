import { GoogleEarthIndexer } from "./GoogleEarthIndexer"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const GoogleEarthIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the Google Earth plugin.
     */
    key: "google-earth-indexer",

    /**
     * Ensures the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        UrlPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Show a link to Google Earth when viewing a location.",

    /**
     * Returns the objects of the plugin.
     */
    entries: [
        {
            key: "GoogleEarthIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "Google Earth Indexer",
            value: {
                schema: "*",
                indexer: new GoogleEarthIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<GoogleEarthIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Google Earth Indexer",
};
