import { IndexesDetail } from "./IndexesDetail"
import { IndexesReducerPluginKey } from "../indexes-reducer/IndexesReducerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const IndexesDetailManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "indexes-detail",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexesReducerPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "IndexesDetail",
            registerWith: ReactViewRegistryContextKey,
            title: "Indexes Detail",
            value: {
                layout: "detail",
                match: "*",
                order: "first",
                fc: IndexesDetail
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Indexes Detail"
};
