import { BingWallpaperCardView } from "./BingWallpaperCardView"
import { BingWallpaperPluginKey } from "../bing-wallpaper/BingWallpaperPluginKey"
import { BingWallpaperSchemaId } from "../bing-wallpaper/BingWallpaperSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const BingWallpaperCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BingWallpaperPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "BingWallpaperCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Bing Wallpaper Card View",
            value: {
                layout: ["card", "preview"],
                match: BingWallpaperSchemaId,
                fc: BingWallpaperCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Card View"
}