import React, { useEffect, useState } from "react"
import { AddTags } from "../add-tags/AddTags"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export const AddTagsCardView: React.FC<ReactViewProps> = (props) => {
    
    const addTags = props.model?.payload?.data as AddTags;
    const dispatch = props.dispatch;
    const tags = addTags.tags;
    const toPayload = addTags.payload;

    // Track whether the user has confirmed the operation
    const [confirmed, setConfirmed] = useState(addTags?.confirmed ?? false);

    useEffect(() => {

        let mounted = true;

        if (confirmed && Array.isArray(tags)) {

            toPayload.tags = toPayload.tags ?? [];
            for(const tag of tags) {
                if (!toPayload.tags.includes(tag)) {
                    toPayload.tags.push(tag);
                }
            }

            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload: toPayload,
                    confirmed: true
                }
            }
            
            if (dispatch && mounted) {
                dispatch(savePayload);
            }
        }

        return () => { mounted = false };

    }, [confirmed, dispatch, tags, toPayload]);

    /**
     * Executed when the user confirms or declines.
     */
    function onConfirm(selectedPayload: Payload) {
        if (selectedPayload.data === true) {
            setConfirmed(true);
        }
        else {
            if (dispatch) {
                dispatch(toPayload);
            }
        }
    }

    /**
     * Selects a view based on the state of the view.
     */
    function selectView() {
        if (confirmed) {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={undefined}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Updating..."
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
        else {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={p => onConfirm(p)}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: ConfirmSchemaId,
                        data: {
                            payload: toPayload,
                            title: "Add Tag " + String(tags)
                        } as Confirm
                    }}}
                ></ReactViewFactory>
            )
        }
    }

    return selectView()
}