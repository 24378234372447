import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./StringCardView.css"

export const StringCardView: React.FC<ReactViewProps> = (props) => {

    const str = props?.model?.payload?.data ?? "";
    
    return (
        <div className="StringCardView">
            <span>{str}</span>
        </div>
    );
}