import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { CompleteTaskPluginKey } from "../complete-task/CompleteTaskPluginKey"
import { CompleteTaskSchemaId } from "../complete-task/CompleteTaskSchemaId"
import { CompleteTaskCardView } from "./CompleteTaskCardView"
import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SavePluginKey } from "../save/SavePluginKey"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"

export const CompleteTaskCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "complete-task-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BooleanPluginKey,
        CompleteTaskPluginKey,
        ConfirmPluginKey,
        ReactViewPluginKey,
        SavePluginKey,
        TaskPluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "CompleteTaskView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: CompleteTaskSchemaId,
                layout: "stream",
                fc: CompleteTaskCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Complete Task View"
}