import { Manifest } from "../../runtime/Manifest"
import { SchemaPluginKey } from "./SchemaPluginKey"

export const SchemaManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SchemaPluginKey,

    /**
     * 
     */
    title: "Schema"
}