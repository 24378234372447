import { KeyMapRegistry } from "../../runtime/KeyMapRegistry";
import { Schema } from "../schema/Schema"

export class SchemaRegistry extends KeyMapRegistry<Schema>{
    constructor() {
        super("$id")
    }

    /**
     * Dereferences a schema and returns the effective schema.
     */
    public deref(schema: Schema, stack?: string[]): Schema {

        const refId = schema?.$ref;
        if (!refId) {
            return schema;
        }

        // Make sure this reference is not being processed already
        if (Array.isArray(stack)) {
            if (stack.includes(refId)) {
                throw new Error(`circular reference ${refId}`);
            }
        }

        // Get the referenced schema (only absolute references are supported right now)
        const refSchema = this.get(refId);
        if (!refSchema) {
            throw new Error(`cannot resolve ${refId}`);
        }

        // Dereference the referenced schema
        const refEffective = this.deref(
            refSchema,
            [...(stack ?? []), refId]);

        // Merge into this schema 
        return { ...refEffective, ...schema};
    }
}
