import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SnippetAnnotator } from "./SnippetAnnotator"
import { SnippetAnnotatorContextKey } from "./SnippetAnnotatorContextKey"
import { SnippetPluginKey } from "../snippet/SnippetPluginKey"
import { SnippetSchemaId } from "../snippet/SnippetSchemaId"

export const SnippetAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "snippet-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        SnippetPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: SnippetAnnotatorContextKey,
            value: new SnippetAnnotator()
        } as ManifestEntry<SnippetAnnotator>,

        // registration
        {
            key: "SnippetAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [SnippetAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: SnippetSchemaId,
                annotator: context[SnippetAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<SnippetAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Snippet Annotator"
}
