import React from "react"
import { IonFooter, IonToolbar, IonButtons } from "@ionic/react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ToolbarCommandBar } from "./ToolbarCommandBar"
import "./ToolbarPageSection.css"

export const ToolbarPageSection: React.FC<ReactViewProps> = (props) => {
    return (
        <IonFooter>
            <IonToolbar class="ToolbarPageSectionToolbar">
                <ToolbarCommandBar
                    dispatch={props.dispatch}
                    matcher={props.matcher}
                    model={props.model}></ToolbarCommandBar>
                <IonButtons slot="end">
                    <ReactViewFactory
                        dispatch={props.dispatch}
                        layout="toolbar-button"
                        matcher={props.matcher}
                        model={props.model}
                        multi={true}></ReactViewFactory>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    )
}