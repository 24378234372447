import { EnumReactInput } from "./EnumReactInput"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactInputPluginKey } from "../react-input/ReactInputPluginKey"
import { ReactInputRegistryContextKey } from "../react-input/ReactInputRegistryContextKey"
import { ReactInputRegistryEntry } from "../react-input/ReactInputRegistryEntry"
import { Schema } from "../schema/Schema"

export const EnumReactInputManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "enum-react-input",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactInputPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "EnumReactInput",
            registerWith: ReactInputRegistryContextKey,
            value: {

                /**
                 * Match against schemas that have an enum and are also a supported data type.
                 */
                match: (s: Schema) => Array.isArray(s?.enum) && !Array.isArray(s.type) && (["integer", "number", "string"].includes(s.type ?? "")),

                /**
                 * The functional component that renders the input control.
                 */
                fc: EnumReactInput,

                /**
                 * Prioritize this last so that more schema-specific inputs can take priority.
                 */
                order: "last"
            },
        } as ManifestEntry<ReactInputRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Enum React Input"
}