import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Task } from "../task/Task"
import { TaskSchemaId } from "../task/TaskSchemaId"

export class TaskAnnotator implements Annotator {

    public async annotate(payload: Payload<Task>): Promise<void> {

        if (payload?.schema !== TaskSchemaId) {
            return;
        }

        if (payload.data) {
            payload.title = payload.data.title
        }
    }
}