import { Attach } from "../attach/Attach"
import { AttachSchemaId } from "../attach/AttachSchemaId"
import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Model } from "../state/Model"

export class AttachCommander implements Commander {

    public async issue(model: Model): Promise<Command[]> {

        const payload = model?.payload;
        if (!payload) {
            return [];
        }
        
        if (!payload.id) {
            return [];
        }

        const attach: Attach = {
            to: payload,
            action: "create"
        };

        return [{
            key: "attach",
            layout: "extended",
            title: "Attach",
            value: {
                schema: AttachSchemaId,
                data: attach
            }
        }]
    }

}