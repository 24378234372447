import { EditCardView } from "./EditCardView"
import { EditPluginKey } from "../edit/EditPluginKey"
import { EditSchemaId } from "../edit/EditSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactInputMatcherContextKey } from "../react-input/ReactInputMatcherContextKey"
import { ReactInputPluginKey } from "../react-input/ReactInputPluginKey"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const EditCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "edit-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        EditPluginKey,
        SchemaRegistryPluginKey,
        ReactInputPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "EditCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [
                ReactInputMatcherContextKey,
                SchemaRegistryContextKey
            ],
            value: (context: ManifestContext) => ({
                match: EditSchemaId,
                layout: "card",
                fc: (props: ReactViewProps) => EditCardView({...props,
                    inputMatcher: context[ReactInputMatcherContextKey],
                    schemas: context[SchemaRegistryContextKey]
                })
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Edit Card View"
}