import { BingMapIndexer } from "./BingMapIndexer"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const BingMapIndexerManifest: Manifest = {

    /** 
     * The key that uniquely identifies the plugin.
     */
    key: "bing-map-indexer",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        UrlPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Show a link to Bing Maps when viewing a location.",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "BingMapIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "Bing Map Indexer",
            value: {
                schema: "*",
                indexer: new BingMapIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<BingMapIndexer>>
    ],

    /**
     * The display properties of the plugin.
     */
    title: "Bing Map Indexer"
};
