import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ServiceCreatorPluginKey } from "../service-creator/ServiceCreatorPluginKey"
import { ServiceCreatorRegistryContextKey } from "../service-creator-registry/ServiceCreatorRegistryContextKey"
import { ServiceCreatorRegistryEntry } from "../service-creator-registry/ServiceCreatorRegistryEntry"
import { ServiceCreatorRegistryPluginKey } from "../service-creator-registry/ServiceCreatorRegistryPluginKey"
import { ServicePluginKey } from "../service/ServicePluginKey"
import { StreamerCreatorRegistryContextKey } from "../streamer-creator-registry/StreamerCreatorRegistryContextKey"
import { StreamerCreatorRegistryPluginKey } from "../streamer-creator-registry/StreamerCreatorRegistryPluginKey"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"
import { StreamerServiceCreator } from "./StreamerServiceCreator"
import { StreamerServiceCreatorContextKey } from "./StreamerServiceCreatorContextKey"

export const StreamerServiceManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "streamer-service",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ServicePluginKey,
        ServiceCreatorPluginKey,
        ServiceCreatorRegistryPluginKey,
        StreamerPluginKey,
        StreamerCreatorRegistryPluginKey,
        StreamerRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // service creator 
        {
            key: StreamerServiceCreatorContextKey,
            requires: [StreamerCreatorRegistryContextKey, StreamerRegistryContextKey],
            value: (context: ManifestContext) => new StreamerServiceCreator(
                context[StreamerCreatorRegistryContextKey],
                context[StreamerRegistryContextKey]
            )
        } as ManifestEntry<StreamerServiceCreator>,

        // service creator registration
        {
            key: "StreamerServiceCreatorRegistryEntry",
            registerWith: ServiceCreatorRegistryContextKey,
            requires: [StreamerServiceCreatorContextKey],
            value: (context: ManifestContext) => ({
                schema: "*",
                creator: context[StreamerServiceCreatorContextKey]
            })
        } as ManifestEntry<ServiceCreatorRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Streamer Service"
}