import { ListPluginKey } from "../list/ListPluginKey"
import { ListSchemaId } from "../list/ListSchemaId"
import { ListView } from "./ListView"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PredicateArrayContextKey } from "../predicate/PredicateArrayContextKey"
import { PredicatePluginKey } from "../predicate/PredicatePluginKey"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const ListViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the search box plugin.
     */
    key: "list-view",

    /**
     * Indicates whether the plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PredicatePluginKey,
        ReactViewPluginKey,
        ListPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ListView",
            registerWith: ReactViewRegistryContextKey,
            requires: [PredicateArrayContextKey],
            title: "List View",
            value: (context: ManifestContext) => ({
                layout: "card",
                match: ListSchemaId,
                fc: (props) => ListView({...props, predicates: context[PredicateArrayContextKey]}) 
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "List View"
}