import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { RssItem } from "../rss/RssItem"
import { RssItemSchemaId } from "../rss/RssItemSchemaId"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class RssItemIndexer implements Indexer {

    public async index(payload: Payload<RssItem>): Promise<Payload[]> {

        if (payload?.schema !== RssItemSchemaId) {
            return [];
        }

        const link = payload.data?.link;
        if (!link) {
            return [];
        }

        return [
            {
                schema: UrlSchemaId,
                key: link,
                data: link,
                title: payload.data.title ?? link
            }
        ]
    }
}