import { Manifest } from "../../runtime/Manifest"
import { WhatsNewPluginKey } from "./WhatsNewPluginKey"

export const WhatsNewManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: WhatsNewPluginKey,

    /**
     * The display title of the plugin.
     */
    title: "What's New"
}