import { parseHostname } from "../../library/parseHostname";

export function isWikipediaUrl(url: string): boolean {
    switch(parseHostname(url)) {
        case "en.wikipedia.org":
        case "wikipedia.org":
            return true;
        default:
            return false;
    }
}