import { createStore, applyMiddleware } from "redux"
import { defaultState } from "./defaults"
import { runtime } from "../plugins"
import { rootReducer } from "./reducers"
import thunk from "redux-thunk"

/**
 * See https://github.com/reduxjs/redux-thunk
 */
export const store = createStore(
    rootReducer,
    defaultState,
    applyMiddleware(thunk.withExtraArgument(runtime))
);