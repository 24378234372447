import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { TaskCardView } from "./TaskCardView"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { TaskSchemaId } from "../task/TaskSchemaId"

export const TaskCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "task-card-view",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        TaskPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Displays a task",

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "TaskCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Task Card View",
            value: {
                layout: ["card", "preview"],
                match: TaskSchemaId,
                fc: TaskCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Task Card View",
}