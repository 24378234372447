import { CopyrightPluginKey } from "./CopyrightPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const CopyrightManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: CopyrightPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Copyright"
}