import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaRegistry } from "./SchemaRegistry"
import { SchemaRegistryContextKey } from "./SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "./SchemaRegistryPluginKey"

export const SchemaRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SchemaRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: SchemaRegistryContextKey,
            value: new SchemaRegistry()
        } as ManifestEntry<SchemaRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Schema Registry"
}