import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NasaApodFeedAnnotator } from "./NasaApodFeedAnnotator"
import { NasaApodFeedAnnotatorContextKey } from "./NasaApodFeedAnnotatorContextKey"
import { NasaApodFeedPluginKey } from "../nasa-apod-feed/NasaApodFeedPluginKey"
import { NasaApodFeedSchemaId } from "../nasa-apod-feed/NasaApodFeedSchemaId"

export const NasaApodFeedAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nasa-apod-feed-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        NasaApodFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NasaApodFeedAnnotatorContextKey,
            value: new NasaApodFeedAnnotator()
        } as ManifestEntry<NasaApodFeedAnnotator>,

        // registration
        {
            key: "NasaApodFeedAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NasaApodFeedAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NasaApodFeedSchemaId,
                annotator: context[NasaApodFeedAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NasaApodFeedAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Subreddit Feed Annotator"
}