import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { ConfirmAnnotator } from "./ConfirmAnnotator"
import { ConfirmAnnotatorContextKey } from "./ConfirmAnnotatorContextKey"
import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ConfirmAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifie the plugin.
     */
    key: "confirm-annotator",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        ConfirmPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: ConfirmAnnotatorContextKey,
            value: new ConfirmAnnotator()
        } as ManifestEntry<ConfirmAnnotator>,

        // registration
        {
            key: "ConfirmAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [ConfirmAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: ConfirmSchemaId,
                annotator: context[ConfirmAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<ConfirmAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Confirm Annotator"
}