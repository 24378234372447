import { Manifest } from "../../runtime/Manifest"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"
import { PollerPluginKey } from "./PollerPluginKey"

export const PollerManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: PollerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PayloadPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Poller"
}