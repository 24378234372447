import { Manifest } from "../../runtime/Manifest"
import { PollerPluginKey } from "../poller/PollerPluginKey"
import { PollerServicePluginKey } from "./PollerServicePluginKey"
import { StorePluginKey } from "../store/StorePluginKey"

export const PollerServiceManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: PollerServicePluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PollerPluginKey,
        StorePluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Poller Service"
}