import React, { useState } from "react"
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonInput,
    IonItem
} from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { UrlSchemaId } from "../url/UrlSchemaId"

/**
 * Examines a payload and returns the criteria to use for searching.
 */
function criteriaFromPayload(payload?: Payload): string {

    if (!payload) {
        return "";
    }

    if (typeof(payload.data) === "string") {
        return payload.data;
    }
    else {
        return payload.data.title ?? payload.title ?? "";
    }
}

export const SearchStringCardView: React.FC<ReactViewProps> = (props) => {

    // The default search criteria is the search payload title
    const [criteria, setCriteria] = useState(criteriaFromPayload(props?.model?.payload));
    
    const dispatch = props.dispatch;
    
    function onsearch() {

        const url = `https://duckduckgo.com/?q=${criteria}`;

        const payload: Payload = {
            schema: UrlSchemaId,
            data: url,
            title: criteria
        }
        
        if (dispatch) {
            dispatch(payload);
        }
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>web search</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem>
                <IonInput
                    type="text"
                    autofocus={true}
                    inputMode="search"
                    onIonChange={e => setCriteria(e.detail.value!)}
                    required={true}
                    value={criteria}></IonInput>
                </IonItem>                
                <IonButton onClick={() => onsearch()}>Search</IonButton>
            </IonCardContent>
        </IonCard>
    )
}