import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RandomPluginKey } from "../random/RandomPluginKey"
import { RandomRegistryContextKey } from "../random-registry/RandomRegistryContextKey"
import { RandomRegistryPluginKey } from "../random-registry/RandomRegistryPluginKey"
import { RandomSelector } from "./RandomSelector"
import { RandomSelectorContextKey } from "./RandomSelectorContextKey"
import { RandomSelectorPluginKey } from "./RandomSelectorPluginKey"

export const RandomSelectorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: RandomSelectorPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        RandomPluginKey,
        RandomRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: RandomSelectorContextKey,
            requires: [RandomRegistryContextKey],
            value: (context: ManifestContext) => 
                new RandomSelector(context[RandomRegistryContextKey])
        } as ManifestEntry<RandomSelector>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Random Selector"
}