import React, { useState } from "react"
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
} from "@ionic/react"
import { NextSchemaId } from "../next/NextSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Store } from "../store/Store"
import { SuggestedTasks } from "./SuggestedTasks"
import { Task } from "../task/Task"
import { TaskSchemaId } from "../task/TaskSchemaId"
import { SuggestedTask } from "./SuggestedTask"

/**
 * The properties of a list item showing a suggested task.
 */
interface SuggestedListItemProps {
    task: Task,
    selected: boolean,
    onChange: (task: Task, selected: boolean) => any
};

/**
 * A list item that shows a checkbox and a suggested task.
 */
const SuggestedListItem: React.FC<SuggestedListItemProps> = (props) => {

    function onchanged(selected: boolean) {
        if (props.onChange) {
            props.onChange(props.task, selected);
        }
    }

    return (
        <IonItem>
            <IonCheckbox
                checked={props.selected}
                onIonChange={(e) => onchanged((e.target as any).checked)}
                slot="start"
                value={props.task.title} ></IonCheckbox>
            <IonLabel>{props.task.title}</IonLabel>
        </IonItem>
    );
}

export interface SuggestedTasksCardProps extends ReactViewProps {
    store: Store,
}

/**
 * A card that shows a list of suggested tasks.
 */
export const SuggestedTasksCard: React.FC<SuggestedTasksCardProps> = (props) => {

    const dispatch = props.dispatch;
    const [checked, setChecked] = useState(new Map<string, boolean>());

    const suggested = props?.model?.payload?.data as SuggestedTasks;
    if (!suggested) {
        return <></>
    }

    const onclick = (task: Task, selected: boolean) => {
        setChecked(prev => (prev.set(task.title!, selected)));
    }

    const oncreate = () => {

        // Get an array of suggested tasks
        const selected = suggested.tasks.filter(s => checked.get(s.title!) === true);

        // Wrap into payloads
        const payloads: Payload<SuggestedTask>[] = selected.map(t => ({
            schema: TaskSchemaId,
            data: t
        }));

        // Open the destination folder
        const store = props.store;
        if (store) {

            store.addRange(payloads).then((v) => {
                const nextPayload: Payload = {
                    schema: NextSchemaId,
                    data: undefined
                }
        
                if (dispatch) {
                    dispatch(nextPayload);
                }        
            })
        }
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>suggested tasks</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonList>
                    {suggested.tasks.map(s => (
                        <SuggestedListItem
                            key={s.suggestionKey}
                            task={s}
                            selected={checked.has(s.title!)}
                            onChange={onclick}></SuggestedListItem>
                    ))}
                </IonList>
                <IonButton onClick={oncreate}>Create Tasks</IonButton>
            </IonCardContent>
        </IonCard>
    )
}