import { RandomGenerator } from "../random/RandomGenerator"
import { Selector } from "../selector/Selector"

export class RandomSelector<T = any> extends Selector<T> {

    private rng: RandomGenerator;

    constructor(rng: RandomGenerator) {
        super();
        if(!(this.rng = rng)) {
            throw new Error("rng must be specified");
        }
    }
    
    public async select(items: T[]): Promise<T | undefined> {

        if (!Array.isArray(items) || items.length === 0) {
            return undefined;
        }

        // Select a random index value
        const value = this.rng.int(0, items.length - 1);
        if (!value) {
            return undefined;
        }
        else if (value.value === undefined) {
            return undefined;
        }

        // Get the item at that index
        const item = items[value.value];
        if (!item) {
            return undefined;
        }

        // TODO: Attach rng details
        return item;
    }   
}