import { NewsFeedPoller } from "./NewsFeedPoller"
import { NewsFeed } from "../newsapi-feed/NewsFeed"
import { NewsFeedSchemaId } from "../newsapi-feed/NewsFeedSchemaId"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { PollerCreator } from "../poller-creator/PollerCreator"

export class NewsFeedPollerCreator implements PollerCreator<NewsFeed> {

    /**
     * Creates a new instance of the news poller from the specified parameters.
     */
    public async create(payload: Payload<NewsFeed>): Promise<Poller | undefined> {

        if (payload?.schema !== NewsFeedSchemaId) {
            return undefined;
        }

        const settings = payload.data as NewsFeed;
        if (!settings) {
            return undefined;
        }
        else {
            return new NewsFeedPoller(settings);
        }
    }
}