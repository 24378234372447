import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { BingWallpaperAnnotator } from "./BingWallpaperAnnotator"
import { BingWallpaperAnnotatorContextKey } from "./BingWallpaperAnnotatorContextKey"
import { BingWallpaperPluginKey } from "../bing-wallpaper/BingWallpaperPluginKey"
import { BingWallpaperSchemaId } from "../bing-wallpaper/BingWallpaperSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const BingWallpaperAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        BingWallpaperPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: BingWallpaperAnnotatorContextKey,
            value: new BingWallpaperAnnotator()
        } as ManifestEntry<BingWallpaperAnnotator>,

        // registration
        {
            key: "BingWallpaperAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [BingWallpaperAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: BingWallpaperSchemaId,
                annotator: context[BingWallpaperAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<BingWallpaperAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Annotator"
}