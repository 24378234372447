import { Goal } from "../goal/Goal"
import { GoalSchemaId} from "../goal/GoalSchemaId"
import { GoalStreamer } from "./GoalStreamer"
import { Payload } from "../payload/Payload";
import { Streamer } from "../streamer/Streamer"
import { StreamerCreator } from "../streamer-creator/StreamerCreator"

export class GoalStreamerCreator implements StreamerCreator {

    public async create(payload: Payload<Goal>): Promise<Streamer<Goal>> {

        if (!payload) {
            throw new Error("payload must be specified");
        }

        if (payload.schema !== GoalSchemaId) {
            throw new Error(`missing or unsupported schema: ${payload.schema}`);
        }

        return new GoalStreamer(payload);
    }
}