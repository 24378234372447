import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SequenceMapFactoryContextKey } from "../sequence-map-factory/SequenceMapFactoryContextKey"
import { SequenceMapFactoryPluginKey } from "../sequence-map-factory/SequenceMapFactoryPluginKey"
import { SequencePluginKey } from "../sequence/SequencePluginKey"
import { SequenceSchemaId } from "../sequence/SequenceSchemaId"
import { SequenceStreamerCreator } from "./SequenceStreamerCreator"
import { SequenceStreamerPluginKey } from "./SequenceStreamerPluginKey"
import { StreamerCreatorPluginKey } from "../streamer-creator/StreamerCreatorPluginKey"
import { StreamerCreatorRegistryContextKey } from "../streamer-creator-registry/StreamerCreatorRegistryContextKey"
import { StreamerCreatorRegistryEntry } from "../streamer-creator-registry/StreamerCreatorRegistryEntry"
import { StreamerCreatorRegistryPluginKey } from "../streamer-creator-registry/StreamerCreatorRegistryPluginKey"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"

export const SequenceStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SequenceStreamerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        StreamerPluginKey,
        StreamerCreatorPluginKey,
        StreamerCreatorRegistryPluginKey,
        SequencePluginKey,
        SequenceMapFactoryPluginKey,
        StreamerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // streamer creator
        {
            key: "SequenceStreamerCreator",
            requires: [SequenceMapFactoryContextKey],
            value: (context: ManifestContext) =>
                new SequenceStreamerCreator(context[SequenceMapFactoryContextKey])
        } as ManifestEntry<SequenceStreamerCreator>,

        // registration
        {
            key: "SequenceStreamerCreatorRegistryEntry",
            registerWith: StreamerCreatorRegistryContextKey,
            requires: ["SequenceStreamerCreator"],
            value: (context: ManifestContext) => ({
                schema: SequenceSchemaId,
                creator: context["SequenceStreamerCreator"]
            })
        } as ManifestEntry<StreamerCreatorRegistryEntry<SequenceStreamerCreator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Sequence Streamer"
}