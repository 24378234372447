import React, { useState } from "react"
import { Attach } from "../attach/Attach"
import { clone } from "../../library/clone"
import { Create } from "../create/Create"
import { CreateSchemaId } from "../create/CreateSchemaId"
import { Edit } from "../edit/Edit"
import { EditSchemaId } from "../edit/EditSchemaId"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./AttachCardView.css"

export const AttachCardView: React.FC<ReactViewProps> = (props) => {

    const attach: Attach = props.model?.payload?.data;
    const [attachment, setAttachment] = useState<Payload>();
    const [edited, setEdited] = useState(false);

    /**
     * Prompts the user for a schema (if not already selected)
     * and creates the initial value of the attached payload.
     */
    function CreateAttachment() {

        function onCreate(p: Payload) {
            setAttachment(p);
            setEdited(false);
        }

        return (
            <ReactViewFactory
                layout="card"
                dispatch={p => onCreate(p)}
                matcher={props.matcher}
                model={{
                    payload: {
                        schema: CreateSchemaId,
                        data: {
                            payload: {
                                schema: attach?.schemaId
                            }
                        }
                    } as Payload<Create>
                }}>
            </ReactViewFactory>
        )
    }

    /**
     * Edits the attached object.
     */
    function EditAttachment() {

        function onEdit(p: Payload) {
            setAttachment(p);
            setEdited(true);

            // Clone the parent payload
            const attachTo = clone(attach.to);
            attachTo.attachments = attachTo.attachments ?? [];
            attachTo.attachments.push(p);

            if (props.dispatch) {
                props.dispatch(attachTo);
            }
        }

        return (
            <ReactViewFactory
                layout="card"
                dispatch={p => onEdit(p)}
                matcher={props.matcher}
                model={{
                    payload: {
                        schema: EditSchemaId,
                        data: {
                            payload: attachment
                        }
                    } as Payload<Edit>
                }}>
            </ReactViewFactory>            
        )
    }

    function PreviewAttachment() {        
        return <></>
    }

    function SelectView() {
    
        if (!attach) {
            return (
                <ReactViewFailure>
                    attach object must be specified
                </ReactViewFailure>
            )
        }
        
        if (!attachment) {
            return (
                <CreateAttachment />
            )
        }

        if (!edited) {
            return (
                <EditAttachment />
            )
        }

        return (
            <PreviewAttachment />
        );
    }

    return (
        <IonCard>
            
            <IonCardHeader className="attach-card-view-header">
                {attachment &&
                    <ReactViewFactory
                        layout="emblem"
                        dispatch={props.dispatch}
                        matcher={props.matcher}
                        model={{
                            payload: attachment
                        }}>
                    </ReactViewFactory>
                }
                <IonCardTitle className="attach-card-view-title">Create attachment</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <SelectView />
            </IonCardContent>

        </IonCard>
    )
}