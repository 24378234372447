import { EarthquakeFeedPluginKey } from "./EarthquakeFeedPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const UsgsEarthquakeFeedManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: EarthquakeFeedPluginKey,

    /**
     * The display title of the plugin.
     */
    title: "USGS Earthquake Feed"
}