import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { QuoteIndexer } from "./QuoteIndexer"
import { QuoteIndexerContextKey } from "./QuoteIndexerContextKey"
import { QuotePluginKey } from "../quote/QuotePluginKey"
import { QuoteSchemaId } from "../quote/QuoteSchemaId"
import { SearchStringPluginKey } from "../search-string/SearchStringPluginKey"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const QuoteIndexerManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: "quote-indexer",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        QuotePluginKey,
        SearchStringPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        
        // indexer
        {
            key: QuoteIndexerContextKey,
            value: new QuoteIndexer()
        } as ManifestEntry<QuoteIndexer>,

        // registration
        {
            key: "QuoteIndexerRegistryEntry",
            registerWith: IndexerRegistryContextKey,
            requires: [QuoteIndexerContextKey],
            value: (context: ManifestContext) => ({
                schema: QuoteSchemaId,
                indexer: context[QuoteIndexerContextKey]
            })
        } as ManifestEntry<IndexerRegistryEntry<QuoteIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Quote Indexer"
}