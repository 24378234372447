import { Annotator } from "../annotator/Annotator"
import { DateNumber } from "../date-number/DateNumber";
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { Payload } from "../payload/Payload";

export class DateNumberAnnotator implements Annotator<DateNumber> {

    public async annotate(payload: Payload<number>): Promise<void> {

        if (payload?.schema !== DateNumberSchemaId) {
            return;
        }

        if (typeof(payload.data) !== "number") {
            return;
        }

        const date = new Date(payload.data);
        payload.title = date.toString();
    }
}