import { Splash } from "../splash/Splash"

export const DefaultSplashes: Splash[] = [
    {
        activity: "bedtime",
        imageUrl: "https://images.unsplash.com/photo-1568052837784-3dcb0d4dc2fc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "Walter Zoo, Gossau SG",
        photographer: "Sepp Rutz",
        photographerUrl: "https://unsplash.com/@rutzsepp"
    },
    {
        activity: "bedtime",
        imageUrl: "https://images.unsplash.com/photo-1517912158475-fb0ab8c8eed1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1908&q=80",
        photographer: "Annie Spratt",
        photographerUrl: "https://unsplash.com/@anniespratt"
    },
    {
        activity: "bedtime",
        imageUrl: "https://images.unsplash.com/photo-1479233270217-77d99c494c4e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        photographer: "Fabian Oelkers",
        photographerUrl: "https://unsplash.com/@foemedia"
    },
    {
        activity: "bedtime",
        imageUrl: "https://images.unsplash.com/photo-1548136479-a8a16809c609?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        photographer: "Jr Korpa",
        photographerUrl: "https://unsplash.com/@korpa"
    },
    {
        activity: "bedtime",
        imageUrl: "https://images.unsplash.com/photo-1505058567159-6f2a114a1a70?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1329&q=80",
        photographer: "Max Felner",
        photographerUrl: "https://unsplash.com/@maxfelner"
    },
    {
        activity: "camping",
        imageUrl: "https://images.unsplash.com/photo-1505232530843-7e94d7faac73?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        location: "Nui Chua Chan, Vietnam",
        photographer: "Chang Duong",
        photographerUrl: "https://unsplash.com/@iamchang"
    },
    {
        activity: "camping",
        imageUrl: "https://images.unsplash.com/photo-1533873984035-25970ab07461?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1953&q=80",
        location: "Ranca Upas, Indonesia",
        photographer: "kilarov zaneit",
        photographerUrl: "https://unsplash.com/@kilarov345"
    },
    {
        activity: "camping",
        imageUrl: "https://images.unsplash.com/photo-1518062304627-5532ae8c3ff2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80",
        location: "Virginia Key Beach Park, United States",
        photographer: "Edward Paterson",
        photographerUrl: "https://unsplash.com/@suh5pence"
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1531428197664-d00237332d29?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "T. Q.",
        photographerUrl: "https://unsplash.com/@tq_photos"
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1565481454375-bfc5c66174b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "Anna Auza",
        photographerUrl: "https://unsplash.com/@annaauza"
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1587027768084-c3a9076c0a43?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "Lucas van Oort",
        photographerUrl: "https://unsplash.com/@switch_dtp_fotografie"
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1545476745-9211a9e7cca8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        photographer: "Jason Abdilla",
        photographerUrl: "https://unsplash.com/@jabdilla_creative",        
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1421940943431-d392fcc1079f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1934&q=80",
        location: "Lisboa, Portugal",
        photographer: "Nuno Silva",
        photographerUrl: "https://unsplash.com/@nmsilva"
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1493953659556-556b14bdaca8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1791&q=80",
        location: "Kumamoto Prefecture, Japan",
        photographer: "pan xiaozhen",
        photographerUrl: "https://unsplash.com/@zhenhappy"
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1462475279937-40cb2b162a99?ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80",
        location: "Johannesburg, South Africa",
        photographer: "Scott Umstattd",
        photographerUrl: "https://unsplash.com/@scott_umstattd"
    },
    {
        activity: "chores",
        imageUrl: "https://images.unsplash.com/photo-1521454633026-23874ee091a4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1987&q=80",
        location: "Laundry in Venice",
        photographer: "Macro Secchi",
        photographerUrl: "https://unsplash.com/@marcosecchi"
    },
    {
        activity: "diversion",
        imageUrl: "https://images.unsplash.com/photo-1566952070167-6cdbc3e8ba2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "Yael Gonzalas",
        photographerUrl: "https://unsplash.com/@yaelgonzalez",
        title: "bubbles"
    },
    {
        activity: "diversion",
        imageUrl: "https://images.unsplash.com/photo-1513728153204-2455146ac551?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1790&q=80",
        location: "Acapulco, Mexico",
        photographer: "Ruben Ramirez",
        photographerUrl: "https://unsplash.com/@pinchebesu",
        title: "Wave crash"
    },
    {
        activity: "diversion",
        imageUrl: "https://images.unsplash.com/photo-1583010616697-65692103dd39?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80",
        photographer: "Ashwini Chaudhary",
        photographerUrl: "https://unsplash.com/@suicide_chewbacca"
    },
    {
        activity: "diversion",
        imageUrl: "https://images.unsplash.com/photo-1578382559568-8bdfcde16749?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1975&q=80",
        location: "Hyvinkää, Finland",
        photographer: "Tom Brunberg",
        photographerUrl: "https://unsplash.com/@tombrunberg"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1500856056008-859079534e9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80",
        photographer: "Zoltan Tasi",
        photographerUrl: "https://unsplash.com/@zoltantasi",
        title: "endless"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1476480862126-209bfaa8edc8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "Juiz de Fora, Brazil",
        photographer: "Bruno Nascimento",
        photographerUrl: "https://unsplash.com/@bruno_nascimento",
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1519053450113-32bed8bbf61d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1789&q=80",
        location: "Khon Kaen, Thailand",
        photographer: "Arisa Chattasa",
        photographerUrl: "https://unsplash.com/@golfarisa",
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1517344368193-41552b6ad3f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "Jesper Aggergaard",
        photographerUrl: "https://unsplash.com/@aggergakker"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1589228589773-7ebefccb6dfc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "Chicago, IL",
        photographer: "Max Bender",
        photographerUrl: "https://unsplash.com/@maxwbender"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1592389209483-02dea6bc5a4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80",
        location: "Leipzig, Germany",
        photographer: "Miikka Luotio",
        photographerUrl: "https://unsplash.com/@mluotio83"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1572565408388-cdd3afe23e82?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "Bondi Beach, NSW, Australia",
        photographer: "Mark Williams",
        photographerUrl: "https://unsplash.com/@markwilliamspics"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1574680096145-d05b474e2155?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "Sven Mieke",
        photographerUrl: "https://unsplash.com/@sxoxm"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1563685804888-4ce3b5a2a649?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "Foto Garage AG",
        photographerUrl: "https://unsplash.com/@fotogarageag"
    },
    {
        activity: "exercise",
        imageUrl: "https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80",
        photographer: "Anastase Maragos",
        photographerUrl: "https://unsplash.com/@visualsbyroyalz",
    },
    {
        activity: "finances",
        imageUrl: "https://images.unsplash.com/photo-1511883040705-6011fad9edfc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1953&q=80",
        photographer: "Jeffrey Blum",
        photographerUrl: "https://unsplash.com/@jeffreyblum"
    },
    {
        activity: "finances",
        imageUrl: "https://images.unsplash.com/photo-1591033594798-33227a05780d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1854&q=80",
        photographer: "John McArthur",
        photographerUrl: "https://unsplash.com/@snowjam"
    },
    {
        activity: "finances",
        imageUrl: "https://images.unsplash.com/photo-1499909694555-1ae5b7067b1a?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80",
        photographer: "Freddie Collins",
        photographerUrl: "https://unsplash.com/@visuals_by_fred"
    },
    {
        activity: "gardening",
        imageUrl: "https://images.unsplash.com/photo-1517174377328-02c8235597f3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1955&q=80",
        photographer: "Johannes Hofmann",
        photographerUrl: "https://unsplash.com/@quicksand"
    },
    {
        activity: "gardening",
        imageUrl: "https://images.unsplash.com/photo-1477414348463-c0eb7f1359b6?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80",
        photographer: "Chris Lawton",
        photographerUrl: "https://unsplash.com/@chrislawton"
    },
    {
        activity: "gardening",
        imageUrl: "https://images.unsplash.com/photo-1457282168619-2383c79b278d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1954&q=80",
        photographer: "Meiying Ng",
        photographerUrl: "https://unsplash.com/@meiying"
    },
    {
        activity: "groceries",
        imageUrl: "https://images.unsplash.com/photo-1533167751327-4091607cecc5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        photographer: "Nikos Kavvadas",
        photographerUrl: "https://unsplash.com/@nikoslefkas"
    },
    {
        activity: "groceries",
        imageUrl: "https://images.unsplash.com/photo-1591100063300-7b8956e7cb51?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1951&q=80",
        location: "Erlangen, Deutschland",
        photographer: "Markus Spiske",
        photographerUrl: "https://unsplash.com/@markusspiske"
    },
    {
        activity: "grooming",
        imageUrl: "https://images.unsplash.com/photo-1519583916722-289d542b19a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "Ogden, UT",
        photographer: "Sharon McCutcheon",
        photographerUrl: "https://unsplash.com/@sharonmccutcheon"
    },
    {
        activity: "grooming",
        imageUrl: "https://images.unsplash.com/photo-1523532855831-190c384cb39a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1489&q=80",
        photographer: "Thom Holmes",
        photographerUrl: "https://unsplash.com/@thomholmes"
    },
    {
        activity: "grooming",
        imageUrl: "https://images.unsplash.com/photo-1521490214993-bfb6f7dc096d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80",
        photographer: "Tommy van Kessel 🤙",
        photographerUrl: "https://unsplash.com/@tommyvkessel"
    },
    {
        activity: "grooming",
        imageUrl: "https://images.unsplash.com/photo-1507500747995-2cb3ce31d6f4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
        location: "Antelope Park, Gweru, Zimbabwe",
        photographer: "Antelope Park",
        photographerUrl: "https://unsplash.com/@mcilvride2000"
    },
    {
        activity: "health",
        imageUrl: "https://images.unsplash.com/photo-1444459094717-a39f1e3e0903?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80",
        location: "Los Angeles, United States",
        photographer: "William Felker",
        photographerUrl: "https://unsplash.com/@gndclouds"
    },
    {
        activity: "health",
        imageUrl: "https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
        photographer: "Autumn Goodman",
        photographerUrl: "https://unsplash.com/@auttgood"
    },
    {
        activity: "health",
        imageUrl: "https://images.unsplash.com/photo-1571172964276-91faaa704e1f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
        photographer: "Nicola Fioravanti",
        photographerUrl: "https://unsplash.com/@nicolafioravanti"
    },
    {
        activity: "home",
        imageUrl: "https://images.unsplash.com/photo-1460533893735-45cea2212645?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80",
        photographer: "Arno Smit",
        photographerUrl: "https://unsplash.com/@_entreprenerd"
    },
    {
        activity: "home",
        imageUrl: "https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        location: "Barra da Tijuca, Brazil",
        photographer: "Breno Assis",
        photographerUrl: "https://unsplash.com/@brenoassis"
    },
    {
        activity: "inspiration",
        imageUrl: "https://images.unsplash.com/photo-1537819191377-d3305ffddce4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80",
        location: "Stars in the Valley of Dreams",
        photographer: "John Fowler",
        photographerUrl: "https://unsplash.com/@wildhoney"
    },
    {
        activity: "inspiration",
        imageUrl: "https://images.unsplash.com/photo-1550684848-fac1c5b4e853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "Melitopol, Ukraine",
        photographer: "Rodion Kutsaev",
        photographerUrl: "https://unsplash.com/@frostroomhead"
    },
    {
        activity: "negotiate",
        imageUrl: "https://images.unsplash.com/photo-1572402123736-c79526db405a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        location: "Siti Khatijah Market, Kelantan, Malaysia",
        photographer: "Alex Hudson",
        photographerUrl: "https://unsplash.com/@aliffhassan91"
    },
    {
        activity: "negotiate",
        imageUrl: "https://images.unsplash.com/photo-1560320110-03ade027704c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        photographer: "Muhammad Taufik",
        photographerUrl: "https://unsplash.com/@opieutopis"
    },
    {
        activity: "school",
        imageUrl: "https://images.unsplash.com/photo-1561089489-f13d5e730d72?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1867&q=80",
        location: "Tory Bldg, Ottawa, ON K1S, Canada",
        photographer: "Shubham Sharan",
        photographerUrl: "https://unsplash.com/@shubhamsharan"
    },
    {
        activity: "school",
        imageUrl: "https://images.unsplash.com/photo-1504275107627-0c2ba7a43dba?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1867&q=80",
        photographer: "moren hsu",
        photographerUrl: "https://unsplash.com/@moren"
    },
    {
        activity: "spirituality",
        imageUrl: "https://images.unsplash.com/photo-1518562180175-34a163b1a9a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80",
        photographer: "Johannes Plenio",
        photographerUrl: "https://unsplash.com/@jplenio"
    },
    {
        activity: "spirituality",
        imageUrl: "https://images.unsplash.com/photo-1453791052107-5c843da62d97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80",
        photographer: "veeterzy",
        photographerUrl: "https://unsplash.com/@veeterzy"
    },
    {
        activity: "spirituality",
        imageUrl: "https://images.unsplash.com/photo-1518837695005-2083093ee35b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
        photographer: "Matt Hardy",
        photographerUrl: "https://unsplash.com/@matthardy"
    },
    {
        activity: "spirituality",
        imageUrl: "https://images.unsplash.com/photo-1579798940896-8bdbbe3ab79b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
        photographer: "Joel Muniz",
        photographerUrl: "https://unsplash.com/@jmuniz"
    },
    {
        activity: "wakeup",
        imageUrl: "https://images.unsplash.com/photo-1500964757637-c85e8a162699?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1978&q=80",
        location: "Altmünster am Traunsee, Neukirchen bei Altmünster, Oberösterreich, Österreich",
        photographer: "Simon Berger",
        photographerUrl: "https://unsplash.com/@8moments"
    },    
    {
        activity: "wakeup",
        imageUrl: "https://images.unsplash.com/photo-1531223544733-884a29345600?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1934&q=80",
        photographer: "Sandra Kaas",
        photographerUrl: "https://unsplash.com/@skaas"
    },
    {
        activity: "wakeup",
        imageUrl: "https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1789&q=80",
        location: "Fribourg, Switzerland",
        photographer: "Federico Respini",
        photographerUrl: "https://unsplash.com/@federicorespini"
    },
    {
        activity: "wakeup",
        imageUrl: "https://images.unsplash.com/photo-1584791801003-79b1831797cf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1951&q=80",
        location: "Erlangen, Deutschland",
        photographer: "Markus Spiske",
        photographerUrl: "https://unsplash.com/@markusspiske"
    },

    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1545262722-9e0d80a0bc01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1979&q=80",
        location: "Instanbul, Turkey",
        photographer: "Alev Takil",
        photographerUrl: "https://unsplash.com/@alevtakil"
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1576570489273-9ab33773a03f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1898&q=80",
        location: "Burbank, CA",
        photographer: "Library of Congress",
        photographerUrl: "https://unsplash.com/@libraryofcongress"
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1507981919056-e4804b83d74e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "New York Times Building, New York",
        photographer: "Valentin Farkasch",
        photographerUrl: "https://unsplash.com/@norobotyet"
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1572833458568-37fdcaeba36b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1872&q=80",
        photographer: "Obi Onyeador",
        photographerUrl: "https://unsplash.com/@thenewmalcolm"
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1504917595217-d4dc5ebe6122?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        location: "Hay, Australia",
        photographer: "Christopher Burns",
        photographerUrl: "https://unsplash.com/@christopher__burns",
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1504328345606-18bbc8c9d7d1?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        location: "Hay, Australia",
        photographer: "Christopher Burns",
        photographerUrl: "https://unsplash.com/@christopher__burns",
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1473621038790-b778b4750efe?ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80",
        photographer: "Clark Young",
        photographerUrl: "https://unsplash.com/@cbyoung"
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        location: "Grass Valley, United States",
        photographer: "Rob Lambert",
        photographerUrl: "https://unsplash.com/@roblambertjr",
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/reserve/oIpwxeeSPy1cnwYpqJ1w_Dufer%20Collateral%20test.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=1832&q=80",
        photographer: "Todd Quakenbush",
        photographerUrl: "https://unsplash.com/@toddquackenbush"
    },
    {
        activity: "work",
        imageUrl: "https://images.unsplash.com/photo-1532448416606-00984ce2cde9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1955&q=80",
        location: "Pune, India",
        photographer: "Atharva Tulsi",
        photographerUrl: "https://unsplash.com/@atharva_tulsi"
    }
]