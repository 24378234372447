import React from "react"
import { Activity } from "../activity/Activity"
import { IonSelect, IonSelectOption } from "@ionic/react"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"

export interface ActivityKeyReactInputProps extends ReactInputProps {
    activities: Activity[]
}

export const ActivityKeyReactInput: React.FC<ActivityKeyReactInputProps> = (props) => {

    const schema = props?.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema missing or not an object 
            </ReactInputFailure>
        )
    }

    if (schema.type !== "string") {
        return (
            <ReactInputFailure>
                Schema type must be string
            </ReactInputFailure>
        )
    }

    function onChange(v: string | undefined) {
        if (props.onChange) {
            props.onChange(v);
        }
    }

    return (
        <IonSelect
            onIonChange={e => onChange(e.detail.value)}
            placeholder={schema.description}
            value={props.value}>
        
        {props.activities.map(v => (
            <IonSelectOption key={v.key} value={v.key}>{v.title}</IonSelectOption>
        ))}

    </IonSelect>
    )
}