import { ListPluginKey } from "../list/ListPluginKey"
import { ListSchemaId } from "../list/ListSchemaId"
import { ListStreamView } from "./ListStreamView"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const ListStreamViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "list-stream-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ListPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ListStreamView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: ListSchemaId,
                layout: "stream",
                fc: ListStreamView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "List Stream View"
}