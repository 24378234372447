import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { BlockExpiredAnnotator } from "./BlockExpiredAnnotator"
import { BlockExpiredAnnotatorContextKey } from "./BlockExpiredAnnotatorContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const BlockExpiredAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "block-expired-annotator",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Automatically blocks payloads that have expired",

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // annotator
        {
            key: BlockExpiredAnnotatorContextKey,
            title: "Block Expired Annotator",
            value: new BlockExpiredAnnotator()
        } as ManifestEntry<BlockExpiredAnnotator>,

        // registration
        {
            key: "BlockExpiredAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [BlockExpiredAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: "*",
                annotator: context[BlockExpiredAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<BlockExpiredAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Block Expired Annotator",
}