import { Registry } from "../../runtime/Registry"
import { StreamerRegistryEntry } from "./StreamerRegistryEntry";

export class StreamerRegistry implements Registry<StreamerRegistryEntry> {

    private streamers = new Set<StreamerRegistryEntry>();

    /**
     * Returns all entries of the registry.
     */
    public all(): StreamerRegistryEntry[] {
        return [...this.streamers.values()]
    }

    /**
     * Adds an entry to the registry.
     */
    public register(value: StreamerRegistryEntry): void {

        if (!value) {
            throw new Error("value must be specified");
        }

        if (typeof(value.streamer) !== "object") {
            throw new Error("streamer must be defined");
        }

        this.streamers.add(value);
    }

    /**
     * Removes an entry from the registry.
     */
    public unregister(value: StreamerRegistryEntry): void {

        if (!value) {
            throw new Error("value must be specified");
        }

        console.debug(`unregistered streamer`);
        this.streamers.delete(value);
    }
}