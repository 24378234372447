import { Payload } from "../payload/Payload"
import { SequenceInstance } from "../sequence/SequenceInstance"
import { Streamer } from "../streamer/Streamer"

export class SequenceStreamer implements Streamer {

    private instance: SequenceInstance;

    constructor(instance: SequenceInstance) {
        if (!(this.instance = instance)) {
            throw new Error("sequence must be specified");
        }
    }

    public next(): Promise<Payload | undefined> {
        return this.instance.next();
    }
}