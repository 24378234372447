import { Converter } from "../converter/Converter"
import { ConverterInspection } from "../converter/ConverterInspection"
import { ConverterRegistryEntry } from "./ConverterRegistryEntry"
import { Payload } from "../payload/Payload"
import { Registry } from "../../runtime/Registry"

export class ConverterRegistry implements Registry<ConverterRegistryEntry>, Converter {

    /**
     * The inputs registered with the registry.
     */
    private entries: ConverterRegistryEntry[] = [];
  
    /**
     * Returns all entries capable of performing the specified conversion.
     */
    public async inspect(payload: Payload): Promise<ConverterInspection[]> {

        if (!payload) {
            throw new Error("payload must be specified");
        }

        const options: ConverterInspection[] = [];

        for(const entry of this.entries) {
            const o = await entry.converter.inspect(payload);
            if (Array.isArray(o)) {
                options.push(...o);
            }
        }

        return options;
    }

    /**
     * Adds an input entry to the registry.
     */
    public register(entry: ConverterRegistryEntry): void {

        if (!entry) {
            throw new Error("entry must be specified");
        }

        if (!entry.converter) {
            throw new Error("entry.converter must be specified");
        }

        if (this.entries.includes(entry)) {
            throw new Error("entry is already registered")
        }

        this.entries.push(entry);
    }

    /**
     * Removes an input entry from the registry.
     */
    public unregister(entry: ConverterRegistryEntry): void {

        if (!entry) {
            throw new Error("entry must be specified");
        }

        const index = this.entries.indexOf(entry);
        if (index === -1) {
            throw new Error("entry not registered");
        }
        else {
            this.entries.splice(index, 1);
        }
    }
}