import React from "react"
import { ReactInputProps } from "./ReactInputProps"
import { ReactInputRegistry } from "./ReactInputRegistry"
import { Schema } from "../schema/Schema"
import { SchemaRegistry } from "../schema-registry/SchemaRegistry"

export class ReactInputMatcher {

    private inputs: ReactInputRegistry;
    private schemas: SchemaRegistry;

    constructor(inputs: ReactInputRegistry, schemas: SchemaRegistry) {
        this.inputs = inputs;
        this.schemas = schemas;
    }

    /**
     * Returns an input component capable of editing a value of the specified schema.
     */
    public resolve(schema: Schema): [Schema, React.FC<ReactInputProps> | undefined] {

        // Dereference the schema
        const deref = this.schemas.deref(schema);

        // Get a matching input control
        const fc = this.inputs.match(deref);

        return [
            deref,
            fc
        ]
    }
}