import { Payload } from "../payload/Payload";
import { PollerCreator } from "../poller-creator/PollerCreator";
import { RssFeed } from "../rss-feed/RssFeed"
import { RssFeedPoller } from "./RssFeedPoller"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export class RssFeedPollerCreator implements PollerCreator<RssFeed> {

    /**
     * Creates an RSS poller object from the specified parameters.
     */
    public async create(params: Payload<RssFeed>): Promise<RssFeedPoller | undefined> {

        if (params?.schema !== RssFeedSchemaId) {
            return undefined;
        }

        const feed = params.data;
        if (!feed) {
            return undefined;
        }

        return new RssFeedPoller(feed);
    }
}