import { DateNumberSchemaId } from "./DateNumberSchemaId"
import { Schema } from "../schema/Schema"

export const DateNumberSchema: Schema = {

    "$id": DateNumberSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    /**
     * The display description of a date number.
     */
    description: "Date specified as milliseconds from the Unix Epoch",

    /**
     * The primitive date type of the value.
     */
    type: "integer",
    
    /**
     * The display title of the date number.
     */
    title: "Date Number"
}