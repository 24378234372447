import React  from "react"
//import { Note } from "../note/Note"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./NoteEmblemView.css"

export const NoteEmblemView: React.FC<ReactViewProps> = (props) => {

    //const note = props?.model?.payload?.data as Note;

    return (
        <div className="note-emblem-view">
            <div className="note-emblem-view-note note-yellow"></div>
        </div>
    );
};