import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"

export abstract class RegExIndexer implements Indexer {

    private regex: RegExp;

    constructor(regex: RegExp) {
        if (!(this.regex = regex)) {
            throw new Error("regular expression must be specified")
        }
    }

    public async index(payload: Payload | undefined): Promise<Payload<any>[]> {

        let title = payload?.title;
        if (!title) {
            return [];
        }

        const indexes: Payload[] = [];
 
        let matches = title.matchAll(this.regex);

        for(let match of matches) {

            const indexedPayload = this.onmatch(match);
            if (indexedPayload) {
                indexes.push(indexedPayload);
            }
        }

        return indexes;
         
    }

    protected abstract onmatch(value: RegExpMatchArray): Payload | undefined;
}