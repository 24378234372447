import React  from "react"
import { BingWallpaper } from "../bing-wallpaper/BingWallpaper"
import { IonCard, IonCardHeader, IonCardTitle } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const BingWallpaperDetailView: React.FC<ReactViewProps> = (props) => {

    // Get the raw bing daily image data
    const hpimage: BingWallpaper = props.model?.payload?.data;
    if (!hpimage) {
        return <></>
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{hpimage.title}</IonCardTitle>
            </IonCardHeader>
        </IonCard>
    )
};