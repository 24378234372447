import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Schema } from "../schema/Schema"
import { SchemaSchemaId } from "../schema/SchemaSchemaId"

export class SchemaAnnotator implements Annotator<Schema> {

    public async annotate(payload: Payload<Schema>): Promise<void> {
        if (payload?.data && (payload.schema === SchemaSchemaId)) {
            payload.title = payload.data.title ?? payload.title;
        }
    }
}