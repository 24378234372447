import { CreatePluginKey } from "../create/CreatePluginKey"
import { CreateSchemaId } from "../create/CreateSchemaId"
import { CreateStreamView } from "./CreateStreamView"
import { EditPluginKey } from "../edit/EditPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const CreateStreamViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "create-stream-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CreatePluginKey,
        EditPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "CreateStreamView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: CreateSchemaId,
                layout: "stream",
                fc: CreateStreamView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Create Stream View"
}