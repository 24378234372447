import { Annotator } from "../annotator/Annotator"
import { Next } from "../next/Next"
import { NextSchemaId } from "../next/NextSchemaId"
import { Payload } from "../payload/Payload"

/**
 * The default title of a Next payload.
 */
const DEFAULT_TITLE = "Next";

/**
 * Annotates a Next payload with a default title.
 */
export class NextAnnotator implements Annotator<Next> {
    public async annotate(payload: Payload<Next>): Promise<void> {
        if (payload?.schema === NextSchemaId) {
            payload.title = payload.title ?? DEFAULT_TITLE;
        }
    }
}