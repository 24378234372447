import { Command } from "./Command"
import { Commander } from "./Commander"
import { compareCommands } from "./compareCommands"
import { Model } from "../state/Model"

/**
 * A set of combined commanders. A commander examines a payload and 
 * generates commands that can be executed by the user.
 */
export class CommanderChain implements Commander {

    /** The commands added to the chain */
    private commanders: Commander[];

    constructor(commanders: Commander[]) {
        if (!Array.isArray(commanders)) {
            throw new Error("commanders must be an array");
        }

        this.commanders = commanders;
    }

    /**
     * Adds a command.
     */
    public add(commander: Commander) {
        this.commanders.push(commander);
    }

    /**
     * Returns all commands issued for the specified payload.
     */
    public async issue(model: Model | undefined, layout?: string): Promise<Command[]> {

        // Get all of the commanders as an array
        const commanders = [...this.commanders];
        const results: Command[] = [];
       
        for(let commander of commanders) {
            const cmds = await commander.issue(model);
            for(let cmd of cmds) {
                if (!layout || cmd.layout === layout) {
                    results.push(cmd);
                }
            }
        }

        return results.sort((a,b) => compareCommands(a, b));
    }

    /**
     * Removes a command.
     */
    public remove(commander: Commander) {
        const index = this.commanders.indexOf(commander);
        if (index !== undefined) {
            this.commanders.splice(index, 1);
        }
    }
}