import { BingMapStyle } from "./BingMapStyle"
import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId";

export class BingMapIndexer implements Indexer {

    public async index(payload: Payload | undefined): Promise<Payload[]> {

        const location = payload?.location;
        if (!location) {
            return [];
        }

        // Define the map styles that will be returned
        const variations: { style: BingMapStyle, caption: string}[] = [
            {
                style: BingMapStyle.AerialWithLabels,
                caption: "aerial"
            },
            {
                style: BingMapStyle.Road,
                caption: "road"
            }
        ];

        const payloads: Payload[] = [];

        for(let variation of variations) {

            const url = this.mapUrl(
                location.latitude,
                location.longitude,
                variation.style);

            const urlPayload: Payload = {
                schema: UrlSchemaId,
                key: url,
                data: url,
                title: `Bing Map (${variation.caption})`
            }

            payloads.push(urlPayload);
        }
            
        return payloads;
    }    

    public mapUrl(latitude: number, longitude: number, style: BingMapStyle): string {

        // Query string parameters:
        //
        // cp = Center Point specified as latitude and longitude decimal degrees separated with ~.
        // style = The style of the map
        //
        // See https://docs.microsoft.com/en-us/bingmaps/articles/create-a-custom-map-url
    
        return `https://bing.com/maps/default.aspx?cp=${latitude}~${longitude}&style=${style}`;    
    }    
}