import { EarthquakeIndexer } from "./EarthquakeIndexer"
import { EarthquakePluginKey } from "../usgs-earthquake/EarthquakePluginKey"
import { EarthquakeSchemaId } from "../usgs-earthquake/EarthquakeSchemaId"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SearchStringPluginKey } from "../search-string/SearchStringPluginKey"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const UsgsEarthquakeIndexerManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: "usgs-earthquake-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        EarthquakePluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        SearchStringPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects provided by the plugin
     */
    entries: [
        {
            key: "EarthquakeIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "USGS Earthquake Indexer",
            value: {
                schema: EarthquakeSchemaId,
                indexer: new EarthquakeIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<EarthquakeIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "USGS Earthquake Indexer",
};
