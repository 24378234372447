import React, { useState }  from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonList,
} from "@ionic/react"

// HACK
import { runtime } from '../plugins';
import { Registration } from "../../runtime/Registration";

export const PluginsCardView: React.FC<ReactViewProps> = (props) => {

    const [plugins, setPlugins] = useState<Registration[]>(runtime.registrations());
 
    /**
     * Executed when the user clicks an install button.
     */
    function onInstall(key: string) {
        runtime.install(key);
        setPlugins(runtime.registrations());
    }

    function onUninstall(key: string) {
        runtime.uninstall(key);
        setPlugins(runtime.registrations());
    }

    if (!props.model?.payload) {
        return <></>
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Plugins</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonList>
                    {plugins.map(p => (
                        <IonItem key={p.manifest.key}>
                            <IonLabel>{p.manifest.key}</IonLabel>
                            {p.installed && 
                                <IonButton
                                    color="warning"
                                    onClick={() => onUninstall(p.manifest.key)}
                                    slot="end">uninstall</IonButton>
                            }
                            {!p.installed &&
                                <IonButton
                                    onClick={() => onInstall(p.manifest.key)}
                                    slot="end">install</IonButton>
                            }
                        </IonItem>
                    ))}
                </IonList>
            </IonCardContent>
        </IonCard>
    );
}