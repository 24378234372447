import { CreateCardView } from "./CreateCardView"
import { CreatePluginKey } from "../create/CreatePluginKey"
import { CreateSchemaId } from "../create/CreateSchemaId"
import { ListPluginKey } from "../list/ListPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const CreateCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "create-card",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CreatePluginKey,
        ListPluginKey,
        SchemaRegistryPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "CreateCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [SchemaRegistryContextKey],
            value: (context: ManifestContext) => ({
                match: CreateSchemaId,
                fc: (props: ReactViewProps) =>
                    CreateCardView({...props, schemas: context[SchemaRegistryContextKey]}),
                layout: "card",
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Create Card View"
}