import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { FavoritesCommander } from "./FavoritesCommander"
import { FavoritesCommanderPluginKey } from "./FavoritesCommanderPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const FavoritesCommanderManifest: Manifest = {

    /**
     * A string that uniquely identifies the plugin.
     */
    key: FavoritesCommanderPluginKey,

    /**
     * Indicates whether the plugin should be installed on first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderRegistryPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "FavoritesCommander",
            registerWith: CommanderArrayContextKey,
            value: new FavoritesCommander()
        } as ManifestEntry<FavoritesCommander>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Favorites Commander"
}