import { Manifest } from "../../runtime/Manifest"
import { NoticePluginKey } from "../notice/NoticePluginKey"
import { NotifierPluginKey } from "./NotifierPluginKey"

export const NotifierManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: NotifierPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NoticePluginKey,
    ],

    /**
     * The display name of the plugin.
     */
    title: "Notifier"
}