import React, { useEffect, useState } from "react"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Convert } from "../convert/Convert"
import { Converter } from "../converter/Converter"
import { ConverterInspection } from "../converter/ConverterInspection"
import { List } from "../list/List"
import { ListSchemaId } from "../list/ListSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Schema } from "../schema/Schema"
import { SchemaRegistry } from "../schema-registry/SchemaRegistry"
import { SchemaSchemaId } from "../schema/SchemaSchemaId"
import { Waiting } from "../waiting/Waiting"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export interface ConverterCardViewProps extends ReactViewProps {
    /**
     * The converter that inspects a payload and returns possible conversions.
     */
    converter: Converter,

    /**
     * The schema registry for looking up information about potential output schemas. 
     */
    schemas: SchemaRegistry
}

export const ConvertCardView: React.FC<ConverterCardViewProps> = (props) => {

    const convert = props?.model?.payload?.data as Convert;
    const converter = props.converter;
    const dispatch = props.dispatch;
    const payloadToConvert = convert?.payload;

    const [toSchema, setToSchema] = useState(convert.toSchema);
    const [inspections, setInspections] = useState<ConverterInspection[]>();
    const [converted, setConverted] = useState<Payload>();
    const [confirmed, setConfirmed] = useState(convert.confirmed ?? false);

    /**
     * Use an effect to inspect the payload and determine possible conversions.
     */
    useEffect(() => {

        let mounted = true;
        if (!toSchema && !Array.isArray(inspections)) {

            // An output schema has not been specified and the user must
            // be prompted. Ensure a list of potential converions is loaded
            if (converter && payloadToConvert) {
                converter.inspect(payloadToConvert).then(i => {
                    if (mounted) {
                        setInspections(i)
                    }
                });
            }    
        }

        return () => { mounted = false };
    }, [converter, inspections, payloadToConvert, toSchema]);

    /**
     * Use an effect to perform the conversion. Note that conversion is performed
     * after a schema is specified and before confirming with the user. This is OK
     * because the conversion does not impact the existing payload and allows the
     * view to show a preview to the person.
     */
    useEffect(() => {

        let mounted = true;
        if (!converted && inspections && payloadToConvert && toSchema) {
            const inspection = inspections.find(i => i.output === toSchema)!;
            inspection.convert().then(p => {
                if (mounted) {
                    setConverted(p);
                }
            });
        }

        return () => { mounted = false };
    }, [converted, inspections, payloadToConvert, toSchema])

    /**
     * Use an effect to dispatch the converted value.
     */
    useEffect(() => {
        if (confirmed && converted && dispatch) {
            dispatch(converted);
        }
    });

    /**
     * Returns a view to indicate that conversion is underway...
     */
    function Converting() {
        return (
            <ReactViewFactory
                dispatch={undefined}
                layout="card"
                matcher={props.matcher}
                model={{
                    payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Converting..."
                        }
                    } as Payload<Waiting>
                }}>
            </ReactViewFactory>
        );
    }

    /**
     * Returns a view for indicating the convert card is initializing.
     */
    function Initializing() {
        return (
            <ReactViewFactory
                dispatch={undefined}
                layout="card"
                matcher={props.matcher}
                model={{
                    payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Initializing..."
                        }
                    } as Payload<Waiting>
                }}>
            </ReactViewFactory>
        );
    }

    /**
     * Returns a view indicating that the payload to convert is missing.
     */
    function MissingPayload() {
        return (
            <ReactViewFailure>
                The Convert object did not specify a payload to convert.
            </ReactViewFailure>
        )
    }

    function MissingSchemas() {
        return (
            <ReactViewFailure>
                The schemas prop was not specified.
            </ReactViewFailure>
        );
    }

    /**
     * Previews the conversion and prompts the user to confirm.
     */
    function PromptConfirmation() {

        // Construct a confirmation payload to prompt the user.
        const confirmPayload: Payload<Confirm> = {
            schema: ConfirmSchemaId,
            data: {
                title: "Convert OK?",
                payload: converted
            }
        }

        // Called when the person confirms or declines
        function onConfirm(p: Payload) {
            if (p.data === true) {
                setConfirmed(true);
            }
            else {

                // Reset the state to prompt the user to select the
                // target schema and then re-confirm.
                setConfirmed(false);
                setConverted(undefined);
                setToSchema(undefined);
            }
        }

        return (
            <ReactViewFactory
                dispatch={p => onConfirm(p)}
                layout="card"
                matcher={props.matcher}
                model={{ payload: confirmPayload }}>
            </ReactViewFactory>
        );
    }

    /**
     * Returns a view for selecting the output schema of the conversion.
     */
    function SelectOutput() {

        // Build an array of schema payloads for selection 
        const schemaPayloads: Payload<Schema>[] = [];
        for(const inspection of inspections ?? []) {
            const schema = props.schemas.get(inspection.output);
            if (schema) {
                schemaPayloads.push({
                    schema: SchemaSchemaId, // TODO: handle schema standards
                    data: schema,
                    title: schema.title
                });
            }
        }

        // Build a List payload for presenting the list of schemas
        const listPayload: Payload<List> = {
            schema: ListSchemaId,
            data: {
                items: schemaPayloads
            }
        }

        // Called by the List view when a schema is selected
        function onSelect(p: Payload) {
            if (p?.schema === SchemaSchemaId) {
                setToSchema(p.data.$id);
            }
        }

        return (
            <ReactViewFactory
                dispatch={p => onSelect(p)}
                layout="card"
                matcher={props.matcher}
                model={{ payload: listPayload }}>
            </ReactViewFactory>
        );
    }

    /**
     * Returns the view based on the current state.
     */
    function SelectView() {

        if (!props.schemas) {
            return <MissingSchemas />
        }

        if (!payloadToConvert) {
            return <MissingPayload />
        }

        if (!toSchema) {
            if (Array.isArray(inspections)) {
                return <SelectOutput />
            }
            else {
                return <Initializing />
            }
        }

        if (!converted) {
            return <Converting />
        }

        if (!confirmed) {
            return <PromptConfirmation />
        }

        return <></>
    }

    return <SelectView/>
}
