import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { DateNumberAnnotator } from "./DateNumberAnnotator"
import { DateNumberAnnotatorContextKey } from "./DateNumberAnnotatorContextKey"
import { DateNumberPluginKey } from "../date-number/DateNumberPluginKey"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const DateNumberAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "date-number-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        DateNumberPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: DateNumberAnnotatorContextKey,
            value: new DateNumberAnnotator()
        } as ManifestEntry<DateNumberAnnotator>,

        // registration
        {
            key: "DateNumberAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [DateNumberAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: DateNumberSchemaId,
                annotator: context[DateNumberAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<DateNumberAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Date Number Annotator"
}
