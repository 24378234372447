import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityArrayContextKey } from "../activity-registry/ActivityArrayContextKey"
import { ActivityRegistryPluginKey } from "../activity-registry/ActivityRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Activity } from "../activity/Activity"
import { DefaultActivities } from "./DefaultActivities"

export const DefaultActivitiesManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "default-activities",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        ActivityRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DefaultActivities",
            registerWith: ActivityArrayContextKey,
            value: DefaultActivities
        } as ManifestEntry<Activity[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Default Activities"
}