import { CancelTaskPluginKey } from "./CancelTaskPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { TaskPluginKey } from "../task/TaskPluginKey"

export const CancelTaskManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: CancelTaskPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        TaskPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Cancel Task"
}