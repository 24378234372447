import { AddTagsCardView } from "./AddTagsCardView"
import { AddTagsPluginKey } from "../add-tags/AddTagsPluginKey"
import { AddTagsSchemaId } from "../add-tags/AddTagsSchemaId"
import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SavePluginKey } from "../save/SavePluginKey"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"

export const AddTagsCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "add-tags-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AddTagsPluginKey,
        BooleanPluginKey,
        ConfirmPluginKey,
        ReactViewPluginKey,
        SavePluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "AddTagsCardView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: AddTagsSchemaId,
                layout: "stream",
                fc: AddTagsCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Add Tags Card View"
}