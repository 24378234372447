import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"
import { Predicate } from "./Predicate"
import { PredicateArrayContextKey } from "./PredicateArrayContextKey"
import { PredicatePluginKey } from "./PredicatePluginKey"

export const PredicateManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: PredicatePluginKey,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PayloadPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: PredicateArrayContextKey,
            value: []
        } as ManifestEntry<Predicate[]>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Predicate"
}