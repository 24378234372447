import { Annotator } from "../annotator/Annotator"
import { NasaApodFeed } from "../nasa-apod-feed/NasaApodFeed"
import { NasaApodFeedSchemaId } from "../nasa-apod-feed/NasaApodFeedSchemaId"

import { Payload } from "../payload/Payload"

export class NasaApodFeedAnnotator implements Annotator<NasaApodFeed> {
    public async annotate(payload: Payload<NasaApodFeed>): Promise<void> {

        if (payload?.schema !== NasaApodFeedSchemaId) {
            return;
        }

        payload.title = payload.title ?? "NASA Astronomy Picture of the Day";
    }
}