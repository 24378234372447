import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { BillAnnotator } from "./BillAnnotator"
import { BillAnnotatorContextKey } from "./BillAnnotatorContextKey"
import { BillPluginKey } from "../bill/BillPluginKey"
import { BillSchemaId } from "../bill/BillSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ManifestContext } from "../../runtime/ManifestContext"

export const BillAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bill-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        BillPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: BillAnnotatorContextKey,
            title: "Bill Annotator",
            value: new BillAnnotator()
        } as ManifestEntry<BillAnnotator>,

        // registration
        {
            key: "BillAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [BillAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: BillSchemaId,
                annotator: context[BillAnnotatorContextKey]                
            })
        } as ManifestEntry<AnnotatorRegistryEntry<BillAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bill Annotator"
}