import { Formatter } from "../formatter/Formatter"
import { LatitudeSchemaId } from "../latitude/LatitudeSchemaId";

export class LatitudeFormatter implements Formatter {

    public format(schemaId: string, latitude: any): string | undefined {

        // References:
        //   -  https://en.wikipedia.org/wiki/Minute_and_second_of_arc

        if (schemaId !== LatitudeSchemaId) {
            return undefined;
        }
        
        if (typeof(latitude) !== "number") {
            return undefined;
        }

        let value: number;
        let direction: string;

        if (latitude < 0) {
            value = latitude * -1;
            direction = "S";
        }
        else {
            value = latitude;
            direction = "N";
        }

        // Calculate degrees and subtract from value
        const degrees = Math.floor(value);
        value -= degrees;

        // Calculate minutes and subtract from value
        const minutes = Math.floor(value * 60.0);
        value -= minutes / 60.0;

        // Calculate seconds
        const seconds = (value * 60.0 * 60.0).toFixed(1);
        
        return `${degrees}° ${minutes}′  ${seconds}″ ${direction}`;
    }
}