import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"
import { RootSchemaId } from "../root/RootSchemaId"
import { ShortcutArrayContextKey } from "./ShortcutArrayContextKey"
import { ShortcutRegistryPluginKey } from "./ShortcutRegistryPluginKey"
import { TagSchemaId } from "../tag/TagSchemaId"

export const ShortcutRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ShortcutRegistryPluginKey,

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ShortcutArrayContextKey,
            title: "Shortcut Array",
            value: [
                // HACK while folders are removed
                {
                    schema: TagSchemaId,
                    key: "Favorites",
                    data: "#favorite",
                    title: "Favorites"
                },
                {
                    schema: RootSchemaId,
                    key: "Space",
                    data: {
                    },
                    title: "Space"
                },
            ]
        } as ManifestEntry<Payload[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Shortcut Registry"
}