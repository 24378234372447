import { Commander } from "../commander/Commander"
import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { DeferCommander } from "./DeferCommander"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const DeferCommanderManifest: Manifest = {

    /**
     * The key that uniquely describes the plugin.
     */
    key: "defer-commander",

    /**
     * Indicates whether the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey,
        CommanderRegistryPluginKey,
    ],

    /**
     * The display description of the plugin.
     */
    description: "A command for deferring a payload to a later time",

    /**
     * Returns the objects supplied by the plugin.
     */
    entries: [
        {
            key: "DeferCommander",
            registerWith: CommanderArrayContextKey,
            title: "Defer Commander",
            value: new DeferCommander()
        } as ManifestEntry<Commander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Defer Commander",
}