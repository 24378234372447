import { CycleSelector } from "../selector/CycleSelector"
import { introSequence } from "./introSequence"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Sequence } from "../sequence/Sequence"
import { SequenceInstance } from "../sequence/SequenceInstance"
import { SequenceMapContextKey } from "../sequence-map/SequenceMapContextKey"
import { SequenceMapPluginKey } from "../sequence-map/SequenceMapPluginKey"
import { SequencePluginKey } from "../sequence/SequencePluginKey"
import { SequenceStreamer } from "../sequence-streamer/SequenceStreamer"
import { SequenceStreamerPluginKey } from "../sequence-streamer/SequenceStreamerPluginKey"
import { Streamer } from "../streamer/Streamer"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"

export const IntroSequenceManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "intro-sequence",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SequencePluginKey,
        SequenceMapPluginKey,
        SequenceStreamerPluginKey,

        // HACK
        StreamerRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // sequence
        {
            key: "IntroSequence",
            registerWith: SequenceMapContextKey,
            registerMapKey: "intro",
            value: introSequence,
        } as ManifestEntry<Sequence>,

        // HACK: need to auto-load instead of creating directly
        {
            key: "IntroStreamer",
            requires: ["IntroSequence"],
            value: (context: ManifestContext) => {
                const sequence = context["IntroSequence"];
                const selector = new CycleSelector(false);
                const instance = new SequenceInstance(sequence, selector);
                return new SequenceStreamer(instance)
            }
        } as ManifestEntry<Streamer>,

        // registration
        {
            key: "IntroStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: ["IntroStreamer"],
            value: (context: ManifestContext) => ({
                streamer: context["IntroStreamer"]
            })
        } as ManifestEntry<StreamerRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Intro Sequence"
}