import { isToday } from "../../library/isToday"
import { Model } from "../state/Model"
import { Notice } from "../notice/Notice"
import { Notifier } from "../notifier/Notifier"
import { Task } from "../task/Task"
import { TaskChange } from "../task/TaskChange"
import { TaskSchemaId } from "../task/TaskSchemaId"
import { TaskStatus } from "../task/TaskStatus"

export class TaskStatusNotifier implements Notifier {

    public async notify(model: Model): Promise<Notice[]> {

        // Skip non-tasks
        const payload = model.payload;
        if(payload?.schema !== TaskSchemaId) {
            return [];
        }

        // Skip missing tasks
        const task = payload.data as Task;
        if (!task) {
            return [];
        }

        const statusNotice = this.notifyToday(task);
        if (statusNotice) {
            return [statusNotice];
        }
        else {
            return [];
        }
    }

    /**
     * Generates a notice for a task completed today.
     */
    private notifyToday(task: Task): Notice | undefined {

        if (!task || !task.history) {
            return undefined;
        }

        const changesToday: TaskChange[] = [];
        let lastStatus: TaskStatus | undefined = undefined;

        // Loop through the history, starting at the most recent entry at end of array
        for(let index = task.history.length - 1; index >= 0; index--) {

            const change = task.history[index];
            if(isToday(change.changedOn)) {

                // Skip non-status changes, e.g., other history records
                if (change.status === undefined) {
                    continue;
                }

                if (lastStatus === undefined) {
                    lastStatus = change.status;
                }

                // If the same day history contains a status change,
                // stop looking. The notice only contains info about
                // the most recent status change, e.g., # of times
                // reopened or completed today.
                if (lastStatus !== change.status) {
                    break;
                }

                changesToday.push(change);
            }
            else {
                // No more change made today
                break;
           }
        }

        if (changesToday.length === 0) {
            return undefined;
        }

        // Define the verb for the status
        let color: string | undefined = undefined;
        let verb: string = "";

        switch(lastStatus) {
            case TaskStatus.Canceled:
                color = "warning";
                verb = "Canceled";
                break;

            case TaskStatus.Completed:
                color = "success";
                verb = "Completed";
                break;

            case TaskStatus.Open:
                color = "primary";
                verb = "Opened";
                break;
        }

        // Format the status date
        switch(changesToday.length) {
            case 1:
                const datestr = new Date(changesToday[0].changedOn).toLocaleTimeString();
                return {
                    title: `${verb} today at ${datestr}`,
                    color: color ?? ""
                }

            default:
                return {
                    title: `${verb} ${changesToday.length} times today`,
                    color: color ?? ""
                }
        }
    }
}