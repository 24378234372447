import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryEntry } from "../formatter-registry/FormatterRegistryEntry"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { LongitudeFormatter } from "./LongitudeFormatter"
import { LongitudeFormatterContextKey } from "./LongitudeFormatterContextKey"
import { LongitudeFormatterPluginKey } from "./LongitudeFormatterPluginKey"
import { LongitudePluginKey } from "../longitude/LongitudePluginKey"
import { LongitudeSchemaId } from "../longitude/LongitudeSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const LongitudeFormatterManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: LongitudeFormatterPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FormatterPluginKey,
        FormatterRegistryPluginKey,
        LongitudePluginKey,
    ],

    entries: [

        // formatter
        {
            key: LongitudeFormatterContextKey,
            value: new LongitudeFormatter()
        } as ManifestEntry<LongitudeFormatter>,

        // registration
        {
            key: "LongitudeFormatterRegistryEntry",
            registerWith: FormatterRegistryContextKey,
            requires: [LongitudeFormatterContextKey],
            value: (context: ManifestContext) => ({
                schemaId: LongitudeSchemaId,
                formatter: context[LongitudeFormatterContextKey]
            })
        } as ManifestEntry<FormatterRegistryEntry<LongitudeFormatter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Longitude Formatter"
}