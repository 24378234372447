import React, { useState } from "react"
import { MathProblem } from "../math-problem/MathProblem"
import { NumberSchemaId } from "../number/NumberSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { solve } from "../math-problem/solve"
import "./MathProblemCardView.css"

export const MathProblemCardView: React.FC<ReactViewProps<MathProblem>> = (props) => {
  
    const [shown, setShown] = useState(false);

    const problem: MathProblem = props?.model?.payload?.data;
    if (!problem) {
        return <ReactViewFailure>Math problem missing</ReactViewFailure>;
    }
  
    function onClick() {
        if (shown) {
            const p: Payload<number> ={
                schema: NumberSchemaId,
                data: solve(problem),
            }
            if (props.dispatch) {
                props.dispatch(p);
            }
        }
        else {
            setShown(prev => !prev);
        }
    }

    return (
        <div className="MathProblem">
            <div className="problem">
                <div className="operand">{problem.operands[0]}</div>
                <div className="operator">{problem.operator}</div>
                <div className="operand">{problem.operands[1]}</div>
                <div className="equalSign">=</div>
            </div>
            <button className="answer" aria-pressed={shown} onClick={(e) => onClick()}>
                <div className="value">{shown ? solve(problem) : 'answer'}</div>
            </button>
        </div>
    );
};
