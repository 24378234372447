import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload";
import { TagSchemaId } from "../tag/TagSchemaId";

export class TagIndexer implements Indexer {

    public async index(payload: Payload): Promise<Payload[]> {
        if (Array.isArray(payload.tags)) {
            return payload.tags.map(t => (
                {
                    schema: TagSchemaId,
                    data: t,
                    title: t,
                    key: t
                }
            ))
        }
        else {
            return [];
        }
    }
}