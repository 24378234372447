import { GoogleMapIndexer } from "./GoogleMapIndexer"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const GoogleMapIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "google-map-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        UrlPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Show a link to Google Maps when viewing a location.",

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "GoogleMapIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "Google Map Indexer",
            value: {
                schema: "*",
                indexer: new GoogleMapIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<GoogleMapIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Google Map Link",
};
