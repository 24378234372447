import React, { useEffect, useState } from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonItem,
    IonText,
    IonLabel 
} from "@ionic/react"
import { openUrl } from "./openUrl"
import { parseHostname } from "../../library/parseHostname"
import { UrlButton } from "./UrlButton"
import { Whitelist } from "../url/Whitelist"

export const UrlCardView: React.FC<ReactViewProps> = (props) => {

    // Get the URL and hostname
    const url = props?.model?.payload?.data;
    const hostname = parseHostname(url);

    // Load the whitelist of auto-opened URLs
    const whitelist = new Whitelist();
    const whitelisted = whitelist.checkUrl(url);

    // Track whether the whitelist checkbox has been checked
    const [checked, setChecked] = useState(whitelist.checkUrl(url));

    // Track whether this is the first render or subsequent re-render
    const [rerender, setRerender] = useState(false);

    function onWhitelist(checked: boolean) {
        if (hostname) {
            if(checked) {
                whitelist.add(hostname);
            }
            else {
                whitelist.remove(hostname);
            }    
        }
        setRerender(true);
        setChecked(checked);
    }

    useEffect(() => {
        // If first time rendering, and the URL is whitelisted, auto-open it.
        if (!rerender) {
            if (whitelisted) {
                openUrl(url);
            }    
        }
    }, [url, rerender, whitelisted]); 

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle className="selectable">{props.model?.payload?.data}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem>
                    <IonCheckbox checked={checked} onIonChange={e => onWhitelist(e.detail.checked)}></IonCheckbox>&nbsp;
                    <IonLabel>Open <IonText color="warning">{hostname}</IonText> links automatically next time</IonLabel>
                </IonItem>
                <UrlButton url={url}/>
            </IonCardContent>
        </IonCard>
    );
}
