import React from "react"
import { BooleanSchemaId } from "../boolean/BooleanSchemaId"
import { Confirm } from "../confirm/Confirm"
import { Payload } from "../payload/Payload"
import { Prompt } from "../prompt/Prompt"
import { PromptSchemaId } from "../prompt/PromptSchemaId"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

/**
 * The default title of the confirmation prompt.
 */
const DEFAULT_TITLE = "Confirm";

/**
 * The default button label to cancel the operation.
 */
const DEFAULT_CANCEL_TITLE = "Cancel";

/**
 * The default button label to confirm the operation.
 */
const DEFAULT_CONFIRM_LABEL = "Confirm";

/**
 * A view that prompts the user to confirm or cancel.
 */
export const ConfirmCardView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    const confirm = payload?.data as Confirm;

    /**
     * Builds a Prompt object for asking the user to confirm the task completion.
     */
     function makePrompt(): Prompt {
        return {
            destructive: confirm.destructive,
            payload: confirm.payload,
            title: confirm.title ?? DEFAULT_TITLE,
            options: [
                {
                    schema: BooleanSchemaId,
                    data: false,
                    key: "Cancel",
                    title: DEFAULT_CANCEL_TITLE
                },
                {
                    schema: BooleanSchemaId,
                    data: true,
                    key: "Confirm",
                    title: DEFAULT_CONFIRM_LABEL
                }
            ]
        }
    }

    /**
     * Executed when the user confirms or declines.
     */
    function onSelect(selectedPayload: Payload) {
        if (props.dispatch) {
            props.dispatch(selectedPayload);
        }
    }

    return (
        <ReactViewFactory
            layout="stream"
            dispatch={p => onSelect(p)}
            matcher={props.matcher}
            model={{ payload: {
                schema: PromptSchemaId,
                data: makePrompt()
            }}}>
        </ReactViewFactory>        
    )
}