import React from "react"
import { IonToggle } from "@ionic/react"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"

export const BooleanReactInput: React.FC<ReactInputProps> = (props) => {

    const schema = props?.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema missing or not an object
            </ReactInputFailure>
        )
    }

    if (schema.type !== "boolean") {
        return (
            <ReactInputFailure>
                Schema type is unsupported: {schema.type}
            </ReactInputFailure>
        )
    }

    // TODO: check specification for defaults and boolean behavior
    const checked = props.value ?? false;

    function onChange(value: boolean) {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    return (
        <IonToggle checked={checked} onIonChange={(e) => onChange(e.detail.checked)}></IonToggle>
    )
}