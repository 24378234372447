import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { ISO4217CodeAnnotator } from "./ISO4217CodeAnnotator"
import { ISO4217CodeAnnotatorContextKey } from "./ISO4217CodeAnnotatorContextKey"
import { ISO4217CodePluginKey } from "../iso-4217-code/ISO4217CodePluginKey"
import { ISO4217CodeSchemaId } from "../iso-4217-code/ISO4217CodeSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const Iso4217CodeAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "iso-4217-code-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        ISO4217CodePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: ISO4217CodeAnnotatorContextKey,
            value: new ISO4217CodeAnnotator()
        } as ManifestEntry<ISO4217CodeAnnotator>,

        // registration
        {
            key: "ISO4217CodeAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [ISO4217CodeAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: ISO4217CodeSchemaId,
                annotator: context[ISO4217CodeAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<ISO4217CodeAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "ISO 4217 Code Annotator"
}
