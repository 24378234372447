import React from "react"
import { Convert } from "../convert/Convert"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"

export const ConvertStreamView: React.FC<ReactViewProps> = (props) => {

    const convert = props?.model?.payload?.data as Convert;

    /**
     * Called by the Convert card view when conversion is finished.
     * The converted payload has not been saved yet. This function will
     * mark the payload with the same parent and dispatch a Save operation.
     */
    function onConvert(payload: Payload) {

        if(payload) {

            // Set the same location (parent) as the original payload.
            payload.parentId = convert.payload.parentId;

            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload
                }
            }

            if (props.dispatch) {
                props.dispatch(savePayload);
            }
        }
    }

    return (
        <ReactViewFactory
            layout="card"
            dispatch={p => onConvert(p)}
            matcher={props.matcher}
            model={props.model}>
        </ReactViewFactory>
    );
}