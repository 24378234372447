import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactInputMatcher } from "./ReactInputMatcher"
import { ReactInputMatcherContextKey } from "./ReactInputMatcherContextKey"
import { ReactInputPluginKey } from "./ReactInputPluginKey"
import { ReactInputRegistry } from "./ReactInputRegistry"
import { ReactInputRegistryContextKey } from "./ReactInputRegistryContextKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const ReactInputManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ReactInputPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // registry
        {
            key: ReactInputRegistryContextKey,
            value: new ReactInputRegistry()
        } as ManifestEntry<ReactInputRegistry>,

        // matcher
        {
            key: ReactInputMatcherContextKey,
            requires: [
                ReactInputRegistryContextKey,
                SchemaRegistryContextKey
            ],
            value: (context: ManifestContext) =>
                new ReactInputMatcher(
                    context[ReactInputRegistryContextKey],
                    context[SchemaRegistryContextKey])
        } as ManifestEntry<ReactInputMatcher>
    ],

    /**
     * The display title of the plugin.
     */
    title: "React Input"
}