import { CycleSelector } from "../selector/CycleSelector"
import { Payload } from "../payload/Payload"
import { Quote } from "../quote/Quote"
import { QuoteSchemaId } from "../quote/QuoteSchemaId"
import { Selector } from "../selector/Selector"
import { Streamer } from "../streamer/Streamer"

export class QuoteStreamer implements Streamer {

    /**
     * The minimum time interval between quotes in milliseconds.
     */
    private interval: number;

    /**
     * The last time a quote was served in milliseconds from the Unix epoch.
     */
    private lastTime: number | undefined;

    /**
     * The quotes supplied by the streamer.
     */
    private quotes: Quote[];

    /**
     * The selector used to select a quote.
     */
    private selector: Selector<Quote>;

    /**
     * The state of the selector. TODO: make persisted state
     */
    private selectorState = {};

    /**
     * Initializes a new instance of the quote streamer
     */
    constructor(quotes: Quote[], interval: number = 0, selector?: Selector) {
        this.quotes = quotes ?? [];
        this.interval = interval;
        this.selector = selector ?? new CycleSelector(true);
    }

    /**
     * Returns the next quote.
     */
    public async next(): Promise<Payload<Quote> | undefined> {

        if (this.quotes.length === 0) {
            return undefined;
        }
        
        // See if a minimum time requirement is configured.
        if (this.interval > 0) {
            if (this.lastTime && this.lastTime > 0) {
                let elapsed = Date.now() - this.lastTime;
                if (elapsed < this.interval) {
                    return undefined;
                }
            }
        }

        const quote = await this.selector.select(this.quotes, this.selectorState);
        if (quote === undefined) {
            return undefined;
        }
        else {
            this.lastTime = Date.now();
            return {
                schema: QuoteSchemaId,
                data: quote
            }
        }
    }
}