import React from "react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { StringSchemaId } from "../string/StringSchemaId"

export const WaitingCardView: React.FC<ReactViewProps> = (props) => {

    const title = props?.model?.payload?.data?.title ?? "Waiting...";

    return (
        <ReactViewFactory
            dispatch={props.dispatch}
            layout="card"
            matcher={props.matcher}
            model={{
                payload: {
                    schema: StringSchemaId,
                    data: title
                }
            }}>
        </ReactViewFactory>
    )
}