import { Config } from "./Config"

/**
 * Fetches JSON data from a service.
 */
export async function fetchJson<T>(url: string, requiresCors: boolean = true): Promise<T | undefined> {

    let fullurl = url;
    if (!fullurl) {
        return undefined;
    }

    if (requiresCors) {
        fullurl = `${Config.DEFAULT_CORS_PROXY}${url}`;
    }

    console.info(`fetchJson(${fullurl})`);
 
    let options = {
        headers: {
            Origin: Config.DEFAULT_ORIGIN
        }
    };

    return fetch(fullurl, options)
        .then(response => response ? response.json() : undefined)
}