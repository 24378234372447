import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Image } from "./Image"
import { IonCard, IonCardContent } from "@ionic/react"

export const ImageCard: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    if (!payload) {
        return <></>
    }

    let image: Image = payload.data;
    if (!image) {
        return (<></>);
    }

    return (
        <IonCard>
            <IonCardContent>
                <img src={image.url} alt={payload.title}/>
            </IonCardContent>
        </IonCard>
    );
}