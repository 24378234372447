import { MoveCardView } from "./MoveCardView"
import { MovePluginKey } from "../move/MovePluginKey"
import { MoveSchemaId } from "../move/MoveSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { TreePluginKey } from "../tree/TreePluginKey"

export const MoveCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the JSON viewer plugin.
     */
    key: "move-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        MovePluginKey,
        ReactViewPluginKey,
        TreePluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "MoveCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Move Card View",
            value: {
                layout: "card",
                match: MoveSchemaId,
                fc: MoveCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],    

    /**
     * The display title of the plugin.
     */
    title: "Move Card View"
};
