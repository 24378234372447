import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"
import { UrlPropertiesDetailView } from "./UrlPropertiesDetailView"
import { UrlSchemaId } from "../url/UrlSchemaId"

export const UrlPropertiesDetailViewManifest: Manifest = {

    /**
     * The key that uniquely identify the plugin.
     */
    key: "url-properties-detail-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "UrlPropertiesDetailView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: UrlSchemaId,
                layout: "detail",
                order: "first",
                fc: UrlPropertiesDetailView                
            }            
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "URL Properties Detail View"
}