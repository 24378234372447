import { ChildrenCardViewContextKey } from "../children-card-view/ChildrenCardViewContextKey"
import { ChildrenCardViewPluginKey } from "../children-card-view/ChildrenCardViewPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NewsFeedPluginKey } from "../newsapi-feed/NewsFeedPluginKey"
import { NewsFeedSchemaId } from "../newsapi-feed/NewsFeedSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const NewsApiFeedCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "newsapi-feed-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ChildrenCardViewPluginKey,
        NewsFeedPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "NewsApiFeedCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [ChildrenCardViewContextKey],
            value: (context: ManifestContext) => (
                { ...context[ChildrenCardViewContextKey], match: NewsFeedSchemaId}
            )
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "NewsAPI Feed Card View"
}