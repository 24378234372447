import { HighlighterPluginKey } from "./HighlighterPluginKey"
import { HighlighterSchema } from "./HighlighterSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Schema } from "../schema/Schema"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const HighlighterManifest: Manifest = {

    /**
     * The key the uniquely identifies the plugin.
     */
    key: HighlighterPluginKey,

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Enables highlighting of matched words",

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "HighlighterSchema",
            registerWith: SchemaRegistryContextKey,
            value: HighlighterSchema
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Highlighter"
};
