import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { JsonSchemaId } from "../json/JsonSchemaId"
import { Model } from "../state/Model"

const COMMAND_KEY = "viewJSON";

/**
 * Returns a model that does not have references to itself due to this command.
 */
function strip(model: Model): Model {

    const stripped = {...model};

    // Copy the commands array
    if (Array.isArray(stripped.commands)) {
        stripped.commands = [...stripped.commands];
    }
    else {
        return stripped;
    }

    for (let idx = 0; idx < stripped.commands.length; idx++) {

        const command = stripped.commands[idx];

        if (command?.key !== COMMAND_KEY) {
            continue;
        }

        if (command.value?.schema !== JsonSchemaId) {
            continue;
        }

        if (command.value.data !== model) {
            continue;
        }

        stripped.commands[idx] = {...command, value: {...command.value, data: undefined}};

    }

    return stripped;
}

export class JsonCommander implements Commander {


    public async issue(model: Model | undefined): Promise<Command[]> {

        if (!model) {
            return [];
        }

        return [
            {
                key: "viewJSON",
                layout: "extended",
                title: "JSON",
                value: {
                    schema: JsonSchemaId,

                    // HACK: avoid circular reference (doesn't get latest model though)
                    data: strip(model),
                    title: `JSON: ${model?.payload?.title}`
                }
            }
        ];
    }
}