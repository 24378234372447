import React from "react"
import { Definition } from "../definition/Definition"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./DefinitionCardView.css"

export const DefinitionCardView: React.FC<ReactViewProps> = (props) => {

    const def = props?.model?.payload?.data as Definition;
    
    return (
        <dl className="definition-card-view">
            <dt>{def.term}</dt>
            <dd>{def.meaning}</dd>
        </dl>
    );
}
