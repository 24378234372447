import { AnyAction } from "redux"
import { ControllerAction } from "./ControllerAction"
import { DefaultModel } from "../state/DefaultModel"
import { Model } from "../state/Model"

/**
 * A reducer for managing the state of the model.
 */
export function model(
        state: Model = DefaultModel, 
        action: AnyAction): Model {

    switch(action.type) {

        case ControllerAction.SetModel:
            return action.value;

        default:
            return state;
    }
}
