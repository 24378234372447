import { Annotator } from "../annotator/Annotator"
import { AnnotatorRegistryEntry } from "./AnnotatorRegistryEntry"
import { Payload } from "../payload/Payload"
import { Registry } from "../../runtime/Registry"

export class AnnotatorRegistry implements Annotator, Registry<AnnotatorRegistryEntry> {

    /**
     * A mapping of schemas to registry entries. Global annotators have schema *.
     */
    private map = new Map<string, Set<AnnotatorRegistryEntry>>();

    /**
     * Annotates the payload with matching annotators.
     */
    public async annotate(payload: Payload): Promise<void> {

        // Find all annotators that can annotate this payload
        const matches = this.match(payload);

        // Run each annotator against the payload
        for (let match of matches) {
            await match.annotator.annotate(payload);
        }
    }

    public match(payload: Payload): AnnotatorRegistryEntry[] {

        // Don't annotate unless the payload is initialized and has a schema
        if (!payload?.schema) {
            return [];
        }

        const entries: AnnotatorRegistryEntry[] = [];

        // Get global annotators
        const globalAnnotators = this.map.get("*");
        if (globalAnnotators) {
            entries.push(...globalAnnotators.values())
        }

        // Get schema annotators
        const schemaAnnotators = this.map.get(payload.schema);
        if (schemaAnnotators) {
            entries.push(...schemaAnnotators.values());
        }

        return entries;
    }

    /**
     * Adds an annotator to the registry.
     */
    public register(entry: AnnotatorRegistryEntry): void {

        if (!entry) {
            throw new Error("entry must be specified");
        }

        if (!entry.schema) {
            throw new Error("schema must be specified in the entry");
        }

        if (typeof(entry.annotator) !== "object") {
            throw new Error("annotator must be specified in the entry");
        }

        // Get the schema set (create if necessary)
        let set = this.map.get(entry.schema);
        if (!set) {
            set = new Set<AnnotatorRegistryEntry>();
            this.map.set(entry.schema, set);
        }

        set.add(entry);
    }

    /**
     * Removes an annotator from the registry.
     */
    public unregister(entry: AnnotatorRegistryEntry): void {

        if (!entry?.schema) {
            throw new Error("entry with schema must be specified");
        }

        const set = this.map.get(entry.schema);
        if (set) {
            set.delete(entry);
            if (set.size === 0) {
                this.map.delete(entry.schema);
            }
        }
    }
}