import { parseTags } from "../../library/parseTags"
import { Payload } from "../payload/Payload"
import { Snippet } from "./Snippet"
import { SnippetSchemaId } from "./SnippetSchemaId"

/**
 * Given a payload and set of snippets, returns the snippets that match the payload.
 */
export function matchSnippets(payload: Payload, snippets: Payload[]): Payload[] {

    if (!payload || !snippets) {
        return [];
    }

    function filterSnippet(forPayload: Payload): boolean {

        if (!forPayload) {
            return false;
        }

        if (forPayload.schema !== SnippetSchemaId) {
            return false;
        }

        const snippet = forPayload.data as Snippet;
        if (!snippet) {
            return false;
        }

        if (!snippet.tags) {
            return false;
        }

        // Parse the tags of the payload title, e.g., "#this"
        let titleTags = parseTags(payload!.title);

        for(let snippetTag of snippet.tags) {
            let index = titleTags.indexOf(snippetTag);
            if (index !== -1) {
                return true;
            }
        }

        return false;
    }

    return snippets.filter(filterSnippet);
}