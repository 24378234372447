import { Manifest } from "../../runtime/Manifest"
import { ReducerPluginKey } from "./ReducerPluginKey"

export const ReducerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ReducerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Reducer"
}