import { ConverterPluginKey } from "../converter/ConverterPluginKey"
import { ConverterRegistryContextKey } from "../converter-registry/ConverterRegistryContextKey"
import { ConverterRegistryEntry } from "../converter-registry/ConverterRegistryEntry"
import { ConverterRegistryPluginKey } from "../converter-registry/ConverterRegistryPluginKey"
import { LinkPluginKey } from "../link/LinkPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StringPluginKey } from "../string/StringPluginKey"
import { UrlConverter } from "./UrlConverter"
import { UrlConverterContextKey } from "./UrlConverterContextKey"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const UrlConverterManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "url-converter",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConverterPluginKey,
        ConverterRegistryPluginKey,
        LinkPluginKey,
        StringPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // converter
        {
            key: UrlConverterContextKey,
            value: new UrlConverter()
        } as ManifestEntry<UrlConverter>,

        // registration
        {
            key: "UrlConverterRegistryEntry",
            registerWith: ConverterRegistryContextKey,
            requires: [UrlConverterContextKey],
            value: (context: ManifestContext) => ({
                converter: context[UrlConverterContextKey]
            })
        } as ManifestEntry<ConverterRegistryEntry<UrlConverter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "URL Converter"
}