import React from "react"
import { Button } from "../button/Button"
import { IonIcon } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { squareOutline as icon } from "ionicons/icons"

export const ButtonEmblemView: React.FC<ReactViewProps> = (props) => {

    function color(button: Button): string | undefined {
        if (button?.destructive) {
            return "danger"
        }
        else {
            return undefined;
        }
    }

    return (
        <IonIcon color={color(props?.model?.payload?.data)} style={{
            width:"32px",
            height: "32px"
        }} icon={icon} />
    )
}