import { Manifest } from "../../runtime/Manifest"
import { WikipediaSummaryPluginKey } from "./WikipediaSummaryPluginKey"

export const WikipediaSummaryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: WikipediaSummaryPluginKey,

    /**
     * The display title of the plugin.
     */
    title: "Wikipedia Summary"
}