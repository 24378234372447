import React  from "react"
import { NasaApod } from "../nasa-apod/NasaApod"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./NasaApodCardView.css"

export const NasaApodCardView: React.FC<ReactViewProps<NasaApod>> = (props) => {

    let response = props?.model?.payload?.data;
    if (!response) {
        return <></>
    }

    return (
        <>
            <h1 className="ApodCardTitle selectable">{response.title}</h1>
            <img className='ApodCard' src={response.hdurl} alt="APOD"></img>
        </>
    )
};