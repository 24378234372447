import { Annotator } from "../annotator/Annotator"
import { Note } from "../note/Note"
import { NoteSchemaId } from "../note/NoteSchemaId"
import { Payload } from "../payload/Payload"

const MAX_LENGTH = 80;

export class NoteAnnotator implements Annotator<Note> {

    public async annotate(payload: Payload<Note>): Promise<void> {
        if (payload?.schema === NoteSchemaId) {

            // Get the note and skip annotation if missing
            const note = payload.data;
            if (!note) {
                return;
            }

            // Build the payload title based on the note title and text
            let title = note.title;
            if (note.text) {
                title = title ? `${title}: ${note.text}` : `${note.text}`
            }

            if (title && title.length > MAX_LENGTH) {
                title = title.substr(0, MAX_LENGTH) + "..."
            }

            payload.title = title ?? payload.title;
        }
    }
} 