import { Cue } from "../cue/Cue"

export const DefaultCues: Cue[] = [
    {
        title: "be grateful"
    },
    {
        title: "be compassionate with yourself"
    },
    {
        title: "examine your assumptions"
    },
    {
        title: "exercise curiosity"
    },
    {
        title: "listen carefully"
    },
    {
        title: "keep an open mind"
    },
    {
        title: "you are not your thoughts"
    },
    {
        title: "turn on"
    },
    {
        title: "you got this"
    },
    {
        title: "reprogram yourself"
    },
    {
        title: "evaluate your priorities"
    },
    {
        activityKey: "bedtime",
        title: "remember your dreams"
    },
    {
        activityKey: "bedtime",
        title: "drink water before bed"
    },
    {
        activityKey: "bedtime",
        title: "create a restful environment"
    },
    {
        activityKey: "exercise",
        title: "love yourself"
    },
    {
        activityKey: "exercise",
        title: "you are beautiful"
    },
    {
        activityKey: "exercise",
        title: "start now"
    },
    {
        activityKey: "exercise",
        title: "stay hydrated"
    },
    {
        activityKey: "exercise",
        title: "visualize yourself"
    },
    {
        activityKey: "inspiration",
        title: "visit somewhere new"
    },
    {
        activityKey: "inspiration",
        title: "listen to new music"
    },
    {
        activityKey: "negotiate",
        title: "shut up and listen"
        //credit: https://www.brodow.com/Ten-Tips-For-Negotiating
    },
    {
        activityKey: "wakeup",
        title: "rise strong"
    },
    {
        activityKey: "wakeup",
        title: "today is yours"
    },
    {
        activityKey: "wakeup",
        title: "you will survive",
    },
    {
        activityKey: "wakeup",
        title: "decide to be"
    },
    {
        activityKey: "work",
        title: "act with integrity"
    },
    {
        activityKey: "work",
        title: "consistency builds trust"
    },
    {
        activityKey: "work",
        title: "reliability builds trust"
    },
    {
        activityKey: "work",
        title: "decide your day"
    },
    {
        activityKey: "work",
        title: "eliminate distractions"
    },
    {
        activityKey: "work",
        title: "keep a tidy workspace"
    },
    {
        activityKey: "work",
        title: "know your goals"
    },
    {
        activityKey: "work",
        title: "listen first"
    },
    {
        activityKey: "work",
        title: "remember your success"
    },
    {
        activityKey: "work",
        title: "schedule regular breaks"
    },
    {
        activityKey: "work",
        title: "separate work and play"
    },
    {
        activityKey: "work",
        title: "strike a high-powered pose"
    },
    {
        activityKey: "work",
        title: "think before acting"
    }
]