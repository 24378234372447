import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PollerCreatorPluginKey } from "../poller-creator/PollerCreatorPluginKey"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryEntry } from "../poller-creator-registry/PollerCreatorRegistryEntry"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"
import { RedditPluginKey } from "../reddit/RedditPluginKey"
import { SubredditFeedPluginKey } from "../subreddit-feed/SubredditFeedPluginKey"
import { SubredditFeedPollerCreator } from "./SubredditFeedPollerCreator"
import { SubredditFeedPollerCreatorContextKey } from "./SubredditFeedPollerCreatorContextKey"
import { SubredditFeedPollerPluginKey } from "./SubredditFeedPollerPluginKey"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId"

/**
 * The settings of the plugin.
 */
export const SubredditFeedPollerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SubredditFeedPollerPluginKey,

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PollerPluginKey,
        PollerCreatorPluginKey,
        PollerCreatorRegistryPluginKey,
        RedditPluginKey,
        SubredditFeedPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Download postings from  a subreddit",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        // poller
        {
            key: SubredditFeedPollerCreatorContextKey,
            value: new SubredditFeedPollerCreator()
        },

        // registration
        {
            key: "SubredditFeedPollerCreatorRegistryEntry",
            registerWith: PollerCreatorRegistryContextKey,
            requires: [SubredditFeedPollerCreatorContextKey],
            value: (context: ManifestContext) => ({
                schema: SubredditFeedSchemaId,
                creator: context[SubredditFeedPollerCreatorContextKey]
            })
        } as ManifestEntry<PollerCreatorRegistryEntry<SubredditFeedPollerCreator>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Subreddit Feed Poller",
}