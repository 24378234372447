import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NumberBasesDetail } from "./NumberBasesDetail"
import { NumberPluginKey } from "../number/NumberPluginKey"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const NumberBasesDetailManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "number-bases-detail",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NumberPluginKey,
        ReactViewPluginKey,
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NumberBasesDetail",
            registerWith: ReactViewRegistryContextKey,
            title: "Number Bases Detail",
            value: {
                layout: "detail",
                match: "*",
                fc: NumberBasesDetail    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin
     */
    title: "Number Bases Detail",
};
