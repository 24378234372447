import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Splash } from "../splash/Splash"
import "./SplashCardView.css"

export const SplashCardView: React.FC<ReactViewProps> = (props) => {

    const splash = props?.model?.payload?.data as Splash;

    return (
        <div className="splash" style={{
            backgroundImage: `url(${splash?.imageUrl})`
        }}>
            <span className="splash-text">set your mindset to <span className="splash-title">{splash.activity}</span></span>
        </div>
    );
};