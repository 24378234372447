import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RssFeedPluginKey } from "../rss-feed/RssFeedPluginKey"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"
import { RssFeedIndexer } from "./RssFeedIndexer"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const RssFeedIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "rss-feed-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        RssFeedPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "RssFeedIndexer",
            registerWith: IndexerRegistryContextKey,
            value: {
                schema: RssFeedSchemaId,
                indexer: new RssFeedIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<RssFeedIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Feed Indexer",
};
