import { Annotator } from "../annotator/Annotator"
import { Bill } from "../bill/Bill"
import { BillSchemaId } from "../bill/BillSchemaId";
import { Payload } from "../payload/Payload"

export class BillAnnotator implements Annotator {

    /**
     * Annotates a payload containing a bill.
     */
    public async annotate(payload: Payload<Bill>): Promise<void> {

        if ((payload?.schema !== BillSchemaId) || (!payload.data)) {
            return;
        }

        payload.title = payload.data.title;
    }
}