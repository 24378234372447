import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { TaskAnnotator } from "./TaskAnnotator"
import { TaskAnnotatorContextKey } from "./TaskAnnotatorContextKey"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { TaskSchemaId } from "../task/TaskSchemaId"

export const TaskAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "task-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        TaskPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: TaskAnnotatorContextKey,
            value: new TaskAnnotator()
        } as ManifestEntry<TaskAnnotator>,

        // registration
        {
            key: "TaskAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [TaskAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: TaskSchemaId,
                annotator: context[TaskAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<TaskAnnotator>>
    ],

    /**
     * The dislay title of the plugin.
     */
    title: "Task Annotator"
}