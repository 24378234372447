import React from "react"
import { Notice } from "../notice/Notice"
import { NoticeBanner } from "./NoticeBanner"
import { NoticesReducerKey } from "../notices-reducer/NoticesReducerKey"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const NoticesPageSection: React.FC<ReactViewProps> = (props) => {

    const model = props?.model;
    if (!model) {
        return <></>
    }

    const notices = model[NoticesReducerKey] as Notice[];
    if (!notices || !Array.isArray(notices)) {
        return <></>
    }
    
    return (
        <>
            {notices.map(n => {
                return <NoticeBanner key={n.title} notice={n}></NoticeBanner>
            })}
        </>
    )
}