import { EarthquakeFeedPoller } from "./EarthquakeFeedPoller"
import { EarthquakeFeed } from "../usgs-earthquake-feed/EarthquakeFeed"
import { EarthquakeFeedSchema } from "../usgs-earthquake-feed/EarthquakeFeedSchema"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { PollerCreator } from "../poller-creator/PollerCreator"

export class EarthquakeFeedPollerCreator implements PollerCreator {

    public async create(payload: Payload<EarthquakeFeed>): Promise<Poller | undefined> {

        if (payload?.schema !== EarthquakeFeedSchema) {
            return undefined;
        }

        if (!payload.data) {
            return undefined;
        }

        return new EarthquakeFeedPoller(payload.data);
    }
}