import React, { useEffect, useState } from "react"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { List } from "../list/List"
import { ListSchemaId } from "../list/ListSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Store } from "../store/Store"

export interface TagCardViewProps extends ReactViewProps {
    store: Store
}

export const TagCardView: React.FC<TagCardViewProps> = (props) => {

    const store = props.store;
    const tag = props?.model?.payload?.data;
    const [payloads, setPayloads] = useState<Payload[]>([]);    

    useEffect(() => {
        store.tagged(tag).then(p => setPayloads(p));
    }, [store, tag]);

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{tag}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <ReactViewFactory
                    dispatch={props.dispatch}
                    layout="card"
                    matcher={props.matcher}
                    model={{
                        payload: {
                            schema: ListSchemaId,
                            data: {
                                items: payloads
                            }
                        } as Payload<List>
                    }}
                ></ReactViewFactory>
            </IonCardContent>
        </IonCard>
    )
}