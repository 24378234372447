import { Manifest } from "../../runtime/Manifest"
import { NasaApodPluginKey } from "./NasaApodPluginKey"

export const NasaApodManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: NasaApodPluginKey,

    /**
     * Indicates whether the plugin is installed by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "NASA Astronomy Picture of the Day"
}