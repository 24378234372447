import { CreatePluginKey } from "./CreatePluginKey"
import { ListPluginKey } from "../list/ListPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const CreateManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: CreatePluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ListPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Create"
}