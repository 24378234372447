import React from "react"
import {
    IonButton,
    IonIcon
} from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { search as searchIcon } from "ionicons/icons"
import { SearchStringSchemaId } from "../search-string/SearchStringSchemaId"

export const SearchToolbarButton: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;
    const payload = props.model?.payload;
    if (!payload) {
        return <></>
    }

    function onClick() {

        let searchPayload: Payload = {
            schema: SearchStringSchemaId,
            data: payload,
            title: payload?.title
        };
    
        if (dispatch) {
            dispatch(searchPayload);
        }
    }

    return (
        <IonButton onClick={() => onClick()} size="large">
            <IonIcon icon={searchIcon} size="large">Search</IonIcon>
        </IonButton>
    );    
}