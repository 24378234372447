import React  from "react"
import { ComCatProperties } from "../usgs-earthquake/ComCatProperties"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonItem,
    IonLabel,
    IonList,
    IonText
} from "@ionic/react"

import "./EarthquakeCardView.css"

/**
 * Translates an alert level into an Ionic theme color.
 */
function alertColor(alertLevel?: string | undefined | null) {

    switch(alertLevel) {
        case "green":
            return "success";

        case "yellow":
        case "orange":
            return "warning";

        case "red":
            return "danger";

        default:
            return "";
    }

}

export const EarthquakeCardView: React.FC<ReactViewProps> = (props) => {

    // Get the underlying properties of the feature. This contains earthquake data.
    const feature = props?.model?.payload?.data;
    if (!feature) {
        return <></>
    }
    
    const fprops = feature.properties as ComCatProperties;

    return (
        
        <>
            <div className="earthquake-main">
                <h1>Earthquake Alert</h1>
                <img
                        alt=""
                        className="earthquake-icon"
                        style={{fill: "white"}}
                        src={`/assets/weather-icons/svg/wi-earthquake.svg`}/> 
                <div className="earthquake-location">{fprops.place}</div> 
                <div className="earthquake-information">
                    <IonList>
                        <IonItem>
                            <IonLabel slot="start">Alert level</IonLabel>
                            <IonText color={alertColor(fprops.alert)}>{fprops.alert ?? "None"}</IonText>
                        </IonItem>
                        <IonItem>
                            <IonLabel slot="start">Magnitude</IonLabel>
                            <IonText>{fprops.mag}</IonText>
                        </IonItem>
                        <IonItem>
                            <IonLabel slot="start">Time</IonLabel>
                            <IonLabel>{new Date(fprops.time).toString()}</IonLabel>
                        </IonItem>
                    </IonList>
                </div>
            </div>
        </>
    )
};