import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NoticeAnnotator } from "./NoticeAnnotator"
import { NoticeAnnotatorContextKey } from "./NoticeAnnotatorContextKey"
import { NoticePluginKey } from "../notice/NoticePluginKey"
import { NoticeSchemaId } from "../notice/NoticeSchemaId"

export const NoticeAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "notice-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        NoticePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NoticeAnnotatorContextKey,
            value: new NoticeAnnotator()
        } as ManifestEntry<NoticeAnnotator>,

        // registration
        {
            key: "NoticeAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NoticeAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NoticeSchemaId,
                annotator: context[NoticeAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NoticeAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Notice Annotator"
}