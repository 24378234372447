import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RedditPluginKey } from "../reddit/RedditPluginKey"
import { RedditThingIndexer } from "../reddit-thing-indexer/RedditThingIndexer"
import { RedditThingSchemaId } from "../reddit/RedditThingSchemaId"
import { UrlPluginKey } from "../url/UrlPluginKey"

/**
 * The settings of the plugin.
 */
export const RedditThingIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "reddit-thing-indexer",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        RedditPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "RedditThingIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "Reddit Thing Indexer",
            value: {
                schema: RedditThingSchemaId,
                indexer: new RedditThingIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<RedditThingIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reddit Thing Indexer",
}