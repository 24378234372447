import { LinkSchemaId } from "./LinkSchemaId"
import { Schema } from "../schema/Schema"
import { UrlSchemaId } from "../url/UrlSchemaId"

export const LinkSchema: Schema = {
    $id: LinkSchemaId,
    title: "Link",
    type: "object",
    properties: {
        "title": {
            title: "Title",
            type: "string"
        },
        "url": {
            title: "URL",
            $ref: UrlSchemaId
        }
    }
}