import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { JsonCommander } from "./JsonCommander"
import { JsonPluginKey } from "../json/JsonPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const JsonCommanderManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: "json-commander",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey,
        CommanderRegistryPluginKey,
        JsonPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "JsonCommander",
            registerWith: CommanderArrayContextKey,
            title: "JSON Commander",
            value: new JsonCommander()
        } as ManifestEntry<JsonCommander>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "JSON Commander"
}