import React from "react";
import { compareEntries } from "./compareEntries"
import { ReactInputProps } from "./ReactInputProps";
import { ReactInputRegistryEntry } from "./ReactInputRegistryEntry"
import { Registry } from "../../runtime/Registry"
import { Schema } from "../schema/Schema";

export class ReactInputRegistry implements Registry<ReactInputRegistryEntry> {

    /**
     * The inputs registered with the registry.
     */
    private inputs: ReactInputRegistryEntry[] = [];

    /**
     * Returns registry entries that match the specified schema.
     * The schema should be fully dereferenced, e.g., schema keywords
     * such as $ref should already be expanded into an effective schema.
     */
    public match(effectiveSchema: Schema): React.FC<ReactInputProps> | undefined {

        // Find all entries that match against the schema
        const matches = this.inputs.filter(v => v.match(effectiveSchema));

        switch(matches.length) {
            case 0:
                return undefined;

            case 1:
                return matches[0].fc;

            default:
                return matches.sort(compareEntries)[0].fc;
        }
    }
   
    /**
     * Adds an input entry to the registry.
     */
    public register(value: ReactInputRegistryEntry): void {

        if (!value) {
            throw new Error("value must be specified");
        }

        if (typeof(value.match) !== "function") {
            throw new Error("match property must be a function");
        }

        if (typeof(value.fc) !== "function") {
            throw new Error("fc property must be a function");
        }

        if (this.inputs.includes(value)) {
            throw new Error("value is already registered");
        }

        this.inputs.push(value);
    }

    /**
     * Removes an input entry from the registry.
     */
    public unregister(value: ReactInputRegistryEntry): void {

        if (!value) {
            throw new Error("value must be specified");
        }

        const index = this.inputs.indexOf(value);
        if (index !== -1) {
            this.inputs.splice(index, 1);
        }
    }
}