import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SplashCardView } from "./SplashCardView"
import { SplashPluginKey } from "../splash/SplashPluginKey"
import { SplashSchemaId } from "../splash/SplashSchemaId"

export const SplashCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "splash-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        SplashPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "SplashCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Splash Card View",
            value: {
                layout: ["card", "preview"],
                match: SplashSchemaId,
                fc: SplashCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Splash Card View"
}