import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Edit } from "../edit/Edit"
import { EditSchemaId } from "../edit/EditSchemaId"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"
import { SchemaRegistry } from "../schema-registry/SchemaRegistry"

export class EditCommander implements Commander {

    private schemas: SchemaRegistry

    constructor(schemas: SchemaRegistry) {
        if (!(this.schemas = schemas)) {
            throw new Error("schema registry must be specified");
        }
    }

    public async issue(model: Model | undefined): Promise<Command[]> {

        if (!model) {
            return [];
        }

        // Payload must exist and have a unique ID
        const payload = model.payload;
        if (!payload) {
            return [];
        }

        // Payload must have a schema to match against an editor
        const schemaId = payload.schema;
        if (!schemaId) {
            return [];
        }

        // See if this payload has a schema
        if (!this.schemas.get(schemaId)) {
            return [];
        }

        const editCommand: Command = {
            key: "edit",
            layout: "extended",
            order: -1,
            title: "Edit",
            value: {
                schema: EditSchemaId,
                data: {
                    payload                    
                }
            } as Payload<Edit>
        };

        return [editCommand];
    }    
}