import { Command } from "../commander/Command"
import { CommanderChain } from "../commander/CommanderChain"
import { Model } from "../state/Model"
import { Reducer } from "../reducer/Reducer";

export class CommandsReducer extends Reducer {

    private chain: CommanderChain;

    /**
     * Initializes a new instance of the class.
     */
    constructor(chain: CommanderChain) {
        super();

        if (!chain) {
            throw new Error("commander chain must be specified");
        }

        this.chain = chain;
    }

   public async reduce(model: Model): Promise<Command[]> {
       if (!model) {
           return [];
       }
       else {
           return this.chain.issue(model);
       }
   }
}