import React from "react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./PreviewView.css"

export const PreviewView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    const title = payload?.title ?? payload?.key ?? payload?.id;

    return (
        <div className="preview-view">
            <ReactViewFactory
                dispatch={props.dispatch}
                layout="emblem"
                matcher={props.matcher}
                model={props.model}>
            </ReactViewFactory>
            <h1>{title}</h1>
        </div>
    );
}