import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"
import { StreamerRegistryStreamer } from "./StreamerRegistryStreamer"
import { StreamerRegistryStreamerContextKey } from "./StreamerRegistryStreamerContextKey"
import { StreamerRegistryStreamerPluginKey } from "./StreamerRegistryStreamerPluginKey"

export const StreamerRegistryStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: StreamerRegistryStreamerPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        StreamerRegistryPluginKey,
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: StreamerRegistryStreamerContextKey,
            description: "Wraps the streamer registry into a single, multiplexed streamer",
            requires: [StreamerRegistryContextKey],
            value: (context: ManifestContext) =>
                new StreamerRegistryStreamer(context[StreamerRegistryContextKey])
        } as ManifestEntry<StreamerRegistryStreamer>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Streamer Registry Streamer"
}