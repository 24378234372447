import { ConfirmEmblemView } from "./ConfirmEmblemView"
import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const ConfirmEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "confirm-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConfirmPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ConfirmEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: ConfirmSchemaId,
                layout: "emblem",
                fc: ConfirmEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Confirm Emblem View"
}