import { Blocking } from "./Blocking"
import { BlockingStore } from "./BlockingStore"

/**
 * The key used to store blocking definitions in local storage.
 */
const DEFAULT_STORAGE_KEY = "BLOCKINGS";

/**
 * Implementation of a blocking store that persists to local storage.
 */
export class BlockingLocalStore extends BlockingStore {

    private storageKey: string;

    /**
     * Initializes the blocker with the specified storage key.
     */
    constructor(storageKey: string = DEFAULT_STORAGE_KEY) {
        super();
        this.storageKey = storageKey;
    }

    /**
     * Loads blockings from storage.
     */
    public load(): Blocking[] {

        let datastr: string | null = localStorage.getItem(this.storageKey);
        if (datastr) {
            return JSON.parse(datastr);
        }
        else {
            return [];
        }
    }

    /**
     * Persists blockings to local storage.
     */
    public save(blockings: Blocking[]) {
        localStorage.setItem(this.storageKey, JSON.stringify(blockings));        
    }
}