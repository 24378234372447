import { Deadline } from "./Deadline"
import { DeadlineSchemaId } from "./DeadlineSchemaId"
import { Payload } from "../payload/Payload"
import { PayloadPredicate } from "../payload/PayloadPredicate"
import { PayloadPredicates } from "../payload/PayloadPredicates"

function Upcoming(ms: number): PayloadPredicate {

    return (p: Payload) => {

        if (!PayloadPredicates.Active(p)) {
            return false;
        }

        if (p.schema !== DeadlineSchemaId) {
            return false;
        }

        const deadline = p.data as Deadline;
        if (!deadline) {
            return false;
        }

        if (!deadline.deadlineOn) {
            return false;
        }

        const remaining = deadline.deadlineOn - Date.now();
        if (remaining < 0) {
            // Deadline has passed
            return false;
        }

        if (remaining > ms) {
            // Deadline is not close yet...
            return false;
        }

        return true;
    }
}

export const DeadlinePredicates = {
    Upcoming
}