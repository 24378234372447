import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { ConfirmPluginKey } from "./ConfirmPluginKey"
import { ConfirmSchema } from "./ConfirmSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PromptPluginKey } from "../prompt/PromptPluginKey"
import { Schema } from "../schema/Schema"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const ConfirmManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ConfirmPluginKey,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BooleanPluginKey,
        PromptPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ConfirmSchema",
            value: ConfirmSchema,
            registerWith: SchemaRegistryContextKey
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Confirm"
}