import { Config } from "../library/Config"

/**
 * Fetches JSON data from a service.
 */
export async function fetchXml(url: string, requiresCors: boolean = true): Promise<Document | undefined> {

    let fullurl = url;
    if (!fullurl) {
        return undefined;
    }

    if (requiresCors) {
        fullurl = `${Config.DEFAULT_CORS_PROXY}${url}`;
    }

    console.info(`fetchXml(${fullurl})`);
 
    let options = {
        headers: {
            Origin: Config.DEFAULT_ORIGIN
        }
    };

    return fetch(fullurl, options)
        .then(response => response.text())
        .then(text => new window.DOMParser().parseFromString(text, "text/xml"));
}