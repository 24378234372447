import React from "react"
import { IonCard, IonCardHeader } from "@ionic/react"
import { Notice } from "../notice/Notice"

export interface NoticeBannerProps {
    notice: Notice
}

export const NoticeBanner: React.FC<NoticeBannerProps> = (props) => {

    if (!props.notice) {
        return <></>
    }

    return (
        <IonCard color={props.notice.color}>
            <IonCardHeader>{props.notice.title}</IonCardHeader>
        </IonCard>
    );
}