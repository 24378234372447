import { AnnotatorRegistry } from "../annotator-registry/AnnotatorRegistry"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Controller } from "../controller/Controller"
import { ControllerArrayContextKey } from "../controller-registry/ControllerArrayContextKey"
import { ControllerPluginKey } from "../controller/ControllerPluginKey"
import { ControllerRegistryPluginKey } from "../controller-registry/ControllerRegistryPluginKey"
import { DefaultPayloadContextKey } from "../default-payload/DefaultPayloadContextKey"
import { DefaultPayloadPluginKey } from "../default-payload/DefaultPayloadPluginKey"
import { EchoController } from "../controller/EchoController"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"
import { ReducerPluginKey } from "../reducer/ReducerPluginKey"
import { ReducerRegistry } from "../reducer-registry/ReducerRegistry"
import { ReducerRegistryContextKey } from "../reducer-registry/ReducerRegistryContextKey"
import { ReducerRegistryPluginKey } from "../reducer-registry/ReducerRegistryPluginKey"
import { RoundRobinController } from "../controller/RoundRobinController"
import { State } from "./State"
import { StateContextKey } from "./StateContextKey"
import { StatePluginKey } from "./StatePluginKey"

export const StateManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: StatePluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        ControllerPluginKey,
        ControllerRegistryPluginKey,
        DefaultPayloadPluginKey,
        ReducerPluginKey,
        ReducerRegistryPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: StateContextKey,
            requires: [
                AnnotatorRegistryContextKey,
                ControllerArrayContextKey,
                DefaultPayloadContextKey,
                ReducerRegistryContextKey
            ],
            value: (context: ManifestContext) => {

                // Get the array of the dynamically loaded controllers
                const controllers: Controller[] = context[ControllerArrayContextKey];

                // Wrap the controller array into a single round-robin controller
                const multi = new RoundRobinController(controllers);

                // Wrap the control in a echo controller for ad hoc payloads
                const echo = new EchoController(multi);

                // Get the list of registered annotators
                const annotators: AnnotatorRegistry = context[AnnotatorRegistryContextKey];

                // Get the list of reducers
                const reducers: ReducerRegistry = context[ReducerRegistryContextKey];

                // Get the default payload if nothing is available
                const defaultPayload: Payload = context[DefaultPayloadContextKey];

                // Annotate the multi controller
                return new State(echo, annotators, reducers, defaultPayload);
            }
        } as ManifestEntry<State>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Controller Root"
}