import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SnippetCardView } from "./SnippetCardView"
import { SnippetPluginKey } from "../snippet/SnippetPluginKey"
import { SnippetSchemaId } from "../snippet/SnippetSchemaId"

export const SnippetCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "snippet-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        SnippetPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "SnippetCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Snippet Card View",
            value: {
                layout: "card",
                match: SnippetSchemaId,
                fc: SnippetCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Snippet Card View"
}