import { DateNumberPluginKey } from "./DateNumberPluginKey"
import { DateNumberSchema } from "./DateNumberSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Schema } from "../schema/Schema"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const DateNumberManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin key.
     */
    key: DateNumberPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DateNumberSchema",
            value: DateNumberSchema,
            registerWith: SchemaRegistryContextKey
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Date Number"
}