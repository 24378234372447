import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Splash } from "../splash/Splash"
import { SplashArrayContextKey } from "./SplashArrayContextKey"
import { SplashArrayPluginKey } from "./SplashArrayPluginKey"
import { SplashPluginKey } from "../splash/SplashPluginKey"

export const SplashArrayManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: SplashArrayPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SplashPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: SplashArrayContextKey,
            title: "Splash Array",
            value: []
        } as ManifestEntry<Splash[]>
    ],

    /**
     * The display title of the plugin
     */
    title: "Splash Array"
}