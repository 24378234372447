import { CatalogPluginKey } from "../catalog/CatalogPluginKey"
import { CatalogRegistryContextKey } from "../catalog/CatalogRegistryContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NewsFeedPluginKey } from "../newsapi-feed/NewsFeedPluginKey"
import { NewsFeedCatalog } from "./NewsFeedCatalog"
import { NewsFeed } from "../newsapi-feed/NewsFeed"
import { Payload } from "../payload/Payload"

export const NewsApiFeedCatalogManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "news-feed-catalog",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CatalogPluginKey,
        NewsFeedPluginKey,
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NewsFeedCatalog",
            registerWith: CatalogRegistryContextKey,
            title: "News Feed Catalog",
            value: NewsFeedCatalog
        } as ManifestEntry<Payload<NewsFeed>[]>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "News Feed Catalog",
};
