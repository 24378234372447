import React from "react"
import { IonButton, IonButtons } from "@ionic/react"
import { Payload } from "../payload/Payload"
import { Prompt } from "../prompt/Prompt"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./PromptCardView.css"

export const PromptCardView: React.FC<ReactViewProps> = (props) => {

    const dispatch = props?.dispatch;
    const payload = props?.model?.payload;
    const prompt = payload?.data as Prompt;

    function onClick(goto: Payload) {
        if (dispatch) {
            dispatch(goto);
        }
    }

    return (
            <section className={`prompt ${prompt.destructive && "destructive"}`}>
                <h1 className="prompt-title">{prompt.title}</h1>
                <h2>{prompt.payload?.title ?? prompt.payload?.key ?? prompt.payload?.id}</h2>
                
                <div className="prompt-preview">
                    <ReactViewFactory
                        dispatch={props.dispatch}
                        layout="preview"
                        matcher={props.matcher}
                        model={{ payload: prompt?.payload }}>
                    </ReactViewFactory>
                </div>

                <IonButtons className="prompt-buttons">
                    {prompt.options.map(p => 
                        <IonButton
                            key={p.title}
                            color="primary"
                            fill="solid"
                            onClick={() => onClick(p)}
                        >{p.title}</IonButton>
                    )}
                </IonButtons>
                
            </section>
    );
}