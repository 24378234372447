import App from "./App"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { store } from "./plugins/redux/store"
import * as serviceWorker from "./serviceWorker"

// Get the root element on which to render the React app
const rootElement = document.getElementById("root");

// Render the app wrapped in the store provider so that child components can access the state
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement);
    
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
