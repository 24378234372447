import React, { useState } from "react"
import { getEmailAddress } from "../email-address/getEmailAddress"
import {
    IonChip,
    IonLabel,
    IonToast,
} from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"


/**
 * Renders a payload link into a button.
 */
export const EmailAddressChipView: React.FC<ReactViewProps> = (props) => {

    // Track whether the copy-to-clipboard toast is shown
    const [showToast, setShowToast] = useState(false);

    // Get the text to display in the chip
    const text = getEmailAddress(props?.model?.payload?.data);
    if (!text) {
        return <></>
    }
    
    // Handle the chip click event to copy the text to the clipboard
    const onclick = () => {

        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
        navigator.clipboard.writeText(text).then(() => {
            setShowToast(true);
        });
    }

    return (
        <>
            <IonChip onClick={() => onclick()}><IonLabel>{text}</IonLabel></IonChip>
            <IonToast
                duration={200}
                isOpen={showToast}
                message="Copied to clipboard"
                onDidDismiss={() => setShowToast(false)}></IonToast>
        </>
    )
}