import { Manifest } from "../../runtime/Manifest"
import { RssPluginKey } from "./RssPluginKey"

export const RssManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: RssPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "RSS"
}