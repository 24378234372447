import { Manifest } from "../../runtime/Manifest"
import { NgcNumberPluginKey } from "./NgcNumberPluginKey"

export const NgcNumberManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: NgcNumberPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "NGC Number"
}