import { Converter } from "../converter/Converter"
import { ConverterInspection } from "../converter/ConverterInspection"
import { Link } from "../link/Link"
import { LinkSchemaId } from "../link/LinkSchemaId"
import { Payload } from "../payload/Payload"
import { StringSchemaId } from "../string/StringSchemaId"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class UrlConverter implements Converter {

    /**
     * Returns possible conversions.
     */
    public async inspect(payload: Payload): Promise<ConverterInspection[]> {

        if (payload?.schema !== UrlSchemaId) {
            return [];
        }

        if (typeof(payload.data) !== "string") {
            return [];
        }

        return [

            /* Link */
            {
                output: LinkSchemaId,
                lossy: false,
                convert: async () => ({
                    schema: LinkSchemaId,
                    data: {
                        title: payload.title ?? payload.data,
                        url: payload.data
                    }
                } as Payload<Link>)
            },

            /* String */
            {
                output: StringSchemaId,
                lossy: false,
                convert: async () => ({
                    schema: StringSchemaId,
                    data: payload.data
                })
            }
        ]
    }
}