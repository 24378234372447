import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { GoalAnnotator } from "./GoalAnnotator"
import { GoalAnnotatorContextKey } from "./GoalAnnotatorContextKey"
import { GoalPluginKey } from "../goal/GoalPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { GoalSchemaId } from "../goal/GoalSchemaId"

export const GoalAnnotatorManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "goal-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        GoalPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: GoalAnnotatorContextKey,
            value: new GoalAnnotator()
        } as ManifestEntry<GoalAnnotator>,

        // registration
        {
            key: "GoalAnnotatorRegistryEntry",
            requires: [GoalAnnotatorContextKey],
            registerWith: AnnotatorRegistryContextKey,
            value: (context: ManifestContext) => ({
                schema: GoalSchemaId,
                annotator: context[GoalAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<GoalAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Goal Annotator"
}