import { Manifest } from "../../runtime/Manifest"
import { SelectorPluginKey } from "../selector/SelectorPluginKey"
import { SequencePluginKey } from "./SequencePluginKey"

export const SequenceManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SequencePluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SelectorPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Sequence"
}