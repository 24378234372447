import { FlashcardSchemaId } from "./FlashcardSchemaId"
import { Schema } from "../schema/Schema"

export const FlashcardSchema: Schema = {

    "$id": FlashcardSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "A flashcard with a front and back",
    title: "Flashcard",

    properties: {
        "front": {
            type: "string",
            description: "The front of the flashcard",
            title: "Front"
        },
        "back": {
            type: "string",
            description: "The back of the flashcard",
            title: "Back"
        },
    }
}