import { BillPluginKey } from "./BillPluginKey"
import { BillSchema } from "./BillSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PaymentPluginKey } from "../payment/PaymentPluginKey"
import { SchedulePluginKey } from "../schedule/SchedulePluginKey"
import { Schema } from "../schema/Schema"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const BillManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: BillPluginKey,

    /**
     * Indicates whether to install the plugin by default at first install.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaRegistryPluginKey,
        PaymentPluginKey,
        SchedulePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "BillSchema",
            value: BillSchema,
            registerWith: SchemaRegistryContextKey
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bill"
}