import { ComCatProperties } from "../usgs-earthquake/ComCatProperties"
import { EarthquakeFeed } from "../usgs-earthquake-feed/EarthquakeFeed"
import { EarthquakeSchemaId } from "../usgs-earthquake/EarthquakeSchemaId"
import { Feature } from "../geojson/Feature"
import { fetchJson } from "../../library/fetchJson"
import { GeoJSON } from "../geojson/GeoJSON"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"

export class EarthquakeFeedPoller implements Poller {

    private feed: EarthquakeFeed;

    /**
     * Initializes the earthquake poller against the specified GeoJSON URL.
     */
    constructor(feed: EarthquakeFeed) {
        if (!(this.feed = feed)) {
            throw new Error("feed must be specified");
        }
    }

    /**
     * Converts a GeoJSON feature into a payload.
     */
    public convertFeature(feature: Feature): Payload {

        if (!feature) {
            throw new Error("feature cannot be null");
        }
        
        // In GeoJSON, each feature has a "properties" object. The
        // USGS earthquake service fills the properties with information
        // about the earthquake such as magnitude.
        let props = feature.properties as ComCatProperties;

        /**
         * Note from RFC 7946 regarding the latitude and longitude of the
         * point: "A position is an array of numbers.  There MUST be two or more
         * elements.  The first two elements are longitude and latitude, or
         * easting and northing, precisely in that order and using decimal
         * numbers.  Altitude or elevation MAY be included as an optional third
         * element.
         */
        return {
            schema: EarthquakeSchemaId,
            data: feature,
            key: feature.id?.toString(),
            location: {
                latitude: feature.geometry?.coordinates[1],
                longitude: feature.geometry?.coordinates[0]
            },
            title: props.title!,
        }
    }

    /**
     * Converts the response from the JSON poller into payloads.
     */
    public convertGeoJSON(data: GeoJSON | undefined): Payload[] {

        if (!data) {
            return [];
        }
        else {
            return data.features.map(feature => {
                return this.convertFeature(feature);
            });    
        }
    }

    /**
     * Polls the service for current earthquake alerts.
     */
    public async poll(): Promise<Payload[]> {
        return fetchJson<GeoJSON>(this.feed.url, this.feed.useCorsProxy).then(
            geo => this.convertGeoJSON(geo));
    }
}