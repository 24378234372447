import { Activity } from "../activity/Activity"
import { ActivityTracker } from "../activity-tracker/ActivityTracker"
import { Cue } from "../cue/Cue"
import { CueSchemaId } from "../cue/CueSchemaId"
import { Payload } from "../payload/Payload"
import { Streamer } from "../streamer/Streamer"

/**
 * The minimum interval between cues when the activity has not changed.
 */
const REPEAT_INTERVAL = 1000 * 60 * 60;

/**
 * Returns a cue whenever the current activity is changed.
 */
export class ActivityCueStreamer implements Streamer {

    /** A mapping of activities to cues */
    private cues: Map<string, Cue[]>;

    /** The last activity for which a cue was returned */
    private lastActivity: Activity | undefined;

    /** The last queue that was sent */
    private lastCue: Cue | undefined;

    /** The date/time of the last time a cue was sent. */
    private lastTime: number | undefined;

    /** Tracks the current activity */
    private tracker: ActivityTracker;

    /**
     * Initializes the streamer with the specified tips.
     */
    constructor(tracker: ActivityTracker, cues: Cue[] = []) {
        
        if (!(this.tracker = tracker)) {
            throw new Error("activity tracker must be specified");
        }

        if (!cues) {
            throw new Error("cues must be specified");
        }

        this.cues = new Map<string, Cue[]>();

        for(let cue of cues) {

            let activityCues = this.cues.get(cue.activityKey ?? "");
            if (!activityCues) {
                activityCues = [cue];
                this.cues.set(cue.activityKey ?? "", activityCues);
            }
            else {
                activityCues.push(cue);
            }
        }
    }

    /**
     * Returns a payload containing a tip, or undefined if no tip is available.
     */
    public async next(): Promise<Payload | undefined> {

        const activity = this.tracker.track()[0];
        const now = Date.now();

        if (activity === this.lastActivity) {
            if (this.lastTime && ((now - this.lastTime) < REPEAT_INTERVAL)) {
                return undefined;
            }
        }
        else {
            this.lastActivity = activity;
        }

        this.lastTime = now;

        // Get the cues for the activity
        let activityCues = this.cues.get(activity?.key ?? "");
        if (!activityCues || activityCues.length === 0) {
            return undefined;
        }

        // Strip out the last cue that was sent (don't send a dupe)
        //activityCues = activityCues.filter(a => a !== this.lastCue);
        if (activityCues.length === 0) {
            return undefined;
        }

        // Select a random index.
        const index = Math.floor(Math.random() * activityCues.length);
        this.lastCue = activityCues[index];

        return {
            schema: CueSchemaId,
            data: this.lastCue
        }
    }
}