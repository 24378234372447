import React from "react"
import { IonSelect, IonSelectOption } from "@ionic/react"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"

export const EnumReactInput: React.FC<ReactInputProps> = (props) => {

    const schema = props?.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema missing or not an object 
            </ReactInputFailure>
        )
    }

    const options = schema.enum;
    if (!Array.isArray(options)) {
        return (
            <ReactInputFailure>
                Enum values missing or not an array {JSON.stringify(schema)}
            </ReactInputFailure>
        )
    }

    switch(schema.type) {
        case "integer":
        case "number":
            if (options.some(v => typeof(v) !== "number")) {
                return (
                    <ReactInputFailure>
                        All option values must be a number
                    </ReactInputFailure>
                )
            }
            break;

        case "string":
            if (options.some(v => typeof(v) !== "string")) {
                return (
                    <ReactInputFailure>
                        All option values must be strings
                    </ReactInputFailure>
                )
            }
            break;

        default: 
            return (
                <ReactInputFailure>
                    Unsupported enum type {schema.type}
                </ReactInputFailure>
            )
    }

    function onChange(v: string | undefined) {
        if (props.onChange) {
            props.onChange(v);
        }
    }

    return (
        <IonSelect onIonChange={e => onChange(e.detail.value)} placeholder={schema.description} value={props.value}>
        {options.map(v => (
            <IonSelectOption key={v} value={v}>{v}</IonSelectOption>
        ))}
    </IonSelect>
    )
}