import { CatalogPluginKey } from "../catalog/CatalogPluginKey"
import { CatalogRegistryContextKey } from "../catalog/CatalogRegistryContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NWSAlertFeed } from "../nws-alert-feed/NWSAlertFeed"
import { NWSAlertFeedCatalog } from "./NWSAlertFeedCatalog"
import { NWSAlertFeedPluginKey } from "../nws-alert-feed/NWSAlertFeedPluginKey"
import { Payload } from "../payload/Payload"

export const NWSAlertFeedCatalogManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nws-alert-feed-catalog",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CatalogPluginKey,
        NWSAlertFeedPluginKey,
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NWSAlertFeedCatalog",
            registerWith: CatalogRegistryContextKey,
            title: "NWS Alert Feed Catalog",
            value: NWSAlertFeedCatalog
        } as ManifestEntry<Payload<NWSAlertFeed>[]>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NWS Alert Feed Catalog",
};
