/**
 * Returns true if a date (specified as milliseconds from Unix epoch) is sometime today.
 */
export function isToday(value: number | undefined): boolean {

    if (value === undefined) {
        return false;
    }
    
    const d1 = new Date(value);
    const d2 = new Date();

    return (
        (d1.getDate() === d2.getDate()) &&
        (d1.getMonth() === d2.getMonth()) &&
        (d1.getFullYear() === d2.getFullYear())
    );
}