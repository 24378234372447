import { AttachCardView } from "./AttachCardView"
import { AttachPluginKey } from "../attach/AttachPluginKey"
import { AttachSchemaId } from "../attach/AttachSchemaId"
import { CreatePluginKey } from "../create/CreatePluginKey"
import { EditPluginKey } from "../edit/EditPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"

export const AttachCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "attach-card-view",
    
    /**
     * Indicates the plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AttachPluginKey,
        CreatePluginKey,
        EditPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "AttachCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Attach Card View",
            value: {
                layout: "card",
                match: AttachSchemaId,
                fc: AttachCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    title: "Attach Card View",
}