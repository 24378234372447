import { Payload } from "../payload/Payload"
import { Streamer } from "../streamer/Streamer"
import { WhatsNew } from "../whats-new/WhatsNew"
import { WhatsNewSchemaId } from "../whats-new/WhatsNewSchemaId"

/**
 * The storage key to use when tracking the next log item to show.
 */
const STORAGE_KEY = "WhatsNewStreamer.nextIndex";

/**
 * A streamer that feeds out entries from the What's New log. It keeps
 * track of the last item shown so that users will not see the log items
 * more than once.
 */
export class WhatsNewStreamer implements Streamer {

    /**
     * The release log, passed to this class through the plugin manifest.
     */
    private log: WhatsNew[];

    /**
     * The index of the next log entry to show to the user.
     */
    private nextIndex?: number;

    constructor(log: WhatsNew[]) {
        if (!Array.isArray(this.log = log)) {
            throw new Error("log must be specified as an array");
        }
    }

    public async next(): Promise<Payload | undefined> {

        // Get the next index of the log to show
        if (this.nextIndex === undefined) {
            const nextStr = localStorage.getItem(STORAGE_KEY);
            if (nextStr) {
                this.nextIndex = parseInt(nextStr);
            }
            else {
                this.nextIndex = 0;
            }
        }

        // Return if the end of the log has been reached
        if (this.nextIndex >= this.log.length) {
            return undefined;
        }

        // Fetch the next item and increment the index
        const next = this.log[this.nextIndex];
        this.nextIndex++;
        localStorage.setItem(STORAGE_KEY, this.nextIndex.toString());

        // Return the payload
        const nextPayload: Payload<WhatsNew> = {
            schema: WhatsNewSchemaId,
            data: next,
            title: next.title
        }

        return nextPayload;
    }
}