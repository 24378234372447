import { NewsFeed } from "../newsapi-feed/NewsFeed"
import { NewsFeedSchemaId } from "../newsapi-feed/NewsFeedSchemaId"
import { Payload } from "../payload/Payload"

/**
 * The catalog offered by the plugin.
 */
export const NewsFeedCatalog: Payload<NewsFeed>[] = [
    {
        key: "NewsFeedCatalog",
        schema: NewsFeedSchemaId,
        title: "News Headlines (US)",
        data: {
            country: "us",
            apiKey: "" // insert key from newsapi.org    
        }
    },
];