import { DeferSchemaId } from './DeferSchemaId'
import { PayloadSchemaId } from '../payload/PayloadSchemaId'
import { Schema } from "../schema/Schema"
import { TimespanSchemaId } from "../timespan/TimespanSchemaId"

export const DeferSchema: Schema = {

    "$id": DeferSchemaId,
    title: "Defer",
    type: "object",
    properties: {
        "payload": {
            "$ref": PayloadSchemaId,
        },
        "by": {
            "$ref": TimespanSchemaId
        }
    }
} 