import { Payload } from "../payload/Payload";
import { Streamer } from "../streamer/Streamer";
import { StreamerRegistry } from "../streamer-registry/StreamerRegistry"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry";

export class StreamerRegistryStreamer implements Streamer {

    /**
     * The most recent streamer that was used.
     */
    private previous?: StreamerRegistryEntry;

    /**
     * The streamers that will be multiplexed.
     */
    private registry: StreamerRegistry;

    constructor(registry: StreamerRegistry) {
        if (!(this.registry = registry)) {
            throw new Error("registry must be specified");
        }
    }

    /**
     * Returns the next payload from a streamer in the registry.
     */
    public async next(): Promise<Payload | undefined> {
      
        // Get all active streamers
        const active = this.registry.all();

        if (this.previous) {

            // Get the index of the previous streamer
            const index = active.indexOf(this.previous);
            if (index !== -1) {

                // Remove streamer up to the last once
                const move = active.splice(0, index + 1);

                // Move these to the back of the list
                active.push(...move);
            }
        }

        for (let entry of active) {
            const result = await entry.streamer.next();
            if (result) {
                this.previous = entry;
                return result;
            }
        }

        return this.previous = undefined;
    }    
}