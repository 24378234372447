import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { CommandsReducerPluginKey } from "../commands-reducer/CommandsReducerPluginKey"
import { ExtendedCommandsDetail } from "./ExtendedCommandsDetail"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const ExtendedCommandsDetailManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "extended-commands-detail",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey,
        CommandsReducerPluginKey,
        CommanderRegistryPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "ExtendedCommandsDetail",
            registerWith: ReactViewRegistryContextKey,
            title: "Extended Commands Detail",
            value: {
                layout: "detail",
                match: "*",
                fc: ExtendedCommandsDetail,
                order: "last"
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin
     */
    title: "Extends Commands Detail",
};
