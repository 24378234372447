import { Annotator } from "../annotator/Annotator"
import { Flashcard } from "../flashcard/Flashcard"
import { FlashcardSchemaId } from "../flashcard/FlashcardSchemaId"
import { Payload } from "../payload/Payload";

export class FlashcardAnnotator implements Annotator<Flashcard> {

    public async annotate(payload: Payload<Flashcard>): Promise<void> {

        if (payload?.schema !== FlashcardSchemaId) {
            return;
        }

        const flashcard = payload.data;
        if (typeof(flashcard) !== "object") {
            return;
        }

        payload.title = `${flashcard.front}`
    }
}