import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityTracker } from "./ActivityTracker"
import { ActivityTrackerContextKey } from "./ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "./ActivityTrackerPluginKey"
import { ActivityTrackerRegistry } from "./ActivityTrackerRegistry"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ActivityTrackerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ActivityTrackerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ActivityTrackerContextKey,
            title: "Activity Tracker",
            value: new ActivityTrackerRegistry()
        } as ManifestEntry<ActivityTracker>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Tracker"
}