import { formatTimespan } from "../timespan-formatter/formatTimespan"
import { Model } from "../state/Model"
import { Notice } from "../notice/Notice";
import { Notifier } from "../notifier/Notifier";

export class DeferNotifier implements Notifier{

    /**
     * Formats the deferral date for suitability in the notice.
     */
     public formatDate(n: number): string {

        if (!n) {
            return "";
        }

        // Determine whether the defferal date has no time component (e.g., midnight).
        const date = new Date(n);
        const noTime = 
            (date.getHours() === 0) &&
            (date.getMinutes() === 0) &&
            (date.getSeconds() === 0);

        if (noTime) {
            return date.toLocaleDateString();
        }
        else {
            return date.toLocaleString();
        }
    }

    public formatDeferral(n: number): string {

        if (!n) {
            return "";
        }

        const now = Date.now();
        if (n < now) {
            return `Deferred until ${this.formatDate(n)} (${formatTimespan(now - n)} ago)`
        }
        else {
            return `Deferred until ${this.formatDate(n)}`
        }
    }

    public async notify(model: Model): Promise<Notice[]> {

        const payload = model?.payload;
        if (!payload?.deferUntil) {
            return [];
        }

        // Create a notice 
        const notice: Notice = {
            color: "medium",
            title: this.formatDeferral(payload.deferUntil)
        }

        return [notice];
    }

}