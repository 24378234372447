import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { TagAnnotator } from "./TagAnnotator"
import { TagAnnotatorContextKey } from "./TagAnnotatorContextKey"
import { TagPluginKey } from "../tag/TagPluginKey"
import { TagSchemaId } from "../tag/TagSchemaId"

export const TagAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "tag-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        TagPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: TagAnnotatorContextKey,
            value: new TagAnnotator()
        } as ManifestEntry<TagAnnotator>,

        // registration
        {
            key: "TagAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [TagAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: TagSchemaId,
                annotator: context[TagAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<TagAnnotator>>
    ],

    /**
     * The dislay title of the plugin.
     */
    title: "Tag Annotator"
}