import React, { useState } from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { IonChip, IonLabel, IonToast } from "@ionic/react"

export const StringChipView: React.FC<ReactViewProps> = (props) => {

    // Track whether the copy-to-clipboard toast is shown
    const [showToast, setShowToast] = useState(false);

    // Get the text to display in the chip
    const text = props?.model?.payload?.data ?? props?.model?.payload?.title;

    // Handle the chip click event to copy the text to the clipboard
    const onclick = () => {

        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
        navigator.clipboard.writeText(text).then(() => {
            setShowToast(true);
        });
    }

    return (
        <>
            <IonChip onClick={() => onclick()}><IonLabel>{text}</IonLabel></IonChip>
            <IonToast
                duration={200}
                isOpen={showToast}
                message="Copied to clipboard"
                onDidDismiss={() => setShowToast(false)}></IonToast>
        </>
    )
}