import { Service } from "../service/Service";
import { Streamer } from "../streamer/Streamer";
import { StreamerRegistry } from "../streamer-registry/StreamerRegistry"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry";

export class StreamerService implements Service {

    private registry: StreamerRegistry;
    private streamer: Streamer;
    private entry?: StreamerRegistryEntry;

    constructor(streamer: Streamer, registry: StreamerRegistry) {
        
        if (!(this.streamer = streamer)) {
            throw new Error("streamer must be specified");
        }

        if (!(this.registry = registry)) {
            throw new Error("registry must be specified");
        }
    }

    public get running(): boolean {
        return this.entry !== undefined;
    }

    public async start(): Promise<boolean> {
        if (this.entry) {
            return false;
        }
        else {
            this.entry = {
                streamer: this.streamer
            }
            this.registry.register(this.entry);
            return true;
        }
    }

    public async stop(): Promise<boolean> {
        if (this.entry) {
            this.registry.unregister(this.entry);
            this.entry = undefined;
            return true;
        }
        else {
            return false;
        }
    }
}