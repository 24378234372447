import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { WhatsNewCardView } from "./WhatsNewCardView"
import { WhatsNewPluginKey } from "../whats-new/WhatsNewPluginKey"
import { WhatsNewSchemaId } from "../whats-new/WhatsNewSchemaId"

export const WhatsNewCardViewManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: "whats-new-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        WhatsNewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "WhatsNewCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "What's New Card View",
            value: {
                layout: ["card"],
                match: WhatsNewSchemaId,
                fc: WhatsNewCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "What's New Card View"
}