import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryEntry } from "../poller-creator-registry/PollerCreatorRegistryEntry"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"
import { RssPluginKey } from "../rss/RssPluginKey"
import { RssFeedPollerCreator } from "./RssFeedPollerCreator"
import { RssFeedPollerPluginKey } from "./RssFeedPollerPluginKey"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export const RssFeedPollerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: RssFeedPollerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PollerCreatorRegistryPluginKey,
        PollerPluginKey,
        RssPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "RssFeedPollerCreator",
            registerWith: PollerCreatorRegistryContextKey,
            title: "RSS Feed Poller Creator",
            value: {
                schema: RssFeedSchemaId,
                creator: new RssFeedPollerCreator()
            }
        } as ManifestEntry<PollerCreatorRegistryEntry<RssFeedPollerCreator>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Feed Poller"
};
