import { CuePluginKey } from "./CuePluginKey"
import { Manifest } from "../../runtime/Manifest"

export const CueManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: CuePluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Cues"
}