import { ActivityPluginKey } from "./ActivityPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const ActivityManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: ActivityPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin
     */
    title: "Activity"
}