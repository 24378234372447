import { PayloadSchemaId } from "../payload/PayloadSchemaId"
import { PersonSchemaId } from "./PersonSchemaId"
import { Schema } from "../schema/Schema"

export const PersonSchema: Schema = {
    $id: PersonSchemaId,
    title: "Person",
    type: "object",
    properties: {
        "properties": {
            title: "Properties",
            type: "array",
            items: {
                "$ref": PayloadSchemaId
            }
        }
    }
}