import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { TagIndexerContextKey } from "./TagIndexerContextKey"
import { TagIndexer } from "./TagIndexer"

export const TagIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "tag-indexer",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // indexer
        {
            key: TagIndexerContextKey,
            value: new TagIndexer()
        } as ManifestEntry<TagIndexer>,

        // registration
        {
            key: "TagIndexerRegistryEntry",
            registerWith: IndexerRegistryContextKey,
            requires: [TagIndexerContextKey],
            value: (context: ManifestContext) => ({
                schema: "*",
                indexer: context[TagIndexerContextKey]
            })
        } as ManifestEntry<IndexerRegistryEntry<TagIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Tag Indexer"
}