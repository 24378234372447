import { isToday } from "../../library/isToday"
import { Payload } from "../payload/Payload"
import { PayloadPredicate } from "../payload/PayloadPredicate"
import { PayloadPredicates } from "../payload/PayloadPredicates"
import { Task } from "./Task"
import { TaskSchemaId } from "./TaskSchemaId"
import { TaskStatus } from "./TaskStatus"
import { triggers } from "../schedule/triggers"

export class TaskPredicates {

    /**
     * Indicates whether the payload is an active task that should be presented
     * to the user. A task becomes inactive if the payload is deactivated or
     * the task properties do not make it active at the current point in time,
     * e.g., not valid today, already finished today, etc.
     */
    public static active(payload: Payload<Task> | undefined): boolean {

        if (!payload) {
            return false;
        }

        if (payload.schema !== TaskSchemaId) {
            return false;
        }

        // Skip inactive payloads, e.g., blocked, deferred, etc.
        if (!PayloadPredicates.Active(payload)) {
            return false;
        }

        // Skip missing tasks
        const task = payload.data as Task;
        if (!task) {
            return false;
        }

        // Skip tasks that have a non-open status
        if (task.status !== undefined && task.status !== TaskStatus.Open) {
            return false;
        }

        // Skip tasks not scheduled at this moment.
        if (task.schedule) {

            if (!triggers(task.schedule)) {
                return false;
            }

            // Skip repeated daily tasks that are done today.
            // TODO: check repeat property
            if (task.schedule.daysOfWeek) {
                if (task.history) {

                    const doneToday = task.history.some(change => {
                        return change.status === TaskStatus.Completed && isToday(change.changedOn);
                    });

                    if(doneToday) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     * Returns true if the payload is an updatable task that can be canceled.
     */
    public static cancelable(payload: Payload | undefined): boolean {
        return TaskPredicates.closeable(payload);
    }

    /**
     * Returns true if the payload is an updatable task that can be closed.
     */
    public static closeable(payload: Payload | undefined): boolean {

        if (!TaskPredicates.updatable(payload)) {
            return false;
        }

        const task = payload?.data as Task;
        if (!task) {
            return false;
        }

        return (task.status === undefined) || (task.status === TaskStatus.Open);
    }

    /**
     * Returns true if the task has been completed.
     */
    public static completed(payload: Payload<Task> | undefined): boolean {

        if (payload?.schema !== TaskSchemaId) {
            return false;
        }

        const status = payload?.data?.status;
        return status === TaskStatus.Completed;
    }

    /**
     * Returns true if the task was completed today.
     */

    public static completedToday(payload: Payload<Task> | undefined): boolean {

        if(payload?.schema !== TaskSchemaId) {
            return false;
        }

        const task = payload.data;
        if (!task) {
            return false;
        }

        if (!Array.isArray(task.history) || task.history.length === 0) {
            return false;
        }

        const midnight = new Date().setHours(0, 0, 0, 0);

        for(let index = task.history.length; index >= 0; index--) {

            const change = task.history[index];
            if (!change) {
                continue;
            }

            if (change.changedOn < midnight) {
                continue;
            }

            if (change.status === TaskStatus.Completed) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns true if the task was completed yesterday.
     */
    public static completedYesterday(payload: Payload<Task> | undefined): boolean {

        if(payload?.schema !== TaskSchemaId) {
            return false;
        }

        const task = payload.data;
        if (!task) {
            return false;
        }

        if (!Array.isArray(task.history) || task.history.length === 0) {
            return false;
        }

        // Get the timerange for yesterday
        const max = new Date().setHours(0, 0, 0, 0);
        const min = max - 1000 * 60 * 60 * 24;

        for(let index = task.history.length; index >= 0; index--) {

            const change = task.history[index];
            if (!change) {
                continue;
            }

            if (change.changedOn > max) {
                // This change occured after the most recent midnight and can be skipped.
                continue;
            }

            if (change.changedOn < min) {
                // This change occured before yesterday and therefore no more history to check.
                return false;
            }

            if (change.status === TaskStatus.Completed) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns a predicate to fetch a task with the specified ID.
     */
    public static getId(id: number): PayloadPredicate<Task> {

        return (payload: Payload) => {

            if (!payload) {
                return false;
            }

            if (payload.id !== id) {
                return false;
            }

            return payload.schema === TaskSchemaId;
        }
    }

    /**
     * Returns a predicate that filters for overdue one-time tasks.
     */
    public static overdueBy(timespan: number): PayloadPredicate {

        return (payload: Payload | undefined) => {

            // HACK: A creation date is needed until start dates are reliably stored
            if (!payload?.createdOn) {
                return false;
            }

            if (!TaskPredicates.active(payload)) {
                return false;
            }
            
            // Look at one-time tasks only
            const task = payload.data! as Task;
            if (!task.schedule?.daysOfWeek) {
                return false;
            }
            
            const elapsed = Date.now() - payload.createdOn;
            return elapsed > timespan;
        }
    }

    /**
     * Returns true if the payload is an updatable task that can be re-opened.
     */
    public static reopenable(payload: Payload | undefined): boolean {

        if (!TaskPredicates.updatable(payload)) {
            return false;
        }

        const task = payload?.data as Task;
        if (!task) {
            return false;
        }

        switch(task.status) {
            case TaskStatus.Completed:
            case TaskStatus.Canceled:
                return true;

            default:
                return false;
        }
    }
        
    /**
     * Returns true if the specified payload is an updatable task.
     */
    public static updatable(payload: Payload<Task> | undefined): boolean {

        if (payload?.schema !== TaskSchemaId) {
            return false;
        }

        if (!payload.id) {
            return false;
        }

        return payload.data !== undefined;
    }    
}