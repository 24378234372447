import { DefaultRealityChecks } from "./DefaultRealityChecks"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RealityCheckPluginKey } from "../reality-check/RealityCheckPluginKey"
import { RealityCheckStreamer } from "./RealityCheckStreamer"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const RealityCheckStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "reality-check-streamer",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        RealityCheckPluginKey,
        StreamerPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // streamer
        {
            key: "RealityCheckStreamer",
            value: new RealityCheckStreamer(DefaultRealityChecks)
        } as ManifestEntry<RealityCheckStreamer>,

        // registration
        {
            key: "RealityCheckStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: ["RealityCheckStreamer"],
            value: (context: ManifestContext) => ({
                streamer: context["RealityCheckStreamer"]
            })
        } as ManifestEntry<StreamerRegistryEntry<RealityCheckStreamer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reality Check Streamer",
}
