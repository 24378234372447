import { ActivityArrayContextKey } from "../activity-registry/ActivityArrayContextKey"
import { ActivityKeyAnnotator } from "./ActivityKeyAnnotator"
import { ActivityKeyAnnotatorContextKey } from "./ActivityKeyAnnotatorContextKey"
import { ActivityKeyPluginKey } from "../activity-key/ActivityKeyPluginKey"
import { ActivityKeySchemaId } from "../activity-key/ActivityKeySchemaId"
import { ActivityRegistryPluginKey } from "../activity-registry/ActivityRegistryPluginKey"
import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ManifestContext } from "../../runtime/ManifestContext"

export const ActivityKeyAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "activity-key-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityKeyPluginKey,
        ActivityRegistryPluginKey,
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: ActivityKeyAnnotatorContextKey,
            requires: [ActivityArrayContextKey],
            title: "Activity Key Annotator",
            value: (context: ManifestContext) => new ActivityKeyAnnotator(context[ActivityArrayContextKey])
        } as ManifestEntry<ActivityKeyAnnotator>,

        // registration
        {
            key: "ActivityKeyAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [ActivityKeyAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: ActivityKeySchemaId,
                annotator: context[ActivityKeyAnnotatorContextKey]                
            })
        } as ManifestEntry<AnnotatorRegistryEntry<ActivityKeyAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Key Annotator"
}
