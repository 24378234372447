import { HeartbeatService } from "./HeartbeatService"

export abstract class SyncService<TSource, TSourceKey, TTarget> extends HeartbeatService {

    private map = new Map<TSourceKey, TTarget>();

    /**
     * Called when the service should synchronized.
     */
    protected async onHeartbeat(): Promise<void> {

        // Get current items from the data source.
        const sourceItems = await this.onFetch();

        // Build a set of keys for fast lookups.
        const loadedKeys = new Set(this.map.keys());

        for(const sourceItem of sourceItems) {

            const sourceKey = this.onKey(sourceItem);
            if (!sourceKey) {
                continue;
            }

            // Remove keys when processed (which will leave orphaned items)
            if (loadedKeys.has(sourceKey)) {
                loadedKeys.delete(sourceKey);
                continue;
            }

            const targetItem = await this.onAdd(sourceItem);
            if (targetItem) {
                this.map.set(sourceKey, targetItem);
                console.debug(`${this.constructor.name} added ${sourceKey}`);
            }
        }

        for(let orphanedKey of loadedKeys) {
            const orphanedItem = this.map.get(orphanedKey!);
            if (orphanedItem) {
                this.map.delete(orphanedKey!);
                await this.onRemove(orphanedItem);
            }
        }
    }

     /**
      * Called when a new payload is loaded into the service.
      */
    protected abstract onAdd(item: TSource): Promise<TTarget | undefined>;

    /**
     * Returns all items to synchronize against.
     */
    protected abstract onFetch(): Promise<TSource[]>;

    /**
     * Returns the key of the item.
     */
    protected abstract onKey(item: TSource): TSourceKey | undefined;

    /**
     * Called when an item is removed.
     */
    protected abstract onRemove(item: TTarget): Promise<void>;
}