import { ConfirmSchemaId } from "./ConfirmSchemaId"
import { Schema } from "../schema/Schema"

export const ConfirmSchema: Schema = {
    "$id": ConfirmSchemaId,
    title: "Confirm",
    type: "object",
    properties: {
        "title": {
            title: "Title",
            type: "string"
        }
    }
}