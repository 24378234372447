import { NWSAlertFeedSchemaId } from "./NWSAlertFeedSchemaId"
import { Schema } from "../schema/Schema"

export const NWSAlertFeedSchema: Schema = {

    "$id": NWSAlertFeedSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "Weather alerts from the National Weather Service",
    title: "NWS Weather Alerts",

    properties: {

        "area": {
            type: "string",
            description: "State or marine area code",
            enum: [
                "AL", // Alabama
                "AK", // Alaska
                "AS", // American Samoa
                "AR", // Arkansas
                "AZ", // Arizona
                "CA", // California
                "CO", // Colorado
                "CT", // Connecticut
                "DE", // Deleware
                "DC", // District of Columbia
                "FL", // Florida
                "GA", // Georgia
                "GU", // Guam
                "HI", // Hawaii
                "ID", // Idaho
                "IL", // Illinois
                "IN", // Indiana
                "IA", // Iowa
                "KS", // Kansas
                "KY", // Kentucky
                "LA", // Louisiana
                "ME", // Maine
                "MD", // Maryland
                "MA", // Massachusetts
                "MI", // Michigan
                "MN", // Minnesota
                "MS", // Mississippi
                "MO", // Missouri
                "MT", // Montana
                "NE", // Nebraska
                "NV", // Nevada
                "NH", // New Hampshire
                "NJ", // New Jersey
                "NM", // New Mexico
                "NY", // New York
                "NC", // North Carolina
                "ND", // North Dakota
                "OH", // Ohio
                "OK", // Oklahoma
                "OR", // Oregon
                "PA", // Pennsylvania
                "PR", // Puerto Rico
                "RI", // Rhode Island
                "SC", // South Carolina
                "SD", // South Dakota
                "TN", // Tennessee
                "TX", // Texas
                "UT", // Utah
                "VT", // Vermont
                "VI", // Virgin Islands
                "VA", // Virginia
                "WA", // Washington
                "WV", // West Virginia
                "WI", // Wisconsin
                "WY", // Wyoming
                "PZ", // Eastern N. Pacific Ocean
                "PK", // N. Pacific Ocean Near Alaska
                "PH", // Central Pacific Ocean
                "PS", // S. Central Pacific Ocean
                "PM", // Western Pacific Ocean
                "AN", // Northwest N. Atlantic Ocean
                "AM", // West N. Atlantic Ocean
                "GM", // Gulf of Mexico
                "LS", // Lake Superior
                "LM", // Lake Michigan
                "LH", // Lake Huron
                "LC", // Lake St. Claire
                "LE", // Lake Erie
                "LO"  // Lake Ontario
            ],
            title: "Area"
        },
    }
}