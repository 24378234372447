import { CatalogPluginKey } from "../catalog/CatalogPluginKey"
import { CatalogRegistryContextKey } from "../catalog/CatalogRegistryContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { StreamerCreatorPluginKey } from "../streamer-creator/StreamerCreatorPluginKey"
import { StreamerCreatorRegistryContextKey } from "../streamer-creator-registry/StreamerCreatorRegistryContextKey"
import { StreamerCreatorRegistryEntry } from "../streamer-creator-registry/StreamerCreatorRegistryEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"
import { SunriseSunsetCard } from "./SunriseSunsetCard"
import { SunriseSunsetCatalog } from "./SunriseSunsetCatalog"
import { SunriseSunsetFeed } from "./SunriseSunsetFeed"
import { SunriseSunsetFeedSchema } from "./SunriseSunsetFeedSchema"
import { SunriseSunsetSchemaId } from "./SunriseSunsetSchemaId"
import { SunriseSunsetStreamerCreator } from "./SunriseSunsetStreamerCreator"

export const SunriseSunsetManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: "sunrise-sunset",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: false,

    /**
     * The display description of the plugin.
     */
    description: "Show the sunrise and sunset each day",

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CatalogPluginKey,
        ReactViewPluginKey,
        StreamerCreatorPluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // streamer creator
        {
            key: "SunriseSunsetStreamerCreator",
            title: "Sunrise Sunset Streamer Creator",
            value: new SunriseSunsetStreamerCreator()
        } as ManifestEntry<SunriseSunsetStreamerCreator>,

        // streamer registration
        {
            key: "SunriseSunsetStreamerCreatorRegistryEntry",
            registerWith: StreamerCreatorRegistryContextKey,
            requires: ["SunriseSunsetStreamerCreator"],
            value: (context: ManifestContext) => ({
                creator: context["SunriseSunsetCreator"],
                schema: SunriseSunsetFeedSchema
            })
        } as ManifestEntry<StreamerCreatorRegistryEntry<SunriseSunsetStreamerCreator>>,

        {
            key: "SunriseSunsetCard",
            registerWith: ReactViewRegistryContextKey,
            title: "Sunrise Sunset Card",
            value: {
                layout: "card",
                match: SunriseSunsetSchemaId,
                fc: SunriseSunsetCard    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
        {
            key: "SunriseSunsetCatalog",
            registerWith: CatalogRegistryContextKey,
            title: "Sunrise Sunset Catalog",
            value: SunriseSunsetCatalog
        } as ManifestEntry<Payload<SunriseSunsetFeed>[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Sunrise/Sunset",
};