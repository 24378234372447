import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { MoveCommander } from "./MoveCommander"
import { MovePluginKey } from "../move/MovePluginKey"

export const MoveCommanderManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: "move-commander",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey,
        CommanderRegistryPluginKey,
        MovePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "MoveCommander",
            registerWith: CommanderArrayContextKey,
            title: "Move Commander",
            value: new MoveCommander()
        } as ManifestEntry<MoveCommander>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Move Commander"
}