import { RssChannel } from './RssChannel';
import { RssGuid } from './RssGuid';
import { RssItem } from './RssItem';

export function parseRss(xml: Document): RssChannel | undefined {
    const el = xml.querySelector("channel");
    if (!el) {
        // Note: be sure no whitespace exists before the <?xml opening tag. This is
        // easy to happen when formatting sample data for unit tests. If that happens,
        // the XML will not parse and the <channel> node will not be found.
        return undefined;
    }
    else {
        return parseRssChannel(el);
    }
};

function parseRssChannel(el: Element): RssChannel {

    const channel: RssChannel = {
        items: []
    }

    for(let index = 0; index < el.children.length; index++) {

        // Get the next child element
        const child = el.children[index];

        switch(child.nodeName) {

            case "copyright":
                channel.copyright = parseRssInnerHtml(child);
                break;

            case "description":
                channel.description = parseRssInnerHtml(child);
                break;

            case "generator":
                channel.generator = parseRssInnerHtml(child);
                break;

            case "item":
                const item = parseRssItem(child);
                if (item) {
                    channel.items.push(item);
                }
                break;

            case "language":
                channel.language = parseRssInnerHtml(child);
                break;

            case "lastBuildDate":
                channel.lastBuildDate = parseRssInnerHtml(child);
                break;

            case "link":
                channel.link = parseRssInnerHtml(child);
                break;

            case "title":
                channel.title = parseRssInnerHtml(child);
                break;
        }
    }

    return channel;
}

function parseRssGuid(el: Element | null): RssGuid | undefined {

    if(!el) {
        return undefined;
    }

    return {
        value: el.innerHTML
    }
}

function parseRssInnerHtml(el: Element | null): string | undefined {

    if (!el) {
        return undefined;
    }

    if (!el.innerHTML) {
        return undefined;
    }

    // Strip whitespace - some feeds put excess whitespace around the content
    const inner = el.innerHTML.trim();
    
    if(inner.startsWith("<![CDATA[") && inner.endsWith("]]>")) {
        return inner.substr(9, inner.length - 12);
    }
    else {
        return inner;
    }
}

function parseRssItem(el: Element): RssItem {
    
    const item: RssItem = {
    }

    for(let index = 0; index < el.children.length; index++) {

        const child = el.children[index];
        switch(child.nodeName) {

            case "category":
                const category = parseRssInnerHtml(child);
                if (category) {
                    if (item.categories === undefined) {
                        item.categories = [category];
                    }
                    else {
                        item.categories.push(category);
                    }
                }
                break;
                
            case "description":
                item.description = parseRssInnerHtml(child);
                break;
            
            case "guid":
                item.guid = parseRssGuid(child);
                break;

            case "link":
                item.link = parseRssInnerHtml(child);
                break;

            case "pubDate":
                item.pubDate = parseRssInnerHtml(child);
                break;

            case "title":
                item.title = parseRssInnerHtml(child);
                break;
        }
    }

    return item;
}