import { Payload } from "../payload/Payload";
import { ServiceCreator } from "../service-creator/ServiceCreator"
import { Service } from "../service/Service";
import { StreamerCreator } from "../streamer-creator/StreamerCreator"
import { StreamerRegistry } from "../streamer-registry/StreamerRegistry";
import { StreamerService } from "./StreamerService";

export class StreamerServiceCreator implements ServiceCreator {

    private creator: StreamerCreator;
    private registry: StreamerRegistry;

    constructor(creator: StreamerCreator, registry: StreamerRegistry) {
        if (!(this.creator = creator)) {
            throw new Error("creator not specified");
        }
        if (!(this.registry = registry)) {
            throw new Error("registry not specified");
        }
    }

    public async create(payload: Payload): Promise<Service | undefined> {
        const streamer = await this.creator.create(payload);
        if (streamer) {
            return new StreamerService(streamer, this.registry);
        }
        else {
            return undefined;
        }
    }
}