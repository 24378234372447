import { IDBPDatabase } from "idb"
import { Folder } from "../folder/Folder";
import { FolderSchemaId } from "../folder/FolderSchemaId";
import { Notice } from "../notice/Notice";
import { NoticeSchemaId } from "../notice/NoticeSchemaId";
import { Payload } from "../payload/Payload"

const MIGRATE_KEY = "BetaDataMigrated";
const MIGRATE_TABLE = "Payloads";

/**
 * Defines properties that were once on a payload but since removed.
 */
export interface ObsoletePayload extends Payload {

    /**
     * Used until Sept 26, 2021 and migrated with migration #6.
     * Specifies the path to an item, e.g., "name/id/id/id".
     * The path property was changed to a numeric parentId.
     */
    path?: string;
}

export async function migrateBeta(db: IDBPDatabase): Promise<IDBPDatabase> {
    return          migrateBeta1(db)  // 24-AUG-2021
        .then(db => migrateBeta2(db)) // 25-AUG-2021
        .then(db => migrateBeta3(db)) // 31-AUG-2021
        .then(db => migrateBeta4(db)) // 01-SEP-2021
        .then(db => migrateBeta5(db)) // 18-SEP-2021
        .then(db => migrateBeta6(db)) // 26-SEP-2021
}

/**
 * Migrates obsolete schemas during development...
 */
export async function migrateBeta1(db: IDBPDatabase): Promise<IDBPDatabase> {

    const v = localStorage.getItem(MIGRATE_KEY);
    if (v) {
        return db;
    }

    // Map obsolete schemas IDs to new schema IDs
    const schemaMap = new Map<string, string>([
        ["Activity",             "https://motley.stream/schema/activity/1.0"],
        ["ActivityKey",          "https://motley.stream/schema/activity-key/1.0"],
        ["Bill",                 "https://motley.stream/schema/bill/1.0"],
        ["BingWallpaper",        "https://motley.stream/schema/bing-wallpaper/1.0"],
        ["BingWallpaperFeed",    "https://motley.stream/schema/bing-wallpaper-feed/1.0"],
        ["CAP1.2",               "https://motley.stream/schema/nws-weather-alert/1.0"],
        ["Copyright",            "https://motley.stream/schema/copyright/1.0"],
        ["CreateFromForm",       "https://motley.stream/schema/create/1.0"],
        ["Cue",                  "https://motley.stream/schema/cue/1.0"],
        ["DateNumber",           "https://motley.stream/schema/date-number/1.0"],
        ["DefaultPayload",       "https://motley.stream/schema/default-payload/1.0"],
        ["earthquake",           "https://motley.stream/schema/usgs-earthquake/1.0"],
        ["EarthquakeFeedSchema", "https://motley.stream/schema/usgs-earthquake-feed/1.0"],
        ["EmailAddress",         "https://motley.stream/schema/email-address/1.0"],
        ["Goal",                 "https://motley.stream/schema/goal/1.0"],
        ["Highlight",            "https://motley.stream/schema/highlighter/1.0"],
        ["ISO4217Code",          "https://motley.stream/schema/iso-4217-code/1.0"],
        ["json",                 "https://motley.stream/schema/json/1.0"],
        ["MessierNumber",        "https://motley.stream/schema/messier-number/1.0"],
        ["ms",                   "https://motley.stream/schema/timespan-ms/1.0"],
        ["NasaApod",             "https://motley.stream/schema/nasa-apod/1.0"],
        ["NasaApodFeedSchema",   "https://motley.stream/schema/nasa-apod-feed/1.0"],
        ["newsapi.org/v2",       "https://motley.stream/schema/newsapi-article/1.0"],
        ["NewsFeedSchema",       "https://motley.stream/schema/newsapi-feed/1.0"],
        ["NgcNumber",            "https://motley.stream/schema/ngc-number/1.0"],
        ["Notice",               "https://motley.stream/schema/notice/1.0"],
        ["NWSAlertFeed",         "https://motley.stream/schema/nws-alert-feed/1.0"],
        ["Path",                 "https://motley.stream/schema/path/1.0"],
        ["Payment",              "https://motley.stream/schema/payment/1.0"],
        ["Quote",                "https://motley.stream/schema/quote/1.0"],
        ["reality-check",        "https://motley.stream/schema/reality-check/1.0"],
        ["reddit/thing",         "https://motley.stream/schema/reddit-thing/1.0"],
        ["rss/item",             "https://motley.stream/schema/rss-item/1.0"],
        ["RssFeed",              "https://motley.stream/schema/rss-feed/1.0"],
        ["Schedule",             "https://motley.stream/schema/schedule/1.0"],
        ["Search",               "https://motley.stream/schema/search/1.0"],
        ["Sequence",             "https://motley.stream/schema/sequence/1.0"],
        ["Snippet",              "https://motley.stream/schema/snippet/1.0"],
        ["Splash",               "https://motley.stream/schema/splash/1.0"],
        ["string",               "https://motley.stream/schema/string/1.0"],
        ["SubredditFeed",        "https://motley.stream/schema/subreddit-feed/1.0"],
        ["suggested-tasks",      "https://motley.stream/schema/suggested-tasks/1.0"],
        ["Task",                 "https://motley.stream/schema/task/1.0"],
        ["url",                  "https://motley.stream/schema/url/1.0"],
        ["WikipediaSummary",     "https://motley.stream/schema/wikipedia-summary/1.0"]
    ]);

    // Add a few oddball schemas that were used before normalizing version numbers..
    schemaMap.set(
        "https://motley.stream/schema/NasaApodFeed",
        schemaMap.get("NasaApodFeedSchema")!);

    schemaMap.set(
        "https://motley.stream/schema/rss-feed",
        schemaMap.get("RssFeed")!);

    // Start a transaction against the table
    const transaction = db.transaction(MIGRATE_TABLE, "readwrite");

    // Update the records
    await transaction.store.openCursor().then(function iterate(cursor: any) {

        if (!cursor) {
            return;
        }

        const payload = cursor.value as Payload;
        if (!payload) {
            return cursor.continue().then(iterate)
        }

        const schemaId = payload.schema;
        if (!schemaId) {
            return cursor.continue().then(iterate)
        }

        const toSchemaId = schemaMap.get(schemaId);
        if (!toSchemaId) {
            return cursor.continue().then(iterate)
        }

        payload.schema = toSchemaId;

        return cursor.update(payload).then((v: any) => cursor.continue().then(iterate));
    });

    localStorage.setItem(MIGRATE_KEY, "1");
    return db;
}

/**
 * Migrates snippet payloads that were not updated to the newer schema IDs
 */
export async function migrateBeta2(db: IDBPDatabase): Promise<IDBPDatabase> {

    const v = localStorage.getItem(MIGRATE_KEY);
    if (v) {
        const vn = parseInt(v);
        if (vn >= 2) {
            return db;
        }
    }

    const transaction = db.transaction(MIGRATE_TABLE, "readwrite");

    // Locate snippets and update them (missed during migration #1)
    await transaction.store.openCursor().then(function iterate(cursor: any) {

        if (!cursor) {
            return;
        }

        const payload = cursor.value as Payload;
        if (!payload) {
            return cursor.continue().then(iterate)
        }

        const schemaId = payload.schema;
        if (schemaId !== "https://motley.stream/schema/snippet/1.0") {
            return cursor.continue().then(iterate)
        }

        // Get the attachment of this payload
        const attachment = payload.data.payload as Payload;
        if (!attachment) {
            return cursor.continue().then(iterate)
        }

        // Update the schema of the attachment
        switch(attachment.schema) {
            case "string":
                attachment.schema = "https://motley.stream/schema/string/1.0";
                break;

            case "url":
                attachment.schema = "https://motley.stream/schema/url/1.0";
                break;

            default:
                return cursor.continue().then(iterate)
        }

        return cursor.update(payload).then((v: any) => cursor.continue().then(iterate));
    });

    localStorage.setItem(MIGRATE_KEY, "2");
    return db;
}
/**
 * Migration #3 (August 31, 2021). This migration changes the structure of snippets.
 */
export async function migrateBeta3(db: IDBPDatabase): Promise<IDBPDatabase> {

    const v = localStorage.getItem(MIGRATE_KEY);
    if (v) {
        const vn = parseInt(v);
        if (vn >= 3) {
            return db;
        }
    }

    // Open a transaction so that snippet payloads can be changed
    const transaction = db.transaction(MIGRATE_TABLE, "readwrite");
    
    // Locate snippets and move payload properties to attachments
    await transaction.store.openCursor().then(function iterate(cursor: any) {

        if (!cursor) {
            return;
        }

        const payload = cursor.value as Payload;
        if (!payload) {
            return cursor.continue().then(iterate)
        }

        const schemaId = payload.schema;
        if (schemaId !== "https://motley.stream/schema/snippet/1.0") {
            return cursor.continue().then(iterate)
        }

        // Get the attachment of this payload
        const attachment = payload.data?.payload as Payload;
        if (!attachment) {
            return cursor.continue().then(iterate)
        }
        else {
            payload.attachments = payload.attachments ?? [];
            payload.attachments.push(attachment);
            delete payload.data.payload;
            return cursor.update(payload).then((v: any) => cursor.continue().then(iterate));
        }            
    });

    localStorage.setItem(MIGRATE_KEY, "3");
    return db;        
}

/**
 * Beta migration #4: converting favorites to tags.
 * 
 * In this migration, the app is moving away from using a folder to track favorites.
 * Instead, payloads are expanded to have a tag array. That allows a tag of #favorite to be
 * used to track favorites. Also note the indexDB is upgraded with an index to make it
 * easy to find payloads of a given tag.
 * 
 */
export async function migrateBeta4(db: IDBPDatabase): Promise<IDBPDatabase> {

    const v = localStorage.getItem(MIGRATE_KEY);
    if (v) {
        const vn = parseInt(v);
        if (vn >= 4) {
            return db;
        }
    }

    // Open a transaction so that snippet payloads can be changed
    const transaction = db.transaction(MIGRATE_TABLE, "readwrite");
    
    // Locate snippets and move payload properties to attachments
    await transaction.store.openCursor().then(function iterate(cursor: any) {

        if (!cursor) {
            return;
        }

        const payload = cursor.value as ObsoletePayload; // needs obsolete .path property
        if (!payload) {
            return cursor.continue().then(iterate)
        }

        // Skip any payloads not located in the Favorites path
        if (payload.path !== "Favorites") {
            return cursor.continue().then(iterate)
        }

        // Add the favorites tag
        if (Array.isArray(payload.tags)) {
            if (payload.tags.includes("#favorite")) {

                // Skip if already added
                return cursor.continue().then(iterate)
            }
            else {
                payload.tags.push("#favorite");
            }
        }
        else {
            payload.tags = ["#favorite"];
        }

        return cursor.update(payload).then((v: any) => cursor.continue().then(iterate));
    });

    localStorage.setItem(MIGRATE_KEY, "4");
    return db;    
}

/**
 * Beta migration #5: Named path conversion to folders. In this migration,
 * any payloads with a string root path (like "Tasks") are converted to 
 * a numeric path to a folder.
 */
export async function migrateBeta5(db: IDBPDatabase): Promise<IDBPDatabase> {

    const v = localStorage.getItem(MIGRATE_KEY);
    if (v) {
        const vn = parseInt(v);
        if (vn >= 5) {
            return db;
        }
    }

    const all: ObsoletePayload[] = await db.getAll(MIGRATE_TABLE);
    const folders = new Map<string, ObsoletePayload>();
    const paths = new Map<string, Set<ObsoletePayload>>();

    for(const payload of all) {

        if (!payload.path) {

            // No path to upgrade. See if this is a folder.
            if (payload.schema !== FolderSchemaId) {
                continue;
            }

            // Make sure this is a root folder.
            if (payload.parentId) {
                continue;
            }

            // Get the name of this root folder
            const folder = payload.data as Folder;
            if (!folder?.name) {
                continue;
            }

            // Add to the map of root folders
            folders.set(folder.name, payload);
            continue;
        }

        const parts = payload.path.split('/');
        if (parseInt(parts[0]) > 0) {

            // This path has already been upgraded to be fully numeric.
            continue;
        }

        // This payload has a string path. Keep track of which string it is under.
        let payloadSet = paths.get(parts[0]);
        if (!payloadSet) {
            payloadSet = new Set<Payload>();
            paths.set(parts[0], payloadSet);
        }

        payloadSet.add(payload);
    }

    /**
     * Create a folder for each missing path.
     */
    for(let path of paths.keys()) {

        // Get or create the folder payload for this key
        let folderPayload = folders.get(path);
        if (!folderPayload) {

            // Initialize a folder that maps to the historical path, e.g., "Tasks"
            folderPayload = {
                key: path,
                schema: FolderSchemaId,
                data: {
                    name: path
                },
                attachments: [
                    {
                        schema: NoticeSchemaId,
                        data: {
                            title: "This folder was migrated from historical data. You can move contents if you wish."
                        }
                    } as Payload<Notice>
                ]
            }

            const id = await db.put(MIGRATE_TABLE, folderPayload);
            folderPayload.id = id as number;
            folders.set(path, folderPayload);
        }

        // The historical folder has been created. Now update each
        // payload that has an old string-style path. Each should 
        // point to the historical folder ID.
        const payloads = paths.get(path);
        if (payloads) {
            for (const payload of payloads) {
                const parts = payload.path!.split('/');
                const folder = folders.get(parts[0])!;
                parts[0] = folder.id!.toString();
                payload.path = parts.join('/');
                await db.put(MIGRATE_TABLE, payload);
            }
        }
    }

    localStorage.setItem(MIGRATE_KEY, "5");
    return db;       
}

export async function migrateBeta6(db: IDBPDatabase): Promise<IDBPDatabase> {

    const v = localStorage.getItem(MIGRATE_KEY);
    if (v) {
        const vn = parseInt(v);
        if (vn >= 6) {
            return db;
        }
    }

    // Open a transaction so that paths can be migrated in a cursor loop
    const transaction = db.transaction(MIGRATE_TABLE, "readwrite");
    
    await transaction.store.openCursor().then(function iterate(cursor: any) {

        if (!cursor) {
            return;
        }

        const payload = cursor.value as ObsoletePayload;
        if (!payload) {
            return cursor.continue().then(iterate)
        }

        // Skip payloads that already have a parent
        if (payload.parentId) {
            return cursor.continue().then(iterate)
        }

        // Skip payloads that don't have a path
        if (!payload.path) {
            return cursor.continue().then(iterate)
        }

        // Get the parts of the path
        const parts = payload.path.split("/");
        if (parts.length === 0) {
            return cursor.continue().then(iterate)
        }

        // Set the parent
        payload.parentId = parseInt(parts[parts.length - 1]);
        delete payload.path;

        return cursor.update(payload).then((v: any) => cursor.continue().then(iterate));
    });


    localStorage.setItem(MIGRATE_KEY, "6");
    return db;
}

