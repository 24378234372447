import { NasaApod } from "../nasa-apod/NasaApod"
import { NasaApodSchemaId } from "../nasa-apod/NasaApodSchemaId"
import { Copyright } from "../copyright/Copyright"
import { CopyrightSchemaId } from "../copyright/CopyrightSchemaId"
import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { SearchStringSchemaId } from "../search-string/SearchStringSchemaId"

export class NasaApodIndexer implements Indexer {

    public async index(payload: Payload): Promise<Payload[]> {

        if (!payload) {
            return [];
        }

        if (payload.schema !== NasaApodSchemaId) {
            return [];
        }

        const apod = payload.data as NasaApod;
        if (!apod) {
            return [];
        }

        if (apod.copyright) {

            const copyrightPayload: Payload<Copyright> = {
                schema: CopyrightSchemaId,
                data: {
                    notice: `© ${apod.copyright}`
                }
            }

            const searchCopyrightPayload: Payload = {
                schema: SearchStringSchemaId,
                data: apod.copyright,
                title: apod.copyright
            }

            return [copyrightPayload, searchCopyrightPayload];   
        }
        else {
            return [];
        }
    }
}