import React from "react"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"

export const EditStreamView: React.FC<ReactViewProps> = (props) => {

    /**
     * Called when the edit card dispatches an edited payload.
     * Per the conventions of the edit card, the payload has not been
     * saved at this point. The edit card is only responsible for 
     * applying changes and dispatching the edited payload.
     */
    function onEdit(payload: Payload) {

        if(payload) {

            // Wrap the edited payload into a Save payload. The Save
            // payload will be handled by a view that is capable of
            // asking for confirmation and saving to storage.
            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload
                }
            }

            if (props.dispatch) {
                props.dispatch(savePayload);
            }
        }
    }

    return (
        <ReactViewFactory
            layout="card"
            dispatch={p => onEdit(p)}
            matcher={props.matcher}
            model={props.model}>
        </ReactViewFactory>
    );
}