import React from "react"
import { Notice } from "../notice/Notice"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./NoticeCardView.css"

export const NoticeCardView: React.FC<ReactViewProps> = (props) => {

    const notice = props?.model?.payload?.data as Notice;
    
    return (
        <div className="notice-card">
            <h1>{notice?.title}</h1>
        </div>
    );
}