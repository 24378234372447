import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { FormatterAnnotator } from "./FormatterAnnotator"
import { FormatterAnnotatorContextKey } from "./FormatterAnnotatorContextKey"
import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const FormatterAnnotatorManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "formatter-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        FormatterPluginKey,
        FormatterRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: FormatterAnnotatorContextKey,
            requires: [FormatterRegistryContextKey],
            value: (context: ManifestContext) =>
                new FormatterAnnotator(context[FormatterRegistryContextKey])
        } as ManifestEntry<FormatterAnnotator>,

        // registration
        {
            key: "FormatterAnnotatorRegistryEntry",
            requires: [FormatterAnnotatorContextKey],
            registerWith: AnnotatorRegistryContextKey,
            value: (context: ManifestContext) => ({
                schema: "*",
                annotator: context[FormatterAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<FormatterAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Formatter Annotator"
}