import { Cue } from "../cue/Cue"
import { CueSchemaId } from "../cue/CueSchemaId"
import { Notice } from "../notice/Notice"
import { NoticeSchemaId } from "../notice/NoticeSchemaId"
import { Payload } from "../payload/Payload"
import { Sequence } from "../sequence/Sequence"

export const introSequence: Sequence = {

    /**
     * The unique key of the sequence.
     */
    key: "introSequence",

    /**
     * The selector to use when selecting each item from the sequence.
     */
    selector: "once",
    
    /**
     * The items of the sequence.
     */
    items: [
        {
            schema: NoticeSchemaId,
            title: "motley.stream",
            data: {
                key: "#this",
                title: "motley.stream"
            } 
        } as Payload<Notice>,
        {
            schema: CueSchemaId,
            data: {
                title: "Challenge reality"
            }
        } as Payload<Cue>
    ]
}
