import { GoalPluginKey } from "../goal/GoalPluginKey"
import { GoalSchemaId } from "../goal/GoalSchemaId"
import { GoalStreamerCreator } from "./GoalStreamerCreator"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerCreatorPluginKey } from "../streamer-creator/StreamerCreatorPluginKey"
import { StreamerCreatorRegistryPluginKey } from "../streamer-creator-registry/StreamerCreatorRegistryPluginKey"
import { StreamerCreatorRegistryContextKey } from "../streamer-creator-registry/StreamerCreatorRegistryContextKey"
import { StreamerCreatorRegistryEntry } from "../streamer-creator-registry/StreamerCreatorRegistryEntry"

export const GoalStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "goal-streamer",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        GoalPluginKey,
        StreamerPluginKey,
        StreamerCreatorPluginKey,
        StreamerCreatorRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // creator
        {
            key: "GoalStreamerCreator",
            value: new GoalStreamerCreator()
        } as ManifestEntry<GoalStreamerCreator>,

        // registration
        {
            key: "GoalStreamerCreatorRegistryEntry",
            requires: ["GoalStreamerCreator"],
            registerWith: StreamerCreatorRegistryContextKey,
            value: (context: ManifestContext) => ({
                schema: GoalSchemaId,
                creator: context["GoalStreamerCreator"]
            })
        } as ManifestEntry<StreamerCreatorRegistryEntry<GoalStreamerCreator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Goal Streamer"
}