import React from "react"
import { checkmarkCircle as completedIcon } from "ionicons/icons"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { Formatter } from "../formatter/Formatter"
import { IonCard, IonCardContent, IonIcon } from "@ionic/react"
import { Task } from "../task/Task"
import { TaskSchemaId } from "../task/TaskSchemaId"
import { TaskStatus } from "../task/TaskStatus"
import { TimespanSchemaId } from "../timespan/TimespanSchemaId"
import "./TaskHistoryDetailView.css"

export interface TaskHistoryDetailViewProps extends ReactViewProps {
    formatter: Formatter
}

/**
 * A control that displays the history of a task in the detail section.
 */
export const TaskHistoryDetailView: React.FC<TaskHistoryDetailViewProps> = props => {

    const payload = props?.model?.payload;
    if (!payload) {
        return <></>
    }

    if (payload.schema !== TaskSchemaId) {
        return <></>
    }

    const task = payload.data as Task;
    if (!task) {
        return <></>
    }    
    
    if (!Array.isArray(task.history) || task.history.length === 0) {
        return <></>
    }

    const history = [...task.history].filter(c => c.status = TaskStatus.Completed);
    history.sort((a,b) => a.changedOn - b.changedOn).reverse();

    return (
        <IonCard>
            <IonCardContent>
                <div className="TaskHistory">
                    <div className="TaskHistoryTitle">History</div>
                    {history.map((change, index) => (
                        <div className="TaskHistoryRow" key={String(change.changedOn) + ":" + String(index)}>
                            <IonIcon
                                className="TaskHistoryIcon"
                                icon={completedIcon}
                                color="success"
                                size="large"></IonIcon>
                            {props.formatter.format(DateNumberSchemaId, change.changedOn)}
                            <div className="TaskHistoryTimespan">
                                {props.formatter.format(TimespanSchemaId, Date.now() - change.changedOn)}
                                <span>&nbsp;ago</span>
                            </div>
                        </div>
                    ))}
                </div>
            </IonCardContent>
        </IonCard>
    )
}