import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Timer } from "../timer/Timer"
import { TimerSchemaId } from "../timer/TimerSchemaId"

export class TimerAnnotator implements Annotator<Timer> {

    /**
     * Annotates a Timer payload.
     */
    public async annotate(payload: Payload | undefined): Promise<void> {

        if (payload?.schema !== TimerSchemaId) {
            return;
        }

        const timer = payload.data as Timer;
        if (!timer) {
            return;
        }

        if (!timer.timespan) {
            return;
        }

        // .key
        payload.key = timer.timespan.toString();

        // .title
        payload.title = timer.name ?? `${timer.timespan.toString()} ms`;
    }
}