import { CreatePluginKey } from "../create/CreatePluginKey"
import { CreateToolbarButton } from "./CreateToolbarButton"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const CreateToolbarButtonManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin
     */
    key: "create-toolbar-button",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CreatePluginKey,
        ReactViewPluginKey
    ],

    /**
     * The description of the plugin shown to the user.
     */
    description: "Provides a toolbar button for quickly creating a payload.",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "CreateToolbarButton",
            registerWith: ReactViewRegistryContextKey,
            value: {
                layout: "toolbar-button",
                match: "*",
                fc: CreateToolbarButton    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Create Toolbar Button"
}