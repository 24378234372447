import React, { useEffect } from "react"
import { IonContent } from "@ionic/react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const StreamPageSection: React.FC<ReactViewProps> = (props) => {

    useEffect(() => {
        // HACK: causes flashing
        const content: any = document.querySelector("#StreamPageSection");
        if (content) {
            content.scrollToTop(400)
        }
    }, [props.model]);

    if (!props.model) {
        return <></>
    }
    
    return (
        <IonContent id="StreamPageSection">

            <ReactViewFactory
                dispatch={props.dispatch}
                layout="stream"
                matcher={props.matcher}
                model={props.model}></ReactViewFactory>

            <ReactViewFactory
                dispatch={props.dispatch}
                layout="detail"
                matcher={props.matcher}
                model={props.model}
                multi={true}></ReactViewFactory>
                
        </IonContent>
    );
}