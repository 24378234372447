import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { EditCommander } from "./EditCommander"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const EditCommanderManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "edit-commander",

    /**
     * Indicates whether to install the plugin at first run
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one
     */
    dependsOn: [
        CommanderPluginKey,
        CommanderRegistryPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "EditCommander",
            registerWith: CommanderArrayContextKey,
            requires: [SchemaRegistryContextKey],
            title: "Edit Commander",
            value: (context: ManifestContext) =>
                new EditCommander(
                    context[SchemaRegistryContextKey])
        } as ManifestEntry<EditCommander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Edit Commander"
}