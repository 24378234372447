import { ActivityKeySchemaId } from "../activity-key/ActivityKeySchemaId"
import { ScheduleSchemaId } from "../schedule/ScheduleSchemaId"
import { Schema } from "../schema/Schema"
import { TaskSchemaId } from "./TaskSchemaId"

export const TaskSchema: Schema = {

    "$id": TaskSchemaId,
    title: "Task",
    type: "object",
    properties: {

        "title": {
            type: "string",
            title: "Title"

        },

        "priority": {
            type: "integer",
            default: 1,
            enum: [-1, 0, 1, 2],
            title: "Priority"
        },

        "activities": {
            "$ref": ActivityKeySchemaId,
            deprecated: true,
            title: "Activity",
            type: "string"
        },

        "schedule": {
            "$ref": ScheduleSchemaId,
            title: "Schedule"
        }
    }
}