
/**
 * Static configuration settings.
 */
export class Config {

    /**
     * The address of the CORS proxy.
     */
    static DEFAULT_CORS_PROXY = "https://damp-ridge-28099.herokuapp.com/";

    /**
     * The origin header to send with web service requests.
     */
    static DEFAULT_ORIGIN = "motley.stream";
}