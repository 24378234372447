import { clone } from "../../library/clone"
import { CycleSelector } from "../selector/CycleSelector"
import { Selector } from "../selector/Selector"
import { Sequence } from "./Sequence"
import { SequenceInstance } from "./SequenceInstance"

export class SequenceCreator {

    /**
     * A key/value map of selectors. This map is used to load the selector
     * that is specified as a key (e.g., "loop") in the sequence definition.
     * If not specified, a default map is created with the built-in selectors.
     */
    private selectors: Map<string, Selector>;

    constructor(selectors?: Map<string, Selector>) {
        this.selectors = selectors ?? new Map<string, Selector>([
            ["once", new CycleSelector(false)],
            ["loop", new CycleSelector(true)]            
        ]);
    }

    /**
     * Initializes a sequence instance from a sequence definition.
     */
    public async create(sequence: Sequence): Promise<SequenceInstance | undefined> {

        const key = sequence?.selector;
        if (!key) {
            return undefined;
        }
        
        const selector = this.selectors.get(key);
        if (!selector) {
            return undefined;
        }

        const state = clone(sequence.defaultState) ?? {};

        return new SequenceInstance(sequence, selector, state);
    }
}