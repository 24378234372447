import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NumberAnnotator } from "./NumberAnnotator"
import { NumberAnnotatorContextKey } from "./NumberAnnotatorContextKey"
import { NumberPluginKey } from "../number/NumberPluginKey"
import { NumberSchemaId } from "../number/NumberSchemaId"

export const NumberAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "number-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        NumberPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NumberAnnotatorContextKey,
            value: new NumberAnnotator()
        } as ManifestEntry<NumberAnnotator>,

        // registration
        {
            key: "NumberAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NumberAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NumberSchemaId,
                annotator: context[NumberAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NumberAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Number Annotator"
}
