import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonList,
    IonText
} from "@ionic/react"

function formatValue(value: any) {
    if (value === undefined || value === "") {
        return <IonText color="medium">(unspecified)</IonText>
    }
    else {
        return value;
    }
}

export const UrlPropertiesDetailView: React.FC<ReactViewProps> = (props) => {

    const str = String(props.model?.payload?.data);
    const url = new URL(str);

    return (
        <IonCard className="selectable">
            <IonCardHeader>
                <IonCardTitle>URL Properties</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonList>
                    <IonItem>
                        <IonLabel>protocol</IonLabel>
                        <IonLabel>{url.protocol}
                            {url.protocol === "http:" &&
                                <IonText color="danger"> warning: http protocol is never secure</IonText>
                            }                        
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>hostname</IonLabel>
                        <IonLabel>{formatValue(url.hostname)}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>port</IonLabel>
                        <IonLabel>{formatValue(url.port)}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>path</IonLabel>
                        <IonLabel>{formatValue(url.pathname)}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>query string</IonLabel>
                        <IonLabel>{formatValue(url.search)}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>hash</IonLabel>
                        <IonLabel>{formatValue(url.hash)}</IonLabel>
                    </IonItem>
                </IonList>
            </IonCardContent>
        </IonCard>
    )
}