import { ActivityArrayContextKey } from "../activity-registry/ActivityArrayContextKey"
import { ActivityKeyPluginKey } from "../activity-key/ActivityKeyPluginKey"
import { ActivityKeyReactInput } from "./ActivityKeyReactInput"
import { ActivityKeySchemaId } from "../activity-key/ActivityKeySchemaId"
import { ActivityRegistryPluginKey } from "../activity-registry/ActivityRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactInputPluginKey } from "../react-input/ReactInputPluginKey"
import { ReactInputProps } from "../react-input/ReactInputProps"
import { ReactInputRegistryContextKey } from "../react-input/ReactInputRegistryContextKey"
import { ReactInputRegistryEntry } from "../react-input/ReactInputRegistryEntry"
import { Schema } from "../schema/Schema"

export const ActivityKeyReactInputManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "activity-key-react-input",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityKeyPluginKey,
        ActivityRegistryPluginKey,
        ReactInputPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ActivityKeyReactInput",
            registerWith: ReactInputRegistryContextKey,
            requires: [ActivityArrayContextKey],
            value: (context: ManifestContext) => ({

                /**
                 * Matches against string schemas
                 */
                match: (s: Schema) => s?.$id === ActivityKeySchemaId,

                /**
                 * The functional component that renders the input control.
                 */
                fc: (props: ReactInputProps) =>
                    ActivityKeyReactInput({...props, activities: context[ActivityArrayContextKey]})
            }),
        } as ManifestEntry<ReactInputRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Key React Input"
}