import { Task } from "./Task"
import { TaskStatus } from "./TaskStatus"

export function markComplete(task: Task): boolean {

    if (!task) {
        return false;
    }

    // Add to the task history
    task.history = task.history ?? [];
    task.history.push({
        changedOn: Date.now(),
        status: TaskStatus.Completed
    });

    // If a one-time task, close it as well.
    if (!task.schedule?.repeat) {
        task.status = TaskStatus.Completed;
    }

    return true;
}