import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Schema } from "../schema/Schema"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { TimerPluginKey } from "./TimerPluginKey"
import { TimerSchema } from "./TimerSchema"

export const TimerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: TimerPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The display description of the plugin.
     */
    description: "A timer that counts down",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "TimerSchema",
            value: TimerSchema,
            registerWith: SchemaRegistryContextKey
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Timer"
}