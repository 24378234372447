import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NewsFeedAnnotator } from "./NewsFeedAnnotator"
import { NewsFeedAnnotatorContextKey } from "./NewsFeedAnnotatorContextKey"
import { NewsFeedPluginKey } from "../newsapi-feed/NewsFeedPluginKey"
import { NewsFeedSchemaId } from "../newsapi-feed/NewsFeedSchemaId"

export const NewsApiFeedAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "newsapi-feed-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        NewsFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NewsFeedAnnotatorContextKey,
            value: new NewsFeedAnnotator()
        } as ManifestEntry<NewsFeedAnnotator>,

        // registration
        {
            key: "NewsFeedAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NewsFeedAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NewsFeedSchemaId,
                annotator: context[NewsFeedAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NewsFeedAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "News API Feed Annotator"
}