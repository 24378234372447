import { hashCode } from "../../library/hashCode"
import { Payload } from "../payload/Payload"
import { SearchString } from "./SearchString"
import { SearchStringSchemaId } from "./SearchStringSchemaId"

export function wrapSearchString(criteria: string): Payload<SearchString> {
    return {
        schema: SearchStringSchemaId,
        key: hashCode(criteria).toString(),
        data: criteria,
        title: criteria
    }
}

