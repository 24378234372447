import { Annotator } from "../annotator/Annotator"
import { NumberSchemaId } from "../number/NumberSchemaId"
import { Payload } from "../payload/Payload";

export class NumberAnnotator implements Annotator {

    public async annotate(payload: Payload<number>): Promise<void> {

        if (payload?.schema !== NumberSchemaId) {
            return;
        }

        if (typeof(payload.data) !== "number") {
            return;
        }

        payload.title = payload.data.toLocaleString();
    }
}