import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"
import { PluginsCardSchema } from "../plugins-card-view/PluginsCardSchema"
import { ShortcutArrayContextKey } from "../shortcut-registry/ShortcutArrayContextKey"
import { ShortcutRegistryPluginKey } from "../shortcut-registry/ShortcutRegistryPluginKey"

export const PluginCardShortcutManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "plugin-card-shortcut",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ShortcutRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "PluginCardShortcut",
            registerWith: ShortcutArrayContextKey,
            value: {
                key: "PluginCardShortcut",
                data: {},
                schema: PluginsCardSchema,
                title: "Plugins"
            }
        } as ManifestEntry<Payload>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Plugin Card Shortcut"
}