import { Converter } from "../converter/Converter"
import { ConverterInspection } from "../converter/ConverterInspection"
import { Payload } from "../payload/Payload"
import { SchemaRegistry } from "../schema-registry/SchemaRegistry"

export class NumberConverter implements Converter {

    private registry: SchemaRegistry;

    constructor(registry: SchemaRegistry) {
        if (!(this.registry = registry)) {
            throw new Error("registry must be specified");
        }
    }

    public async inspect(payload: Payload<number>): Promise<ConverterInspection[]> {

        if (typeof(payload.data) !== "number") {
            return [];
        }

        // Get all numeric schemas
        const schemas = this.registry.all().filter(
            s => (s.type === "integer") || (s.type === "number"));

        // Look at each schema and setup a conversion
        const inspections: ConverterInspection[] = [];
        for (const schema of schemas) {

            // Skip the same schema as the incoming payload
            if (schema.$id === payload.schema) {
                continue;
            }

            // Check minimum value permitted by the schema
            if ((schema.minimum !== undefined) && (payload.data < schema.minimum)) {
                continue;
            }

            // Check maximum value permitted by the schema
            if ((schema.maximum !== undefined) && (payload.data > schema.maximum)) {
                continue;
            }

            // TODO: other schema validations
            // TODO: precision (floating point to integer)
            
            const inspection: ConverterInspection = {
                output: schema.$id!,
                convert: async () => ({
                    schema: schema.$id,
                    data: payload.data
                })
            };

            inspections.push(inspection);
        }

        return inspections;
    }

}