import { Blocking } from "./Blocking"
import { BlockingStore } from "./BlockingStore"
import { Payload } from "../payload/Payload"
import { get as _get } from "lodash"

/**
 * Examines payloads and determines whether to block.
 */
export class Blocker {

    /**
     * Maps schemas to blocking definitions.
     */
    private schemaMap: Map<string, Blocking[]> | undefined;

    /**
     * An object that loads and saves blocking definitions.
     */
    private store: BlockingStore;

    /**
     * Initializes the blocker with the specified blocking storage.
     */
    constructor(store: BlockingStore) {

        if (!store) {
            throw new Error("store must be specified");
        }

        this.store = store;
    }

    /**
     * Adds a blocking definition.
     */
    public add(blocking: Blocking) {

        if (!blocking) {
            throw new Error("blocking must be specified");
        }

        this.ensureLoaded();
        this.map(blocking);
        this.ensureSaved();
    }

    /**
     * Returns all blocking definitions.
     */
    public all(): Blocking[] {

        this.ensureLoaded();

        let combined: Blocking[] = [];

        for (let schemaBlockings of this.schemaMap!.values()) {
            combined.push(...schemaBlockings)
        }

        return combined;
    }

    /**
     * Examines a payload and determines whether it should be blocked.
     */
    public blocked(payload: Payload): boolean {

        if (!payload) {
            return false;
        }

        if(!payload.data) {
            return false;
        }

        if(!payload.schema) {
            return false;
        }

        this.ensureLoaded();

        // Load the blocking definitions for this schema.
        const blockings = this.schemaMap!.get(payload.schema);
        if (!blockings || blockings.length === 0) {
            return false;
        }

        for(let blocking of blockings) {

            if(!blocking) {
                continue;
            }

            if(!blocking.match) {
                continue;
            }

            if (!blocking.path) {
                continue;
            }

            // Get the value at the specified path
            let value = _get(payload.data, blocking.path);

            if (blocking.match === value) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns true if the blocker is empty of any definitions.
     */
    public empty(): boolean {
        this.ensureLoaded();

        for(let blockings of this.schemaMap!.values()) {
            if (blockings.length > 0) {
                return false;
            }
        }

        return true;
    }

    /**
     * Safely ensures blockings are loaded from storage.
     */
    private ensureLoaded() {

        if (!this.schemaMap) {

            // Initialize the schema to blocking map
            this.schemaMap = new Map<string, Blocking[]>();

            // Load blockings from storage
            const blockings = this.store.load();

            for(let blocking of blockings) {
                this.map(blocking);
            }
        }
    }

    /**
     * Safely ensures blockings are saved to storage.
     */
    private ensureSaved() {

        if (this.schemaMap) {

            // Load all blockings
            let blockings = this.all();

            // Save to storage
            this.store.save(blockings);
        }
    }

    /**
     * Adds a blocking to the map.
     */
    private map(blocking: Blocking) {

        if(!blocking) {
            throw new Error("blocking must be specified");
        }

        if(!this.schemaMap) {
            throw new Error("schema map not initialized");
        }

        if (!blocking.schema) {
            throw new Error("blocking must specify target schema");
        }

        // Get the array of blockings for this schema
        let blockings = this.schemaMap.get(blocking.schema);
        if (!blockings) {
            blockings = [];
            this.schemaMap.set(blocking.schema, blockings);
        }

        blockings.push(blocking);
    }
}