import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ServicePluginKey } from "../service/ServicePluginKey"
import { ServiceRegistry } from "./ServiceRegistry"
import { ServiceRegistryContextKey } from "./ServiceRegistryContextKey"
import { ServiceRegistryPluginKey } from "./ServiceRegistryPluginKey"

export const ServiceRegistryManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: ServiceRegistryPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ServicePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ServiceRegistryContextKey,
            value: new ServiceRegistry()
        } as ManifestEntry<ServiceRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Service Registry"
}