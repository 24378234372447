import React from "react"
import { IonApp, IonRouterOutlet } from "@ionic/react"
import { IonReactHashRouter } from "@ionic/react-router"
import { Redirect, Route } from "react-router-dom"

import HomePage from './pages/HomePage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

const App: React.FC = () => (
    <IonApp>
        <IonReactHashRouter>
                <IonRouterOutlet id="outlet">
                    <Route path="/home" component={HomePage} exact={true} />
                    <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
                </IonRouterOutlet>          
        </IonReactHashRouter>
    </IonApp>
);

export default App;
