import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PayloadSchema } from "./PayloadSchema"
import { PayloadSchemaContextKey } from "./PayloadSchemaContextKey"
import { Schema } from "../schema/Schema"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"

export const PayloadManifest: Manifest = {

    /**
     * The unique key of the payload.
     */
    key: "payload",

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: PayloadSchemaContextKey,
            registerWith: SchemaRegistryContextKey,
            value: PayloadSchema
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Payloads"
}