import { AddTagsPluginKey } from "./AddTagsPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { TagPluginKey } from "../tag/TagPluginKey"

export const AddTagsManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: AddTagsPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        TagPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Add Tag"
}