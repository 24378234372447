import { LinkEmblemView } from "./LinkEmblemView"
import { LinkPluginKey } from "../link/LinkPluginKey"
import { LinkSchemaId } from "../link/LinkSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const LinkEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "link-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        LinkPluginKey,
        ReactViewPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "LinkEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: LinkSchemaId,
                layout: "emblem",
                fc: LinkEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Link Emblem View"
}