import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { SubredditFeed } from "../subreddit-feed/SubredditFeed"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class SubredditFeedIndexer implements Indexer<SubredditFeed> {

    public async index(payload: Payload<SubredditFeed>): Promise<Payload[]> {

        if ((payload?.schema === SubredditFeedSchemaId) && payload.data?.subreddit) {

            const title = `r/${payload.data.subreddit}`;
            const url = `https://reddit.com/r/${payload.data.subreddit}`;

            return [
                {
                    schema: UrlSchemaId,
                    data: url,
                    key: url,
                    title,
                }
            ]
        }
        else {
            return [];
        }
    }
}