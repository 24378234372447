import { Annotator } from "../annotator/Annotator"
import { Highlighter } from "../highlighter/Highlighter"
import { HighlighterSchemaId } from "../highlighter/HighlighterSchemaId"
import { Payload } from "../payload/Payload"

export class HighlighterAnnotator implements Annotator<Highlighter> {
    
    public async annotate(payload: Payload<Highlighter>): Promise<void> {

        if (payload?.schema !== HighlighterSchemaId) {
            return;
        }

        if (!payload.data) {
            return;
        }

        payload.title = payload.data.match;
        return;
    }
}