import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommandsReducer } from "./CommandsReducer"
import { CommanderChain } from "../commander/CommanderChain"
import { CommanderChainContextKey } from "./CommanderChainContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { CommandsReducerPluginKey } from "./CommandsReducerPluginKey"
import { CommandsReducerKey } from "./CommandsReducerKey"
import { ControllerPluginKey } from "../controller/ControllerPluginKey"
import { ControllerRegistryPluginKey } from "../controller-registry/ControllerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReducerPluginKey } from "../reducer/ReducerPluginKey"
import { ReducerRegistryContextKey } from "../reducer-registry/ReducerRegistryContextKey"
import { ReducerRegistryEntry } from "../reducer-registry/ReducerRegistryEntry"
import { ReducerRegistryPluginKey } from "../reducer-registry/ReducerRegistryPluginKey"

export const CommandsReducerManifest: Manifest = {
 
    /**
     * A string that uniquely identifies the plugin.
     */
    key: CommandsReducerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey,
        CommanderRegistryPluginKey,
        ControllerPluginKey,
        ControllerRegistryPluginKey,
        ReducerPluginKey,
        ReducerRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: CommanderChainContextKey,
            requires: [CommanderArrayContextKey],
            title: "Commander Chain",
            value: (context:ManifestContext) =>
                new CommanderChain(context[CommanderArrayContextKey])
        } as ManifestEntry<CommanderChain>,
        {
            key: "CommandsReducer",
            registerWith: ReducerRegistryContextKey,
            requires: [CommanderChainContextKey],
            title: "Commands Reducer",
            value: (context: ManifestContext) => ({
                key: CommandsReducerKey,
                reducer: new CommandsReducer(context[CommanderChainContextKey])
            })
        } as ManifestEntry<ReducerRegistryEntry<CommandsReducer>>
    ],
 
    /**
     * The display title of the plugin.
     */
    title: "Commands Reducer"
}