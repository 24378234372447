import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { StringCardView } from "./StringCardView"
import { StringPluginKey } from "../string/StringPluginKey"
import { StringSchemaId } from "../string/StringSchemaId"

export const StringCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "string-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        StringPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "StringCardView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: StringSchemaId,
                layout: ["card", "preview"],
                fc: StringCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "String Card View"
}