import { Heartbeat } from "../../library/Heartbeat";
import { Service } from "./Service";

export abstract class HeartbeatService implements Service {

    /**
     * A heartbeat that fires the service should be synchronized.
     */
    private heartbeat: Heartbeat;

    /**
     * Initializes the service.
     */
    constructor(interval: number) {
        this.heartbeat = new Heartbeat(
            () => this.onHeartbeat(),
            interval,
            false);         
    }

    /**
     * Indicates whether the service is running.
     */
    public get running(): boolean {
        return this.heartbeat.running;
    }

    /**
     * Starts the service.
     */
    public async start(): Promise<boolean> {
        return this.heartbeat.start();
    }

    /**
     * Stops the service.
     */
    public async stop(): Promise<boolean> {
        return this.heartbeat.stop();
    }

    /**
     * Called when the service should synchronize.
     */
    protected abstract onHeartbeat(): Promise<void>;
}