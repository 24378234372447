import { CAP12 } from "../geojson/CAP12"
import { EmailAddressSchemaId } from "../email-address/EmailAddressSchemaId"
import { Feature } from "../geojson/Feature"
import { Indexer } from "../indexer/Indexer"
import { NWSAlertSchemaId } from "../nws-alert/NWSAlertSchemaId"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class NWSAlertIndexer implements Indexer {
    public async index(payload: Payload | undefined): Promise<Payload[]> {

        if (!payload) {
            return [];
        }

        if (payload?.schema !== NWSAlertSchemaId) {
            return [];
        }

        const feature = payload.data as Feature;
        if (!feature) {
            return [];
        }

        // In GeoJSON, each feature has a "properties" object. The
        // USGS earthquake service fills the properties with information
        // about the earthquake such as magnitude.
        let props = feature.properties as CAP12;
        if (!props) {
            return [];
        }

        const payloads: Payload[] = [];

        if (props.sender) {

            payloads.push(
                {
                    schema: EmailAddressSchemaId,
                    data: props.sender,
                    title: props.sender
                },
                {
                    schema: UrlSchemaId,
                    data: "https://www.weather.gov/alerts",
                    key: "https://www.weather.gov/alerts",
                    title: "NWS Active Alerts"
                }
            );

        }

        return payloads;
    }
}