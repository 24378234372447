import { GeoJSONPluginKey } from "./GeoJSONPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const GeojsonManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: GeoJSONPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "GeoJSON"
}