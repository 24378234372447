import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class OpenStreetMapIndexer implements Indexer {
    
    public mapUrl(latitude: number, longitude: number): string {

        // https://www.openstreetmap.org/?mlat=39.9155&mlon=4.1745
        // https://help.openstreetmap.org/questions/74220/display-a-map-with-a-pin-on-it-by-passing-parameters-in-the-url
        // https://wiki.openstreetmap.org/wiki/Browsing#bbox_URLs
    
        return `https://www.openstreetmap.org/?` +
                `mlat=${latitude}&mlon=${longitude}`;
    }
        
    public async index(payload: Payload | undefined): Promise<Payload[]> {

        const location = payload?.location;
        if (!location) {
            return [];
        }

        const url = this.mapUrl(
            location.latitude,
            location.longitude);

        const urlPayload: Payload = {
            schema: UrlSchemaId,
            key: url,
            data: url,
            title: "OpenStreetMap"
        }

        return [urlPayload];
    }
}