import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { TimerCardView } from "./TimerCardView"
import { TimerCardViewPluginKey } from "./TimerCardViewPluginKey"
import { TimerPluginKey } from "../timer/TimerPluginKey"
import { TimerSchemaId } from "../timer/TimerSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const TimerCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: TimerCardViewPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        TimerPluginKey,
    ],

    /**
     * The display description of the plugin.
     */
    description: "Displays a timer",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "TimerCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Timer Card View",
            value: {
                layout: "card",
                match: TimerSchemaId,
                fc: TimerCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Timer Card View"
};
