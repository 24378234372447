import { fetchXml } from "../../library/fetchXml"
import { parseRss } from "../rss/parseRss"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { RssFeed } from "../rss-feed/RssFeed"
import { RssItemSchemaId } from "../rss/RssItemSchemaId"

/**
 * An RSS (really simple syndication) poller.
 */
export class RssFeedPoller implements Poller {

    /**
     * The feed being polled.
     */
    private feed: RssFeed;

    /**
     * Initializes a new instance of the class.
     */
    constructor(feed: RssFeed) {
        if (!(this.feed = feed)) {
            throw new Error("feed must be specified");
        }
    }

    /**
     * Fetches items from the RSS URL.
     */
    public async poll(): Promise<Payload[]> {

        if (!this.feed.url) {
            console.error("missing RSS feed URL");
            return [];
        }

        return fetchXml(this.feed.url, this.feed.useCorsProxy ?? true).then(
            xml => this.convertXml(xml)
        );
    }

    /**
     * Converts the RSS XML into payloads.
     */
    protected async convertXml(xml: Document | undefined): Promise<Payload[]> {

        if (!xml) {
            return [];
        }

        const payloads: Payload[] = [];

        // Parse the RSS XML into objects
        const channel = parseRss(xml);
        if (!channel) {
            return [];
        }

        for(let item of channel.items) {

            const payload: Payload = {
                schema: RssItemSchemaId,
                data: item,
                key: item.guid?.value,
                title: item.title
            }
           
            payloads.push(payload);
        }

        return payloads;
    }
}