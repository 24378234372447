import { BingWallpaperFeedEmblemView } from "./BingWallpaperFeedEmblemView"
import { BingWallpaperFeedPluginKey } from "../bing-wallpaper-feed/BingWallpaperFeedPluginKey"
import { BingWallpaperFeedSchemaId } from "../bing-wallpaper-feed/BingWallpaperFeedSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"

export const BingWallpaperFeedEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-feed-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BingWallpaperFeedPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "BingWallpaperFeedEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: BingWallpaperFeedSchemaId,
                layout: "emblem",
                fc: BingWallpaperFeedEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Feed Emblem View"
}