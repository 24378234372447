import { ButtonCardView } from "./ButtonCardView"
import { ButtonSchemaId } from "../button/ButtonSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const ButtonCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "button-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ButtonCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Button Card View",
            value: {
                layout: ["card", "preview"],
                match: ButtonSchemaId,
                fc: ButtonCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Button Card View",
}