import { defaultQuotes } from "./DefaultQuotes"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { QuotePluginKey } from "../quote/QuotePluginKey"
import { QuoteStreamer } from "./QuoteStreamer"
import { QuoteStreamerContextKey } from "./QuoteStreamerContextKey"
import { QuoteStreamerPluginKey } from "./QuoteStreamerPluginKey"
import { RandomSelectorContextKey } from "../random-selector/RandomSelectorContextKey"
import { RandomSelectorPluginKey } from "../random-selector/RandomSelectorPluginKey"
import { SelectorPluginKey } from "../selector/SelectorPluginKey"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"

/**
 * The default interval (minimum time) between quotes in milliseconds.
 */
const DEFAULT_INTERVAL = 1000.0 * 60.0 * 60.0;

export const QuoteStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: QuoteStreamerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        QuotePluginKey,
        RandomSelectorPluginKey,
        SelectorPluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "A streamer that returns famous quotes.",

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // streamer
        {
            key: QuoteStreamerContextKey,
            requires: [RandomSelectorContextKey],
            value: (context: ManifestContext) =>
                new QuoteStreamer(defaultQuotes, DEFAULT_INTERVAL, context[RandomSelectorContextKey])
        } as ManifestEntry<QuoteStreamer>,

        // registration
        {
            key: "QuoteStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: [QuoteStreamerContextKey],
            value: (context: ManifestContext) => ({
                streamer: context[QuoteStreamerContextKey]
            })
        } as ManifestEntry<StreamerRegistryEntry<QuoteStreamer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Quote Streamer",
};
