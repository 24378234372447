import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistry } from "./AnnotatorRegistry"
import { AnnotatorRegistryContextKey } from "./AnnotatorRegistryContextKey"
import { AnnotatorRegistryPluginKey } from "./AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const AnnotatorRegistryManifest: Manifest = {

    /**
     * A string that uniquely identifies the plugin.
     */
    key: AnnotatorRegistryPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: AnnotatorRegistryContextKey,
            title: "Annotator Registry",
            value: new AnnotatorRegistry()
        } as ManifestEntry<AnnotatorRegistry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Annotator Registry"
}