import { SaveSchemaId } from './SaveSchemaId'
import { Schema } from "../schema/Schema"

export const SaveSchema: Schema = {

    "$id": SaveSchemaId,
    title: "Save",
    type: "object",
    properties: {
        "payload": {
            "$ref": "https://motley.stream/schema/payload/1.0",
        },
        "confirmed": {
            title: "Confirmed",
            type: "boolean"
        }
    }
} 