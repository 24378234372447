import { RandomGenerator } from "../random/RandomGenerator"
import { RandomValue } from "../random/RandomValue";

export class RandomRegistry extends RandomGenerator {

    private generators: RandomGenerator[] = [];

    public float(): RandomValue<number> {
        if (this.generators.length === 0) {
            return { value: undefined }
        }
        else {
            return this.generators[this.generators.length - 1].float();
        }
    }

    public int(min: number, max: number): RandomValue<number> {
        if (this.generators.length === 0) {
            return { value: undefined }
        }
        else {
            return this.generators[this.generators.length - 1].int(min, max);
        }
    }

    public register(rng: RandomGenerator) {
        if (typeof(rng) !== 'object') {
            throw new Error('rng must be an object')
        }

        this.generators.push(rng);
    }

    public unregister(rng: RandomGenerator) {
        const index = this.generators.indexOf(rng);
        if (index !== -1) {
            this.generators.splice(index, 1);
        }
    }
}