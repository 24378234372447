import { Command } from "./Command"

export function compareCommands(a: Command, b: Command): number {

    // Sort out undefined commands
    if (!a) {
        if(b) {
            return 1;
        }
        else {
            return 0;
        }
    }
    else {
        if (!b) {
            return -1;
        }
    }

    switch(a.order) {
        case "first":
            if (b.order === "first") {
                return 0;
            }
            else {
                return -1;
            }

        case "last":
            if (b.order === "last") {
                return 0;
            }
            else {
                return 1;
            }
    }

    switch(b.order) {
        case "first":
            return 1;

        case "last":
            return -1;
    }

    const an = a.order ?? 0;
    const bn = b.order ?? 0;

    return an - bn;
}