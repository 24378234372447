import React from "react"
import { IonImg } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const WhatsNewEmblemView: React.FC<ReactViewProps> = (props) => {
    return (
        <IonImg style={{
            width:"32px",
            height: "32px"
        }} src="/assets/favicon/favicon-32x32.png" />
    )
}