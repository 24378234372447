import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { OpenStreetMapIndexer } from "./OpenStreetMapIndexer"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const OpenStreetMapIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "openstreetmap-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        UrlPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Show a link to OpenStreetMap when viewing a location.",

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "OpenStreetMapIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "OpenStreetMap Indexer",
            value: {
                schema: "*",
                indexer: new OpenStreetMapIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<OpenStreetMapIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "OpenStreetMap Indexer",
};
