import { Annotator } from "../annotator/Annotator"
import { Cue } from "../cue/Cue"
import { CueSchemaId } from "../cue/CueSchemaId"
import { hashCode } from "../../library/hashCode"
import { Payload } from "../payload/Payload"

export class CueAnnotator implements Annotator {

    /**
     * Annotates a Cue payload.
     */
    public async annotate(payload: Payload | undefined): Promise<void> {

        if (payload?.schema !== CueSchemaId) {
            return;
        }

        const cue = payload.data as Cue;
        if (!cue) {
            return;
        }

        // .title
        payload.title = cue.title;

        // .key (hash of title)
        if (!payload.key && payload.title) {
            payload.key = hashCode(payload.title).toString();
        }
    }
}