import { WhatsNew } from "../whats-new/WhatsNew"

function releasedOn(year: number, month: number, day: number): number {
    return new Date(year, month, day).valueOf();
}

export const WhatsNewLog: WhatsNew[] = [
    {
        releasedOn: releasedOn(2021, 9, 29),
        title: "History Started",
        type: "feature",
        version: "0.0.1",
        description: "Significant features and bug fixes will be announced here."
    }
]