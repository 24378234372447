import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NewsArticleIndexer } from "./NewsArticleIndexer"
import { NewsArticleIndexerContextKey } from "./NewsArticleIndexerContextKey"
import { NewsArticleSchemaId } from "../newsapi/NewsArticleSchemaId"
import { NewsPluginKey } from "../newsapi/NewsPluginKey"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const NewsApiArticleIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "newsapi-article-indexer",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        NewsPluginKey,
        PayloadPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // indexer
        {
            key: NewsArticleIndexerContextKey,
            value: new NewsArticleIndexer()
        } as ManifestEntry<NewsArticleIndexer>,

        // registration 
        {
            key: "NewsArticleIndexerRegistryEntry",
            registerWith: IndexerRegistryContextKey,
            requires: [NewsArticleIndexerContextKey],
            value: (context: ManifestContext) => ({
                schema: NewsArticleSchemaId,
                indexer: context[NewsArticleIndexerContextKey]
            })
        } as ManifestEntry<IndexerRegistryEntry<NewsArticleIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "NewsAPI Article Indexer"
}