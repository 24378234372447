import React from "react"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { List } from "../list/List"
import { ListSchemaId } from "../list/ListSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const AttachmentsDetailView: React.FC<ReactViewProps> = (props) => {
   
    const model = props?.model;
    if (!model) {
        return <></>
    }

    const attachments = model.payload?.attachments as Payload[];
    if (!Array.isArray(attachments) || attachments.length === 0) {
        return <></>
    }
 
    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Attachments</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <ReactViewFactory
                    dispatch={props.dispatch}
                    layout="card"
                    matcher={props.matcher}
                    model={{
                        payload: {
                            schema: ListSchemaId,
                            data: {
                                items: attachments
                            }
                        } as Payload<List>
                    }}>
                </ReactViewFactory>
            </IonCardContent>
        </IonCard>
    );
}