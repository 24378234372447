import React from "react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { StringSchemaId } from "../string/StringSchemaId"

export const BooleanCardView: React.FC<ReactViewProps> = (props) => {

    const value = props.model?.payload?.data;

    return (
        <ReactViewFactory
            dispatch={props.dispatch}
            layout="card"
            matcher={props.matcher}
            model={{
                payload: {
                    schema: StringSchemaId,
                    data: String(value)
                }
            }}>
        </ReactViewFactory>
    )
}