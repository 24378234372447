import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Waiting } from "../waiting/Waiting"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

const DEFAULT_TITLE = "Waiting"

export class WaitingAnnotator implements Annotator {

    public async annotate(payload: Payload<Waiting>): Promise<void> {
        if (payload?.schema === WaitingSchemaId) {
            payload.title = payload.data?.title ?? payload.title ?? DEFAULT_TITLE;
        }
    }
}