import { Annotator } from "../annotator/Annotator"
import { EmailAddressSchemaId } from "../email-address/EmailAddressSchemaId"
import { Payload } from "../payload/Payload";

export class EmailAddressAnnotator implements Annotator {

    public async annotate(payload: Payload<string>): Promise<void> {

        if (payload?.schema !== EmailAddressSchemaId) {
            return;
        }

        if (typeof(payload.data) !== "string") {
            return;
        }

        payload.key = payload.title = payload.data;
    }
}