import React from "react"
import {
    IonChip,
    IonText,
} from "@ionic/react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./ChipView.css"

export const ChipView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    if (!payload) {
        return <></>
    }

    if (!payload.data) {
        return <></>
    }

    function onclick() {
        if (props.dispatch && payload) {
            props.dispatch(payload);
        }
    }

    return (
        <>
            <IonChip outline={true} color="primary" onClick={() => onclick()} style={{height: "48px"}}>
                <div className="chip-view-emblem">
                    <ReactViewFactory
                        layout="emblem"
                        dispatch={props.dispatch}
                        matcher={props.matcher}
                        model={props.model}>
                    </ReactViewFactory>
                </div>

                <IonText>{payload.title}</IonText>
            </IonChip>
        </>
    );
}