import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { RssFeedEmblemView } from "./RssFeedEmblemView"
import { RssFeedPluginKey } from "../rss-feed/RssFeedPluginKey"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export const RssFeedEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "rss-feed-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        RssFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "RssFeedEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: RssFeedSchemaId,
                layout: "emblem",
                fc: RssFeedEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Feed Emblem View"
}