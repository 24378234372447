import { Manifest } from "../../runtime/Manifest"
import { PromptPluginKey } from "./PromptPluginKey"

export const PromptManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: PromptPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The display title of the plugin.
     */
    title: "Prompt"
}