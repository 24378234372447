import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityToggleTracker } from "../activity-toggle-tracker/ActivityToggleTracker"
import { ActivityToggleTrackerContextKey } from "../activity-toggle-tracker/ActivityToggleTrackerContextKey"
import { ActivityToggleTrackerPluginKey } from "./ActivityToggleTrackerPluginKey"
import { ActivityTrackerContextKey } from "../activity-tracker/ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "../activity-tracker/ActivityTrackerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ActivityToggleTrackerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ActivityToggleTrackerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        ActivityTrackerPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: ActivityToggleTrackerContextKey,
            registerWith: ActivityTrackerContextKey,
            value: new ActivityToggleTracker()
        } as ManifestEntry<ActivityToggleTracker>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Toggle Tracker"
}