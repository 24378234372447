import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { CancelTaskPluginKey } from "../cancel-task/CancelTaskPluginKey"
import { CancelTaskSchemaId } from "../cancel-task/CancelTaskSchemaId"
import { CancelTaskCardView } from "./CancelTaskCardView"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PromptPluginKey } from "../prompt/PromptPluginKey"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SavePluginKey } from "../save/SavePluginKey"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"

export const CancelTaskCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "cancel-task-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BooleanPluginKey,
        CancelTaskPluginKey,
        PromptPluginKey,
        ReactViewPluginKey,
        SavePluginKey,
        TaskPluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "CancelTaskView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: CancelTaskSchemaId,
                layout: "stream",
                fc:CancelTaskCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Cancel Task View"
}