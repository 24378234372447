import { Annotator } from "../annotator/Annotator"
import { ISO4217Code } from "../iso-4217-code/ISO4217Code"
import { ISO4217CodeSchemaId } from "../iso-4217-code/ISO4217CodeSchemaId"
import { Payload } from "../payload/Payload"

export class ISO4217CodeAnnotator implements Annotator<ISO4217Code> {

    public async annotate(payload: Payload<ISO4217Code>): Promise<void> {

        if (payload?.schema !== ISO4217CodeSchemaId) {
            return;
        }

        payload.title = payload.data;
    }
}