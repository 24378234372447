
export function parseDays(dayNames: string | undefined): boolean[] {

    let d: boolean[] = [
        false, // [0] = Sunday
        false, // [1] = Monday
        false, // [2] = Tuesday
        false, // [3] = Wednesday
        false, // [4] = Thursday
        false, // [5] = Friday
        false  // [6] = Saturday
    ]

    if (!dayNames) {
        return d;
    }

    let parts = dayNames.split(',');
    for(let part of parts) {
        switch(part.trim()) {
            case "sun":
                d[0] = true;
                break;

            case "mon":
                d[1] = true;
                break;

            case "tue":
                d[2] = true;
                break;

            case "wed":
                d[3] = true;
                break;

            case "thu":
                d[4] = true;
                break;

            case "fri":
                d[5] = true;
                break;

            case "sat":
                d[6] = true;
                break;

            case "daily":
                d[0] = true;
                d[1] = true;
                d[2] = true;
                d[3] = true;
                d[4] = true;
                d[5] = true;
                d[6] = true;
                break;
                
            case "weekdays":
                d[1] = true;
                d[2] = true;
                d[3] = true;
                d[4] = true;
                d[5] = true;
                break;

            case "weekends":
                d[0] = true;
                d[6] = true;
                break;
        }
    }

    return d;
}