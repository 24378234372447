import { Manifest } from "../../runtime/Manifest"
import { StorePluginKey } from "./StorePluginKey"

export const StoreManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: StorePluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The display description of the plugin.
     */
    description: "Defines interfaces and supporting classes for storing payloads",

    /**
     * The display title of the plugin.
     */
    title: "Store"
}