import { Streamer } from "../streamer/Streamer"
import { Payload } from "../payload/Payload"
import { RealityCheck } from "../reality-check/RealityCheck"
import { RealityCheckSchemaId } from "../reality-check/RealityCheckSchemaId"
import { UrlSchemaId } from "../url/UrlSchemaId"

/**
 * The minimum interval between reality checks in milliseconds.
 */
const MINIMUM_INTERVAL = 1000.0 * 60 * 60 * 1.0; // 1 hour

/**
 * The variability of each reality check in milliseconds.
 */
const VARIABLE_INTERVAL = 1000.0 * 60 * 30; // 30 minutes

/**
 * Periodically supplies a reality check.
 */
export class RealityCheckStreamer implements Streamer {

    /**
     * The next time a reality check will be pertormed in ms from the Unix epoch.
     */
    private nextTime: number = 0;

    /**
     * The reality check data.
     */
    private realityChecks: RealityCheck[];

    /**
     * Initializes a new instance of the reality check streamer.
     */
    constructor(realityChecks: RealityCheck[]) {
        this.realityChecks = realityChecks;
    }

    public convert(realityCheck: RealityCheck): Payload | undefined {

        if (!realityCheck) {
            return undefined;
        }

        let payload: Payload = {
            data: realityCheck,
            schema: RealityCheckSchemaId,
            title: realityCheck.title
        };

        if (realityCheck.links) {
            for(let link of realityCheck.links) {
                payload.attachments = payload.attachments ?? [];
                payload.attachments.push({
                    schema: UrlSchemaId,
                    data: link.url,
                    key: link.url,
                    title: link.title,
                });
            }
        }

        return payload;
    }

    /**
     * Returns the next reality check.
     */
    public async next(): Promise<Payload | undefined> {

        // Is it time for a reality check?
        let now = Date.now();
        if ((this.nextTime > 0) && (now < this.nextTime)) {
            return undefined;
        }

        // Select a random index
        let index = Math.floor(Math.random() * this.realityChecks.length);

        let realityCheck = this.realityChecks[index];
        if (!realityCheck) {
            return undefined;
        }
        else {

            // Calculate the time of the next reality check.
            this.nextTime = now 
                + MINIMUM_INTERVAL
                + Math.floor(Math.random() * VARIABLE_INTERVAL);

            return this.convert(realityCheck);
        }
    }
}