import { Annotator } from "../annotator/Annotator"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { ControllerPluginKey } from "../controller/ControllerPluginKey"
import { ControllerRegistryPluginKey } from "../controller-registry/ControllerRegistryPluginKey"
import { Indexer } from "../indexer/Indexer"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { IndexesReducer } from "./IndexesReducer"
import { IndexesReducerKey } from "./IndexesReducerKey"
import { IndexesReducerPluginKey } from "./IndexesReducerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReducerPluginKey } from "../reducer/ReducerPluginKey"
import { ReducerRegistryContextKey } from "../reducer-registry/ReducerRegistryContextKey"
import { ReducerRegistryEntry } from "../reducer-registry/ReducerRegistryEntry"
import { ReducerRegistryPluginKey } from "../reducer-registry/ReducerRegistryPluginKey"

export const IndexesReducerManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: IndexesReducerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        ControllerPluginKey,
        ControllerRegistryPluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        ReducerPluginKey,
        ReducerRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "IndexesReducer",
            registerWith: ReducerRegistryContextKey,
            requires: [IndexerRegistryContextKey, AnnotatorRegistryContextKey],
            title: "Indexes Reducer",
            value: (context: ManifestContext) => {
                
                const indexer: Indexer = context[IndexerRegistryContextKey];
                const annotator: Annotator = context[AnnotatorRegistryContextKey];

                return {
                    key: IndexesReducerKey,
                    reducer: new IndexesReducer(indexer, annotator)
                }
            }
        } as ManifestEntry<ReducerRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Indexes Reducer"
}