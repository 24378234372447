import { Payload } from "../payload/Payload"
import { Streamer } from "../streamer/Streamer"
import { StreamerCreator } from "../streamer-creator/StreamerCreator"
import { SunriseSunsetFeed } from "./SunriseSunsetFeed"
import { SunriseSunsetFeedSchema } from "./SunriseSunsetFeedSchema"
import { SunriseSunsetStreamer } from "./SunriseSunsetStreamer"

export class SunriseSunsetStreamerCreator implements StreamerCreator {

    public async create(payload: Payload<SunriseSunsetFeed>): Promise<Streamer | undefined> {

        if (payload?.schema !== SunriseSunsetFeedSchema) {
            return undefined;
        }

        const feed = payload.data;
        if (!feed) {
            return undefined;
        }

        return new SunriseSunsetStreamer(feed);
    }    
}