import { IndexesReducerPluginKey } from "../indexes-reducer/IndexesReducerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ShortcutRegistryPluginKey } from "../shortcut-registry/ShortcutRegistryPluginKey"
import { ShortcutToolbarButton } from "./ShortcutToolbarButton"

export const ShortcutToolbarButtonManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "shortcut-toolbar-button",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexesReducerPluginKey,
        ReactViewPluginKey,
        ShortcutRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin key.
     */
    entries: [
        {
            key: "ShortcutToolbarButton",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: "*",
                layout: "toolbar-button",
                fc: ShortcutToolbarButton
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Shortcut Toolbar Button"
}