import { Selector } from "./Selector"
import { SelectorState } from "./SelectorState"

export class CycleSelector<T = any> extends Selector<T> {

    constructor(public loop: boolean = false) {
        super();
    }

    public async select(items: T[], state: SelectorState): Promise<T | undefined> {

        if (!Array(items) || items.length === 0) {
            return undefined;
        }

        // Get the current index to run
        if (state.index === undefined) {
            state.index = 0;
        }
        else if ((state.index < 0) || (state.index >= items.length)) {
            if (this.loop) {
                state.index = 0;
            }
            else {
                return undefined;
            }
        }
        
        return items[state.index++];
    }
}