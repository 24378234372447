import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { TagCardView } from "./TagCardView"
import { TagSchemaId } from "../tag/TagSchemaId"

export const TagCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "tag-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DefaultStorePluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "TagCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [DefaultStoreContextKey],
            value: (context: ManifestContext) => ({
                layout: "card",
                match: TagSchemaId,
                fc: (props: ReactViewProps) =>
                    TagCardView({...props, store: context[DefaultStoreContextKey]})
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Tag Card View"
}