import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Quote } from "../quote/Quote"
import { QuoteSchemaId } from "../quote/QuoteSchemaId"

export class QuoteAnnotator implements Annotator {

    /**
     * Annotates a Quote payload.
     */
    public async annotate(payload: Payload | undefined): Promise<void> {

        if (payload?.schema !== QuoteSchemaId) {
            return;
        }

        const quote = payload.data as Quote;
        if (!quote) {
            return;
        }

        // .key
        payload.key = payload.key ?? quote.key;

        // .title
        payload.title = payload.title ?? quote.text;
    }
}