export enum ControllerVerb {

    /**
     * Moves to the previous payload.
     */
    BACK = "back",

    /**
     * Gets the current payload.
     */
    GET = "get",

    /**
     * Moves to the next payload.
     */
    NEXT = "next",

    /**
     * Echoes the specified payload back as a model.
     */
    ECHO = "echo",
}