import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ToolbarPageSection } from "./ToolbarPageSection"

export const ToolbarPageSectionManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "toolbar-page-section",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ToolbarPageSection",
            registerWith: ReactViewRegistryContextKey,
            value: {
                layout: "page-section",
                order: "last",
                match: "*",
                fc: ToolbarPageSection
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Toolbar Page Section"
}