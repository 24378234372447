import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { MessierNumberPluginKey } from "../messier-number/MessierNumberPluginKey"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { WikipediaLinkDetailView } from "./WikipediaLinkDetailView"
import { WikipediaSummaryPluginKey } from "../wikipedia-summary/WikipediaSummaryPluginKey"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const WikipediaLinkDetailViewManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "wikipedia-link-detail-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        MessierNumberPluginKey,
        ReactViewPluginKey,
        UrlPluginKey,
        WikipediaSummaryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "WikipediaLinkDetailView",
            registerWith: ReactViewRegistryContextKey,
            title: "Wikipedia Link Detail View",
            value: {
                match: "*",
                layout: "detail",
                fc: WikipediaLinkDetailView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Wikipedia Link Detail View"
}