import React, { useEffect, useState } from "react"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { RemoveTags } from "../remove-tags/RemoveTags"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export const RemoveTagsCardView: React.FC<ReactViewProps> = (props) => {
    
    const dispatch = props.dispatch;
    const remove = props.model?.payload?.data as RemoveTags;
    const tags = remove.tags;
    const fromPayload = remove.payload;

    // Track whether the user has confirmed the operation
    const [confirmed, setConfirmed] = useState(remove?.confirmed ?? false);

    useEffect(() => {

        let mounted = true;

        if (confirmed && Array.isArray(tags) && Array.isArray(fromPayload.tags)) {

            for(let i = fromPayload.tags.length - 1; i >= 0; i--) {
                if (tags.includes(fromPayload.tags[i])) {
                    fromPayload.tags.splice(i, 1);
                }
            }

            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload: fromPayload,
                    confirmed: true
                }
            }
            
            if (dispatch && mounted) {
                dispatch(savePayload);
            }            
        }

        return () => { mounted = false };

    }, [confirmed, dispatch, tags, fromPayload]);

    /**
     * Executed when the user confirms or declines.
     */
    function onConfirm(selectedPayload: Payload) {
        if (selectedPayload.data === true) {
            setConfirmed(true);
        }
        else {
            if (dispatch) {
                dispatch(fromPayload);
            }
        }
    }

    /**
     * Selects a view based on the state of the view.
     */
    function selectView() {
        if (confirmed) {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={undefined}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Updating..."
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
        else {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={p => onConfirm(p)}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: ConfirmSchemaId,
                        data: {
                            payload: fromPayload,
                            title: "Delete Tag " + String(tags)
                        } as Confirm
                    }}}
                ></ReactViewFactory>
            )
        }
    }

    return selectView()
}