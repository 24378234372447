import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Notice } from "../notice/Notice"
import { NotifierPluginKey } from "../notifier/NotifierPluginKey"
import { NotifierArrayContextKey } from "./NotifierArrayContextKey"
import { NotifierRegistryPluginKey } from "./NotifierRegistryPluginKey"

export const NotifierRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: NotifierRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NotifierPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: NotifierArrayContextKey,
            value: []
        } as ManifestEntry<Notice[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Notifier Registry"
}