import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { MessierNumberIndexer } from "./MessierNumberIndexer"
import { MessierNumberIndexerContextKey } from "./MessierNumberIndexerContextKey"
import { MessierNumberIndexerPluginKey } from "./MessierNumberIndexerPluginKey"
import { MessierNumberPluginKey } from "../messier-number/MessierNumberPluginKey"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"
import { RegExIndexerPluginKey } from "../regex-indexer/RegExIndexerPluginKey"

export const MessierNumberIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: MessierNumberIndexerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        MessierNumberPluginKey,
        PayloadPluginKey,
        RegExIndexerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: MessierNumberIndexerContextKey,
            value: new MessierNumberIndexer()
        } as ManifestEntry<MessierNumberIndexer>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Messier Number Indexer"
}