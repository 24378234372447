import { CancelTask } from "../cancel-task/CancelTask"
import { CancelTaskSchemaId } from "../cancel-task/CancelTaskSchemaId"
import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { CompleteTask } from "../complete-task/CompleteTask"
import { CompleteTaskSchemaId } from "../complete-task/CompleteTaskSchemaId"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"
import { ReopenTask } from "../reopen-task/ReopenTask"
import { ReopenTaskSchemaId } from "../reopen-task/ReopenTaskSchemaId"
import { TaskPredicates } from "../task/TaskPredicates"
import { TaskSchemaId } from "../task/TaskSchemaId"

export class TaskCommander implements Commander {
    
    public async issue(model: Model | undefined): Promise<Command[]> {

        if (!model) {
            return [];
        }

        const payload = model.payload;
        if (payload?.schema !== TaskSchemaId) {
            return [];
        }

        if (TaskPredicates.closeable(payload)) {
            return [
                {
                    emoji: "✔",
                    key: "closeTask",
                    layout: "extended",
                    order: "last",
                    title: "Complete",
                    value: {
                        schema: CompleteTaskSchemaId,
                        data: {
                            payload
                        }
                    } as Payload<CompleteTask>
                },
                {
                    key: "cancelTask",
                    layout: "extended",
                    order: "last",
                    title: "Cancel Permanently",
                    value: {
                        schema: CancelTaskSchemaId,
                        data: {
                            payload
                        }
                    } as Payload<CancelTask>
                }
            ];
        }

        if (TaskPredicates.reopenable(payload)) {
            return [
                {
                    key: "reopenTask",
                    layout: "extended",
                    order: "last",
                    title: "Reopen",
                    value: {
                        schema: ReopenTaskSchemaId,
                        data: {
                            payload
                        }
                    } as Payload<ReopenTask>
                }
            ];
        }

        return [];
    }
}