import { IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Timer } from "../timer/Timer"
import './TimerCardView.css';

export const TimerCardView: React.FC<ReactViewProps<Timer>> = (props) => {

    const timer: Timer | undefined = props?.model?.payload?.data;
    const timespan = timer?.timespan ?? 1000 * 60;
    const [started, setStarted] = useState(false);
    const [lastTick, setLastTick] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState(timespan);

    let centiseconds = ("0" + (Math.floor(timeRemaining / 10) % 100)).slice(-2);
    let seconds = ("0" + (Math.floor(timeRemaining / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timeRemaining / 60000) % 60)).slice(-2);
    let hours = ("0" + Math.floor(timeRemaining / 3600000)).slice(-2);

    useEffect(() => {
        const timerId = setInterval(() => onTick(), 10);
        return () => clearInterval(timerId);
    });

    function onStart() {
        setStarted(true);
        setLastTick(Date.now());
    }

    function onStop() {
        setStarted(false);
        setLastTick(0);
    }

    function onTick() {
        if (started) {
            const elapsed = Date.now() - lastTick;
            if (elapsed >= timeRemaining) {
                onStop();
                setTimeRemaining(0);
            }
            else {
                setLastTick(Date.now());
                setTimeRemaining((prev) => prev - elapsed);
            }
        }
    }

    function onReset() {
        setTimeRemaining(timespan);
        setStarted(false);
    }

    return (
        <div className="timer">
            
            <div className="timer-display">
                {hours} : {minutes} : {seconds} : {centiseconds}
            </div>
            <div className="timer-buttons">
                <IonButton className="start" onClick={(e) => onStart()}>Start</IonButton>
                <IonButton className="stop" onClick={(e) => onStop()}>Stop</IonButton>
                <IonButton className="reset" onClick={(e) => onReset()}>Reset</IonButton>
            </div>
        </div>
    );
};
