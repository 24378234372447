import { ConverterPluginKey } from "../converter/ConverterPluginKey"
import { ConverterRegistryContextKey } from "../converter-registry/ConverterRegistryContextKey"
import { ConverterRegistryEntry } from "../converter-registry/ConverterRegistryEntry"
import { ConverterRegistryPluginKey } from "../converter-registry/ConverterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NumberConverter } from "./NumberConverter"
import { NumberConverterContextKey } from "./NumberConverterContextKey"
import { NumberPluginKey } from "../number/NumberPluginKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const NumberConverterManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "number-converter",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConverterPluginKey,
        ConverterRegistryPluginKey,
        NumberPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // converter
        {
            key: NumberConverterContextKey,
            requires: [SchemaRegistryContextKey],
            value: (context: ManifestContext) => 
                new NumberConverter(context[SchemaRegistryContextKey])
        } as ManifestEntry<NumberConverter>,

        // registration
        {
            key: "NumberConverterRegistryEntry",
            registerWith: ConverterRegistryContextKey,
            requires: [NumberConverterContextKey],
            value: (context: ManifestContext) => ({
                converter: context[NumberConverterContextKey]
            })
        } as ManifestEntry<ConverterRegistryEntry<NumberConverter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Number Converter"
}