import { FlashcardCardView } from "./FlashcardCardView"
import { FlashcardPluginKey } from "../flashcard/FlashcardPluginKey"
import { FlashcardSchemaId } from "../flashcard/FlashcardSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const FlashcardCardViewManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "flashcard-card-view",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FlashcardPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "FlashcardCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Flashcard Card View",
            value: {
                layout: ["card", "preview"],
                match: FlashcardSchemaId,
                fc: FlashcardCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Flashcard Card View"
}