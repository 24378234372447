import { Annotator } from "../annotator/Annotator"
import { Definition } from "../definition/Definition"
import { DefinitionSchemaId } from "../definition/DefinitionSchemaId"
import { Payload } from "../payload/Payload"

export class DefinitionAnnotator implements Annotator<Definition> {

    public async annotate(payload: Payload<Definition>): Promise<void> {

        const def = payload.data;
        if (def && (payload.schema === DefinitionSchemaId)) {

            if (def.term) {
                payload.title = def.term
                if (def.meaning) {
                    payload.title += ": " + def.meaning;
                }
            }
            else {
                payload.title = def.meaning ?? payload.title;
            }
        }
    }
} 