import React, { useEffect, useState } from "react"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Delete } from "../delete/Delete"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Store } from "../store/Store"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

/**
 * The extended properties of the card.
 */
interface DeleteCardViewProps extends ReactViewProps {
    store: Store;
}

export const DeleteCardView: React.FC<DeleteCardViewProps> = (props) => {

    const dispatch = props.dispatch;
    const del = props?.model?.payload?.data as Delete;
    const store = props.store;

    // Track whether the user has confirmed the operation
    const [confirmed, setConfirmed] = useState(del?.confirmed ?? false);

    useEffect(() => {

        let mounted = true;

        if (confirmed && del?.payload?.id && store) {

            store.delete(del.payload.id).then(p => {
                debugger;
                if (dispatch && mounted && p) {
                    dispatch(p);
                }
            });
        }

        return () => { mounted = false };

    }, [confirmed, del, dispatch, store]);

    /**
     * Executed when the user confirms or declines.
     */
     function onConfirm(selectedPayload: Payload) {
        if (selectedPayload.data === true) {
            setConfirmed(true);
        }
        else {
            if (dispatch) {
                dispatch(del.payload);
            }
        }
    }

    /**
     * Selects a view based on the state of the view.
     */
     function SelectView() {
        if (confirmed) {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={undefined}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Deleting..."
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
        else {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={p => onConfirm(p)}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: ConfirmSchemaId,
                        data: {
                            destructive: true,
                            payload: del.payload,
                            title: "Delete"
                        } as Confirm
                    }}}
                ></ReactViewFactory>
            )
        }
    }

    return SelectView()
}