import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Tag } from "../tag/Tag"
import { TagSchemaId } from "../tag/TagSchemaId"

export class TagAnnotator implements Annotator<Tag> {

    public async annotate(payload: Payload<Tag>): Promise<void> {
        if (payload?.schema === TagSchemaId) {
            payload.title = payload.data ?? payload.title;
        }
    }
}