import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RssPluginKey } from "../rss/RssPluginKey"
import { RssItemSchemaId } from "../rss/RssItemSchemaId"
import { RssItemIndexer } from "./RssItemIndexer"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const RssItemIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "rss-item-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        RssPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "RssItemIndexer",
            registerWith: IndexerRegistryContextKey,
            value: {
                schema: RssItemSchemaId,
                indexer: new RssItemIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<RssItemIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Item Indexer",
};
