import { Schema } from "../schema/Schema"
import { NoteSchemaId } from "./NoteSchemaId"

export const NoteSchema: Schema = {
    $id: NoteSchemaId,
    title: "Note",
    type: "object",
    properties: {
        "title": {
            title: "Title",
            description: "The title of subject of the note",
            type: "string"
        },
        "text": {
            title: "Text",
            description: "The text body of the note",
            type: "string"
        }
    }
}