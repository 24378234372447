import { Annotator } from "../annotator/Annotator"
import { Indexer } from "../indexer/Indexer"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"
import { Reducer } from "../reducer/Reducer"

export class IndexesReducer extends Reducer {

    private annotator?: Annotator;
    private indexer: Indexer;

    constructor(indexer: Indexer, annotator?: Annotator) {
        super();
        if (!(this.indexer = indexer)) {
            throw new Error("indexer must be specified");
        }

        this.annotator = annotator;
    }

    public async reduce(context: Model): Promise<Payload[]> {

        const payload = context?.payload;
        if (!payload) {
            return [];
        }

        const indexes = await this.indexer.index(payload);
        if (this.annotator) {
            for(const index of indexes) {
                this.annotator.annotate(index);
            }
        }

        return indexes;
    }
}