import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { InstagramUsernameIndexer } from "./InstagramUsernameIndexer"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RegExIndexerPluginKey } from "../regex-indexer/RegExIndexerPluginKey"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const InstagramUsernameIndexerManifest: Manifest = {

    /**
     * A key that uniquely describes the plugin.
     */
    key: "instagram-username-indexer",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        RegExIndexerPluginKey,
        UrlPluginKey
    ],

    /**
     * The display description of the plugin.
     */
     description: "Show a link to an Instagram @username",

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "InstagramUsernameIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "Instagram Username Indexer",
            value: {
                schema: "*",
                indexer: new InstagramUsernameIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<InstagramUsernameIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Instagram Username Indexer",
};