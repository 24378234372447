import { Annotator } from "../annotator/Annotator"
import { NWSAlertFeed } from "../nws-alert-feed/NWSAlertFeed"
import { NWSAlertFeedSchemaId } from "../nws-alert-feed/NWSAlertFeedSchemaId"
import { Payload } from "../payload/Payload"

export class NWSAlertFeedAnnotator implements Annotator<NWSAlertFeed> {
    public async annotate(payload: Payload<NWSAlertFeed>): Promise<void> {
        if (payload?.schema === NWSAlertFeedSchemaId) {
            payload.title = "National Weather Service Alerts";
            if (payload.data?.area) {
                payload.title += ` (${payload.data.area})`;
            }
        }
    }
}