import { DefaultRandomGenerator } from "../random/DefaultRandomGenerator"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RandomPluginKey } from "../random/RandomPluginKey"
import { RandomRegistry } from "./RandomRegistry"
import { RandomRegistryContextKey } from "./RandomRegistryContextKey"
import { RandomRegistryPluginKey } from "./RandomRegistryPluginKey"

export const RandomRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: RandomRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        RandomPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: RandomRegistryContextKey,
            value: new RandomRegistry()
        } as ManifestEntry<RandomRegistry>,
        {
            key: "DefaultRandomGenerator",
            registerWith: RandomRegistryContextKey,
            value: new DefaultRandomGenerator()
        } as ManifestEntry<DefaultRandomGenerator>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Random Registry"
}