import { BooleanFormatter } from "./BooleanFormatter"
import { BooleanFormatterContextKey } from "./BooleanFormatterContextKey"
import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { BooleanSchemaId } from "../boolean/BooleanSchemaId"
import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryEntry } from "../formatter-registry/FormatterRegistryEntry"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const BooleanFormatterManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: "boolean-formatter",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BooleanPluginKey,
        FormatterPluginKey,
        FormatterRegistryPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // formatter
        {
            key: BooleanFormatterContextKey,
            title: "Boolean Formatter",
            value: new BooleanFormatter()
        } as ManifestEntry<BooleanFormatter>,

        // registration
        {
            key: "BooleanFormatterRegistryEntry",
            registerWith: FormatterRegistryContextKey,
            requires: [BooleanFormatterContextKey],
            title: "Boolean Formatter Registry Entry",
            value: (context: ManifestContext) => ({
                schemaId: BooleanSchemaId,
                formatter: context[BooleanFormatterContextKey]
            })
        } as ManifestEntry<FormatterRegistryEntry<BooleanFormatter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Boolean Formatter"
}