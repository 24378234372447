import React from "react"
import { Edit } from "../edit/Edit"
import { EditSchemaId } from "../edit/EditSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const CreateStreamView: React.FC<ReactViewProps> = (props) => {

    /**
     * Called when the create card dispatches a created payload.
     * Per the conventions of the create card, the payload has not been
     * saved at this point. The create card is only responsible for 
     * applying initial values and dispatching the new payload.
     */
    function onCreate(payload: Payload) {

        if(payload) {

            const editPayload: Payload<Edit> = {
                schema: EditSchemaId,
                data: {
                    payload
                }
            }

            if (props.dispatch) {
                props.dispatch(editPayload);
            }
        }
    }

    return (
        <ReactViewFactory
            layout="card"
            dispatch={p => onCreate(p)}
            matcher={props.matcher}
            model={props.model}>
        </ReactViewFactory>
    );
}