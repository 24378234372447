import { BingWallpaperPluginKey } from "./BingWallpaperPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const BingWallpaperManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: BingWallpaperPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper"
}