import { GoalEmblemView } from "./GoalEmblemView"
import { GoalPluginKey } from "../goal/GoalPluginKey"
import { GoalSchemaId } from "../goal/GoalSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const GoalEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "goal-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        GoalPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "GoalEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: GoalSchemaId,
                layout: "emblem",
                fc: GoalEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Goal Emblem View"
}