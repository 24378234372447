import React  from "react"
import { BingWallpaper } from "../bing-wallpaper/BingWallpaper"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./BingWallpaperCardView.css"

export const BingWallpaperCardView: React.FC<ReactViewProps> = (props) => {

    // Get the raw bing daily image data
    const hpimage: BingWallpaper = props.model?.payload?.data;
    if (!hpimage) {
        return <></>
    }

    return (
        <>
        <img
            alt={hpimage.title}
            className='BingWallpaperCard'
            src={"https://bing.com" + hpimage.url}></img>
        </>
    )
};