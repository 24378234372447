import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Selector } from "../selector/Selector"
import { SelectorMapContextKey } from "./SelectorMapContextKey"
import { SelectorMapPluginKey } from "./SelectorMapPluginKey"
import { SelectorPluginKey } from "../selector/SelectorPluginKey"

export const SelectorMapManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SelectorMapPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SelectorPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: SelectorMapContextKey,
            value: new Map<string, Selector>()
        } as ManifestEntry<Map<string, Selector>>
    ],

    /**
     * The display title of the plugin key.
     */
    title: "Selector Registry"
}