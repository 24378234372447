import { CopyrightPluginKey } from "../copyright/CopyrightPluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SplashIndexer } from "./SplashIndexer"
import { SplashPluginKey } from "../splash/SplashPluginKey"
import { SplashSchemaId } from "../splash/SplashSchemaId"

export const SplashIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "splash-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CopyrightPluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        SplashPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "SplashIndexer",
            registerWith: IndexerRegistryContextKey,
            value: {
                schema: SplashSchemaId,
                indexer: new SplashIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<SplashIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Splash Indexer"
}