import { ConverterPluginKey } from "./ConverterPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const ConverterManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: ConverterPluginKey,

    /**
     * The display title of the plugin.
     */
    title: "Converter"
}