import { MathProblem } from "./MathProblem"

export function solve(problem: MathProblem): number {

    const [operand1, operand2] = problem.operands;

    switch(problem.operator) {
        case "+":
            return operand1 + operand2;

        case "-":
            return operand1 - operand2;

        case "*":
            return operand1 * operand2;

        case "/":
            return operand1 / operand2;
        
        default:
            throw new Error(`Unsupported operator ${problem.operator}`)
    }
}