import { Activity } from "../activity/Activity";

export const JuneteenthActivity: Activity = {
    
    /**
     * The unique key of the activity.
     */
    key: "juneteenth",

    /**
     * The default schedule of the activity.
     */
    schedule: {
        months: "Jun",
        daysOfMonth: "19"
    },

    /**
     * The tags associated with the activity.
     */
    tags: [
        "blackhistory",
        "juneteenth"
    ],

    /**
     * The title of the activity.
     */
    title: "Juneteenth"
}