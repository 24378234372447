import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { DefinitionAnnotator } from "./DefinitionAnnotator"
import { DefinitionAnnotatorContextKey } from "./DefinitionAnnotatorContextKey"
import { DefinitionPluginKey } from "../definition/DefinitionPluginKey"
import { DefinitionSchemaId } from "../definition/DefinitionSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const DefinitionAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "definition-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        DefinitionPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: DefinitionAnnotatorContextKey,
            value: new DefinitionAnnotator()
        } as ManifestEntry<DefinitionAnnotator>,

        // registration
        {
            key: "DefinitionAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [DefinitionAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: DefinitionSchemaId,
                annotator: context[DefinitionAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<DefinitionAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Definition Annotator"
}
