import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { DeadlineAnnotator } from "./DeadlineAnnotator"
import { DeadlineAnnotatorContextKey } from "./DeadlineAnnotatorContextKey"
import { DeadlinePluginKey } from "../deadline/DeadlinePluginKey"
import { DeadlineSchemaId } from "../deadline/DeadlineSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const DeadlineAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "deadline-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        DeadlinePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: DeadlineAnnotatorContextKey,
            value: new DeadlineAnnotator()
        } as ManifestEntry<DeadlineAnnotator>,

        // registration
        {
            key: "DeadlineAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [DeadlineAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: DeadlineSchemaId,
                annotator: context[DeadlineAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<DeadlineAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Deadline Annotator"
}
