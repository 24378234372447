import React from "react"
import { IonText } from "@ionic/react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { UrlSchemaId } from "../url/UrlSchemaId"
import "./UrlPreviewView.css"

export const UrlPreviewView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    if (!payload) {
        return (
            <ReactViewFailure>
                Missing payload
            </ReactViewFailure>
        )
    }

    const schema = payload.schema;
    if (!schema) {
        return (
            <ReactViewFailure>
                The payload does not have a schema.
            </ReactViewFailure>
        )
    }

    let urlString: string | undefined;
    switch(schema) {
        case UrlSchemaId:
            urlString = payload.data as string;
            break;

        default:
            return (
                <ReactViewFailure>
                    Unsupported schema "{schema}"
                </ReactViewFailure>
            )
    }

    if (!urlString) {
        return (
            <ReactViewFailure>
                Payload does not have a URL value
            </ReactViewFailure>
        )
    }

    const url = new URL(urlString);
    const urlSafe = url.protocol === "https:";
    const urlColor = urlSafe ? "" : "danger";

    return (
        <div className="url-preview-view">
            <ReactViewFactory
                dispatch={props.dispatch}
                layout="emblem"
                matcher={props.matcher}
                model={props.model}>
            </ReactViewFactory>
            <IonText color={urlColor}>{urlString}</IonText>
        </div>        
    )
}