import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { FolderAnnotator } from "./FolderAnnotator"
import { FolderAnnotatorContextKey } from "./FolderAnnotatorContextKey"
import { FolderPluginKey } from "../folder/FolderPluginKey"
import { FolderSchemaId } from "../folder/FolderSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const FolderAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "folder-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        FolderPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: FolderAnnotatorContextKey,
            value: new FolderAnnotator()
        } as ManifestEntry<FolderAnnotator>,

        // registration
        {
            key: "FolderAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [FolderAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: FolderSchemaId,
                annotator: context[FolderAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<FolderAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Folder Annotator"
}