import { RandomGenerator } from "./RandomGenerator"
import { RandomValue } from "./RandomValue";

export class DefaultRandomGenerator extends RandomGenerator {

    public float(): RandomValue<number> {
        return {
            value: Math.random()
        }
    }

    public int(min: number, max: number): RandomValue<number> {

        // Ensure min and max are sorted correctly.
        const [nmin, nmax] = min < max ? [min, max] : [max, min];
        const range = nmax - nmin + 1;

        return {
            value: Math.floor(Math.random() * range) + nmin
        }
    }
}