import { FlashcardEmblemView } from "./FlashcardEmblemView"
import { FlashcardPluginKey } from "../flashcard/FlashcardPluginKey"
import { FlashcardSchemaId } from "../flashcard/FlashcardSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const FlashcardEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "flashcard-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FlashcardPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "FlashcardEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: FlashcardSchemaId,
                layout: "emblem",
                fc: FlashcardEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Flashcard Emblem View"
}