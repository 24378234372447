import React from "react"
import { codeOutline as icon} from "ionicons/icons"
import { IonIcon } from "@ionic/react"
import { Model } from "../state/Model"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const SchemaEmblemView: React.FC<ReactViewProps> = (props) => {

    const schemaId = props?.model?.payload?.data?.$id as string;
    if (!schemaId) {

        // The payload does not identify the specific schema with $id.
        // Use the generic icon to indicate a schema payload.
        return (
            <IonIcon style={{
                width:"32px",
                height: "32px"
            }} icon={icon} />
        );
    }
    else {

        // This schema has a unique identifier. See if an emblem exists
        // for that type of data. If so, use that emblem, or otherwise
        // use the default schema emblem.
        const model: Model = {
            payload: {
                schema: schemaId,
                data: undefined
            }
        }

        const match = props.matcher.match("emblem", model)
        if (match && match.length > 0) {

            return (
                <ReactViewFactory
                    layout="emblem"
                    dispatch={props.dispatch}
                    matcher={props.matcher}
                    model={model}>
                </ReactViewFactory>
            )
        }
        else {
            return (
                <IonIcon style={{
                    width:"32px",
                    height: "32px"
                }} icon={icon} />
            );    
        }
    }
}