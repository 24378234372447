import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NotifierArrayContextKey } from "../notifier-registry/NotifierArrayContextKey"
import { NotifierPluginKey } from "../notifier/NotifierPluginKey"
import { NotifierRegistryPluginKey } from "../notifier-registry/NotifierRegistryPluginKey"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { TaskStatusNotifier } from "./TaskStatusNotifier"

export const TaskStatusNotifierManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "task-status-notifier",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        NotifierPluginKey,
        NotifierRegistryPluginKey,
        TaskPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "TaskStatusNotifier",
            registerWith: NotifierArrayContextKey,
            value: new TaskStatusNotifier()
        } as ManifestEntry<TaskStatusNotifier>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Task Status Notifier"
}