import { HighlighterSchemaId } from "./HighlighterSchemaId"
import { Schema } from "../schema/Schema"

export const HighlighterSchema: Schema = {

    "$id": HighlighterSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "Text to highlight",
    title: "Highlighter",

    properties: {
        "match": {
            type: "string",
            description: "The text to search and highlight",
            title: "Match Text"
        },
    }
}