import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { DeleteCardView } from "./DeleteCardView"
import { DeletePluginKey } from "../delete/DeletePluginKey"
import { DeleteSchemaId } from "../delete/DeleteSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"

export const DeleteCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "delete-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConfirmPluginKey,
        DefaultStorePluginKey,
        DeletePluginKey,
        ReactViewPluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DeleteCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [DefaultStoreContextKey],
            value: (context: ManifestContext) => ({
                match: DeleteSchemaId,
                layout: "card",
                fc: (props: ReactViewProps) =>
                    DeleteCardView({
                        ...props,
                        store: context[DefaultStoreContextKey]
                    })
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Delete Card View"
}