import React from "react"
import { Button } from "../button/Button"
import { IonButton } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./ButtonCardView.css"

export const ButtonCardView: React.FC<ReactViewProps> = (props) => {

    const button = props?.model?.payload?.data as Button;
    const color = button?.destructive ? "danger" : undefined;

    function onClick() {
        if (props.dispatch && button?.value) {
            props.dispatch(button.value);
        }
    }

    return (
        <IonButton
            className="button-card-view"
            color={color}
            onClick={() => onClick()}>{button?.title}</IonButton>
    )
}