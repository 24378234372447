import React, { useEffect, useState } from "react"
import { IonButton } from "@ionic/react"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"
import { Schema } from "../schema/Schema"

export const ArrayReactInput: React.FC<ReactInputProps> = (props) => {

    const onChange = props.onChange;

    // Initialize the array state
    const [values, setValues] = useState<any[]>(props.value ?? []);

    // Pass changes to the parent
    useEffect(() => {
        if (onChange) {
            onChange(values);
        }
    }, [onChange, values]);

    // Validate the array (after hooks are defined per React restrictions)
    if (!Array.isArray(values)) {
        return (
            <ReactInputFailure>
                Value must be an array but is {String(values)}
            </ReactInputFailure>
        )
    }

    // Get and validate the array schema
    const schema = props.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema is missing or not an object
            </ReactInputFailure>
        )
    }

    if (schema.type !== "array") {
        return (
            <ReactInputFailure>
                Schema type is unsupported: {schema.type}
            </ReactInputFailure>
        )
    }

    // Validate the matcher for creating input components for a given schema
    if (typeof(props.inputMatcher) !== "object") {
        return (
            <ReactInputFailure>
                matcher missing or not an object
            </ReactInputFailure>
        )
    }

    // Get and validate the item schema
    const itemSchema = schema.items;
    if (typeof(itemSchema) !== "object") {
        return (
            <ReactInputFailure>
                Array schema must specify an items property as an object
            </ReactInputFailure>
        )
    }

    // Resolve the item schema and get a matching input control
    const [itemResolvedSchema, itemInput] = props.inputMatcher.resolve(itemSchema);
    if (!itemInput) {
        return (
            <ReactInputFailure>
                No control for array item schema
            </ReactInputFailure>
        )
    }

    /**
     * Executed when the + button is clicked and a value is being added to the array
     */
    function onAdd(s: Schema) {
        setValues(prev => [...prev, s.default]);
    }

    function onItemChange(index: number, value: any) {
        setValues(prev => {
            const newValues = [...prev];
            newValues[index] = value;
            return [...newValues];
        });
    }

    return (
        <>
            {values.map((itemValue, itemIndex) => itemInput({
                inputMatcher: props.inputMatcher,
                onChange: (value) => onItemChange(itemIndex, value),
                schema: itemResolvedSchema,
                value: itemValue
            }))}
            <IonButton onClick={() => onAdd(itemSchema)}>+</IonButton>
        </>
    )
}