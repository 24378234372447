import { ButtonSchemaId } from "./ButtonSchemaId"
import { PayloadSchemaId } from "../payload/PayloadSchemaId"
import { Schema } from "../schema/Schema"

export const ButtonSchema: Schema = {
    "$id": ButtonSchemaId,
    title: "Button",
    type: "object",
    properties: {
        title: {
            title: "Title",
            type: "string"
        },
        destructive: {
            title: "Destructive",
            type: "boolean"
        },
        value: {
            title: "Value",
            "$ref": PayloadSchemaId
        }
    }
}