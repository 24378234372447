import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Predicate } from "../predicate/Predicate"
import { PredicateArrayContextKey } from "../predicate/PredicateArrayContextKey"
import { PredicatePluginKey } from "../predicate/PredicatePluginKey"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { TaskPredicates } from "../task/TaskPredicates"

export const TaskPredicatesManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "task-predicates",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PredicatePluginKey,
        TaskPluginKey
    ],
    
    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ActiveTasksPredicate",
            registerWith: PredicateArrayContextKey,
            value: {
                key: "ActiveTasksPredicate",
                predicate: TaskPredicates.active,
                title: "Active Tasks"
            }
        } as ManifestEntry<Predicate>,
        {
            key: "CompletedTasksPredicate",
            registerWith: PredicateArrayContextKey,
            value: {
                key: "CompletedTasksPredicate",
                predicate: TaskPredicates.completed,
                title: "Completed Tasks"
            }
        } as ManifestEntry<Predicate>,
        {
            key: "CompletedTasksTodayPredicate",
            registerWith: PredicateArrayContextKey,
            value: {
                key: "CompletedTasksTodayPredicate",
                predicate: TaskPredicates.completedToday,
                title: "Completed Today"
            }
        } as ManifestEntry<Predicate>,
        {
            key: "CompletedTasksYesterdayPredicate",
            registerWith: PredicateArrayContextKey,
            value: {
                key: "CompletedTasksYesterdayPredicate",
                predicate: TaskPredicates.completedYesterday,
                title: "Completed Yesterday"
            }
        } as ManifestEntry<Predicate>,
        {
            key: "OverdueTasksPredicate",
            registerWith: PredicateArrayContextKey,
            value: {
                key: "OverdueTaskPredicate",
                predicate: TaskPredicates.overdueBy(1000 * 60 * 60 * 24),
                title: "Overdue tasks"
            }
        } as ManifestEntry<Predicate>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Task Predicates"
}