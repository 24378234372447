import { Formatter } from "../formatter/Formatter"
import { FormatterRegistryEntry } from "./FormatterRegistryEntry"
import { KeyMapRegistry } from "../../runtime/KeyMapRegistry"

export class FormatterRegistry extends KeyMapRegistry<FormatterRegistryEntry> implements Formatter {
    constructor() {
        super("schemaId")
    }

    public format<T = any>(schemaId: string, value: T): string | undefined {
        const entry = this.get(schemaId);
        if (entry) {
            return entry.formatter?.format(schemaId, value)
        }
    }
} 
