import { AddTags } from "../add-tags/AddTags"
import { AddTagsSchemaId } from "../add-tags/AddTagsSchemaId"
import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"
import { RemoveTags } from "../remove-tags/RemoveTags"
import { RemoveTagsSchemaId } from "../remove-tags/RemoveTagsSchemaId"

export const FAVORITE_TAG = "#favorite";

export class FavoritesCommander implements Commander {

    public async issue(model: Model): Promise<Command[]> {

        const payload = model?.payload;
        if (!payload || !payload.id) {
            return [];
        }

        if (Array.isArray(payload.tags) && payload.tags.includes(FAVORITE_TAG)) {
            return [
                {
                    key: "un#favorite",
                    emoji: "💔",
                    layout: "extended",
                    title: "Unfavor",
                    value: {
                        schema: RemoveTagsSchemaId,
                        data: {
                            payload,
                            confirmed: true,
                            tags: [FAVORITE_TAG]
                        }
                    } as Payload<RemoveTags>                    
                }
            ];
        }
        else {

            return [
                {
                    key: "#favorite",
                    emoji: "❤",
                    layout: "extended",
                    title: "Favor",
                    value: {
                        schema: AddTagsSchemaId,
                        data: {
                            payload,
                            confirmed: true,
                            tags: [FAVORITE_TAG]
                        }
                    } as Payload<AddTags>
                }
            ]
        }
    }
}