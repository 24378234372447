import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerCreatorPluginKey } from "../streamer-creator/StreamerCreatorPluginKey"
import { StreamerCreatorRegistry } from "./StreamerCreatorRegistry"
import { StreamerCreatorRegistryContextKey } from "./StreamerCreatorRegistryContextKey"
import { StreamerCreatorRegistryPluginKey } from "./StreamerCreatorRegistryPluginKey"

export const StreamerCreatorRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: StreamerCreatorRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        StreamerCreatorPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: StreamerCreatorRegistryContextKey,
            value: new StreamerCreatorRegistry()
        } as ManifestEntry<StreamerCreatorRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Streamer Creator Registry"
}