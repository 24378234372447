import { ActivityArrayContextKey } from "../activity-registry/ActivityArrayContextKey"
import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityRegistryPluginKey } from "../activity-registry/ActivityRegistryPluginKey"
import { ActivityToggleTrackerContextKey } from "../activity-toggle-tracker/ActivityToggleTrackerContextKey"
import { ActivityToggleTrackerPluginKey } from "../activity-toggle-tracker/ActivityToggleTrackerPluginKey"
import { ActivityToolbarButton } from "./ActivityToolbarButton"
import { ActivityTrackerContextKey } from "../activity-tracker/ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "../activity-tracker/ActivityTrackerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"

export const ActivityToggleToolbarButtonManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "activity-toggle-toolbar-button",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        ActivityRegistryPluginKey,
        ActivityToggleTrackerPluginKey,
        ActivityTrackerPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Makes it easier to switch activities such as work or chores",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "ActivityToolbarButton",
            registerWith: ReactViewRegistryContextKey,
            requires: [
                ActivityArrayContextKey,
                ActivityToggleTrackerContextKey,
                ActivityTrackerContextKey
            ],
            title: "Activity Toolbar Toggle",
            value: (context: ManifestContext) => ({
                layout: "toolbar-button",
                match: "*",
                fc: (props: ReactViewProps) =>
                    ActivityToolbarButton({...props,
                        activities: context[ActivityArrayContextKey],
                        setter: context[ActivityToggleTrackerContextKey],
                        tracker: context[ActivityTrackerContextKey]
                    }),
            })
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Toggle"
}