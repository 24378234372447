import { CatalogPluginKey } from "../catalog/CatalogPluginKey"
import { CatalogRegistryContextKey } from "../catalog/CatalogRegistryContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"
import { SubredditFeed } from "../subreddit-feed/SubredditFeed"
import { SubredditFeedCatalog } from "./SubredditFeedCatalog"
import { SubredditFeedPollerPluginKey } from "../subreddit-feed-poller/SubredditFeedPollerPluginKey"

export const SubredditFeedCatalogManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "subreddit-feed-catalog",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CatalogPluginKey,
        SubredditFeedPollerPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Popular reddit forums",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "SubredditFeedCatalog",
            registerWith: CatalogRegistryContextKey,
            title: "Reddit Catalog",
            value: SubredditFeedCatalog
        } as ManifestEntry<Payload<SubredditFeed>[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reddit Catalog",
}