import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryEntry } from "../formatter-registry/FormatterRegistryEntry"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { KilometersFormatter } from "./KilometersFormatter"
import { KilometersFormatterContextKey } from "./KilometersFormatterContextKey"
import { KilometersFormatterPluginKey } from "./KilometersFormatterPluginKey"
import { KilometersPluginKey } from "../kilometers/KilometersPluginKey"
import { KilometersSchemaId } from "../kilometers/KilometersSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const KilometersFormatterManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: KilometersFormatterPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FormatterPluginKey,
        FormatterRegistryPluginKey,
        KilometersPluginKey,
    ],

    entries: [

        // formatter
        {
            key: KilometersFormatterContextKey,
            value: new KilometersFormatter()
        } as ManifestEntry<KilometersFormatter>,

        // registration
        {
            key: "KilometersFormatterRegistryEntry",
            registerWith: FormatterRegistryContextKey,
            requires: [KilometersFormatterContextKey],
            value: (context: ManifestContext) => ({
                schemaId: KilometersSchemaId,
                formatter: context[KilometersFormatterContextKey]
            })
        } as ManifestEntry<FormatterRegistryEntry<KilometersFormatter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Kilometers Formatter"
}