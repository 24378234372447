import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Sequence } from "../sequence/Sequence"
import { SequenceMapContextKey } from "./SequenceMapContextKey"
import { SequenceMapPluginKey } from "./SequenceMapPluginKey"
import { SequencePluginKey } from "../sequence/SequencePluginKey"

export const SequenceMapManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: SequenceMapPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SequencePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: SequenceMapContextKey,
            value: new Map<string, Sequence>()
        } as ManifestEntry<Map<string, Sequence>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Sequence Map"
}