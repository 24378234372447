import { CatalogPluginKey } from "../catalog/CatalogPluginKey"
import { CatalogRegistryContextKey } from "../catalog/CatalogRegistryContextKey"
import { EarthquakeFeed } from "../usgs-earthquake-feed/EarthquakeFeed"
import { EarthquakeFeedCatalog } from "./EarthquakeFeedCatalog"
import { EarthquakeFeedPluginKey } from "../usgs-earthquake-feed/EarthquakeFeedPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"

export const UsgsEarthquakeFeedCatalogManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "usgs-earthquake-feed-catalog",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CatalogPluginKey,
        EarthquakeFeedPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "EarthquakeFeedCatalog",
            registerWith: CatalogRegistryContextKey,
            value: EarthquakeFeedCatalog
        } as ManifestEntry<Payload<EarthquakeFeed>[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Earthquake Feed Catalog",
}