import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { StringPluginKey } from "../string/StringPluginKey"
import { WaitingCardView } from "./WaitingCardView"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export const WaitingCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the string payload plugin.
     */
    key: "waiting-card-view",

    /**
     * Indicates the string payload plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        ReactViewPluginKey,
        StringPluginKey,
        WaitingPluginKey
    ],

    /**
     * Returns the objects supplied by the plugin.
     */
    entries: [
        {
            key: "WaitingCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Waiting Card View",
            value: {
                layout: ["card", "preview"],
                match: WaitingSchemaId,
                fc: WaitingCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Waiting Card View"
}