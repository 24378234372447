import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RssFeedAnnotator } from "./RssFeedAnnotator"
import { RssFeedAnnotatorContextKey } from "./RssFeedAnnotatorContextKey"
import { RssFeedPluginKey } from "../rss-feed/RssFeedPluginKey"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export const RssFeedAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "rss-feed-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        RssFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: RssFeedAnnotatorContextKey,
            value: new RssFeedAnnotator()
        } as ManifestEntry<RssFeedAnnotator>,

        // registration
        {
            key: "RssFeedAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [RssFeedAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: RssFeedSchemaId,
                annotator: context[RssFeedAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<RssFeedAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Feed Annotator"
}