import { Activity } from "../activity/Activity"
import { ActivityTracker } from "../activity-tracker/ActivityTracker"
import { triggers } from "../schedule/triggers"

export class ActivityScheduleTracker extends ActivityTracker {

    constructor(public activities: Activity[] = []) {
        super();
    }

    public track(): Activity[] {
        const scheduled: Activity[] = [];
        for(let activity of this.activities) {

            const schedule = activity?.schedule;
            if (!schedule) {
                continue;
            }

            if (triggers(schedule)) {
                scheduled.push(activity);
            }
        }
        return scheduled;
    }
}