import { CAP12 } from "../geojson/CAP12"
import { Feature } from "../geojson/Feature"
import { fetchJson } from "../../library/fetchJson"
import { GeoJSON } from "../geojson/GeoJSON"
import { NWSAlertSchemaId } from "../nws-alert/NWSAlertSchemaId"
import { NWSAlertFeed } from "../nws-alert-feed/NWSAlertFeed"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"

/**
 * A poller that returns weather alerts from the National Weather Service.
 * 
 * Per https://weather-gov.github.io/api/reporting-issues:
 * "Any outages or other technical operational issues with the live service (such as faulty or
 * missing data) should be reported to NCO/OMB Tech Control by emailing nco.ops@noaa.gov or
 * calling (301) 713-0902.""
 */
export class NWSAlertFeedPoller implements Poller {

    /**
     * The feed settings
     */
    private feed: NWSAlertFeed

    /**
     * Initializes a new instance of the weather alert poller.
     */
    constructor(feed: NWSAlertFeed) {
        if (!(this.feed = feed)) {
            throw new Error("feed must be specified");
        }
    }

    /**
     * Converts a GeoJSON feature into a payload.
     */
    public convertFeature(feature: Feature): Payload {

        if (!feature) {
            throw new Error("feature cannot be null");
        }
        
        // In GeoJSON, each feature has a "properties" object.
        const props = feature.properties as CAP12;

        return {
            data: feature,
            expiresOn: props.expires ? Date.parse(props.expires) : undefined,
            key: feature.id?.toString(),
            schema: NWSAlertSchemaId,
            title: props.headline,
        }
    }

    /**
     * Converts the response from into payloads.
     */
    public convertGeoJSON(data: GeoJSON | undefined): Payload[] {

        if (!data) {
            return [];
        }
        else {
            // HACK due to server issues; implement better error handling in future
            if (!data.features) {
                console.warn(`invalid response: ${data}`);
                return [];
            }

            return data.features.map(feature => {
                return this.convertFeature(feature);
            });
        }
    }

    /**
     * Polls for weather alerts.
     */
    public async poll(): Promise<Payload[]> {

        const url = `https://api.weather.gov/alerts/active?area=${this.feed.area}`;

        return fetchJson<GeoJSON>(url, true)
            .then(geo => this.convertGeoJSON(geo))
            .catch(ex => {
                console.error(ex);
                return [];
            });
    }
}