import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { ConvertCardView } from "./ConvertCardView"
import { ConverterPluginKey } from "../converter/ConverterPluginKey"
import { ConverterRegistryContextKey } from "../converter-registry/ConverterRegistryContextKey"
import { ConverterRegistryPluginKey } from "../converter-registry/ConverterRegistryPluginKey"
import { ConvertPluginKey } from "../convert/ConvertPluginKey"
import { ConvertSchemaId } from "../convert/ConvertSchemaId"
import { ListPluginKey } from "../list/ListPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"

export const ConvertCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "convert-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConfirmPluginKey,
        ConvertPluginKey,
        ConverterPluginKey,
        ConverterRegistryPluginKey,
        ListPluginKey,
        ReactViewPluginKey,
        SchemaRegistryPluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ConvertCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [
                ConverterRegistryContextKey,
                SchemaRegistryContextKey
            ],
            value: (context: ManifestContext) => ({
                match: ConvertSchemaId,
                layout: "card",
                fc: props => ConvertCardView({
                    ...props,
                    converter: context[ConverterRegistryContextKey],
                    schemas: context[SchemaRegistryContextKey]
                })
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Convert Card View"
}