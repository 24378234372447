import { ActivityKeySchemaId } from "./ActivityKeySchemaId"
import { Schema } from "../schema/Schema"

export const ActivityKeySchema: Schema = {

    /**
     * The URI that uniquely identifies the activity key schema.
     */
    "$id": ActivityKeySchemaId,

    /**
     * The display title of the activity key schema.
     */
    title: "Activity Key",
    
    /**
     * The simple data type of an activity key (string).
     */
    type: "string"
}