import React from "react"
import { IonSelect, IonSelectOption } from "@ionic/react"
import { Predicate } from "../predicate/Predicate"

export interface PredicateFilterProps {
    /**
     * Identifies the instance of the filter so the selected predicate can be remembered.
     */
    instanceKey?: string;

    /**
     * The function to call when the selected filter changes.
     */
    onPredicate: (value: Predicate | undefined) => void;

    /**
     * The list of filters that can be selected.
     */
    predicates: Predicate[],
}

export const PredicateFilter: React.FC<PredicateFilterProps> = (props) => {

    function onSelect(value: Predicate | string | undefined) {
        if (props.onPredicate) {
            if (value === undefined || typeof(value) === "string") {
                props.onPredicate(undefined);
            }
            else {
                props.onPredicate(value);
            }
        }
    }

    return (
        <IonSelect 
                onIonChange={e => onSelect(e.detail.value)}
                placeholder="select filter">

            <IonSelectOption key="*">(all)</IonSelectOption>
            
            {props.predicates.map(predicate => (
                <IonSelectOption
                    key={predicate.key}
                    value={predicate}>{predicate.title}</IonSelectOption>
            ))}

        </IonSelect>        
    );
}