import { DeferPluginKey } from "./DeferPluginKey"
import { DeferSchema } from "./DeferSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"
import { Schema } from "../schema/Schema"
import { TimespanPluginKey } from "../timespan/TimespanPluginKey"

export const DeferManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: DeferPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaRegistryPluginKey,
        TimespanPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DeferSchema",
            value: DeferSchema,
            registerWith: SchemaRegistryContextKey
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Defer"
}