import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { RedditPluginKey } from "../reddit/RedditPluginKey"
import { RedditThingCardView } from "./RedditThingCardView"
import { RedditThingSchemaId } from "../reddit/RedditThingSchemaId"

/**
 * The settings of the plugin.
 */
export const RedditThingCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "reddit-thing-card-card",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        RedditPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Displays a reddit thing",
    
    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "RedditThingCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Reddit Thing Card View",
            value: {
                layout: "card",
                match: RedditThingSchemaId,
                fc: RedditThingCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reddit Thing Card View"
}