import { DateNumberEmblemView } from "./DateNumberEmblemView"
import { DateNumberPluginKey } from "../date-number/DateNumberPluginKey"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const DateNumberEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "date-number-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DateNumberPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DateNumberEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: DateNumberSchemaId,
                layout: "emblem",
                fc: DateNumberEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Date Number Emblem View"
}