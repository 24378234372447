import React from "react"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { WhatsNew } from "../whats-new/WhatsNew"
import "./WhatsNewCardView.css"

export const WhatsNewCardView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    if (!payload) {
        return (
            <ReactViewFailure>payload missing</ReactViewFailure>
        )
    }

    const whatsnew = payload.data as WhatsNew;
    if (!whatsnew) {
        return (
            <ReactViewFailure>payload empty</ReactViewFailure>
        )
    }

    const type = (function() {
        switch(whatsnew.type) {
            case "feature":
                return "New Feature";

            case "fix":
                return "Bug Fix";

            default:
                return "Misc. Change";
        }
    })();

    return (
        <div className="whats-new-card-view selectable">
            <IonCard>
                <IonCardHeader className="whats-new-card-view-header">
                    <ReactViewFactory
                        dispatch={props.dispatch}
                        layout="emblem"
                        matcher={props.matcher}
                        model={props.model}>    
                    </ReactViewFactory>
                    <IonCardTitle className="whats-new-card-view-title">What's New</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="whats-new-card-view-content">
                    <h2>{whatsnew.title}</h2>
                    <h3>{new Date(whatsnew.releasedOn).toLocaleDateString()} - {type}</h3>
                    <p>{whatsnew.description}</p>
                </IonCardContent>
            </IonCard>
        </div>
    )
}
