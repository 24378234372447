import { EarthquakeCardView } from "./EarthquakeCardView"
import { EarthquakePluginKey } from "../usgs-earthquake/EarthquakePluginKey"
import { EarthquakeSchemaId } from "../usgs-earthquake/EarthquakeSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const UsgsEarthquakeCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "usgs-earthquake-card-view",

    /** 
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        EarthquakePluginKey,
        ReactViewPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Displays a USGS earthquake alert",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "EarthquakeCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Earthquake Card View",
            value: {
                layout: ["card", "preview"],
                match: EarthquakeSchemaId,
                fc: EarthquakeCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "USGS Earthquake Card View"
};
