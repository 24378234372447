import { Action } from "../controller/Action"
import { Controller } from "../controller/Controller"
import { ControllerVerb } from "../controller/ControllerVerb"
import { Payload } from "../payload/Payload"
import { Streamer } from "../streamer/Streamer"

export class StreamerController implements Controller {

    private streamer: Streamer;

    constructor(streamer: Streamer) {
        if (!(this.streamer = streamer)) {
            throw new Error("streamer must be specified");
        }
    }

    public async dispatch<TParam = any>(action: Action<TParam>): Promise<Payload | undefined> {

        if (action?.verb !== ControllerVerb.NEXT) {
            return undefined;
        }

        return this.streamer.next();
    }
}