import React  from "react"
import { IonCard, IonCardContent } from "@ionic/react"
import { NasaApod } from "../nasa-apod/NasaApod"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const NasaApodDetailView: React.FC<ReactViewProps<NasaApod>> = (props) => {

    return (
        <IonCard className="selectable">
            <IonCardContent>
                {props?.model?.payload?.data?.explanation}                
            </IonCardContent>
        </IonCard>
    )
};