import React from "react"
import { Bill } from "../bill/Bill"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./BillCardView.css"

export const BillCardView: React.FC<ReactViewProps> = (props) => {

    const bill: Bill = props?.model?.payload?.data;
    if (!bill) {
        return <></>
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: bill.currency ?? 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });    

    return (
        <div className="bill-card selectable">
            {bill.title &&
                <div className="bill-card-title">{bill.title}</div>
            }
            {bill.account &&
                <div className="bill-card-account">{bill.account}</div>
            }
            {bill.amount &&
                <div className="bill-card-amount">{formatter.format(bill.amount)}</div>
            }
        </div>
    )
}