import { EmailAddressPluginKey } from "../email-address/EmailAddressPluginKey"
import { GeoJSONPluginKey } from "../geojson/GeoJSONPluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NWSAlertIndexer } from "./NWSAlertIndexer"
import { NWSAlertPluginKey } from "../nws-alert/NWSAlertPluginKey"
import { NWSAlertSchemaId } from "../nws-alert/NWSAlertSchemaId"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const NWSAlertIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nws-alert-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        EmailAddressPluginKey,
        GeoJSONPluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        NWSAlertPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NWSAlertIndexer",
            registerWith: IndexerRegistryContextKey,
            title: "NWS Alert Indexer",
            value: {
                schema: NWSAlertSchemaId,
                indexer: new NWSAlertIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<NWSAlertIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NWS Alert Indexer",
};
