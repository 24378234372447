import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NoteAnnotator } from "./NoteAnnotator"
import { NoteAnnotatorContextKey } from "./NoteAnnotatorContextKey"
import { NotePluginKey } from "../note/NotePluginKey"
import { NoteSchemaId } from "../note/NoteSchemaId"

export const NoteAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "note-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        NotePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NoteAnnotatorContextKey,
            value: new NoteAnnotator()
        } as ManifestEntry<NoteAnnotator>,

        // registration
        {
            key: "NoteAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NoteAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NoteSchemaId,
                annotator: context[NoteAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NoteAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Note Annotator"
}
