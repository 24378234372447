import { Indexer } from "../indexer/Indexer"
import { IndexerRegistryEntry } from "./IndexerRegistryEntry"
import { Payload } from "../payload/Payload"
import { Registry } from "../../runtime/Registry"

const GLOBAL_MATCH = "*";

export class IndexerRegistry implements Indexer, Registry<IndexerRegistryEntry> {

    private map = new Map<string, Set<IndexerRegistryEntry>>();

    /**
     * Indexes a payload with the set of registered indexers.
     */
    public async index(payload: Payload): Promise<Payload[]> {

        const matches = this.match(payload);
        if (matches.length === 0) {
            return [];
        }

        const results: Payload[] = [];
        for(const settings of matches) {
            if (settings.indexer) {
                results.push(...await settings.indexer.index(payload))
            }
        }

        return results;
    }

    /**
     * Returns all registrations that can index the specified payload.
     */
    public match(payload: Payload): IndexerRegistryEntry[] {

        if (!payload) {
            return [];
        }

        const matches: IndexerRegistryEntry[] = [];

        // Get schema indexers
        if (payload.schema) {
            const schemaSet = this.map.get(payload.schema);
            if (schemaSet) {
                matches.push(...schemaSet.values())
            }
        }
        
        // Get global indexers
        const globalSet = this.map.get(GLOBAL_MATCH);
        if (globalSet) {
            matches.push(...globalSet.values())
        }

        return matches;
    }

    /**
     * Adds an indexer to the registry.
     */
    public register(value: IndexerRegistryEntry): void {

        if (!value) {
            throw new Error("value must be specified");
        }

        if (typeof(value.schema) !== "string") {
            throw new Error("schema is undefined or not a string value")
        }
        
        if (typeof(value.indexer) !== "object") {
            throw new Error("indexer is undefined or not an object")
        }

        // Get the target schema. If unspecified, target all.
        const schema = value.schema ?? GLOBAL_MATCH;

        // Get the set of indexers for this schema
        let set = this.map.get(schema);
        if (!set) {
            set = new Set<IndexerRegistryEntry>();
            this.map.set(schema, set);
        }

        set.add(value);
    }

    /**
     * Removes an indexer from the registry.
     */
    public unregister(value: IndexerRegistryEntry): void {

        if (!value) {
            throw new Error("value must be specified");
        }

        // Get the target schema. If unspecified, target all.
        const schema = value.schema ?? GLOBAL_MATCH;

        // Get the set of indexers for this schema
        const set = this.map.get(schema);
        if (!set) {
            return;
        }

        set.delete(value);
    }
}