import React from "react"
import { ISO4217CodeSchemaId } from "../iso-4217-code/ISO4217CodeSchemaId"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./ISO4217CodeEmblemView.css"

export const ISO4217CodeEmblemView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    if (payload?.schema !== ISO4217CodeSchemaId) {
        return <></>
    }

    const code = payload?.data as string;
    if (!code) {
        return <></>
    }

    var formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: code,
    });  

    const symbolPart = formatter.formatToParts().find(v => v.type === "currency");
    if (!symbolPart) {
        return <></>
    }


    return (
        <div className="ISO4217CodeEmblem">
            <span className={`ISO4217CodeEmblemL${symbolPart.value.length}`}>{symbolPart.value}</span>
        </div>
    )
}