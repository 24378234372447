import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react"
import { ReactViewProps } from '../react-view/ReactViewProps'
import './NumberBasesDetail.css'

export const NumberBasesDetail: React.FC<ReactViewProps> = (props) => {
    
    const value = props?.model?.payload?.data;
    if (typeof(value) !== 'number') {
        return <></>;
    }

    return (
        <IonCard className="card-detail">
            <IonCardContent>
                <IonGrid>
                    <IonRow className="column-titles">
                        <IonCol>
                            <div>
                                Type of System
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                Base
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                Value
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Binary
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                2
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(2)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Ternary
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                3
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(3)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Quaternary
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                4
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(4)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Quinary
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                5
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(5)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Senary
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                6
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(6)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Septenary
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                7
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(7)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Octal
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                8
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(8)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Nonary
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                9
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(9)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Decimal
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                10
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Undecimal
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                11
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(11)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Duodecimal
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                12
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(12)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Hexadecimal
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                16
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(16)}
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div>
                                Vigesimal
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                20
                            </div>
                        </IonCol>
                        <IonCol>
                            <div>
                                {value.toString(20)}
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
}