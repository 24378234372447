import React from "react"
import { ReactInputFailure } from "./ReactInputFailure"
import { ReactInputProps } from "./ReactInputProps"

export const ReactInput: React.FC<ReactInputProps> = (props) => {

    if (typeof(props.inputMatcher) !== "object") {
        return (
            <ReactInputFailure>
                A matcher must be specified.
            </ReactInputFailure>
        )
    }

    if (typeof(props.schema) !== "object") {
        return (
            <ReactInputFailure>
                A schema must be specified
            </ReactInputFailure>
        )
    }

    const [schema, fc] = props.inputMatcher.resolve(props.schema);
    if (typeof(fc) !== "function") {
        return (
            <ReactInputFailure>
                unsupported schema {props.schema?.$id}
            </ReactInputFailure>
        )
    }

    return fc({...props, schema});
}