import { ListPluginKey } from "./ListPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const ListManifest: Manifest = {

    /**
     * The key that uniquely identifies the search box plugin.
     */
    key: ListPluginKey,

    /**
     * Indicates whether the plugin should be installed at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "List"
}