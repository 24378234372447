import React  from "react"
import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonIcon
} from "@ionic/react"
import { logoReddit } from "ionicons/icons"
import { RedditT3Image } from "./RedditT3Image"
import { T3 } from "../reddit/T3"
import './RedditT3Card.css'

/**
 * The properties of the component.
 */
interface RedditT3CardProps {
    t3: T3
}

export const RedditT3Card: React.FC<RedditT3CardProps> = (props) => {

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardSubtitle>
                    <span className="reddit-t3-header-logo"><IonIcon icon={logoReddit}></IonIcon></span>
                    <span className="reddit-t3-header-subreddit">{props.t3.subreddit_name_prefixed}</span>
                    <span className="reddit-t3-header-author">• Posted by {props.t3.author}</span>
                </IonCardSubtitle>
                <IonCardTitle className="reddit-t3-header-title selectable">
                    {props.t3.title}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="reddit-t3-content">
                <RedditT3Image t3={props.t3}></RedditT3Image>
            </IonCardContent>
        </IonCard>      

    );
};