import { BingWallpaperFeed } from "../bing-wallpaper-feed/BingWallpaperFeed"
import { BingWallpaperFeedCatalog } from "./BingWallpaperFeedCatalog"
import { BingWallpaperFeedPluginKey } from "../bing-wallpaper-feed/BingWallpaperFeedPluginKey"
import { CatalogPluginKey } from "../catalog/CatalogPluginKey"
import { CatalogRegistryContextKey } from "../catalog/CatalogRegistryContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"

export const BingWallpaperFeedCatalogManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-feed-catalog",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BingWallpaperFeedPluginKey,
        CatalogPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "BingWallpaperFeedCatalog",
            registerWith: CatalogRegistryContextKey,
            value: BingWallpaperFeedCatalog
        } as ManifestEntry<Payload<BingWallpaperFeed>[]>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Feed Catalog",
};
