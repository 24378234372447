import { Annotator } from "../annotator/Annotator"
import { NasaApod } from "../nasa-apod/NasaApod"
import { NasaApodSchemaId } from "../nasa-apod/NasaApodSchemaId"
import { Payload } from "../payload/Payload";

export class NasaApodAnnotator implements Annotator {

    public async annotate(payload: Payload<NasaApod>): Promise<void> {

        if (payload?.schema !== NasaApodSchemaId) {
            return;
        }

        if (payload.data) {
            payload.key = payload.data.date;
            payload.title = payload.data.title;
        }
    }
}