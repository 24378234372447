import { LoveSplashes } from "./LoveSplashes"
import { LoveSplashesPluginKey } from "./LoveSplashesPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Splash } from "../splash/Splash"
import { SplashArrayContextKey } from "../splash-array/SplashArrayContextKey"
import { SplashArrayPluginKey } from "../splash-array/SplashArrayPluginKey"
import { SplashPluginKey } from "../splash/SplashPluginKey"

export const LoveSplashesManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: LoveSplashesPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SplashPluginKey,
        SplashArrayPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "LoveSplashes",
            registerWith: SplashArrayContextKey,
            value: LoveSplashes
        } as ManifestEntry<Splash[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Love Splashes"
}