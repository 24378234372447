import { AttachPluginKey } from "./AttachPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const AttachManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: AttachPluginKey,

    /**
     * Indicates whether to install the plugi by default at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Attach"
}