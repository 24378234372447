import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReducerPluginKey } from "../reducer/ReducerPluginKey"
import { ReducerRegistry } from "./ReducerRegistry"
import { ReducerRegistryContextKey } from "./ReducerRegistryContextKey"
import { ReducerRegistryPluginKey } from "./ReducerRegistryPluginKey"

export const ReducerRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ReducerRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReducerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ReducerRegistryContextKey,
            title: "Reducer Registry",
            value: new ReducerRegistry()
        } as ManifestEntry<ReducerRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reducer Registry"
}