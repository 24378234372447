import React from "react"
import { Link } from "../link/Link"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { UrlSchemaId } from "../url/UrlSchemaId"

export const LinkCardView: React.FC<ReactViewProps> = (props) => {

    const link = props?.model?.payload?.data as Link;

    // Wrap the Link into a URL that can be passed to the URL card view.
    const payload: Payload<string> = {
        schema: UrlSchemaId,
        data: link?.url
    }

    // For now, use the URL card until a better custom one can be created
    return (
        <ReactViewFactory
            dispatch={props.dispatch}
            layout="card"
            matcher={props.matcher}
            model={{ payload }}></ReactViewFactory>
    );
}