import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Folder } from "../folder/Folder"
import { FolderSchemaId } from "../folder/FolderSchemaId"

export class FolderAnnotator implements Annotator<Folder> {
    public async annotate(payload: Payload<Folder>): Promise<void> {
        if ((payload?.schema === FolderSchemaId) && (payload.data?.name)) {
            payload.key = payload.data.name;
            payload.title = payload.data.name;
        }
    }
}