import React from "react"
import { addCircle } from "ionicons/icons"
import { CreateSchemaId } from "../create/CreateSchemaId"
import { IonButton, IonIcon } from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Create } from "../create/Create"

export const CreateToolbarButton: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;

    function onclick() {

        const parentId = props?.model?.payload?.id;

        const payload: Payload<Create> = {
            schema: CreateSchemaId,
            data: {
                payload: {
                    schema: undefined,
                    data: undefined,
                    parentId
                }
            },
        }

        if (dispatch) {
            dispatch(payload);
        }
    }

    return (
        <IonButton onClick={() => onclick()} size="large">
            <IonIcon icon={addCircle} size="large"></IonIcon>
        </IonButton>
    );
}