import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonCard,
    IonCardContent,
    IonText
} from "@ionic/react"

export const DefaultPayloadCard: React.FC<ReactViewProps> = (props) => {
    
    return (
        <IonCard>
            <IonCardContent>
                <IonText>No payloads are available!</IonText>
            </IonCardContent>
        </IonCard>
    );
};