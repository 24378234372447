import React from "react"
import { Command } from "../commander/Command"
import { IonButton } from "@ionic/react"
import { Payload } from "../payload/Payload"

interface ExtendedCommandButtonProps {
    /**
     * The command to execute.
     */    
    command: Command;

    /**
     * The function to dispatch the next payload.
     */
    dispatch: undefined | ((p: Payload) => void);
}

export const ExtendedCommandButton: React.FC<ExtendedCommandButtonProps> = (props) => {

    function onclick() {
        if (props.command?.value && props.dispatch) {
            props.dispatch(props.command.value);
        }
    }

    function color(command: Command): string | undefined {
        if (command?.destructive) {
            return "danger"
        }
        else {
            return undefined;
        }
    }

    return (
        <IonButton
                color={color(props.command)}
                expand="block"
                fill="clear"
                onClick={() => onclick()}>
            {props.command.emoji} {props.command.title}
        </IonButton>
    )
}