import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { Quote } from "../quote/Quote"
import { QuoteSchemaId } from "../quote/QuoteSchemaId"
import { SearchStringSchemaId } from "../search-string/SearchStringSchemaId"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class QuoteIndexer implements Indexer {

    public async index(payload: Payload | undefined): Promise<Payload[]> {

        if (!payload || payload.schema !== QuoteSchemaId) {
            return [];
        }

        const quote = payload.data as Quote;
        if (!quote) {
            return [];
        }

        let payloads: Payload[] = [];

        if (Array.isArray(quote.links)) {
            for(const link of quote.links) {
                payloads.push({
                    schema: UrlSchemaId,
                    data: link.url,
                    key: link.url,
                    title: link.title
                })
            }
        }

        if (quote.who) {
            payloads.push({
                schema: SearchStringSchemaId,
                data: quote.who,
                key: quote.who,
                title: quote.who
            });
        }

        if (quote.text) {
            payloads.push({
                schema: SearchStringSchemaId,
                data: quote.text,
                key: quote.text,
                title: '"' + quote.text.substr(0, 16) + '..."'
            })
        }
        
        return payloads;
    }

    
}