import { ActivityCueStreamer } from "./ActivityCueStreamer"
import { ActivityCueStreamerContextKey } from "./ActivityCueStreamerContextKey"
import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityTrackerContextKey } from "../activity-tracker/ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "../activity-tracker/ActivityTrackerPluginKey"
import { CuePluginKey } from "../cue/CuePluginKey"
import { DefaultCues } from "./DefaultCues"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"

export const ActivityCueStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "activity-cue-streamer",

    /**
     * Indicates whether the plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        ActivityTrackerPluginKey,
        CuePluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey,
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: ActivityCueStreamerContextKey,
            requires: [ActivityTrackerContextKey],
            title: "Activity Cue Streamer",
            value: (context: ManifestContext) => 
                new ActivityCueStreamer(context[ActivityTrackerContextKey], DefaultCues)              
        } as ManifestEntry<ActivityCueStreamer>,
        {
            key: "ActivityCueStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: [ActivityCueStreamerContextKey],
            value: (context: ManifestContext) => ({
                streamer: context["ActivityCueStreamer"]
            })                
        } as ManifestEntry<StreamerRegistryEntry<ActivityCueStreamer>>,
    ],
 
    /**
     * The display title of the plugin.
     */
    title: "Activity Cue Streamer",
}