import { DeferNotifier } from "./DeferNotifier"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NotifierPluginKey } from "../notifier/NotifierPluginKey"
import { NotifierRegistryPluginKey } from "../notifier-registry/NotifierRegistryPluginKey"
import { TimespanFormatterPluginKey } from "../timespan-formatter/TimespanFormatterPluginKey"
import { NotifierArrayContextKey } from "../notifier-registry/NotifierArrayContextKey"
import { Notifier } from "../notifier/Notifier"

export const DeferNotifierManifest: Manifest = {

    /**
     * A key that uniquely identifies the plugin.
     */
    key: "defer-notifier",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        NotifierPluginKey,
        NotifierRegistryPluginKey,
        TimespanFormatterPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "DeferNotifier",
            registerWith: NotifierArrayContextKey,
            value: new DeferNotifier()
        } as ManifestEntry<Notifier>
    ],
 
    /**
     * The display title of the plugin.
     */
    title: "Defer Notice Indexer"
}