import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { BingWallpaperFeedAnnotator } from "./BingWallpaperFeedAnnotator"
import { BingWallpaperFeedAnnotatorContextKey } from "./BingWallpaperFeedAnnotatorContextKey"
import { BingWallpaperFeedPluginKey } from "../bing-wallpaper-feed/BingWallpaperFeedPluginKey"
import { BingWallpaperFeedSchemaId } from "../bing-wallpaper-feed/BingWallpaperFeedSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const BingWallpaperFeedAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-feed-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        BingWallpaperFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: BingWallpaperFeedAnnotatorContextKey,
            value: new BingWallpaperFeedAnnotator()
        } as ManifestEntry<BingWallpaperFeedAnnotator>,

        // registration
        {
            key: "BingWallpaperFeedAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [BingWallpaperFeedAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: BingWallpaperFeedSchemaId,
                annotator: context[BingWallpaperFeedAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<BingWallpaperFeedAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Feed Annotator"
}