import React from "react"
import { Copyright } from "../copyright/Copyright"
import { CopyrightSchemaId } from "../copyright/CopyrightSchemaId"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonList,
    IonText } from "@ionic/react"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"

/**
 * Returns all copyright payloads for the specified model.
 */
function getCopyrights(model: Model | undefined): Payload<Copyright>[] {

    if (!model) {
        return [];
    }

    // Get indexed items
    const index: Payload[] = model.index;
    if (!Array.isArray(index)) {
        return [];
    }

    const results: Payload<Copyright>[] = [];

    // Check the index
    for(let p of index) {
        if (p?.schema !== CopyrightSchemaId) {
            continue;
        }
        if (p.data) {
            results.push(p);
        }
    }

    return results;
}

export const CopyrightDetail: React.FC<ReactViewProps> = (props) => {

    const copyrights = getCopyrights(props.model);
    if (copyrights.length > 0) {
        return (
            <IonCard className="selectable">
                <IonCardHeader>
                    <IonCardTitle>
                        Credits
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonList>
                        {copyrights.map(p => (
                            <IonItem key={p.id ?? p.key}>
                                <IonText>{p.data.notice}</IonText>
                            </IonItem>
                        ))}
                    </IonList>
                </IonCardContent>
            </IonCard>
        )    
    }
    else {
        return <></>
    }
}