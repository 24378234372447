import { ChildrenCardViewContextKey } from "../children-card-view/ChildrenCardViewContextKey"
import { ChildrenCardViewPluginKey } from "../children-card-view/ChildrenCardViewPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { RssFeedPluginKey } from "../rss-feed/RssFeedPluginKey"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export const RssFeedCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "rss-feed-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ChildrenCardViewPluginKey,
        ReactViewPluginKey,
        RssFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "RssFeedCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [ChildrenCardViewContextKey],
            value: (context: ManifestContext) => (
                { ...context[ChildrenCardViewContextKey], match: RssFeedSchemaId}
            )
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Feed Card View"
}