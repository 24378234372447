import { Manifest } from "../../runtime/Manifest"
import { RandomPluginKey } from "./RandomPluginKey"

export const RandomManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: RandomPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Random Library"
}