import React, { useState } from "react"
import { IonItem, IonLabel, IonList } from "@ionic/react"
import { List } from "../list/List"
import { Payload } from "../payload/Payload"
import { Predicate } from "../predicate/Predicate"
import { PredicateFilter } from "./PredicateFilter"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { TextFilter } from "./TextFilter"
import "./ListView.css"

export interface ListViewProps extends ReactViewProps {
    /**
     * The available filters for selecting subsets of the folder contains, e.g., all tasks.
     */
    predicates: Predicate[],
}

export const ListView: React.FC<ListViewProps> = (props) => {

    const select = props?.model?.payload?.data as List;
    const [criteria, setCriteria] = useState("");
    const [predicate, setPredicate] = useState<Predicate | undefined>();

    function onCriteria(criteria: string) {
        setCriteria(criteria);
    }

    function onPredicate(predicate: Predicate | undefined) {
        setPredicate(predicate);
    }

    function onSelect(payload: Payload) {
        if (props.dispatch) {
            props.dispatch(payload);
        }
    }

    // Returns filtered results
    const filtered = () => {

        let f = select.items;
        
        // Apply the selected predicate
        if (predicate) {
            f = f.filter(predicate.predicate);
        }

        // Apply the text criteria
        const upper = criteria.toUpperCase();
        if (upper.length > 0) {
            f = f.filter(p => {
                return p && p.title && p.title.toUpperCase().includes(upper);
            });    
        }

        // Sort the results
        return f.sort((a,b) => {
            return (a?.title ?? "").localeCompare(b?.title ?? "");
        });
    };

    return (
        <IonList>

            <IonItem>
                <TextFilter
                    onChange={(value) => onCriteria(value)}
                    value={criteria}>
                </TextFilter>
                <PredicateFilter
                    onPredicate={(predicate) => onPredicate(predicate)}
                    predicates={props.predicates}>
                </PredicateFilter>
            </IonItem>

            {filtered().map(p =>
                <IonItem button={true} key={p.key ?? p.id} onClick={() => onSelect(p)}>
                    <span className="list-view-item-emblem">
                        <ReactViewFactory
                            dispatch={props.dispatch}
                            matcher={props.matcher}
                            layout="emblem"
                            multi={false}
                            model={{ payload: p }}
                        />
                    </span>
                    <IonLabel>{p.title}</IonLabel>
                </IonItem> 
            )}
        </IonList>
    )
}