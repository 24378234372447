import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NasaApodDetailView } from "./NasaApodDetailView"
import { NasaApodPluginKey } from "../nasa-apod/NasaApodPluginKey"
import { NasaApodSchemaId } from "../nasa-apod/NasaApodSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const NasaApodDetailViewManifest: Manifest = {

    /**
     * A key that uniquely identifies the plugin.
     */
    key: "nasa-apod-detail-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NasaApodPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Displays the explanation from an Astronomy Picture of the Day",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NasaApodDetailView",
            registerWith: ReactViewRegistryContextKey,
            title: "NASA APOD Detail View",
            value: {
                layout: "detail",
                match: NasaApodSchemaId,
                fc: NasaApodDetailView,
                order: "first"
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NASA Astronomy Picture of the Day Detail"
};
