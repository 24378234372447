import React from "react"
import { IonInput } from "@ionic/react"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"

export const NumberReactInput: React.FC<ReactInputProps> = (props) => {

    const schema = props?.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema missing or not an object
            </ReactInputFailure>
        )
    }

    switch(schema.type) {
        case "integer":
        case "number":
            break;

        default:
            return (
                <ReactInputFailure>
                    Unsupported schema type {schema.type}
                </ReactInputFailure>
            )
    }

    // Get the value of the field
    const value = props.value ?? schema.default;
    
    // Get the range of values as strings
    const max = schema.maximum !== undefined ? String(schema.maximum) : undefined;
    const min = schema.minimum !== undefined ? String(schema.minimum) : undefined;

    function onChanged(value: string | undefined) {
        if (value === undefined || value === "") {
            props.onChange(undefined);
        }
        else {
            props.onChange(parseFloat(value));                
        }
    }

    const step = schema.type === "number" ? "any" : "1";

    return (
        <IonInput
            max={max}
            min={min}
            onIonChange={e => onChanged(e.detail.value ?? undefined)}
            step={step}
            type="number"
            value={value}></IonInput>    
    );
}   