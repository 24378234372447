import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NasaApodCardView } from "./NasaApodCardView"
import { NasaApodCardViewPluginKey } from "./NasaApodCardViewPluginKey"
import { NasaApodPluginKey } from "../nasa-apod/NasaApodPluginKey"
import { NasaApodSchemaId } from "../nasa-apod/NasaApodSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const NasaApodCardViewManifest: Manifest = {

    /**
     * A key that uniquely identifies the plugin.
     */
    key: NasaApodCardViewPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NasaApodPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Provides a card to show the daily Astronomy Picture of the Day from NASA.",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NasaApodCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "NASA APOD Card View",
            value: {
                layout: ["card", "preview"],
                match: NasaApodSchemaId,
                fc: NasaApodCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NASA Astronomy Picture of the Day Card"
};
