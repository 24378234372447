import React  from "react"
import { CAP12 } from "../geojson/CAP12"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./NWSAlertCardView.css"

function weatherIcon(event: string): string | undefined {
    switch(event) {
        case "Coastal Flood Advisory":
            return "wi-flood";

        case "Dense Fog Advisory":
            return "wi-fog";

        case "Dust Advisory":
            return "wi-dust";

        case "Excessive Heat Warning":
            return "wi-thermometer";
            
        case "Excessive Heat Watch":
            return "wi-thermometer";

        case "Fire Weather Watch":
            return "wi-fire";

        case "Flash Flood Warning":
            return "wi-flood";

        case "Flood Advisory":
            return "wi-flood";

        case "Flood Warning":
            return "wi-flood";

        case "Flood Watch":
            return "wi-flood";

        case "Heat Advisory":
            return "wi-thermometer";

        case "High Surf Advisory":
            return undefined;

        case "High Wind Warning":
            return "wi-strong-wind";

        case "High Wind Watch":
            return "wi-strong-wind";

        case "Hydrologic Outlook":
            return undefined;

        case "Red Flag Warning":
            return "wi-fire";
            
        case "Severe Thunderstorm Warning":
            return "wi-thunderstorm";

        case "Special Weather Statement":
            return undefined;

        case "Wind Advisory":
            return "wi-windy";

        case "Winter Storm Warning":
            return "wi-snow";

        case "Winter Weather Advisory":
            return "wi-snow";

        default:
            return undefined;
    }
}

export const NWSAlertCardView: React.FC<ReactViewProps> = (props) => {

    const feature = props?.model?.payload?.data;
    if (!feature) {
        return <></>
    }

    // Get the underlying properties of the feature.
    const fprops = feature.properties as CAP12;
    
    // Try to map the weather event to a weather icon file
    const icon = weatherIcon(fprops?.event);

    return (
        <>
            <div className="weather-alert-main selectable">
                <h1 className="weather-alert-heading">{fprops.event}</h1>                
                {icon &&
                    <img
                        alt=""
                        className="weather-alert-icon"
                        style={{fill: "white"}}
                        src={`/assets/weather-icons/svg/${icon}.svg`}/>
                }
                <h2 className="weather-alert-area">{fprops.areaDesc}</h2>
                <div className="weather-alert-panels">
                    <div className={"weather-alert-panel " + fprops.severity}>
                        <div className="weather-alert-panel-label">Severity</div>
                        <div className="weather-alert-panel-value">{fprops.severity}</div>
                    </div>
                    <div className="weather-alert-panel">
                        <div className="weather-alert-panel-label">Certainty</div>
                        <div className="weather-alert-panel-value">{fprops.certainty}</div>
                    </div>
                    <div className="weather-alert-panel">
                        <div className="weather-alert-panel-label">Urgency</div>
                        <div className="weather-alert-panel-value">{fprops.urgency}</div>
                    </div>
                </div>
                <div className="weather-alert-instructions">{fprops.instruction}</div>
            </div>
        </>
    )
};