import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NWSAlertFeedAnnotator } from "./NWSAlertFeedAnnotator"
import { NWSAlertFeedAnnotatorContextKey } from "./NWSAlertFeedAnnotatorContextKey"
import { NWSAlertFeedPluginKey } from "../nws-alert-feed/NWSAlertFeedPluginKey"
import { NWSAlertFeedSchemaId } from "../nws-alert-feed/NWSAlertFeedSchemaId"

export const NWSAlertFeedAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nws-alert-feed-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        NWSAlertFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NWSAlertFeedAnnotatorContextKey,
            value: new NWSAlertFeedAnnotator()
        } as ManifestEntry<NWSAlertFeedAnnotator>,

        // registration
        {
            key: "NWSAlertFeedAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NWSAlertFeedAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NWSAlertFeedSchemaId,
                annotator: context[NWSAlertFeedAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NWSAlertFeedAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "NWS Alert Feed Annotator"
}