import { ChildrenPluginKey } from "./ChildrenPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const ChildrenManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: ChildrenPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Children"
}