import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { RealityCheckCardView } from "./RealityCheckCardView"
import { RealityCheckPluginKey } from "../reality-check/RealityCheckPluginKey"
import { RealityCheckSchemaId } from "../reality-check/RealityCheckSchemaId"

export const RealityCheckCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "reality-check-card-view",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        RealityCheckPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "RealityCheckCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Reality Check Card View",
            value: {
                layout: ["card", "preview"],
                match: RealityCheckSchemaId,
                fc: RealityCheckCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reality Check Card View",
}
