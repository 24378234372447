import { BingWallpaperFeedPluginKey } from "../bing-wallpaper-feed/BingWallpaperFeedPluginKey"
import { BingWallpaperFeedSchemaId } from "../bing-wallpaper-feed/BingWallpaperFeedSchemaId"
import { ChildrenCardViewContextKey } from "../children-card-view/ChildrenCardViewContextKey"
import { ChildrenCardViewPluginKey } from "../children-card-view/ChildrenCardViewPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"

export const BingWallpaperFeedCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-feed-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BingWallpaperFeedPluginKey,
        ChildrenCardViewPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "BingWallpaperFeedCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [ChildrenCardViewContextKey],
            value: (context: ManifestContext) => (
                { ...context[ChildrenCardViewContextKey], match: BingWallpaperFeedSchemaId}
            )
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Feed Card View"
}