import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NextCard } from "./NextCardView"
import { NextPluginKey } from "../next/NextPluginKey"
import { NextSchemaId } from "../next/NextSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"

export const NextCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "next-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NextPluginKey,
        ReactViewPluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "NextCardView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: NextSchemaId,
                layout: "card",
                fc: NextCard
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Next Card View"
}