import React, { useEffect, useState } from "react"
import { fetchJson } from "../../library/fetchJson"
import { isWikipediaUrl } from "../wikipedia-summary/isWikipediaUrl"
import { Payload } from "../payload/Payload"
import { ReactViewMatcher } from "../react-view/ReactViewMatcher"
import { WikipediaSummary } from "../wikipedia-summary/WikipediaSummary"
import { WikipediaSummaryCardView } from "./WikipediaSummaryCardView"
import { WikipediaSummarySchemaId } from "../wikipedia-summary/WikipediaSummarySchemaId"

export interface WikipediaSummaryCardViewLoaderProps {
    dispatch?: (payload: Payload) => void;
    matcher: ReactViewMatcher;
    url: string
}

export const WikipediaSummaryCardViewLoader: React.FC<WikipediaSummaryCardViewLoaderProps> = (props) => {

    const url = props.url;
    const [summary, setSummary] = useState<WikipediaSummary>();

    useEffect(() => {

        let mounted = true;

        if (isWikipediaUrl(url)) {
            // Get the title of the page
            const title = url.split('/').pop()!;

            // Construct the API URL, which takes the page title as a parameter
            // https://stackoverflow.com/questions/8555320/is-there-a-wikipedia-api-just-for-retrieve-content-summary
            const endpoint = `https://en.wikipedia.org/api/rest_v1/page/summary/${title}`;

            // Fetch the page summary
            fetchJson<WikipediaSummary>(endpoint).then(result => {
                if (mounted && result?.extract) {
                    setSummary(result);
                }
            });
        }
    
        return () => { mounted = false };
        
    }, [url]);

    return (
        <WikipediaSummaryCardView dispatch={props.dispatch} matcher={props.matcher} model={{
            payload: {
                schema: WikipediaSummarySchemaId,
                data: summary
            }
        }}></WikipediaSummaryCardView>
    );
}
