import { Action } from "../controller/Action"
import { ControllerVerb } from "../controller/ControllerVerb"
import { StateContextKey } from "../state/StateContextKey"
import { Payload } from "../payload/Payload"
import { Runtime } from "../../runtime/Runtime"
import { setModel } from "./actions.state"
import { State } from "../state/State"

export function controllerDispatch(action: Action) {
    return function(dispatch: any, getState: any, runtime: Runtime) {
        const controller  = runtime.tryGet<State>(StateContextKey);
        if (controller) {
            controller.dispatch(action).then(model => {
                dispatch(setModel(model))
            });    
        }
    }
}

export function goPush(payload: Payload) {
    return function(dispatch: any, getState: any, runtime: Runtime) {

        const state = runtime.tryGet<State>(StateContextKey);
        if(state) {

            const action = {
                verb: ControllerVerb.ECHO,
                param: payload
            };

            state.dispatch(action).then(model => {
                dispatch(setModel(model))
            });
        }
    }
}

export function goRefresh() {
    return function(dispatch: any, getState: any, runtime: Runtime) {
        const controller = runtime.tryGet<State>(StateContextKey);
        if(controller) {
            controller.dispatch({ verb: ControllerVerb.NEXT }).then(model => {
                dispatch(setModel(model))
            });
        }
    }
}
