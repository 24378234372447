import { Runtime } from "../runtime/Runtime"

// Plugins
import { ActivityManifest } from "./activity/_manifest"
import { ActivityCueStreamerManifest } from "./activity-cue-streamer/_manifest"
import { ActivityKeyManifest } from "./activity-key/_manifest"
import { ActivityKeyAnnotatorManifest } from "./activity-key-annotator/_manifest"
import { ActivityKeyReactInputManifest } from "./activity-key-react-input/_manifest"
import { ActivityRegistryManifest } from "./activity-registry/_manifest"
import { ActivityScheduleTrackerManifest } from "./activity-schedule-tracker/_manifest"
import { ActivityTasksDetailViewManifest } from "./activity-tasks-detail-view/_manifest"
import { ActivityToggleToolbarButtonManifest } from "./activity-toggle-toolbar-button/_manifest"
import { ActivityToggleTrackerManifest } from "./activity-toggle-tracker/_manifest"
import { ActivityTrackerManifest } from "./activity-tracker/_manifest"
import { AddTagsManifest } from "./add-tags/_manifest"
import { AddTagsCardViewManifest } from "./add-tags-card-view/_manifest"
import { AncestorsIndexerManifest } from "./ancestors-indexer/_manifest"
import { AnnotatorManifest } from "./annotator/_manifest"
import { AnnotatorRegistryManifest } from "./annotator-registry/_manifest"
import { ArrayReactInputManifest } from "./array-react-input/_manifest"
import { AttachManifest } from "./attach/_manifest"
import { AttachCardViewManifest } from "./attach-card-view/_manifest"
import { AttachCommanderManifest } from "./attach-commander/_manifest"
import { AttachStreamViewManifest } from "./attach-stream-view/_manifest"
import { AttachmentsDetailViewManifest } from "./attachments-detail-view/_manifest"
import { BillManifest } from "./bill/_manifest"
import { BillAnnotatorManifest } from "./bill-annotator/_manifest"
import { BillCardViewManifest } from "./bill-card-view/_manifest"
import { BillEmblemViewManifest } from "./bill-emblem-view/_manifest"
import { BingWallpaperManifest } from "./bing-wallpaper/_manifest"
import { BingWallpaperAnnotatorManifest } from "./bing-wallpaper-annotator/_manifest"
import { BingWallpaperCardViewManifest } from "./bing-wallpaper-card-view/_manifest"
import { BingWallpaperDetailViewManifest } from "./bing-wallpaper-detail-view/_manifest"
import { BingWallpaperFeedManifest } from "./bing-wallpaper-feed/_manifest"
import { BingWallpaperFeedAnnotatorManifest } from "./bing-wallpaper-feed-annotator/_manifest"
import { BingWallpaperFeedCardViewManifest } from "./bing-wallpaper-feed-card-view/_manifest"
import { BingWallpaperFeedCatalogManifest } from "./bing-wallpaper-feed-catalog/_manifest"
import { BingWallpaperFeedEmblemViewManifest } from "./bing-wallpaper-feed-emblem-view/_manifest"
import { BingWallpaperFeedPollerManifest } from "./bing-wallpaper-feed-poller/_manifest"
import { BingWallpaperIndexerManifest } from "./bing-wallpaper-indexer/_manifest"
import { BingMapIndexerManifest } from "./bing-map-indexer/_manifest"
import { BlockContentManifest } from "./block-content/_manifest"
import { BlockExpiredAnnotatorManifest } from "./block-expired-annotator/_manifest"
import { BooleanManifest } from "./boolean/_manifest"
import { BooleanCardViewManifest } from "./boolean-card-view/_manifest"
import { BooleanFormatterManifest } from "./boolean-formatter/_manifest"
import { BooleanReactInputManifest } from "./boolean-react-input/_manifest"
import { ButtonManifest } from "./button/_manifest"
import { ButtonAnnotatorManifest } from "./button-annotator/_manifest"
import { ButtonCardViewManifest } from "./button-card-view/_manifest"
import { ButtonEmblemViewManifest } from "./button-emblem-view/_manifest"
import { CancelTaskManifest } from "./cancel-task/_manifest"
import { CancelTaskCardViewManifest } from "./cancel-task-card-view/_manifest"
import { CatalogManifest } from "./catalog/_manifest"
import { ChildrenManifest } from "./children/_manifest"
import { ChildrenCardViewManifest } from "./children-card-view/_manifest"
import { ChildrenCommanderManifest } from "./children-commander/_manifest"
import { ChipViewManifest } from "./chip-view/_manifest"
import { CommanderManifest } from "./commander/_manifest"
import { CommanderRegistryManifest } from "./commander-registry/_manifest"
import { CommandsReducerManifest } from "./commands-reducer/_manifest"
import { CompleteTaskManifest } from "./complete-task/_manifest"
import { CompleteTaskCardViewManifest } from "./complete-task-card-view/_manifest"
import { CompletedManifest } from "./completed/_manifest"
import { CompletedCardViewManifest } from "./completed-card-view/_manifest"
import { ConfirmManifest } from "./confirm/_manifest"
import { ConfirmAnnotatorManifest } from "./confirm-annotator/_manifest"
import { ConfirmEmblemViewManifest } from "./confirm-emblem-view/_manifest"
import { ConfirmCardViewManifest } from "./confirm-card-view/_manifest"
import { ControllerManifest } from "./controller/_manifest"
import { ControllerRegistryManifest } from "./controller-registry/_manifest"
import { ConvertManifest } from "./convert/_manifest"
import { ConvertCardViewManifest } from "./convert-card-view/_manifest"
import { ConvertCommanderManifest } from "./convert-commander/_manifest"
import { ConvertStreamViewManifest } from "./convert-stream-view/_manifest"
import { ConverterManifest } from "./converter/_manifest"
import { ConverterRegistryManifest } from "./converter-registry/_manifest"
import { CopyrightManifest } from "./copyright/_manifest"
import { CopyrightDetailViewManifest } from "./copyright-detail-view/_manifest"
import { CopyrightEmblemViewManifest } from "./copyright-emblem-view/_manifest"
import { CreateManifest } from "./create/_manifest"
import { CreateCardViewManifest } from "./create-card-view/_manifest"
import { CreateStreamViewManifest } from "./create-stream-view/_manifest"
import { CreateToolbarButtonManifest } from "./create-toolbar-button/_manifest"
import { CueManifest } from "./cue/_manifest"
import { CueAnnotatorManifest } from "./cue-annotator/_manifest"
import { CueCardViewManifest } from "./cue-card-view/_manifest"
import { DateNumberManifest } from "./date-number/_manifest"
import { DateNumberAnnotatorManifest } from "./date-number-annotator/_manifest"
import { DateNumberEmblemViewManifest } from "./date-number-emblem-view/_manifest"
import { DateNumberFormatterManifest } from "./date-number-formatter/_manifest"
import { DateNumberReactInputManifest } from "./date-number-react-input/_manifest"
import { DeadlineManifest } from "./deadline/_manifest"
import { DeadlineAnnotatorManifest } from "./deadline-annotator/_manifest"
import { DeadlineEmblemViewManifest } from "./deadline-emblem-view/_manifest"
import { DeadlineStreamerManifest } from "./deadline-streamer/_manifest"
import { DefaultActivitiesManifest } from "./default-activities/_manifest"
import { DefaultCardViewManifest } from "./default-card-view/_manifest"
import { DefaultPayloadCardManifest } from "./default-payload-card/_manifest"
import { DefaultPayloadManifest } from "./default-payload/_manifest"
import { DefaultSplashesManifest } from "./default-splashes/_manifest"
import { DefaultStoreManifest } from "./default-store/_manifest"
import { DeferManifest } from "./defer/_manifest"
import { DeferCardViewManifest } from "./defer-card-view/_manifest"
import { DeferCommanderManifest } from "./defer-commander/_manifest"
import { DeferNotifierManifest } from "./defer-notifier/_manifest"
import { DeferPredicatesManifest } from "./defer-predicates/_manifest"
import { DefinitionManifest } from "./definition/_manifest"
import { DefinitionAnnotatorManifest } from "./definition-annotator/_manifest"
import { DefinitionCardViewManifest } from "./definition-card-view/_manifest"
import { DeleteManifest } from "./delete/_manifest"
import { DeleteCardViewManifest } from "./delete-card-view/_manifest"
import { DeleteCommanderManifest } from "./delete-commander/_manifest"
import { EditManifest } from "./edit/_manifest"
import { EditCardViewManifest } from "./edit-card-view/_manifest"
import { EditCommanderManifest } from "./edit-commander/_manifest"
import { EditStreamViewManifest } from "./edit-stream-view/_manifest"
import { EmailAddressManifest } from "./email-address/_manifest"
import { EmailAddressAnnotatorManifest } from "./email-address-annotator/_manifest"
import { EmailAddressChipViewManifest } from "./email-address-chip-view/_manifest"
import { EmailAddressEmblemViewManifest } from "./email-address-emblem-view/_manifest"
import { EmblemViewManifest } from "./emblem-view/_manifest"
import { EnumReactInputManifest } from "./enum-react-input/_manifest"
import { ExtendedCommandsDetailManifest } from "./extended-commands-detail/_manifest"
import { FavoritesCommanderManifest } from "./favorites-commander/_manifest"
import { FlashcardManifest } from "./flashcard/_manifest"
import { FlashcardAnnotatorManifest } from "./flashcard-annotator/_manifest"
import { FlashcardCardViewManifest } from "./flashcard-card-view/_manifest"
import { FlashcardEmblemViewManifest } from "./flashcard-emblem-view/_manifest"
import { FolderManifest } from "./folder/_manifest"
import { FolderAnnotatorManifest } from "./folder-annotator/_manifest"
import { FolderCardViewManifest } from "./folder-card-view/_manifest"
import { FolderEmblemViewManifest } from "./folder-emblem-view/_manifest"
import { FolderPreviewViewManifest } from "./folder-preview-view/_manifest"
import { FormatterManifest } from "./formatter/_manifest"
import { FormatterAnnotatorManifest } from "./formatter-annotator/_manifest"
import { FormatterRegistryManifest } from "./formatter-registry/_manifest"
import { GeojsonManifest } from "./geojson/_manifest"
import { GoalManifest } from "./goal/_manifest"
import { GoalAnnotatorManifest } from "./goal-annotator/_manifest"
import { GoalCardViewManifest } from "./goal-card-view/_manifest"
import { GoalEmblemViewManifest } from "./goal-emblem-view/_manifest"
import { GoalStreamerManifest } from "./goal-streamer/_manifest"
import { GoogleEarthIndexerManifest } from "./google-earth-indexer/_manifest"
import { GoogleMapIndexerManifest } from "./google-map-indexer/_manifest"
import { HackerNewsManifest } from "./hacker-news/_manifest"
import { HighlighterManifest } from "./highlighter/_manifest"
import { HighlighterAnnotatorManifest } from "./highlighter-annotator/_manifest"
import { HighlighterEmblemViewManifest } from "./highlighter-emblem-view/_manifest"
import { ImagesManifest } from "./images/_manifest"
import { IndexesDetailManifest } from "./indexes-detail/_manifest"
import { IndexerManifest } from "./indexer/_manifest"
import { IndexesReducerManifest } from "./indexes-reducer/_manifest"
import { IndexerRegistryManifest } from "./indexer-registry/_manifest"
import { InstagramUsernameIndexerManifest } from "./instagram-username-indexer/_manifest"
import { IntroSequenceManifest } from "./intro-sequence/_manifest"
import { Iso4217CodeManifest } from "./iso-4217-code/_manifest"
import { Iso4217CodeAnnotatorManifest } from "./iso-4217-code-annotator/_manifest"
import { Iso4217CodeArrayManifest } from "./iso-4217-code-array/_manifest"
import { Iso4217CodeEmblemViewManifest } from "./iso-4217-code-emblem-view/_manifest"
import { Jesus } from "./Jesus/_manifest"
import { JsonManifest } from "./json/_manifest"
import { JsonCardViewManifest } from "./json-card-view/_manifest"
import { JsonCommanderManifest } from "./json-commander/_manifest"
import { JuneteenthActivityManifest } from "./juneteenth-activity/_manifest"
import { JuneteenthSplashesManifest } from "./juneteenth-splashes/_manifest"
import { KilometersManifest } from "./kilometers/_manifest"
import { KilometersFormatterManifest } from "./kilometers-formatter/_manifest"
import { LatitudeManifest } from "./latitude/_manifest"
import { LatitudeFormatterManifest } from "./latitude-formatter/_manifest"
import { LinkManifest } from "./link/_manifest"
import { LinkAnnotatorManifest } from "./link-annotator/_manifest"
import { LinkCardViewManifest } from "./link-card-view/_manifest"
import { LinkEmblemViewManifest } from "./link-emblem-view/_manifest"
import { ListManifest } from "./list/_manifest"
import { ListEmblemViewManifest } from "./list-emblem-view/_manifest"
import { ListViewManifest } from "./list-view/_manifest"
import { ListStreamViewManifest } from "./list-stream-view/_manifest"
import { LongitudeManifest } from "./longitude/_manifest"
import { LongitudeFormatterManifest } from "./longitude-formatter/_manifest"
import { LoveManifest } from "./love/_manifest"
import { LoveActivityManifest } from "./love-activity/_manifest"
import { LoveSplashesManifest } from "./love-splashes/_manifest"
import { MathProblemManifest } from "./math-problem/_manifest"
import { MathProblemCardViewManifest } from "./math-problem-card-view/_manifest"
import { MathProblemStreamerManifest } from "./math-problem-streamer/_manifest"
import { MessierNumberManifest } from "./messier-number/_manifest"
import { MessierNumberAnnotatorManifest } from "./messier-number-annotator/_manifest"
import { MessierNumberIndexerManifest } from "./messier-number-indexer/_manifest"
import { MoveManifest } from "./move/_manifest"
import { MoveCardViewManifest } from "./move-card-view/_manifest"
import { MoveCommanderManifest } from "./move-commander/_manifest"
import { MoveStreamViewManifest } from "./move-stream-view/_manifest"
import { NasaApodManifest } from "./nasa-apod/_manifest"
import { NasaApodAnnotatorManifest } from "./nasa-apod-annotator/_manifest"
import { NasaApodCardViewManifest } from "./nasa-apod-card-view/_manifest"
import { NasaApodDetailViewManifest } from "./nasa-apod-detail-view/_manifest"
import { NasaApodFeedManifest } from "./nasa-apod-feed/_manifest"
import { NasaApodFeedAnnotatorManifest } from "./nasa-apod-feed-annotator/_manifest"
import { NasaApodFeedCardViewManifest } from "./nasa-apod-feed-card-view/_manifest"
import { NasaApodFeedPollerManifest } from "./nasa-apod-feed-poller/_manifest"
import { NasaApodIndexerManifest } from "./nasa-apod-indexer/_manifest"
import { NewsApiManifest } from "./newsapi/_manifest"
import { NewsApiArticleCardViewManifest } from "./newsapi-article-card-view/_manifest"
import { NewsApiArticleIndexerManifest } from "./newsapi-article-indexer/_manifest"
import { NewsApiFeedManifest } from "./newsapi-feed/_manifest"
import { NewsApiFeedAnnotatorManifest } from "./newsapi-feed-annotator/_manifest"
import { NewsApiFeedCardViewManifest } from "./newsapi-feed-card-view/_manifest"
import { NewsApiFeedCatalogManifest } from "./newsapi-feed-catalog/_manifest"
import { NewsApiFeedEmblemViewManifest } from "./newsapi-feed-emblem-view/_manifest"
import { NewsApiFeedPollerManifest } from "./newsapi-feed-poller/_manifest"
import { NextManifest } from "./next/_manifest"
import { NextAnnotatorManifest } from "./next-annotator/_manifest"
import { NextButtonManifest } from "./next-button/_manifest"
import { NextCardViewManifest } from "./next-card-view/_manifest"
import { NextEmblemViewManifest } from "./next-emblem-view/_manifest"
import { NgcNumberManifest } from "./ngc-number/_manifest"
import { NgcNumberIndexerManifest } from "./ngc-number-indexer/_manifest"
import { NoteManifest } from "./note/_manifest"
import { NoteAnnotatorManifest } from "./note-annotator/_manifest"
import { NoteEmblemViewManifest } from "./note-emblem-view/_manifest"
import { NoticeManifest } from "./notice/_manifest"
import { NoticeAnnotatorManifest } from "./notice-annotator/_manifest"
import { NoticeAttachmentNotifierManifest } from "./notice-attachment-notifier/_manifest"
import { NoticeCardViewManifest } from "./notice-card-view/_manifest"
import { NoticeEmblemViewManifest } from "./notice-emblem-view/_manifest"
import { NoticesPageSectionManifest } from "./notices-page-section/_manifest"
import { NoticesReducerManifest } from "./notices-reducer/_manifest"
import { NotifierManifest } from "./notifier/_manifest"
import { NotifierRegistryManifest } from "./notifier-registry/_manifest"
import { NumberManifest } from "./number/_manifest"
import { NumberAnnotatorManifest } from "./number-annotator/_manifest"
import { NumberBasesDetailManifest } from "./number-bases-detail/_manifest"
import { NumberConverterManifest } from "./number-converter/_manifest"
import { NumberIndexerManifest } from "./number-indexer/_manifest"
import { NumberReactInputManifest } from "./number-react-input/_manifest"
import { NWSAlertManifest } from "./nws-alert/_manifest"
import { NWSAlertCardViewManifest } from "./nws-alert-card-view/_manifest"
import { NWSAlertDetailViewManifest } from "./nws-alert-detail-view/_manifest"
import { NWSAlertFeedManifest } from "./nws-alert-feed/_manifest"
import { NWSAlertFeedAnnotatorManifest } from "./nws-alert-feed-annotator/_manifest"
import { NWSAlertFeedCardManifest } from "./nws-alert-feed-card/_manifest"
import { NWSAlertFeedCatalogManifest } from "./nws-alert-feed-catalog/_manifest"
import { NWSAlertFeedPollerManifest } from "./nws-alert-feed-poller/_manifest"
import { NWSAlertIndexerManifest } from "./nws-alert-indexer/_manifest"
import { ObjectReactInputManifest } from "./object-react-input/_manifest"
import { OpenStreetMapIndexerManifest } from "./openstreetmap-indexer/_manifest"
import { PageViewManifest } from "./page-view/_manifest"
import { PayloadManifest } from "./payload/_manifest"
import { PaymentManifest } from "./payment/_manifest"
import { PaymentAnnotatorManifest } from "./payment-annotator/_manifest"
import { PersonManifest } from "./person/_manifest"
import { PluginsCardViewManifest } from "./plugins-card-view/_manifest"
import { PluginCardShortcutManifest } from "./plugins-card-shortcut/_manifest"
import { PollerManifest } from "./poller/_manifest"
import { PollerCreatorManifest } from "./poller-creator/_manifest"
import { PollerCreatorRegistryManifest } from "./poller-creator-registry/_manifest"
import { PollerServiceManifest } from "./poller-service/_manifest"
import { PollerStreamerManifest } from "./poller-streamer/_manifest"
import { PredicateManifest } from "./predicate/_manifest"
import { PreviewViewManifest } from "./preview-view/_manifest"
import { PromptManifest } from "./prompt/_manifest"
import { PromptCardViewManifest } from "./prompt-card-view/_manifest"
import { QuoteManifest } from "./quote/_manifest"
import { QuoteAnnotatorManifest } from "./quote-annotator/_manifest"
import { QuoteIndexerManifest } from "./quote-indexer/_manifest"
import { QuoteStreamerManifest } from "./quote-streamer/_manifest"
import { QuoteCardViewManifest } from "./quote-card-view/_manifest"
import { RandomManifest } from "./random/_manifest"
import { RandomRegistryManifest } from "./random-registry/_manifest"
import { RandomSelectorManifest } from "./random-selector/_manifest"
import { ReactInputManifest } from "./react-input/_manifest"
import { ReactViewManifest } from "./react-view/_manifest"
import { RealityCheckManifest } from "./reality-check/_manifest"
import { RealityCheckCardViewManifest } from "./reality-check-card-view/_manifest"
import { RealityCheckStreamerManifest } from "./reality-check-streamer/_manifest"
import { RedditManifest } from "./reddit/_manifest"
import { RedditThingCardViewManifest } from "./reddit-thing-card-view/_manifest"
import { RedditThingChipViewManifest } from "./reddit-thing-chip-view/_manifest"
import { RedditThingIndexerManifest } from "./reddit-thing-indexer/_manifest"
import { ReducerManifest } from "./reducer/_manifest"
import { ReducerRegistryManifest } from "./reducer-registry/_manifest"
import { RegExIndexerManifest } from "./regex-indexer/_manifest"
import { RemoveTagsManifest } from "./remove-tags/_manifest"
import { RemoveTagsCardViewManifest } from "./remove-tags-card-view/_manifest"
import { ReopenTaskManifest } from "./reopen-task/_manifest"
import { ReopenTaskCardViewManifest } from "./reopen-task-card-view/_manifest"
import { RootManifest } from "./root/_manifest"
import { RootCardViewManifest } from "./root-card-view/_manifest"
import { RootEmblemViewManifest } from "./root-emblem-view/_manifest"
import { RootToolbarButtonManifest } from "./root-toolbar-button/_manifest"
import { RssManifest } from "./rss/_manifest"
import { RssFeedManifest } from "./rss-feed/_manifest"
import { RssFeedAnnotatorManifest } from "./rss-feed-annotator/_manifest"
import { RssFeedCardViewManifest } from "./rss-feed-card-view/_manifest"
import { RssFeedCatalogManifest } from "./rss-feed-catalog/_manifest"
import { RssFeedEmblemViewManifest } from "./rss-feed-emblem-view/_manifest"
import { RssFeedIndexerManifest } from "./rss-feed-indexer/_manifest"
import { RssFeedPollerManifest } from "./rss-feed-poller/_manifest"
import { RssItemCardViewManifest } from "./rss-item-card-view/_manifest"
import { RssItemIndexerManifest } from "./rss-item-indexer/_manifest"
import { SaveManifest } from "./save/_manifest"
import { SaveCardViewManifest } from "./save-card-view/_manifest"
import { ScheduleManifest } from "./schedule/_manifest"
import { SchemaManifest } from "./schema/_manifest"
import { SchemaAnnotatorManifest } from "./schema-annotator/_manifest"
import { SchemaEmblemViewManifest } from "./schema-emblem-view/_manifest"
import { SchemaRegistryManifest } from "./schema-registry/_manifest"
import { SearchStringManifest } from "./search-string/_manifest"
import { SearchStringCardViewManifest } from "./search-string-card-view/_manifest"
import { SearchStringEmblemViewManifest } from "./search-string-emblem-view/_manifest"
import { SearchToolbarButtonManifest } from "./search-toolbar-button/_manifest"
import { SelectorManifest } from "./selector/_manifest"
import { SelectorMapManifest } from "./selector-map/_manifest"
import { SequenceManifest } from "./sequence/_manifest"
import { SequenceMapFactoryManifest } from "./sequence-map-factory/_manifest"
import { SequenceMapManifest } from "./sequence-map/_manifest"
import { SequenceStreamerManifest } from "./sequence-streamer/_manifest"
import { ServiceManifest } from "./service/_manifest"
import { ServiceCreatorManifest } from "./service-creator/_manifest"
import { ServiceCreatorRegistryManifest } from "./service-creator-registry/_manifest"
import { ServiceLoaderServiceManifest } from "./service-loader-service/_manifest"
import { ServiceRegistryManifest } from "./service-registry/_manifest"
import { ShortcutRegistryManifest } from "./shortcut-registry/_manifest"
import { ShortcutRegistryCommanderManifest } from "./shortcut-registry-commander/_manifest"
import { ShortcutToolbarButtonManifest } from "./shortcut-toolbar-button/_manifest"
import { SnippetManifest } from "./snippet/_manifest"
import { SnippetAnnotatorManifest } from "./snippet-annotator/_manifest"
import { SnippetCardViewManifest } from "./snippet-card-view/_manifest"
import { SnippetEmblemViewManifest } from "./snippet-emblem-view/_manifest"
import { SnippetIndexerManifest } from "./snippet-indexer/_manifest"
import { SplashManifest } from "./splash/_manifest"
import { SplashAnnotatorManifest } from "./splash-annotator/_manifest"
import { SplashArrayManifest } from "./splash-array/_manifest"
import { SplashCardViewManifest } from "./splash-card-view/_manifest"
import { SplashIndexerManifest } from "./splash-indexer/_manifest"
import { SplashStreamerManifest } from "./splash-streamer/_manifest"
import { StateManifest } from "./state/_manifest"
import { StoreManifest } from "./store/_manifest"
import { StoreStreamerManifest } from "./store-streamer/_manifest"
import { StreamPageSectionManifest } from "./stream-page-section/_manifest"
import { StreamViewManifest } from "./stream-view/_manifest"
import { StreamerManifest } from "./streamer/_manifest"
import { StreamerControllerManifest } from "./streamer-controller/_manifest"
import { StreamerCreatorManifest } from "./streamer-creator/_manifest"
import { StreamerCreatorRegistryManifest } from "./streamer-creator-registry/_manifest"
import { StreamerRegistryManifest } from "./streamer-registry/_manifest"
import { StreamerRegistryStreamerManifest } from "./streamer-registry-streamer/_manifest"
import { StreamerServiceManifest } from "./streamer-service/_manifest"
import { StringManifest } from "./string/_manifest"
import { StringAnnotatorManifest } from "./string-annotator/_manifest"
import { StringCardViewManifest } from "./string-card-view/_manifest"
import { StringChipViewManifest } from "./string-chip-view/_manifest"
import { StringEmblemViewManifest } from "./string-emblem-view/_manifest"
import { StringReactInputManifest } from "./string-react-input/_manifest"
import { SubredditFeedManifest } from "./subreddit-feed/_manifest"
import { SubredditFeedAnnotatorManifest } from "./subreddit-feed-annotator/_manifest"
import { SubredditFeedCardViewManifest } from "./subreddit-feed-card-view/_manifest"
import { SubredditFeedCatalogManifest } from "./subreddit-feed-catalog/_manifest"
import { SubredditFeedEmblemViewManifest } from "./subreddit-feed-emblem-view/_manifest"
import { SubredditFeedIndexerManifest } from "./subreddit-feed-indexer/_manifest"
import { SubredditFeedPollerManifest } from "./subreddit-feed-poller/_manifest"
import { SunriseSunsetManifest } from "./sunrise-sunset/_manifest"
import { TagManifest } from "./tag/_manifest"
import { TagAnnotatorManifest } from "./tag-annotator/_manifest"
import { TagCardViewManifest } from "./tag-card-view/_manifest"
import { TagEmblemViewManifest } from "./tag-emblem-view/_manifest"
import { TagIndexerManifest } from "./tag-indexer/_manifest"
import { TaskManifest } from "./task/_manifest"
import { TaskAnnotatorManifest } from "./task-annotator/_manifest"
import { TaskCardViewManifest } from "./task-card-view/_manifest"
import { TaskCommanderManifest } from "./task-commander/_manifest"
import { TaskEmblemViewManifest } from "./task-emblem-view/_manifest"
import { TaskHistoryDetailViewManifest } from "./task-history-detail-view/_manifest"
import { TaskPredicatesManifest } from "./task-predicates/_manifest"
import { TaskStatusNotifierManifest } from "./task-status-notifier/_manifest"
import { TaskStreamerManifest } from "./task-streamer/_manifest"
import { TaskStreamViewManifest } from "./task-stream-view/_manifest"
import { TaskSuggestionsManifest } from "./task-suggestions/_manifest"
import { TimerManifest } from "./timer/_manifest"
import { TimerAnnotatorManifest } from "./timer-annotator/_manifest"
import { TimerCardViewManifest } from "./timer-card-view/_manifest"
import { TimerEmblemViewManifest } from "./timer-emblem-view/_manifest"
import { TimespanManifest } from "./timespan/_manifest"
import { TimespanFormatterManifest } from "./timespan-formatter/_manifest"
import { ToolbarPageSectionManifest } from "./toolbar-page-section/_manifest"
import { TreeManifest } from "./tree/_manifest"
import { TreeCardViewManifest } from "./tree-card-view/_manifest"
import { UrlManifest } from "./url/_manifest"
import { UrlAnnotatorManifest } from "./url-annotator/_manifest"
import { UrlCardViewManifest } from "./url-card-view/_manifest"
import { UrlConverterManifest } from "./url-converter/_manifest"
import { UrlEmblemViewManifest } from "./url-emblem-view/_manifest"
import { UrlPreviewViewManifest } from "./url-preview-view/_manifest"
import { UrlPropertiesDetailViewManifest } from "./url-properties-detail-view/_manifest"
import { UsgsEarthquakeManifest } from "./usgs-earthquake/_manifest"
import { UsgsEarthquakeCardViewManifest } from "./usgs-earthquake-card-view/_manifest"
import { USGSEarthquakeDetailViewManifest } from "./usgs-earthquake-detail-view/_manifest"
import { UsgsEarthquakeFeedManifest } from "./usgs-earthquake-feed/_manifest"
import { UsgsEarthquakeFeedCardViewManifest } from "./usgs-earthquake-feed-card-view/_manifest"
import { UsgsEarthquakeFeedCatalogManifest } from "./usgs-earthquake-feed-catalog/_manifest"
import { UsgsEarthquakeFeedPollerManifest } from "./usgs-earthquake-feed-poller/_manifest"
import { UsgsEarthquakeIndexerManifest } from "./usgs-earthquake-indexer/_manifest"
import { WaitingManifest } from "./waiting/_manifest"
import { WaitingAnnotatorManifest } from "./waiting-annotator/_manifest"
import { WaitingCardViewManifest } from "./waiting-card-view/_manifest"
import { WhatsNewManifest } from "./whats-new/_manifest"
import { WhatsNewCardViewManifest } from "./whats-new-card-view/_manifest"
import { WhatsNewEmblemViewManifest } from "./whats-new-emblem-view/_manifest"
import { WhatsNewLogManifest } from "./whats-new-log/_manifest"
import { WhatsNewStreamerManifest } from "./whats-new-streamer/_manifest"
import { WikipediaLinkDetailViewManifest } from "./wikipedia-link-detail-view/_manifest"
import { WikipediaSummaryManifest } from "./wikipedia-summary/_manifest"
import { WikipediaSummaryCardViewManifest } from "./wikipedia-summary-card-view/_manifest"

console.info(
    "%c motley.stream ",
    "background-color:green; color:white; padding:5px;border-radius:3px");

export const runtime = new Runtime();

// Register other plugins
runtime.register(
    ActivityManifest,
    ActivityCueStreamerManifest,
    ActivityKeyManifest,
    ActivityKeyAnnotatorManifest,
    ActivityKeyReactInputManifest,
    ActivityRegistryManifest,
    ActivityScheduleTrackerManifest,
    ActivityTasksDetailViewManifest,
    ActivityToggleToolbarButtonManifest,
    ActivityToggleTrackerManifest,
    ActivityTrackerManifest,
    AddTagsManifest,
    AddTagsCardViewManifest,
    AncestorsIndexerManifest,
    AnnotatorManifest,
    AnnotatorRegistryManifest,
    ArrayReactInputManifest,
    AttachManifest,
    AttachCardViewManifest,
    AttachCommanderManifest,
    AttachStreamViewManifest,
    AttachmentsDetailViewManifest,
    BillManifest,
    BillAnnotatorManifest,
    BillCardViewManifest,
    BillEmblemViewManifest,
    BingWallpaperManifest,
    BingWallpaperAnnotatorManifest,
    BingWallpaperCardViewManifest,
    BingWallpaperDetailViewManifest,
    BingWallpaperFeedManifest,
    BingWallpaperFeedAnnotatorManifest,
    BingWallpaperFeedCardViewManifest,
    BingWallpaperFeedCatalogManifest,
    BingWallpaperFeedEmblemViewManifest,
    BingWallpaperFeedPollerManifest,
    BingWallpaperIndexerManifest,
    BingMapIndexerManifest,
    BlockContentManifest,
    BlockExpiredAnnotatorManifest,
    BooleanManifest,
    BooleanCardViewManifest,
    BooleanFormatterManifest,
    BooleanReactInputManifest,
    ButtonManifest,
    ButtonAnnotatorManifest,
    ButtonCardViewManifest,
    ButtonEmblemViewManifest,
    CancelTaskManifest,
    CancelTaskCardViewManifest,
    CatalogManifest,
    ChildrenManifest,
    ChildrenCardViewManifest,
    ChildrenCommanderManifest,
    ChipViewManifest,
    CommanderManifest,
    CommanderRegistryManifest,
    CommandsReducerManifest,
    CompleteTaskManifest,
    CompleteTaskCardViewManifest,
    CompletedManifest,
    CompletedCardViewManifest,
    ConfirmManifest,
    ConfirmAnnotatorManifest,
    ConfirmEmblemViewManifest,
    ConfirmCardViewManifest,
    ControllerManifest,
    ControllerRegistryManifest,
    ConvertManifest,
    ConvertCardViewManifest,
    ConvertCommanderManifest,
    ConvertStreamViewManifest,
    ConverterManifest,
    ConverterRegistryManifest,
    CopyrightManifest,
    CopyrightDetailViewManifest,
    CopyrightEmblemViewManifest,
    CreateManifest,
    CreateCardViewManifest,
    CreateStreamViewManifest,
    CreateToolbarButtonManifest,    
    CueManifest,
    CueAnnotatorManifest,
    CueCardViewManifest,
    DateNumberManifest,
    DateNumberAnnotatorManifest,
    DateNumberEmblemViewManifest,
    DateNumberFormatterManifest,    
    DateNumberReactInputManifest,
    DeadlineManifest,
    DeadlineAnnotatorManifest,
    DeadlineEmblemViewManifest,
    DeadlineStreamerManifest,
    DefaultActivitiesManifest,
    DefaultCardViewManifest,
    DefaultPayloadManifest,
    DefaultPayloadCardManifest,
    DefaultSplashesManifest,
    DefaultStoreManifest,
    DeferManifest,
    DeferCardViewManifest,
    DeferCommanderManifest,
    DeferNotifierManifest,
    DeferPredicatesManifest,
    DefinitionManifest,
    DefinitionAnnotatorManifest,
    DefinitionCardViewManifest,
    DeleteManifest,
    DeleteCardViewManifest,
    DeleteCommanderManifest,
    EditManifest,
    EditCardViewManifest,
    EditCommanderManifest,
    EditStreamViewManifest,
    EmailAddressManifest,
    EmailAddressAnnotatorManifest,
    EmailAddressChipViewManifest,
    EmailAddressEmblemViewManifest,
    EmblemViewManifest,
    EnumReactInputManifest,
    ExtendedCommandsDetailManifest,
    FavoritesCommanderManifest,
    FlashcardManifest,
    FlashcardAnnotatorManifest,
    FlashcardCardViewManifest,
    FlashcardEmblemViewManifest,
    FolderManifest, 
    FolderAnnotatorManifest,
    FolderCardViewManifest,
    FolderEmblemViewManifest,
    FolderPreviewViewManifest,
    FormatterManifest,
    FormatterAnnotatorManifest,
    FormatterRegistryManifest,
    GeojsonManifest,
    GoalManifest,
    GoalAnnotatorManifest,
    GoalCardViewManifest,
    GoalEmblemViewManifest,
    GoalStreamerManifest,
    GoogleEarthIndexerManifest,
    GoogleMapIndexerManifest,
    HackerNewsManifest,
    HighlighterManifest,
    HighlighterAnnotatorManifest,
    HighlighterEmblemViewManifest,
    ImagesManifest,
    IndexesDetailManifest,
    IndexerManifest,
    IndexesReducerManifest,
    IndexerRegistryManifest,
    InstagramUsernameIndexerManifest,
    IntroSequenceManifest,
    Iso4217CodeManifest,
    Iso4217CodeAnnotatorManifest,
    Iso4217CodeArrayManifest,
    Iso4217CodeEmblemViewManifest,
    Jesus,
    JsonManifest,
    JsonCardViewManifest,
    JsonCommanderManifest,
    JuneteenthActivityManifest,
    JuneteenthSplashesManifest,
    KilometersManifest,
    KilometersFormatterManifest,
    LatitudeManifest,
    LatitudeFormatterManifest,
    LinkManifest,
    LinkAnnotatorManifest,
    LinkCardViewManifest,
    LinkEmblemViewManifest,
    ListManifest,
    ListEmblemViewManifest,
    ListViewManifest,
    ListStreamViewManifest,
    LongitudeManifest,
    LongitudeFormatterManifest,
    LoveManifest,
    LoveActivityManifest,
    LoveSplashesManifest,
    MathProblemManifest,
    MathProblemCardViewManifest,
    MathProblemStreamerManifest,
    MessierNumberManifest,
    MessierNumberAnnotatorManifest,
    MessierNumberIndexerManifest,
    MoveManifest,
    MoveCardViewManifest,
    MoveCommanderManifest,
    MoveStreamViewManifest,
    NasaApodManifest,
    NasaApodAnnotatorManifest,
    NasaApodCardViewManifest,
    NasaApodDetailViewManifest,
    NasaApodIndexerManifest,
    NasaApodFeedManifest,
    NasaApodFeedAnnotatorManifest,
    NasaApodFeedCardViewManifest,
    NasaApodFeedPollerManifest,
    NewsApiManifest,
    NewsApiArticleCardViewManifest,
    NewsApiArticleIndexerManifest,
    NewsApiFeedManifest,
    NewsApiFeedAnnotatorManifest,
    NewsApiFeedCardViewManifest,
    NewsApiFeedCatalogManifest,
    NewsApiFeedEmblemViewManifest,
    NewsApiFeedPollerManifest,
    NextManifest,
    NextAnnotatorManifest,
    NextButtonManifest,
    NextCardViewManifest,
    NextEmblemViewManifest,
    NgcNumberManifest,
    NgcNumberIndexerManifest,
    NoteManifest,
    NoteAnnotatorManifest,
    NoteEmblemViewManifest,
    NoticeManifest,
    NoticeAnnotatorManifest,
    NoticeAttachmentNotifierManifest,
    NoticeCardViewManifest,
    NoticeEmblemViewManifest,
    NoticesPageSectionManifest,
    NoticesReducerManifest,
    NotifierManifest,
    NotifierRegistryManifest,
    NumberManifest,
    NumberAnnotatorManifest,
    NumberBasesDetailManifest,
    NumberConverterManifest,
    NumberIndexerManifest,
    NumberReactInputManifest,
    NWSAlertManifest,
    NWSAlertCardViewManifest,
    NWSAlertDetailViewManifest,
    NWSAlertFeedManifest,
    NWSAlertFeedAnnotatorManifest,
    NWSAlertFeedCardManifest,
    NWSAlertFeedCatalogManifest,
    NWSAlertFeedPollerManifest,
    NWSAlertIndexerManifest,
    ObjectReactInputManifest,
    OpenStreetMapIndexerManifest,
    PageViewManifest,
    PayloadManifest,
    PaymentManifest,
    PaymentAnnotatorManifest,
    PersonManifest,
    PluginsCardViewManifest,
    PluginCardShortcutManifest,
    PollerManifest,
    PollerCreatorManifest,
    PollerCreatorRegistryManifest,
    PollerServiceManifest,
    PollerStreamerManifest,
    PredicateManifest,
    PreviewViewManifest,
    PromptManifest,
    PromptCardViewManifest,
    QuoteManifest,
    QuoteAnnotatorManifest,
    QuoteCardViewManifest,
    QuoteIndexerManifest,
    QuoteStreamerManifest,
    RandomManifest,
    RandomRegistryManifest,
    RandomSelectorManifest,
    ReactInputManifest,
    ReactViewManifest,
    RealityCheckManifest,
    RealityCheckCardViewManifest,
    RealityCheckStreamerManifest,
    RedditManifest,
    RedditThingCardViewManifest,
    RedditThingChipViewManifest,
    RedditThingIndexerManifest,
    RegExIndexerManifest,
    ReducerManifest,
    ReducerRegistryManifest,
    RemoveTagsManifest,
    RemoveTagsCardViewManifest,
    ReopenTaskManifest,
    ReopenTaskCardViewManifest,
    RootManifest,
    RootCardViewManifest,
    RootEmblemViewManifest,
    RootToolbarButtonManifest,
    RssManifest,
    RssFeedManifest,
    RssFeedAnnotatorManifest,
    RssFeedCardViewManifest,
    RssFeedCatalogManifest,
    RssFeedEmblemViewManifest,
    RssFeedIndexerManifest,
    RssFeedPollerManifest,
    RssItemCardViewManifest,
    RssItemIndexerManifest,
    SaveManifest,
    SaveCardViewManifest,
    ScheduleManifest,
    SchemaManifest,
    SchemaAnnotatorManifest,
    SchemaEmblemViewManifest,
    SchemaRegistryManifest,
    SearchStringManifest,
    SearchStringCardViewManifest,
    SearchStringEmblemViewManifest,
    SearchToolbarButtonManifest,
    SelectorManifest,
    SelectorMapManifest,
    SequenceManifest,
    SequenceMapFactoryManifest,
    SequenceMapManifest,
    SequenceStreamerManifest,
    ServiceManifest,
    ServiceCreatorManifest,
    ServiceCreatorRegistryManifest,
    ServiceLoaderServiceManifest,
    ServiceRegistryManifest,
    ShortcutRegistryManifest,
    ShortcutRegistryCommanderManifest,
    ShortcutToolbarButtonManifest,
    SnippetManifest,
    SnippetAnnotatorManifest,
    SnippetCardViewManifest,
    SnippetEmblemViewManifest,
    SnippetIndexerManifest,
    SplashManifest,
    SplashAnnotatorManifest,
    SplashArrayManifest,
    SplashCardViewManifest,
    SplashIndexerManifest,
    SplashStreamerManifest,
    StateManifest,
    StoreManifest,
    StoreStreamerManifest,
    StreamViewManifest,
    StreamPageSectionManifest,
    StreamerManifest,
    StreamerControllerManifest,
    StreamerCreatorManifest,
    StreamerCreatorRegistryManifest,
    StreamerRegistryManifest,
    StreamerRegistryStreamerManifest,
    StreamerServiceManifest,
    StringManifest,
    StringAnnotatorManifest,
    StringCardViewManifest,
    StringChipViewManifest,
    StringEmblemViewManifest,
    StringReactInputManifest,
    SubredditFeedManifest,
    SubredditFeedAnnotatorManifest,
    SubredditFeedCardViewManifest,
    SubredditFeedCatalogManifest,
    SubredditFeedEmblemViewManifest,
    SubredditFeedIndexerManifest,
    SubredditFeedPollerManifest,
    SunriseSunsetManifest,
    TagManifest,
    TagAnnotatorManifest,
    TagCardViewManifest,
    TagEmblemViewManifest,
    TagIndexerManifest,
    TaskManifest,
    TaskAnnotatorManifest,
    TaskCardViewManifest,
    TaskCommanderManifest,
    TaskEmblemViewManifest,
    TaskHistoryDetailViewManifest,
    TaskPredicatesManifest,
    TaskStatusNotifierManifest,
    TaskStreamerManifest,
    TaskStreamViewManifest,
    TaskSuggestionsManifest,
    TimespanManifest,
    TimespanFormatterManifest,
    TimerManifest,
    TimerAnnotatorManifest,
    TimerCardViewManifest,
    TimerEmblemViewManifest,
    ToolbarPageSectionManifest,
    TreeManifest,
    TreeCardViewManifest,
    UrlManifest,
    UrlAnnotatorManifest,
    UrlCardViewManifest,
    UrlConverterManifest,
    UrlEmblemViewManifest,
    UrlPreviewViewManifest,
    UrlPropertiesDetailViewManifest,
    UsgsEarthquakeManifest,
    UsgsEarthquakeCardViewManifest,
    USGSEarthquakeDetailViewManifest,
    UsgsEarthquakeFeedManifest,
    UsgsEarthquakeFeedCardViewManifest,
    UsgsEarthquakeFeedCatalogManifest,
    UsgsEarthquakeFeedPollerManifest,
    UsgsEarthquakeIndexerManifest,
    WaitingManifest,
    WaitingAnnotatorManifest,
    WaitingCardViewManifest,
    WhatsNewManifest,
    WhatsNewCardViewManifest,
    WhatsNewEmblemViewManifest,
    WhatsNewLogManifest,
    WhatsNewStreamerManifest,
    WikipediaLinkDetailViewManifest,
    WikipediaSummaryManifest,
    WikipediaSummaryCardViewManifest
    );
 
runtime.installDefaults();

console.info(
    "%c🌞 welcome back, my friend",
    "font-size:12pt;padding-top:12pt;padding-bottom:12pt");
