import { Manifest } from "../../runtime/Manifest";
import { SelectorPluginKey } from "./SelectorPluginKey";

export const SelectorManifest: Manifest = {
    
    /**
     * The key that uniquely identifies the plugin.
     */
    key: SelectorPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Selector"
}