import { clone } from "../../library/clone"
import { Indexer } from "../indexer/Indexer"
import { matchSnippets } from "../snippet/matchSnippets"
import { Payload } from "../payload/Payload"
import { PayloadPredicates } from "../payload/PayloadPredicates"
import { SnippetSchemaId } from "../snippet/SnippetSchemaId"
import { Store } from "../store/Store"

export class SnippetIndexer implements Indexer {

    private store: Store;

    constructor(store: Store) {
        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }
    }
    
    public async index(payload: Payload | undefined): Promise<Payload[]> {

        if (!payload) {
            return [];
        }
        
        // Get all active snippets
        const activeSnippetPayloads =
            (await this.store.schema(SnippetSchemaId)).filter(PayloadPredicates.Active);

        if (!activeSnippetPayloads) {
            return [];
        }

        // Get snippets that match against this payload
        const matchingSnippetPayloads = matchSnippets(payload, activeSnippetPayloads);

        // Return snippet attachments
        const values: Payload[] = [];
        for(const snippetPayload of matchingSnippetPayloads) {
            if (Array.isArray(snippetPayload.attachments)) {
                values.push(...snippetPayload.attachments)
            }
        }

        return clone(values);
    }
}