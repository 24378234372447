import { EarthquakePluginKey } from "./EarthquakePluginKey"
import { GeoJSONPluginKey } from "../geojson/GeoJSONPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const UsgsEarthquakeManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: EarthquakePluginKey,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        GeoJSONPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "USGS Earthquakes"
}