import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { RedditPluginKey } from "../reddit/RedditPluginKey"
import { RedditThingChipView } from "./RedditThingChipView"
import { RedditThingSchemaId } from "../reddit/RedditThingSchemaId"

/**
 * The settings of the plugin.
 */
export const RedditThingChipViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "reddit-thing-chip-view",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        RedditPluginKey,
        ReactViewPluginKey
    ],
    
    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "RedditThingChipView",
            registerWith: ReactViewRegistryContextKey,
            title: "Reddit Thing Chip View",
            value: {
                layout: "chip",
                match: RedditThingSchemaId,
                fc: RedditThingChipView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>        
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reddit Thing Chip View"
}