import { Payload } from "../payload/Payload"
import { RssFeed } from "../rss-feed/RssFeed"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export const catalog: Payload<RssFeed>[] = [ 

    /* Destructing Yourself */
    {
        key: "https://deconstructingyourself.com/all-articles",
        schema: RssFeedSchemaId,
        data: {
            title: "Destructing Yourself - Meditation",
            url: "https://deconstructingyourself.com/feed",
            useCorsProxy: true
        }
},

    /* fs */
    {
        key: "https://fs.blog/category/creativity/",
        schema: RssFeedSchemaId,
        data: {
            title: "fs blog - Creativity",
            url: "https://fs.blog/category/creativity/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/decision-making/feed/",
        schema: RssFeedSchemaId,
        data: {
            title: "fs blog - Decision Making",
            url: "https://fs.blog/category/decision-making/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/happiness/",
        schema: RssFeedSchemaId,
        data: {
            title: "fs blog - Happiness",
            url: "https://fs.blog/category/happiness/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/history/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - History blog.",
            title: "fs blog - History",
            url: "https://fs.blog/category/history/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/human-nature/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Human Nature blog.",
            title: "fs blog - Human Nature",
            url: "https://fs.blog/category/human-nature/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/leadership/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Leadership blog.",
            title: "fs blog - Leadership",
            url: "https://fs.blog/category/leadership/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/mental-models/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Mental-Models blog.",
            title: "fs blog - Mental-Models",
            url: "https://fs.blog/category/mental-models/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/psychology/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Psychology blog.",
            title: "fs blog - Psychology",
            url: "https://fs.blog/category/psychology/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/philosophy/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Philosophy blog.",
            title: "fs blog - Philosophy",
            url: "https://fs.blog/category/philosophy/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/productivity/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Productivity blog.",
            title: "fs blog - Productivity",
            url: "https://fs.blog/category/productivity/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/science/feed/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Science blog.",
            title: "fs blog - Science",
            url: "https://fs.blog/category/science/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/self-improvement/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Self-Improvement blog.",
            title: "fs Blog - Self-Improvement",
            url: "https://fs.blog/category/self-improvement/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/technology/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Technology blog.",
            title: "fs blog - Technology",
            url: "https://fs.blog/category/technology/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/thinking/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Thinking blog.",
            title: "fs blog - Thinking",
            url: "https://fs.blog/category/thinking/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://fs.blog/category/thought-and-opinion/",
        schema: RssFeedSchemaId,
        data: {
            description: "An fs - Thought & Opinion blog.",
            title: "fs blog - Thought & Opinion",
            url: "https://fs.blog/category/thought-and-opinion/feed/",
            useCorsProxy: true
        }
    },

    /* Lamajo Wellness */
    {
        key: "https://lamajowellness.com/category/blog/",
        schema: RssFeedSchemaId,
        data: {
            description: "Lamajo Wellness - Mindfulness Meditation and Healthy Living",
            title: "Lamajo Wellness - Mindfulness Meditation and Healthy Living",
            url: "https://lamajowellness.com/feed/",
            useCorsProxy: true
        }
    },

    /* Mindful Balance */
    {
        key: "https://mindfulbalance.org/",
        schema: RssFeedSchemaId,
        data: {
            description: "Mindful Balance Blog",
            title: "Mindful Balance",
            url: "https://mindfulbalance.org/feed/",
            useCorsProxy: true
        }
    },

    /* Mindfulness at Work */
    {
        key: "https://mindfulnessatwork.ie/workplace-wellness-blog/",
        schema: RssFeedSchemaId,
        data: {
            description: "Mindfulness at Work Blog",
            title: "Mindfulness at Work",
            url: "https://mindfulnessatwork.ie/feed/",
            useCorsProxy: true
        }
    },

    /* nautil.us */
    {
        key: "http://nautil.us",
        schema: RssFeedSchemaId,
        data: {
            description: "Nautilus is a different kind of science magazine. We deliver big-picture science by reporting on a single monthly topic from multiple perspectives. Read a new chapter in the story every Thursday.",
            title: "Nautilus (blog)",
            url: "http://nautil.us/rss/all",
            useCorsProxy: false
        }
    },

    /* Pick the Brain */
    {
        key: "https://www.pickthebrain.com/blog/category/health-and-fitness/",
        schema: RssFeedSchemaId,
        data: {
            description: "Blog - Pick the Brain - Health",
            title: "Pick the Brain - Health",
            url: "https://www.pickthebrain.com/blog/category/health-and-fitness/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://www.pickthebrain.com/blog/category/motivation/",
        schema: RssFeedSchemaId,
        data: {
            description: "Blog - Pick the Brain - Motivation",
            title: "Pick the Brain - Motivation",
            url: "https://www.pickthebrain.com/blog/category/motivation/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://www.pickthebrain.com/blog/category/productivity/",
        schema: RssFeedSchemaId,
        data: {
            description: "Blog - Pick the Brain - Productivity",
            title: "Pick the Brain - Productivity",
            url: "https://www.pickthebrain.com/blog/category/productivity/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://www.pickthebrain.com/blog/category/self-improvement/",
        schema: RssFeedSchemaId,
        data: {
            description: "Blog - Pick the Brain - Self-Improvement",
            title: "Pick the Brain - Self-Improvement",
            url: "https://www.pickthebrain.com/blog/category/self-improvement/feed/",
            useCorsProxy: true
        }
    },

    /* Pluralistic */
    /* not rendered yet 
    {
        key: "Pluralistic",
        description: "Pluralistic: Daily links from Cory Doctorow",
        pollingInterval: DEFAULT_INTERVAL,
        type: RsPollerType,
        title: "Pluralistic",
        url: "https://pluralistic.net/feed/",
        useCorsProxy: true
    },
    */

    /* Pocket Mindfulness */
    {
        key: "https://www.pocketmindfulness.com/category/mindfulness/",
        schema: RssFeedSchemaId,
        data: {
            description: "Pocket Mindfulness Channel - Mindfulness.",
            title: "Pocket Mindfulness - Mindfulness",
            url: "https://www.pocketmindfulness.com/category/mindfulness/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://www.pocketmindfulness.com/category/mindfulness-exercises/",
        schema: RssFeedSchemaId,
        data: {
            description: "Pocket Mindfulness Channel - Mindfulness Exercises.",
            title: "Pocket Mindfulness - Mindfulness Exercises",
            url: "https://www.pocketmindfulness.com/category/mindfulness-exercises/feed/",
            useCorsProxy: true
        }
    },

    /* psyche.co */
    {
        key: "https://psyche.co",
        schema: RssFeedSchemaId,
        data: {
            description: "Psyche is a new digital magazine from Aeon that illuminates the human condition through three prisms: mental health; the perennial question of ‘how to live’; and the artistic and transcendent facets of life.",
            title: "Psyche",
            url: "https://psyche.co/feed",
            useCorsProxy: true
        }
    },

    /* Vlog */
    {
        key: "https://www.nowplay.uk/articles?category=NowPlay%20Everyday",
        schema: RssFeedSchemaId,
        data: {
            description: "Vlog - Now Play Everyday Wellbeing",
            title: "Vlog - Now Play Everyday Wellbeing",
            url: "https://www.nowplay.uk/articles?format=rss",
            useCorsProxy: true
        }
    },    

    /* Wikimedia */
    {
        key: "https://commons.wikimedia.org/wiki/Commons:Picture_of_the_day",
        schema: RssFeedSchemaId,
        data: {
            description: "Wikimedia - Picture of the Day",
            title: "Wikimedia - Picture of the Day",
            url: "https://commons.wikimedia.org/w/api.php?action=featuredfeed&feed=potd&feedformat=rss&language=en",
            useCorsProxy: true
        }
    },

    /* Zen Habits */
    {
        key: "https://zenhabits.net/",
        schema: RssFeedSchemaId,
        data: {
            description: "Zen Habits -  Blog about finding simplicity and mindfulness day-to-day",
            title: "Zen Habits",
            url: "https://zenhabits.net/feed/",
            useCorsProxy: true
        }
    },    
];
