import { Payload } from "../payload/Payload"
import { Selector } from "../selector/Selector"
import { SelectorState } from "../selector/SelectorState"
import { Sequence } from "./Sequence"

/**
 * An instance of a sequence.
 */
export class SequenceInstance {

    private sequence: Sequence;
    private selector: Selector;
    private state: SelectorState;

    constructor(sequence: Sequence, selector: Selector, state?: SelectorState) {

        if (!(this.sequence = sequence)) {
            throw new Error("sequence must be specified");
        }

        if (!(this.selector = selector)) {
            throw new Error("selector must be specified");
        }

        if (!(this.state = state ?? {})) {
            throw new Error("state must be specified");
        }
    }
    
    public next(): Promise<Payload | undefined> {
        return this.selector.select(this.sequence.items, this.state);
    }
}