import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistry } from "./IndexerRegistry"
import { IndexerRegistryContextKey } from "./IndexerRegistryContextKey"
import { IndexerRegistryPluginKey } from "./IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
 
export const IndexerRegistryManifest: Manifest = {

    /**
     * A string that uniquely identifies the plugin.
     */
    key: IndexerRegistryPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: IndexerRegistryContextKey,
            value: new IndexerRegistry()
        } as ManifestEntry<IndexerRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Indexer Registry"
}