import { Action } from "./Action"
import { Controller } from "./Controller"
import { ControllerVerb } from "./ControllerVerb"
import { Payload } from "../payload/Payload"

export class EchoController extends Controller {

    private controller: Controller;

    constructor(controller: Controller) {
        super();
        if (!(this.controller = controller)) {
            throw new Error("controller must be specified");
        }
    }

    public async dispatch<TParam>(action: Action<TParam>): Promise<Payload | undefined> {

        const payload = (action.param as any) as Payload | undefined;

        switch(action.verb) {
            case ControllerVerb.ECHO:
                return payload;

            default:
                return this.controller.dispatch(action);
        }

    }  
}