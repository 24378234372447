import { NgcNumberSchemaId } from "../ngc-number/NgcNumberSchemaId"
import { Payload } from "../payload/Payload";
import { RegExIndexer } from "../regex-indexer/RegExIndexer"

const MIN_NGC = 1;
const MAX_NGC = 7840;
const REGEX = /[Nn][Gg][Cc]\s?-?\s?(\d{1,4})/g;

export class NgcNumberIndexer extends RegExIndexer {

    constructor() {
        super(REGEX);
    }

    protected onmatch(value: RegExpMatchArray): Payload | undefined {

        const id = parseInt(value[1]);
        if (id < MIN_NGC || id > MAX_NGC) {
            return undefined;
        }
        
        return {
            schema: NgcNumberSchemaId,
            data: id,
            title: `NGC ${id}`
        }
    }
}