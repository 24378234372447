import { BingWallpaperFeed } from "../bing-wallpaper-feed/BingWallpaperFeed"
import { BingWallpaperFeedPoller } from "./BingWallpaperFeedPoller"
import { BingWallpaperFeedSchemaId } from "../bing-wallpaper-feed/BingWallpaperFeedSchemaId"
import { Poller } from "../poller/Poller"
import { PollerCreator } from "../poller-creator/PollerCreator"
import { Payload } from "../payload/Payload"

export class BingWallpaperFeedPollerCreator implements PollerCreator<BingWallpaperFeed> {

    public async create(payload: Payload<BingWallpaperFeed>): Promise<Poller | undefined> {

        if (payload?.schema !== BingWallpaperFeedSchemaId) {
            return undefined;
        }

        const feed = payload.data;
        if (!feed) {
            return undefined;
        }

        return new BingWallpaperFeedPoller(payload.data);
    }
}