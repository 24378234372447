import { MessierNumberSchemaId } from "../messier-number/MessierNumberSchemaId"
import { Payload } from "../payload/Payload";
import { RegExIndexer } from "../regex-indexer/RegExIndexer"

const REGEX = /[Mm](?:essier)?\s?-?\s?(\d{1,3})/g;

export class MessierNumberIndexer extends RegExIndexer {

    constructor() {
        super(REGEX);
    }

    protected onmatch(value: RegExpMatchArray): Payload | undefined {

        const id = parseInt(value[1]);
        if (id < 1 || id > 110) {
            return undefined;
        }

        return {
            schema: MessierNumberSchemaId,
            data: id,
            title: `Messier ${id}`
        }
    }
}