import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PollerCreatorPluginKey } from "../poller-creator/PollerCreatorPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"
import { PollerCreatorRegistry } from "./PollerCreatorRegistry"
import { PollerCreatorRegistryContextKey } from "./PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryPluginKey } from "./PollerCreatorRegistryPluginKey"

export const PollerCreatorRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: PollerCreatorRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PollerPluginKey,
        PollerCreatorPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: PollerCreatorRegistryContextKey,
            value: new PollerCreatorRegistry()
        } as ManifestEntry<PollerCreatorRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Poller Creator Registry"
}