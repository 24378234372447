import { CopyrightPluginKey } from "../copyright/CopyrightPluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { MessierNumberIndexer } from "../messier-number-indexer/MessierNumberIndexer"
import { MessierNumberIndexerContextKey } from "../messier-number-indexer/MessierNumberIndexerContextKey"
import { MessierNumberIndexerPluginKey } from "../messier-number-indexer/MessierNumberIndexerPluginKey"
import { NasaApodPluginKey } from "../nasa-apod/NasaApodPluginKey"
import { NasaApodIndexer } from "./NasaApodIndexer"
import { NasaApodSchemaId } from "../nasa-apod/NasaApodSchemaId"
import { NgcNumberIndexer } from "../ngc-number-indexer/NgcNumberIndexer"
import { NgcNumberIndexerContextKey } from "../ngc-number-indexer/NgcNumberIndexerContextKey"
import { NgcNumberIndexerPluginKey } from "../ngc-number-indexer/NgcNumberIndexerPluginKey"

export const NasaApodIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nasa-apod-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CopyrightPluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        MessierNumberIndexerPluginKey,
        NasaApodPluginKey,
        NgcNumberIndexerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "NasaApodIndexer",
            registerWith: IndexerRegistryContextKey,
            value: {
                schema: NasaApodSchemaId,
                indexer: new NasaApodIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<NasaApodIndexer>>,
        {
            key: "NasaApodMessierNumberIndexer",
            registerWith: IndexerRegistryContextKey,
            requires: [MessierNumberIndexerContextKey],
            value: (context: ManifestContext) => ({
                schema: NasaApodSchemaId,
                indexer: context[MessierNumberIndexerContextKey]
            })
        } as ManifestEntry<IndexerRegistryEntry<MessierNumberIndexer>>,
        {
            key: "NasaApodNgcNumberIndexer",
            registerWith: IndexerRegistryContextKey,
            requires: [NgcNumberIndexerContextKey],
            value: (context: ManifestContext) => ({
                schema: NasaApodSchemaId,
                indexer: context[NgcNumberIndexerContextKey]
            })
        } as ManifestEntry<IndexerRegistryEntry<NgcNumberIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "NASA APOD Indexer"
}
