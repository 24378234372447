import { GeoJSONPluginKey } from "../geojson/GeoJSONPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NWSAlertFeedSchemaId } from "../nws-alert-feed/NWSAlertFeedSchemaId"
import { NWSAlertFeedPluginKey } from "../nws-alert-feed/NWSAlertFeedPluginKey"
import { NWSAlertFeedPollerCreator } from "./NWSAlertFeedPollerCreator"
import { PollerCreatorPluginKey } from "../poller-creator/PollerCreatorPluginKey"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"
import { PollerCreatorRegistryEntry } from "../poller-creator-registry/PollerCreatorRegistryEntry"
import { PollerPluginKey } from "../poller/PollerPluginKey"
import { NWSAlertPluginKey } from "../nws-alert/NWSAlertPluginKey"

export const NWSAlertFeedPollerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nws-alert-feed-poller",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        GeoJSONPluginKey,
        NWSAlertFeedPluginKey,
        PollerPluginKey,
        PollerCreatorPluginKey,
        PollerCreatorRegistryPluginKey,
        NWSAlertPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NWSAlertFeedPollerCreator",
            registerWith: PollerCreatorRegistryContextKey,
            title: "NWS Alert Feed Poller Creator",
            value: {
                schema: NWSAlertFeedSchemaId,
                creator: new NWSAlertFeedPollerCreator()
            }
        } as ManifestEntry<PollerCreatorRegistryEntry<NWSAlertFeedPollerCreator>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NWS Alert Feed Poller",
};
