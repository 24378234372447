import { catalog } from "./catalog"
import { catalogOfDesign } from "./catalog-design"
import { CatalogPluginKey } from "../catalog/CatalogPluginKey"
import { CatalogRegistryContextKey } from "../catalog/CatalogRegistryContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"
import { RssFeed } from "../rss-feed/RssFeed"
import { RssFeedPollerPluginKey } from "../rss-feed-poller/RssFeedPollerPluginKey"

export const RssFeedCatalogManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "rss-feed-catalog",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CatalogPluginKey,
        RssFeedPollerPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "RssFeedCatalog",
            registerWith: CatalogRegistryContextKey,
            title: "RSS Feed Catalog",
            value: [
                ...catalog,
                ...catalogOfDesign
            ]
        } as ManifestEntry<Payload<RssFeed>[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Feed Catalog"
};
