import { Payload } from "../payload/Payload"
import { RssFeed } from "../rss-feed/RssFeed"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export const catalogOfDesign: Payload<RssFeed>[] = [
    {
        key: "https://alistapart.com/main/feed/",
        schema: RssFeedSchemaId,
        data: {
            title: "A List Apart",                
            url: "https://alistapart.com/main/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://boxesandarrows.com/feed/",
        schema: RssFeedSchemaId,
        data: {
            title: "Boxes and Arrows",
            url: "https://boxesandarrows.com/feed/",
            useCorsProxy: true
        }
    },
    {
        key: "https://uxmovement.com/feed/",
        schema: RssFeedSchemaId,
        data: {
            title: "UX Movement",
            url: "https://uxmovement.com/feed/",
            useCorsProxy: true
        }
    }
];
