import { Task } from "./Task"
import { TaskStatus } from "./TaskStatus"

export function markCanceled(task: Task): boolean {

    if (!task) {
        return false;
    }

    task.status = TaskStatus.Canceled;

    // Add to the task history
    task.history = task.history ?? [];
    task.history.push({
        changedOn: Date.now(),
        status: TaskStatus.Canceled
    });
 
    return true;
}