import React from "react"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel
} from "@ionic/react"
import { Model } from "../state/Model"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Snippet } from "../snippet/Snippet"

export const SnippetCardView: React.FC<ReactViewProps> = (props) => {

    if (!props.matcher) {
        return (
            <ReactViewFailure>matcher prop not specified</ReactViewFailure>
        )
    }
    
    const payload = props?.model?.payload;
    if (!payload) {
        return (
            <ReactViewFailure>Payload not specified</ReactViewFailure>
        )
    }

    const snippet = payload.data as Snippet;
    if (!snippet) {
        return (
            <ReactViewFailure>Payload does not have a snippet object</ReactViewFailure>
        );
    }

    // Normalize tags into a CSV string
    const tags = (snippet.tags ?? []).join(", ");

    // Normalize the attachment array for easier rendering in JSX
    const models: Model[] = (payload.attachments ?? []).map(p => ({
        payload: p
    }));

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>snippet</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem>
                    <IonLabel>For content with tags:</IonLabel>
                    <IonLabel slot="end">{tags}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Show chips:</IonLabel>
                    <IonLabel slot="end">
                        {models.map(model =>
                            <ReactViewFactory dispatch={props.dispatch} layout="chip" matcher={props.matcher} model={model} />
                        )}
                    </IonLabel>
                </IonItem>
            </IonCardContent>
        </IonCard>
    )
}