import React from "react"
import { isWikipediaUrl } from "../wikipedia-summary/isWikipediaUrl"
import { MessierNumberSchemaId } from "../messier-number/MessierNumberSchemaId"
import { Model } from "../state/Model"
import { NgcNumberSchemaId } from "../ngc-number/NgcNumberSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { UrlSchemaId } from "../url/UrlSchemaId"
import { WikipediaSummaryCardViewLoader } from "../wikipedia-summary-card-view/WikipediaSummaryCardViewLoader"

function getWikipediaLinks(model: Model | undefined): string[] {

    /** Returns the Wikipedia URL of the specified payload, or undefined */
    function getUrl(payload: Payload): string | undefined {
        switch(payload.schema) {
            case UrlSchemaId:
                if (isWikipediaUrl(payload.data)) {
                    return payload.data;
                }
                else {
                    return undefined;
                }

            case MessierNumberSchemaId:
                const nmb = parseInt(payload.data);
                if ((nmb >=1) && (nmb <= 110)) {
                    return `https://en.wikipedia.org/wiki/Messier_${nmb}`;
                }
                else {
                    return undefined;
                }

            case NgcNumberSchemaId:
                const ngc = parseInt(payload.data);
                if ((ngc >= 1) && (ngc <= 7840)) {
                    return `https://en.wikipedia.org/wiki/NGC_${ngc}`;
                }
                else {
                    return undefined;
                }

            default:
                return undefined; 
        }
    }

    const urls: string[] = [];

    // Look for wikipedia links in the index values.
    const indexes = model?.index as Payload[];
    if (Array.isArray(indexes)) {
        for(let payload of indexes) {
            const url = getUrl(payload);
            if (url) {
                urls.push(url);
            }
        }
    }

    // Look for wikipedia link attachments.
    const attachments = model?.payload?.attachments;
    if (Array.isArray(attachments)) {
        for(let payload of attachments) {
            const url  = getUrl(payload);
            if (url) {
                urls.push(url);
            }
        }
    }

    return urls;
}

export const WikipediaLinkDetailView: React.FC<ReactViewProps> = (props) => {

    const urls = getWikipediaLinks(props.model);

    return (
        <>
            {urls && urls.map(url =>
                <WikipediaSummaryCardViewLoader
                    dispatch={props.dispatch}
                    key={url}
                    matcher={props.matcher}
                    url={url} />
            )}
        </>
    );
}