import React from "react"
import { Command } from "../commander/Command"
import { CommandsReducerKey } from "../commands-reducer/CommandsReducerKey"
import { ExtendedCommandButton } from "./ExtendedCommandButton"
import { IonCard, IonCardContent } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const ExtendedCommandsDetail: React.FC<ReactViewProps> = (props) => {

    const model = props?.model;
    if (!model) {
        return <></>
    }

    const commands: Command[] = model[CommandsReducerKey];
    if (!commands || !Array.isArray(commands)) {
        return <></>
    }
    
    const extended = commands.filter(c => c.layout === "extended");

    return (
        <>
        {commands.length > 0 &&
            <IonCard>
                <IonCardContent>
                    {extended.map(command =>
                        <ExtendedCommandButton dispatch={props.dispatch} key={command.key} command={command}></ExtendedCommandButton>)}
                </IonCardContent>
            </IonCard>
        }
        </>
    )
}