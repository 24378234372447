import { BillSchemaId } from "./BillSchemaId"
import { ISO4217CodeSchemaId } from "../iso-4217-code/ISO4217CodeSchemaId"
import { ScheduleSchemaId } from "../schedule/ScheduleSchemaId"
import { Schema } from "../schema/Schema"

export const BillSchema: Schema = {
    "$id": BillSchemaId,
    type: "object",
    title: "Bill",
    properties: {
        "account": {
            type: "string",
            title: "Account"
        },
        "amount": {
            type: "number",
            title: "Amount"
        },
        "currency": {
            "$ref": ISO4217CodeSchemaId,
            title: "Currency"
        },
        "schedule": {
            "$ref": ScheduleSchemaId,
            title: "Schedule"
        },
        "title": {
            type: "string",
            title: "Title"
        },
    }
}