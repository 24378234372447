import { parseDays } from "./parseDays"
import { parseMonths } from "./parseMonths"
import { Schedule } from "./Schedule"
import { sinceMidnight } from "./sinceMidnight"

/**
 * Determine whether a schedule (or at least one schedule in an array of schedules) is
 * active on a given date and time. If no date/time is specified, then the current
 * date/time is assumed.
 * 
 */
export function triggers(schedule: Schedule | Schedule[] | undefined, when?: number): boolean {

    if (!schedule) {
        return false;
    }

    if (Array.isArray(schedule)) {
        return triggersSlots(schedule, when);
    }

    return triggersObject(schedule, when)
}

function triggersBeginTime(beginTime: number | undefined, when?: number): boolean {
    if (beginTime === undefined) {
        return false;
    }
    else {
        return sinceMidnight(when) >= beginTime;
    }
}

function triggersDaysOfMonth(csv: string, when?: number): boolean {

    if (!csv) {
        return false;
    }

    // Get a date object for the specified moment
    const date = when ? new Date(when) : new Date();

    // Get the 1-based day of the month
    const dom = date.getDate();

    // Split the comma-separated values
    const days = csv.split(",");

    for (let day of days) {
        if (parseInt(day) === dom) {
            return true;
        }
    }

    return false;
}

function triggersDaysOfWeek(expr: string, when?: number): boolean {

    // This triggers repeats on cerain days of the week. The string params
    // is an expression such as "daily" or "mon". Parse the expression
    // to get an array of [0..6] with true/false for each day of the week.
    const days = parseDays(expr);

    // Get the current 0-based day of week (0=Sunday, 1=Monday, etc.).
    const day = (when ? new Date(when) : new Date()).getDay();

    // See if the schedule is assignable on today's day of the week.
    return days[day];
} 

function triggersEnabled(value: boolean | ((when?: number) => boolean), when?: number) {

    if (value === undefined) {
        return false;
    }

    switch(typeof(value)) {
        case "boolean":
            return value;

        case "function":
            return value(when);

        default:
            return value === true;
    }
}

function triggersEndTime(endTime: number | undefined, when?: number): boolean {
    if (endTime === undefined) {
        return false;
    }
    else {
        return sinceMidnight(when) < endTime;
    }
}

function triggersKey(key: string, value: any, when?: number) {
    switch(key) {
        case "daysOfWeek":
            return triggersDaysOfWeek(value, when);

        case "daysOfMonth":
            return triggersDaysOfMonth(value, when);

        case "beginTime":
            return triggersBeginTime(value, when);

        case "enabled":
            return triggersEnabled(value);

        case "endTime":
            return triggersEndTime(value, when);
            
        case "months":
            return triggersMonths(value, when);

        case "slots":
            return triggersSlots(value, when);
    
        default:
            return false;
    }
}

function triggersMonths(value: number | number[] | string | string[], when?: number): boolean {

    // Get the current 0-based month (0=January, 1=February, etc.)
    const month = (when ? new Date(when) : new Date()).getMonth();

    // Parse the input value into an array of 0-based month values
    const months = parseMonths(value);
    if (months.length === 0) {
        return true;
    }
    
    // See if the month is included in the parsed results
    return months.includes(month);
}

function triggersObject(obj: { [key:string]: any }, when?: number): boolean {

    for(let key in obj) {
        const result = triggersKey(key, obj[key], when);
        if (!result) {
            return false;
        }
    }

    return true;
}

function triggersSlots(values: Schedule[], when?: number): boolean {

    for (let value of values) {
        const result = triggers(value, when);
        if (!result) {
            return false;
        }
    }

    return true;
}