import { Manifest } from "../../runtime/Manifest"
import { RedditPluginKey } from "./RedditPluginKey"

export const RedditManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: RedditPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Reddit"
}