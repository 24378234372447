import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistry } from "./StreamerRegistry"
import { StreamerRegistryContextKey } from "./StreamerRegistryContextKey"
import { StreamerRegistryPluginKey } from "./StreamerRegistryPluginKey"

export const StreamerRegistryManifest: Manifest = {
 
    /**
     * The key that uniquely identifies the type of plugin.
     */
    key: StreamerRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed first.
     */
    dependsOn: [
        StreamerPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: StreamerRegistryContextKey,
            value: new StreamerRegistry()
        } as ManifestEntry<StreamerRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Streamer Registry"
}