import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Payment } from "../payment/Payment"
import { PaymentSchemaId } from "../payment/PaymentSchemaId"

export class PaymentAnnotator implements Annotator {

    public async annotate(payload: Payload): Promise<void> {

        if (payload?.schema !== PaymentSchemaId) {
            return;
        }

        const payment = payload.data as Payment;
        if (!payment) {
            return;
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: payment.currency ?? 'USD',
        });          

        payload.title = `${formatter.format(payment.amount)}`;
        if (payment.paidOn) {
            payload.title += ` on ${new Date(payment.paidOn).toLocaleDateString()}`
        }
    }
}