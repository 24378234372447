import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Defer } from "../defer/Defer"
import { DeferSchemaId } from "../defer/DeferSchemaId"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"

export class DeferCommander implements Commander {

    public async issue(model: Model | undefined): Promise<Command[]> {

        if (!model) {
            return [];
        }

        const payload = model.payload;
        if (!payload) {
            return [];
        }

        if (!payload.id) {
            return [];
        }

        const commands: Command[] = [];

        commands.push(
            {
                key: "defer",
                layout: "extended",
                title: "Defer",
                value: {
                    schema: DeferSchemaId,
                    data: {
                        payload
                    }
                } as Payload<Defer>
            }
        );

        return commands;
    }
}