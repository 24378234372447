import { ActivityKeyPluginKey } from "./ActivityKeyPluginKey"
import { ActivityKeySchema } from "./ActivityKeySchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"
import { Schema } from "../schema/Schema"

export const ActivityKeyManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ActivityKeyPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ActivityKeySchema",
            value: ActivityKeySchema,
            registerWith: SchemaRegistryContextKey
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Key"
}