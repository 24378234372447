import React, { useEffect, useState } from "react"
import { Annotator } from "../annotator/Annotator"
import { Children } from "../children/Children"
import { ChildrenSchemaId } from "../children/ChildrenSchemaId"
import { Create } from "../create/Create"
import { CreateSchemaId } from "../create/CreateSchemaId"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { List } from "../list/List"
import { ListSchemaId } from "../list/ListSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Store } from "../store/Store"
import { Waiting } from "../waiting/Waiting"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"
import "./ChildrenCardView.css"

export interface ChildrenCardViewProps extends ReactViewProps {

    /**
     * The annotator to apply against objects loaded from the store.
     */
    annotator: Annotator;

    /**
     * The store containing the child objects to load.
     */
    store: Store
}

export const ChildrenCardView: React.FC<ChildrenCardViewProps> = (props) => {

    let parent: Payload | undefined = props?.model?.payload;
    if (parent?.schema === ChildrenSchemaId) {
        parent = (parent.data as Children).payload;
    }

    const annotator = props.annotator;
    const store = props.store;
    const [payloads, setPayloads] = useState<Payload[]>();

    useEffect(() => {

        let mounted = true;

        if (store) {
            store?.children(parent?.id).then(children => {
                if (mounted && annotator) {
                    return Promise.all(children.map(p => annotator.annotate(p))).then(() => children);
                }
                else {
                    return children;
                }
            }).then(children => {
                if (mounted && Array.isArray(children)) {
                    setPayloads(children);
                }                    
            });
        }

        return () => { mounted = false };

    }, [annotator, parent, store]);

    function onClickNew() {

        if (props.dispatch) {    
            const createPayload: Payload<Create> = {
                schema: CreateSchemaId,
                data: {
                    payload: {
                        schema: undefined,
                        data: undefined,
                        parentId: parent?.id
                    }
                } as Create
            }
            
            props.dispatch(createPayload);
        }
    }
        
    function SelectView() {

        if (Array.isArray(payloads)) {
            return (
                <ReactViewFactory
                    layout="card"
                    dispatch={props.dispatch}
                    matcher={props.matcher}
                    model={{
                        payload: {
                            schema: ListSchemaId,
                            key: parent?.id ?? parent?.key,
                            data: {
                                items: payloads
                            }
                        } as Payload<List>
                    }}>
                </ReactViewFactory>                
            );
        }
        else {
            return (
                <ReactViewFactory
                    layout="card"
                    dispatch={props.dispatch}
                    matcher={props.matcher}
                    model={{
                        payload: {
                            schema: WaitingSchemaId,
                            data: {
                                title: "Loading..."
                            }
                        } as Payload<Waiting>
                    }}>

                </ReactViewFactory>
            )
        }
    }

    return (
        <IonCard>
            <IonCardHeader>
                <div className="children-card-view-header">
                    <div className="children-card-view-emblem">
                        <ReactViewFactory
                            dispatch={props.dispatch}
                            matcher={props.matcher}
                            layout="emblem"
                            model={{ payload: parent }}>
                        </ReactViewFactory>
                    </div>
                    <IonCardTitle className="children-card-view-title">{parent?.title}</IonCardTitle>
                    <div className="children-card-view-create">
                        <IonButton fill="outline" onClick={() => onClickNew()}>Create</IonButton>
                    </div>
                </div>
            </IonCardHeader>
            <IonCardContent>
                <SelectView />
            </IonCardContent>
        </IonCard>
    );
}