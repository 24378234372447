import { Registry } from "./Registry"

export class KeyMapRegistry<T extends { [key:string]: any }> implements Registry<T> {

    private key: string;
    private map = new Map<string, T>();

    constructor(key: string & keyof T) {
        this.key = key;
    }

    all(): T[] {
        return [...this.map.values()];
    }

    get(key: string): T | undefined {
        return this.map.get(key);
    }

    register(value: T): void {
        const keyvalue = value[this.key];
        if (keyvalue === undefined) {
            throw new Error(`value missing ${this.key}`)
        }
        this.map.set(keyvalue, value);
    }

    unregister(value: T): void {
        const keyvalue = value[this.key];
        if (keyvalue === undefined) {
            throw new Error(`value missing ${this.key}`)
        }
        this.map.delete(keyvalue);
    }    
}