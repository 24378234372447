import { Activity } from "../activity/Activity"
import { ActivityArrayContextKey } from "../activity-registry/ActivityArrayContextKey"
import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ActivityRegistryPluginKey } from "../activity-registry/ActivityRegistryPluginKey"

export const LoveActivityManifest: Manifest = {

    /**
     * The key that uniquely identifies
     */
    key: "love-activity",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        ActivityRegistryPluginKey,
        "love"
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "love-activity",
            registerWith: ActivityArrayContextKey,
            value: {
                key: "love",
                title: "Love"
            }
        } as ManifestEntry<Activity>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Love Activity"
}