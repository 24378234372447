import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { FolderPluginKey } from "../folder/FolderPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ServiceCreatorRegistryContextKey } from "../service-creator-registry/ServiceCreatorRegistryContextKey"
import { ServiceCreatorRegistryPluginKey } from "../service-creator-registry/ServiceCreatorRegistryPluginKey"
import { ServiceLoaderService } from "./ServiceLoaderService"
import { ServiceLoaderServiceContextKey } from "./ServiceLoaderServiceContextKey"
import { ServiceLoaderServicePluginKey } from "./ServiceLoaderServicePluginKey"
import { ServiceRegistryContextKey } from "../service-registry/ServiceRegistryContextKey"
import { ServiceRegistryEntry } from "../service-registry/ServiceRegistryEntry"
import { ServiceRegistryPluginKey } from "../service-registry/ServiceRegistryPluginKey"

export const ServiceLoaderServiceManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ServiceLoaderServicePluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FolderPluginKey,
        ServiceCreatorRegistryPluginKey,
        ServiceRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        // service
        {
            key: ServiceLoaderServiceContextKey,
            requires: [
                ServiceRegistryContextKey,
                ServiceCreatorRegistryContextKey,
                DefaultStoreContextKey
            ],
            value: (context: ManifestContext) =>
                new ServiceLoaderService(
                   context[ServiceRegistryContextKey],
                   context[ServiceCreatorRegistryContextKey],
                   context[DefaultStoreContextKey],
                   undefined // parentId
                )
        } as ManifestEntry<ServiceLoaderService>,

        // registration
        {
            key: "ServiceRegistryServiceRegistryEntry",
            registerWith: ServiceRegistryContextKey,
            requires: [ServiceLoaderServiceContextKey],
            value: (context: ManifestContext) => ({
                key: "ServiceLoaderService",
                service: context[ServiceLoaderServiceContextKey],
                startup: "auto"
            })
        } as ManifestEntry<ServiceRegistryEntry<ServiceLoaderService>>        
    ],

    /**
     * The display title of the service.
     */
    title: "Service Loader Service"
}