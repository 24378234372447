import React from "react"
import { Goal } from "../goal/Goal";
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./GoalCardView.css"

export const GoalCardView: React.FC<ReactViewProps> = (props) => {

    const goal: Goal = props?.model?.payload?.data;
    if (!goal) {
        return <></>
    }

    return (
        <div className="goal-card">
            <div className="goal-card-title">{goal.title}</div>
        </div>
    )
}