import { Manifest } from "../../runtime/Manifest"
import { NWSAlertPluginKey } from "./NWSAlertPluginKey"

export const NWSAlertManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: NWSAlertPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "NWS Alert"
}