import { Payload } from "../payload/Payload"
import { PayloadPredicates } from "../payload/PayloadPredicates"
import { Store } from "../store/Store"
import { Streamer } from "../streamer/Streamer"

export class StoreStreamer implements Streamer {

    /**
     * The store containing payloads.
     */
    private store: Store;

    /**
     * The ID of the parent item, or undefined for the root.
     */
    private parentId: number | undefined;
    
    constructor(store: Store, parentId?: number | undefined) {
        
        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }

        this.parentId = parentId;
    }

    /**
     * Returns the next payload.
     */
    public async next(): Promise<Payload | undefined> {

        // HACK: use cursor
        const payloads = await this.store.children(this.parentId);

        for(const payload of payloads) {
            if (PayloadPredicates.Active(payload) && (payload.openedOn === undefined)) {
                payload.openedOn = Date.now();
                return this.store.put(payload);                
            }
        }

        return undefined;
    }
}