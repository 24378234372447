import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { ConfirmCardView } from "./ConfirmCardView"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PromptPluginKey } from "../prompt/PromptPluginKey"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"

export const ConfirmCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "confirm-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConfirmPluginKey,
        PromptPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ConfirmCardView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: ConfirmSchemaId,
                layout: "card",
                fc: ConfirmCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Confirm Card View"
}