import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SchemaAnnotator } from "./SchemaAnnotator"
import { SchemaAnnotatorContextKey } from "./SchemaAnnotatorContextKey"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaSchemaId } from "../schema/SchemaSchemaId"

export const SchemaAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "schema-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        SchemaPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: SchemaAnnotatorContextKey,
            value: new SchemaAnnotator()
        } as ManifestEntry<SchemaAnnotator>,

        // registration
        {
            key: "SchemaAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [SchemaAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: SchemaSchemaId,
                annotator: context[SchemaAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<SchemaAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Schema Annotator"
}
