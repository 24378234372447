import { ScheduleSchemaId } from "./ScheduleSchemaId"
import { Schema } from "../schema/Schema"

export const ScheduleSchema: Schema = {

    /**
     * The unique ID of the schedule schema.
     */
    "$id": ScheduleSchemaId,

    /**
     * The JSON data type of a schedule.
     */
    type: "object",

    /**
     * The properties of a schedule object.
     */
    properties: {

        "daysOfMonth": {
            type: "string",
            description: "Comma-delimited list of 1-based day numbers",
            title: "Days of Month"
        },

        "daysOfWeek": {
            type: "string",
            description: "Comma-delimited list of weekday abbreviations",
            title: "Days of Week"
        },

        "enabled": {
            type: "boolean",
            title: "Enabled"
        },

        "months": {
            type: "array",
            description: "Month abbreviations or 1-based numbers",
            items: {
                anyOf: [
                    {
                        type: "string",
                        title: "Month Abbr",                        
                        enum: [
                            "jan",
                            "feb",
                            "mar",
                            "apr",
                            "may",
                            "jun",
                            "jul",
                            "aug",
                            "sep",
                            "oct",
                            "nov",
                            "dec"
                        ]
                    },
                    {
                        type: "integer",
                        title: "Month Number",
                        minimum: 1,
                        maximum: 12
                    }
                ]
            },
            title: "Months"
        },

        "repeat": {
            type: "string",
            enum: [
                "once",
                "hourly",
                "daily",
                "weekly",
                "monthly",
                "yearly"
            ],
            title: "Repeat"
        }
    },

    /**
     * The display title of the schema.
     */
    title: "Schedule"
}