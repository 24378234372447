import { Annotator } from "../annotator/Annotator"
import { Blocker } from "./Blocker"
import { Payload } from "../payload/Payload"

/**
 * An annotator that blocks payloads.
 */
export class BlockingAnnotator implements Annotator {

    /**
     * The blocker object that examines payloads and determines whether to block.
     */
    private blocker: Blocker;

    /**
     * Initializes the blocking annotator with the specified blocker. A blocker
     * examines a payload item and blocks it if it matches certain criteria.
     */
    constructor(blocker: Blocker) {

        if (!blocker) {
            throw new Error("blocker must be specified");
        }

        this.blocker = blocker;
    }

    /**
     * Examines the payload and marks matching payloads as blocked.
     */
    public async annotate(payload: Payload | undefined): Promise<void> {

        if (!payload) {
            return;
        }
       
        if (this.blocker.blocked(payload)) {

            console.info(`BlockingAnnotator: blocked "${payload.title}"`);
            payload.blocked = true;
        }
    }
}