import { Blocking } from "./Blocking"

/**
 * A base class for a blocker that has persistent storage.
 */
export abstract class BlockingStore {

    /**
     * Returns all of the blockings from storage.
     */
    public abstract load(): Blocking[];

    /**
     * Saves the specified blockings to storage.
     */
    public abstract save(blockings: Blocking[]): void;
}