import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ServiceCreatorPluginKey } from "../service-creator/ServiceCreatorPluginKey"
import { ServiceCreatorRegistry } from "./ServiceCreatorRegistry"
import { ServiceCreatorRegistryContextKey } from "./ServiceCreatorRegistryContextKey"
import { ServiceCreatorRegistryPluginKey } from "./ServiceCreatorRegistryPluginKey"
import { ServicePluginKey } from "../service/ServicePluginKey"

export const ServiceCreatorRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ServiceCreatorRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ServicePluginKey,
        ServiceCreatorPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ServiceCreatorRegistryContextKey,
            value: new ServiceCreatorRegistry()
        } as ManifestEntry<ServiceCreatorRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Service Creator Registry"
}