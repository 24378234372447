import { Schema } from "../schema/Schema"

export function match(schema: Schema): boolean {

    if (schema?.type !== "array") {
        return false;
    }

    // The schema of array items must be specified
    if (typeof(schema.items) !== "object") {
        return false;
    }

    return true;
}