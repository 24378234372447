import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistry } from "./FormatterRegistry"
import { FormatterRegistryContextKey } from "./FormatterRegistryContextKey"
import { FormatterRegistryPluginKey } from "./FormatterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const FormatterRegistryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: FormatterRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FormatterPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: FormatterRegistryContextKey,
            value: new FormatterRegistry()
        } as ManifestEntry<FormatterRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Formatter Registry"
}