import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NoticePluginKey } from "../notice/NoticePluginKey"
import { NoticesReducer } from "./NoticesReducer"
import { NoticesReducerKey } from "./NoticesReducerKey"
import { NoticesReducerPluginKey } from "./NoticesReducerPluginKey"
import { NotifierPluginKey } from "../notifier/NotifierPluginKey"
import { NotifierRegistryPluginKey } from "../notifier-registry/NotifierRegistryPluginKey"
import { NotifierArrayContextKey } from "../notifier-registry/NotifierArrayContextKey"
import { ReducerPluginKey } from "../reducer/ReducerPluginKey"
import { ReducerRegistryContextKey } from "../reducer-registry/ReducerRegistryContextKey"
import { ReducerRegistryEntry } from "../reducer-registry/ReducerRegistryEntry"
import { ReducerRegistryPluginKey } from "../reducer-registry/ReducerRegistryPluginKey"

export const NoticesReducerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: NoticesReducerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NoticePluginKey,
        NotifierPluginKey,
        NotifierRegistryPluginKey,
        ReducerPluginKey,
        ReducerRegistryPluginKey,
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "NoticesReducer",
            registerWith: ReducerRegistryContextKey,
            requires: [NotifierArrayContextKey],
            value: (context: ManifestContext) => ({
                key: NoticesReducerKey,
                reducer: new NoticesReducer(context[NotifierArrayContextKey])
            })
        } as ManifestEntry<ReducerRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Notices Reducer"
}