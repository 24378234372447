import { Schema } from "../schema/Schema"
import { SnippetSchemaId } from "./SnippetSchemaId"

export const SnippetSchema: Schema = {
    "$id": SnippetSchemaId,
    title: "Snippet",
    type: "object",
    properties: {
        tags: {
            title: "Tags",
            type: "array",
            items: {
                type: "string"
            }
        }
    }
}