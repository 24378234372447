import { ChildrenCommander } from "./ChildrenCommander"
import { ChildrenPluginKey } from "../children/ChildrenPluginKey"
import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ChildrenCommanderManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "children-commander",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ChildrenPluginKey,
        CommanderRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ChildrenCommander",
            registerWith: CommanderArrayContextKey,
            value: new ChildrenCommander()
        } as ManifestEntry<ChildrenCommander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Children Commander"
}