import { Formatter } from "../formatter/Formatter"
import { LongitudeSchemaId } from "../longitude/LongitudeSchemaId";

export class LongitudeFormatter implements Formatter {

    /**
     * Formats a longitude value into DMS (degrees, minutes, seconds) human-friendly format.
     */
    public format(schemaId: string, longitude: any): string | undefined {

        if (schemaId !== LongitudeSchemaId) {
            return undefined;
        }

        if (typeof(longitude) !== "number") {
            return undefined;
        }
        
        let value: number;
        let direction: string;

        if (longitude < 0) {
            value = longitude * -1;
            direction = "W";
        }
        else {
            value = longitude;
            direction = "E";
        }

        // Calculate degrees and subtract from value
        const degrees = Math.floor(value);
        value -= degrees;

        // Calculate minutes and subtract from value
        const minutes = Math.floor(value * 60.0);
        value -= minutes / 60.0;

        // Calculate seconds
        const seconds = (value * 60.0 * 60.0).toFixed(1);
        
        return `${degrees}° ${minutes}′  ${seconds}″ ${direction}`;
    }

}