import { Indexer } from "../indexer/Indexer"
import { NumberSchemaId } from "../number/NumberSchemaId"
import { Payload } from "../payload/Payload"

export class NumberIndexer implements Indexer<number> {

    public async index(payload: Payload<number>): Promise<Payload[]> {
        
        const value = payload?.data;
        if (typeof (value) !== "number") {
            return [];
        }
        
        return [
            {
                schema: NumberSchemaId,
                data: value - 1,
                key: (value - 1).toString(),
            },
            {
                schema: NumberSchemaId,
                data: value + 1,
                key: (value + 1).toString(),
            }
        ]
    }
}