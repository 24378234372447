import { Action } from "../controller/Action"
import { AnnotatorRegistry } from "../annotator-registry/AnnotatorRegistry"
import { Controller } from "../controller/Controller"
import { Model } from "./Model"
import { Payload } from "../payload/Payload"
import { ReducerRegistry } from "../reducer-registry/ReducerRegistry"

export class State  {

    /** The annotators to apply from payloads provided by the controller */
    private annotators: AnnotatorRegistry;

    /** The controller being annotated */
    private controller: Controller;

    /** The reducers that apply additional properties to the model */
    private reducers: ReducerRegistry;

    constructor(controller: Controller, annotators: AnnotatorRegistry, reducers: ReducerRegistry, public defaultPayload?: Payload) {

        if (!(this.controller = controller)) {
            throw new Error("controller must be specified");
        }

        if (!(this.annotators = annotators)) {
            throw new Error("annotators must be specified")
        }

        if (!(this.reducers = reducers)) {
            throw new Error("reducers must be specified");
        }
    }

    /**
     * Dispatches an action to the controller.
     */
    public async dispatch<TParam = any>(action: Action<TParam>): Promise<Model> {

        // Dispatch and get the payload
        let payload = await this.controller.dispatch(action);
        if (!payload) {
            if (this.defaultPayload) {
                payload = {...this.defaultPayload}
            }
        }

        // Annotate the payload
        if (payload) {
            await this.annotators.annotate(payload);

            // Annotate attachments. HACK: ?
            if (Array.isArray(payload.attachments)) {
                for(let attachment of payload.attachments) {
                    await this.annotators.annotate(attachment);
                }
            }
        }

        // Construct the base model around the payload
        let model: Model = {
            payload
        }

        // Apply reducers
        for (let settings of this.reducers.all()) {
            model[settings.key] = await settings.reducer.reduce(model);
        }

        return model;
    }
}