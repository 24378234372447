import { NWSAlertFeedSchemaId } from "../nws-alert-feed/NWSAlertFeedSchemaId"
import { Payload } from "../payload/Payload"

export const NWSAlertFeedCatalog: Payload[] = [
    {
        key: "weather.gov/alerts/MN",
        schema: NWSAlertFeedSchemaId,
        title: "Weather Alerts - MN, USA",
        data: {
            area: "MN"
        }
    },
    {
        key: "weather.gov/alerts/WA",
        schema: NWSAlertFeedSchemaId,
        title: "Weather Alerts - WA, USA",
        data: {
            area: "WA"
        }
    }
]