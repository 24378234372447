import { Payload } from "../payload/Payload"
import { Splash } from "../splash/Splash"
import { SplashSchemaId } from "../splash/SplashSchemaId"

export function getActivity(payload: Payload | undefined): string | undefined{
    switch(payload?.schema) {
        case SplashSchemaId:
            const splash = payload.data as Splash;
            return splash?.activity;

        default:
            return undefined;
    }
}