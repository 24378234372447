import React from "react"
import { IonPage } from "@ionic/react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const PageView: React.FC<ReactViewProps> = (props) => {
  
    return (
        <IonPage>
            <ReactViewFactory
                dispatch={props.dispatch}
                layout="page-section"
                matcher={props.matcher}
                model={props.model}
                multi={true}></ReactViewFactory>
        </IonPage>
    );
}