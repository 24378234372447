import { BingWallpaperArchive } from "../bing-wallpaper/BingWallpaperArchive"
import { BingWallpaperFeed } from "../bing-wallpaper-feed/BingWallpaperFeed"
import { BingWallpaperSchemaId } from "../bing-wallpaper/BingWallpaperSchemaId"
import { fetchJson } from "../../library/fetchJson"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"

export class BingWallpaperFeedPoller implements Poller {

    private feed: BingWallpaperFeed;

    constructor(feed: BingWallpaperFeed) {
        if (!(this.feed = feed)) {
            throw new Error("feed must be specified");
        }
    }

    /**
     * Converts a bing image to a payload.
     */
    public convert(archive: BingWallpaperArchive | undefined): Payload[] {

        if (!archive) {
            return [];
        }
        else {
            return archive.images.map(image => ({
                schema: BingWallpaperSchemaId,
                key: image.hsh,
                data: image
            }));
        }
    }

    /**
     * Polls for new images from the bing daily image service.
     */
    public async poll(): Promise<Payload[]> {

        /**
         * Build the URL of the Bing daily image web service. The n parameter specifies the
         * number of days back to return. The mkt parameter specifies the locale.
         * The format parameter is set to "js" to return JSON.
         */

        const mkt = this.feed.locale ?? "en-us";
        const url = `https://www.bing.com/HPImageArchive.aspx?format=js&idx=0&n=1&mkt=${mkt}`;

        return fetchJson<BingWallpaperArchive>(url, true).then(
            archive => this.convert(archive));
    }    
}