import { Manifest } from "../../runtime/Manifest"
import { RegExIndexerPluginKey } from "./RegExIndexerPluginKey"

export const RegExIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: RegExIndexerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "RegEx Indexer"
}