import { Manifest } from "../../runtime/Manifest"
import { SearchStringPluginKey } from "./SearchStringPluginKey"

export const SearchStringManifest: Manifest = {

    /**
     * The key that uniquely identifies the search box plugin.
     */
    key: SearchStringPluginKey,

    /**
     * Indicates whether the plugin should be installed at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Search String"
}