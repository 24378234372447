import React from "react"
import { Quote } from "../quote/Quote"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./QuoteCardView.css"

export const QuoteCardView: React.FC<ReactViewProps<Quote>> = (props) => {

    let quote: Quote | undefined = props?.model?.payload?.data;
    if (!quote) {
        return (<></>);
    }
    
    return (
        <div className="quote">
            <div className="quote-text selectable">
                {quote.text}
            </div>
            {quote?.who?.length > 0 && 
                <div className="quote-who selectable">
                    {quote.who}
                </div>
            }
        </div>
    );
};