import { GeoJSONPluginKey } from "../geojson/GeoJSONPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NWSAlertDetailView } from "./NWSAlertDetailView"
import { NWSAlertPluginKey } from "../nws-alert/NWSAlertPluginKey"
import { NWSAlertSchemaId } from "../nws-alert/NWSAlertSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const NWSAlertDetailViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nws-alert-detail-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        GeoJSONPluginKey,
        NWSAlertPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NWSAlertDetailView",
            registerWith: ReactViewRegistryContextKey,
            title: "NWS Alert Detail View",
            value: {
                layout: "detail",
                match: NWSAlertSchemaId,
                fc: NWSAlertDetailView
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NWS Alert Detail View",
};
