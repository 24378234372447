import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NextAnnotator } from "./NextAnnotator"
import { NextAnnotatorContextKey } from "./NextAnnotatorContextKey"
import { NextPluginKey } from "../next/NextPluginKey"
import { NextSchemaId } from "../next/NextSchemaId"

export const NextAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "next-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        NextPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NextAnnotatorContextKey,
            value: new NextAnnotator()
        } as ManifestEntry<NextAnnotator>,

        // registration
        {
            key: "NextAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NextAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NextSchemaId,
                annotator: context[NextAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NextAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Next Annotator"
}