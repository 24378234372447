import { Payload } from "../payload/Payload"
import { Registry } from "../../runtime/Registry"
import { Service } from "../service/Service"
import { ServiceCreator } from "../service-creator/ServiceCreator";
import { ServiceCreatorRegistryEntry } from "./ServiceCreatorRegistryEntry"

export class ServiceCreatorRegistry implements ServiceCreator, Registry<ServiceCreatorRegistryEntry> {

    private schemaMap = new Map<string, Set<ServiceCreatorRegistryEntry>>();

    /**
     * Creates a service from the specified payload.
     */
    public async create(payload: Payload): Promise<Service | undefined> {
        const entries = this.match(payload);
        for (const entry of entries) {

            const service = await entry.creator.create(payload);
            if (service) {
                return service;
            }
        }
    }

    /**
     * Returns the registry entry that will create a service for a given payload.
     */
    public match(payload: Payload): ServiceCreatorRegistryEntry[] {

        const matches: ServiceCreatorRegistryEntry[] = [];

        // Match against the specific schema
        if (payload?.schema) {
            const entries = this.schemaMap.get(payload.schema);
            if (entries) {
                matches.push(...entries.values())
            }
        }

        // Match against global registrations
        const global = this.schemaMap.get("*");
        if (global) {
            matches.push(...global.values());
        }

        return matches;
    }

    /**
     * Adds a service creator to the registry.
     */
    public register(entry: ServiceCreatorRegistryEntry): void {

        if (!entry) {
            throw new Error("entry must be specified");
        }

        if (!entry.schema) {
            throw new Error("service creator entry must have schema");
        }

        if (typeof(entry.creator) !== "object") {
            throw new Error("service creator must have creator object");
        }

        // Get the creator set for this schema
        let entries = this.schemaMap.get(entry.schema);
        if (!entries) {
            entries = new Set<ServiceCreatorRegistryEntry>();
            this.schemaMap.set(entry.schema, entries);
        }

        entries.add(entry);
    }

    /**
     * Removes a service creator from the registry.
     */
    public unregister(entry: ServiceCreatorRegistryEntry): void {

        if (!entry) {
            throw new Error("entry must be specified");
        }

        if (!entry.schema) {
            throw new Error("entry must have schema");
        }

        const schemaEntries = this.schemaMap.get(entry.schema);
        if (schemaEntries) {
            schemaEntries.delete(entry);
            if (schemaEntries.size === 0) {
                this.schemaMap.delete(entry.schema);
            }
        }
    }
}