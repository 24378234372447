import { Commander } from "../commander/Commander"
import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { ControllerVerb } from "../controller/ControllerVerb"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Model } from "../state/Model"
import { Next } from "../next/Next"
import { NextSchemaId } from "../next/NextSchemaId"
import { Payload } from "../payload/Payload"

export const NextButtonManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "next-button",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The objects that must be installed before this one.
     */
    dependsOn: [
        CommanderRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "NextButtonCommander",
            registerWith: CommanderArrayContextKey,
            value: {
                issue: async (model: Model) => [
                    {
                        emoji: "⯈",
                        key: ControllerVerb.NEXT,
                        layout: "toolbar",
                        title: "Next",
                        value: { schema: NextSchemaId } as Payload<Next>,
                    }
                ]
            }
        } as ManifestEntry<Commander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Next Button"
}