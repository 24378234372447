import { compareReactViews } from "./compareReactViews"
import { Model } from "../state/Model";
import { ReactViewLayout } from "./ReactViewLayout";
import { ReactViewMatchCriteria } from "./ReactViewMatchCriteria";
import { ReactViewRegistryEntry } from "./ReactViewRegistryEntry"

/**
 * A set of views. A view renders a payload into UI.
 */
export class ReactViewRegistry {

    /** A mapping between each layout and its corresponding entries */
    private map = new Map<ReactViewLayout, Set<ReactViewRegistryEntry>>();

    /**
     * Gets every view for the specified layout.
     */
    public match(layout: ReactViewLayout, model: Model | undefined): ReactViewRegistryEntry[] {

        function isMatch(criteria: ReactViewMatchCriteria): boolean {
            if (criteria === "*") {
                return true;
            }
            else {
                return model?.payload?.schema === criteria;
            }
        }

        if (!layout) {
            console.error("layout missing");
            return [];
        }

        const matches: ReactViewRegistryEntry[] = [];

        // Get all matches against the layout and schema
        const entries = this.map.get(layout);
        if (entries) {
            for(const entry of entries) {
                if (isMatch(entry.match)) {
                    matches.push(entry);
                }
            }
        }

        // Sort the matches
        return matches.sort((a,b) => compareReactViews(a, b));
    }

    /**
     * Adds a view to the registry.
     */
    public register(entry: ReactViewRegistryEntry): boolean {

        if (!entry) {
            console.error(`entry missing`);
            return false;
        }

        if (!entry.fc) {
            console.error(`entry.fc missing`);
            return false;
        }

        if (!entry.match) {
            console.error(`entry.schema missing`);
            return false;
        }

        if (!entry.layout) {
            console.error(`entry.layout missing`);
            return false;
        }

        // Normalize the layouts into an array
        const layouts = Array.isArray(entry.layout) ? entry.layout : [entry.layout];

        // Register against each layout
        for(const layout of layouts) {

            let layoutEntries = this.map.get(layout);
            if (!layoutEntries) {
                layoutEntries = new Set<ReactViewRegistryEntry>();
                this.map.set(layout, layoutEntries);
            }

            layoutEntries.add(entry);
        }

        return true;
    }

    /**
     * Removes a view from the factory.
     */
    public unregister(entry: ReactViewRegistryEntry): boolean {

        if (!entry) {
            console.error(`entry missing`);
            return false;
        }

        if (!entry.layout) {
            console.error(`entry.layout missing`);
            return false;
        }

        // Normalize the layouts into an array
        const layouts = Array.isArray(entry.layout) ? entry.layout : [entry.layout];
        
        // Unregister each layout
        for (const layout of layouts) {

            const layoutEntries = this.map.get(layout);
            if (layoutEntries) {
                layoutEntries.delete(entry);
            }
            else {
                console.warn(`unrecognized layout`)
            }
        }

        return true;
    }
}