import { HackerNewsPluginKey } from "./HackerNewsPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const HackerNewsManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: HackerNewsPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The display description of the plugin.
     */
    description: "Core types for the Hacker News API (in progress)",

    /**
     * The display title of the plugin.
     */
    title: "Hacker News"
}