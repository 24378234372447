import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { TreeCardView } from "./TreeCardView"
import { TreePluginKey } from "../tree/TreePluginKey"
import { TreeSchemaId } from "../tree/TreeSchemaId"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"

export const TreeCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "tree-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        DefaultStorePluginKey,
        ReactViewPluginKey,
        TreePluginKey,
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "TreeCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [DefaultStoreContextKey, AnnotatorRegistryContextKey],
            title: "Tree Card View",
            value: (context: ManifestContext) => ({
                layout: "card",
                match: TreeSchemaId,
                fc: props => TreeCardView(
                    {
                        ...props,
                        annotator: context[AnnotatorRegistryContextKey],
                        store: context[DefaultStoreContextKey]
                    })    
            })
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Tree Card View"
};
