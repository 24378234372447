import React, { useEffect, useState } from "react"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { markReopen } from "../task/markReopen"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReopenTask } from "../reopen-task/ReopenTask"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export const ReopenTaskCardView: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;
    const reopen = props.model?.payload?.data as ReopenTask;
    const taskPayload = reopen.payload;

    // Track whether the user has confirmed the completion
    const [confirmed, setConfirmed] = useState(reopen?.confirmed ?? false);

    useEffect(() => {

        let mounted = true;

        if (confirmed) {

            markReopen(taskPayload.data);

            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload: taskPayload,
                    confirmed: true
                }
            }
            
            if (dispatch && mounted) {
                dispatch(savePayload);
            }
        }

        return () => { mounted = false };

    }, [dispatch, confirmed, taskPayload]);

    function onConfirm(selectedPayload: Payload) {
        if (selectedPayload.data === true) {
            setConfirmed(true);
        }
        else {
            if (dispatch) {
                dispatch(taskPayload);
            }
        }
    }

    function selectView() {
        if (confirmed) {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={undefined}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Reopening..."
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
        else {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={p => onConfirm(p)}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: ConfirmSchemaId,
                        data: {
                            payload: taskPayload,
                            title: "Reopen Task"
                        } as Confirm
                    }}}
                ></ReactViewFactory>
            )
        }
    }

    return selectView()    
}