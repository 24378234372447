import { Annotator } from "../annotator/Annotator"
import { Deadline } from "../deadline/Deadline"
import { DeadlineSchemaId } from "../deadline/DeadlineSchemaId"
import { Payload } from "../payload/Payload"

export class DeadlineAnnotator implements Annotator<Deadline> {
    
    public async annotate(payload: Payload<Deadline>): Promise<void> {
        if (payload.data && (payload.schema === DeadlineSchemaId)) {
            let title = payload.data.title;
            if (typeof(payload.data.deadlineOn) === "number") {
                const deadlineOn = new Date(payload.data.deadlineOn);
                title = title ? `${title} on ${deadlineOn.toLocaleDateString()}` : `${deadlineOn.toLocaleDateString()}`
            }
            payload.title = title ?? payload.title
        }
    }
}