import { Payload } from "./Payload"
import { PayloadPredicate } from "./PayloadPredicate"

export class PayloadPredicates {

    /**
     * Returns true for active payloads (payloads that are not blocked,
     * not expired not deferred to the future, etc.).
     */
    public static Active: PayloadPredicate = (payload: Payload) => {

        const now = Date.now();

        if (!payload) {
            return false;
        }

        if (payload.blocked) {
            return false;
        }

        if (payload.deferUntil && payload.deferUntil > now) {
            return false;
        }

        if (payload.expiresOn && payload.expiresOn < now) {
            return false;
        }

        return true;
    }

    /**
     * Returns a predicate for matching as a potential copy of the given payload.
     */
    public static CopyOf(payload: Payload): PayloadPredicate {
 
        // Match ID
        if (payload.id) {
            return (p: Payload) => p.id === payload.id;
        }
    
        // Match schema and key
        if (payload.schema && payload.key) {
            return (p: Payload) => (p.schema === payload.schema) && (p.key === payload.key);
        }

        // Match data contents
        if (payload.data) {
            const datastr = JSON.stringify(payload.data);
            return (p: Payload) => {
                if (p.data) {
                    return datastr === JSON.stringify(p.data);
                }
                else {
                    return false;
                }
            }
        }

        // Match payload entirely
        const payloadstr = JSON.stringify(payload);    
        return (p: Payload) => {
    
            if (p.id || p.key) {
                return false;
            }
    
            return JSON.stringify(p) === payloadstr;
        }            
    }

    public static DeferredToFuture(payload: Payload): boolean {
        if (payload?.deferUntil && payload.deferUntil > Date.now()) {
            return true;
        }
        else {
            return false;
        }
    }
}