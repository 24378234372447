import { ChildrenCardViewContextKey } from "../children-card-view/ChildrenCardViewContextKey"
import { ChildrenCardViewPluginKey } from "../children-card-view/ChildrenCardViewPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NasaApodFeedPluginKey } from "../nasa-apod-feed/NasaApodFeedPluginKey"
import { NasaApodFeedSchemaId } from "../nasa-apod-feed/NasaApodFeedSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const NasaApodFeedCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nasa-apod-feed-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ChildrenCardViewPluginKey,
        NasaApodFeedPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "NasaApodFeedCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [ChildrenCardViewContextKey],
            value: (context: ManifestContext) => (
                { ...context[ChildrenCardViewContextKey], match: NasaApodFeedSchemaId}
            )
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "NASA APOD Feed Card View"
}