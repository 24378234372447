import React, { useEffect, useState } from "react"
import { clone } from "../../library/clone"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Create } from "../create/Create"
import { List } from "../list/List"
import { ListSchemaId } from "../list/ListSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Schema } from "../schema/Schema"
import { SchemaRegistry } from "../schema-registry/SchemaRegistry"
import { SchemaSchemaId } from "../schema/SchemaSchemaId"

export function compareSchema(s1: Schema, s2: Schema): number {
    const title1 = s1?.title ?? s1.$id ?? "";
    const title2 = s2?.title ?? s2.$id ?? "";
    return title1.localeCompare(title2);
}

export interface CreateCardViewProps extends ReactViewProps {
    /**
     * The registry containing all schemas from which a payload can be created.
     */
    schemas: SchemaRegistry
}

export const CreateCardView: React.FC<CreateCardViewProps> = (props) => {

    const create = props?.model?.payload?.data as Create;
    const dispatch = props.dispatch;
    
    // Set the initial value based on the payload specified in the
    // Create object. If undefined is specified, the user will be
    // prompted to select a schema and then the card will create the
    // initial value. Specifying an initial payload with an empty
    // schema has the same effect. 
    const [payload, setPayload] = useState(clone(create?.payload) ?? {} as Payload);

    // Track whether the creation has been confirmed. If not confirmed, a 
    // preview of the object will be shown and the person can decide
    // whether to cancel or create it.
    const [confirmed, setConfirmed] = useState(create?.confirmed);

    /**
     * Dispatch when confirmed.
     */
    useEffect(() => {

        if (confirmed && dispatch && payload) {
            dispatch(payload);
        }

    }, [confirmed, dispatch, payload]);

    /**
     * Displays a preview of the payload being created.
     */
    function Preview() {
        return (
            <ReactViewFactory
                layout="preview"
                dispatch={undefined}
                matcher={props.matcher}
                model={{ payload }}>
            </ReactViewFactory>
        );
    }

    /**
     * Prompts the user to select a schema. Once a schema is selected,
     * the created payload is updated to reflect that schema and its
     * schema-specific default value.
     */
    function SetSchemaId() {

        if (!props.schemas) {
            return (
                <ReactViewFailure>
                    schemas prop must be specified
                </ReactViewFailure>
            )
        }

        // Build an array of payloads for each schema that can be
        // created. The schema can be created if its JSON schema has been
        // registered in the schema registry.
        const schemaPayloads = props.schemas.all().sort(compareSchema).map(s => ({
            schema: SchemaSchemaId,
            data: s,
            title: s.title ?? s.$id
        } as Payload<Schema>));

        // Create a List view that will be handled by the list view card. Per
        // conventions, the list card will display the list to the user and
        // dispatch back the selected payload (in this case one of the schema payloads).
        const listPayload: Payload<List> = {
            schema: ListSchemaId,
            data: {
                items: schemaPayloads
            }
        }

        function onSet(p: Payload<Schema>) {
            if (p && p.data && p.data.$id) {

                // Initialize the default value
                const schema = props.schemas.get(p.data.$id);

                // Create the default value
                let def = schema?.default;
                switch(schema?.type) {
                    case "object":
                        if (!def) {
                            def = {};
                        }
                }

                // Set the schema and default value
                setPayload(prev => (
                    {
                        ...prev,
                        schema: p.data.$id,
                        data: def
                    }));
            }
        }

        return (
            <ReactViewFactory
                layout="card"
                dispatch={p => onSet(p)}
                matcher={props.matcher}
                model={{ payload: listPayload }}>
            </ReactViewFactory>
        )
    }

    /**
     * Prompts for confirmation to create the payload.
     */
    function Confirm() {

        let payloadToPreview: Payload;

        // The initial payload is empty and probably won't render nicely
        // since many properties will be undefined or empty. Instead
        // try to display a preview of the schema itself.
        if (payload.schema) {
            const schema = props.schemas.get(payload.schema);
            payloadToPreview = {
                schema: SchemaSchemaId,
                data: schema,
                title: schema?.title
            }
        }
        else {
            payloadToPreview = payload;
        }

        const confirmPayload: Payload<Confirm> = {
            schema: ConfirmSchemaId,
            data: {
                payload: payloadToPreview,
                title: "Create"
            }
        }

        function onConfirm(p: Payload) {
            if (typeof(p.data) === "boolean") {
                if (p.data) {
                    setConfirmed(p.data);
                }
                else {
                    setPayload(prev => ({
                        ...prev,
                        schema: undefined,
                        data: undefined
                    }))
                }
            }
        }

        return (
            <ReactViewFactory
                layout="card"
                dispatch={p => onConfirm(p)}
                matcher={props.matcher}
                model={{ payload: confirmPayload }}>
            </ReactViewFactory>
        );
    }

    /**
     * Selects the best view based on the state of the view.
     */
    function SelectView() {

        // If no schema has been specified, the prompt the user to select
        // a schema and update the created payload based on that schema.
        if (!payload.schema) {
            return (
                <SetSchemaId />
            );               
        }

        // Confirm the creation
        if (!confirmed) {
            return (
                <Confirm />
            )
        }

        return (
            <Preview/>
        );    
    }

    return (
        <SelectView />
    )
}