import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SplashAnnotator } from "./SplashAnnotator"
import { SplashAnnotatorContextKey } from "./SplashAnnotatorContextKey"
import { SplashPluginKey } from "../splash/SplashPluginKey"
import { SplashSchemaId } from "../splash/SplashSchemaId"

export const SplashAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "splash-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        SplashPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: SplashAnnotatorContextKey,
            value: new SplashAnnotator()
        } as ManifestEntry<SplashAnnotator>,

        // registration
        {
            key: "SplashAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [SplashAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: SplashSchemaId,
                annotator: context[SplashAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<SplashAnnotator>>
    ],

    /**
     * The dislay title of the plugin.
     */
    title: "Splash Annotator"
}