import { ChildrenCardViewContextKey } from "../children-card-view/ChildrenCardViewContextKey"
import { ChildrenCardViewPluginKey } from "../children-card-view/ChildrenCardViewPluginKey"
import { EarthquakeFeedPluginKey } from "../usgs-earthquake-feed/EarthquakeFeedPluginKey"
import { EarthquakeFeedSchema } from "../usgs-earthquake-feed/EarthquakeFeedSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const UsgsEarthquakeFeedCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "usgs-earthquake-feed-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ChildrenCardViewPluginKey,
        EarthquakeFeedPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "EarthquakeFeedCardView",
            registerWith: ReactViewRegistryContextKey,
            requires: [ChildrenCardViewContextKey],
            value: (context: ManifestContext) => (
                { ...context[ChildrenCardViewContextKey], match: EarthquakeFeedSchema}
            )
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Earthquake Feed Card View"
}