import { Activity } from "../activity/Activity"

export const DefaultActivities: Activity[] = [
    {
        key: "wakeup",
        schedule: {
            daysOfWeek: "daily",
            beginTime: 1000 * 60 * 60 * 5,
            endTime: 1000 * 60 * 60 * 10
        },
        tags: ["wakeup"],
        title: "A new day"
    },
    {
        key: "bedtime",
        tags: ["bedtime", "sleep"],
        title: "Bedtime",
        schedule: {
            slots: [
                {
                    daysOfWeek: "sun,mon,tue,wed,thu",
                    beginTime: 1000 * 60 * 60 * 22
                }
            ]
        } 
    },
    {
        key: "camping",
        tags: ["camping"],
        title: "Camping"
    },
    {
        key: "chores",
        tags: ["chores"],
        title: "Chores"
    },
    {
        key: "diversion",
        tags: ["funny", "joke"],
        title: "Diversion"
    },
    {
        key: "exercise",
        tags: ["exercise", "fitness"],
        title: "Exercise",
        schedule: {
            beginTime: 1000 * 60 * 60 * 8, // 8AM
            endTime: 1000 * 60 * 60 * 9   // 9AM            
        }        
    },
    {
        key: "finances",
        tags: ["bills", "debt", "finance", "finances", "money"],
        title: "Finances"
    },
    {
        key: "gardening",
        tags: ["garden", "gardening", "landscaping"],
        title: "Gardening"
    },
    {
        key: "groceries",
        tags: ["grocery", "groceries"],
        title: "Groceries"
    },
    {
        key: "grooming",
        tags: ["grooming", "haircut"],
        title: "Grooming",
        schedule: {
            beginTime: 1000 * 60 * 60 * 9, // 9AM
            endTime: 1000 * 60 * 60 * 9.5   // 9:30AM            
        }
    },
    {
        key: "health",
        tags: ["health"],
        title: "Health"
    },
    {
        key: "home",
        tags: ["home"],
        title: "Home"
    },
    {
        key: "inspiration",
        tags: ["inspiration"],
        title: "Inspiration"
    },
    {
        key: "negotiate",
        tags: ["negotiate"],
        title: "Negotiate"
    },
    {
        key: "school",
        tags: ["school", "education"],
        title: "School"
    },
    {
        key: "spirituality",
        tags: ["spirtual", "spirituality"],
        title: "Spirtuality"
    },
    {
        key: "work",
        tags: ["work"],
        title: "Work",
        schedule: {
            daysOfWeek: "mon,tue,wed,thu,fri",
            beginTime: 1000 * 60 * 60 * 9, // 9AM
            endTime: 1000 * 60 * 60 * 17   // 5PM
        }
    },
    {
        key: "#this",
        tags: ["this"],
        title: "#this"
    }
];