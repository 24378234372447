import { ControllerArrayContextKey } from "../controller-registry/ControllerArrayContextKey"
import { ControllerPluginKey } from "../controller/ControllerPluginKey"
import { ControllerRegistryPluginKey } from "../controller-registry/ControllerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerController } from "./StreamerController"
import { StreamerRegistryStreamerContextKey } from "../streamer-registry-streamer/StreamerRegistryStreamerContextKey"
import { StreamerRegistryStreamerPluginKey } from "../streamer-registry-streamer/StreamerRegistryStreamerPluginKey"

export const StreamerControllerManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: "streamer-controller",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ControllerPluginKey,
        ControllerRegistryPluginKey,
        StreamerRegistryStreamerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "StreamerController",
            registerWith: ControllerArrayContextKey,
            requires: [StreamerRegistryStreamerContextKey],
            value: (context: ManifestContext) => {
                return new StreamerController(context[StreamerRegistryStreamerContextKey]);
            }
        } as ManifestEntry<StreamerController>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Streamer Controller"
}