import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"
import { PollerServicePluginKey } from "../poller-service/PollerServicePluginKey"
import { PollerStreamerCreator } from "./PollerStreamerCreator"
import { PollerStreamerCreatorContextKey } from "./PollerStreamerCreatorContextKey"
import { PollerStreamerPluginKey } from "./PollerStreamerPluginKey"
import { StreamerCreatorPluginKey } from "../streamer-creator/StreamerCreatorPluginKey"
import { StreamerCreatorRegistryPluginKey } from "../streamer-creator-registry/StreamerCreatorRegistryPluginKey"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerCreatorRegistryEntry } from "../streamer-creator-registry/StreamerCreatorRegistryEntry"
import { StreamerCreatorRegistryContextKey } from "../streamer-creator-registry/StreamerCreatorRegistryContextKey"

export const PollerStreamerManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: PollerStreamerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DefaultStorePluginKey,
        PollerPluginKey,
        PollerCreatorRegistryPluginKey,
        PollerServicePluginKey,
        StreamerPluginKey,
        StreamerCreatorPluginKey,
        StreamerCreatorRegistryPluginKey,
    ],

    /**
     * The objects provided by the plugin.
     */
    entries:  [

        // streamer creator
        {
            key: PollerStreamerCreatorContextKey,
            requires: [
                PollerCreatorRegistryContextKey,
                DefaultStoreContextKey
            ],
            value: (context: ManifestContext) => 
                new PollerStreamerCreator(
                    context[PollerCreatorRegistryContextKey],
                    context[DefaultStoreContextKey])
        } as ManifestEntry<PollerStreamerCreator>,

        // registration
        {
            key: "PollerStreamerCreatorRegistryEntry",
            registerWith: StreamerCreatorRegistryContextKey,
            requires: [PollerStreamerCreatorContextKey],
            value: (context: ManifestContext) => ({
                schema: "*",
                creator: context[PollerStreamerCreatorContextKey]
            })
        } as ManifestEntry<StreamerCreatorRegistryEntry<PollerStreamerCreator>>
    ], 

    /**
     * The display title of the plugin.
     */
    title: "Poller Streamer"
}