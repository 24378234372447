import { Activity } from "../activity/Activity"
import { ActivityTracker } from "../activity-tracker/ActivityTracker"

export class ActivityToggleTracker extends ActivityTracker {

    private activity: Activity | undefined;

    /**
     * Gets the current activity.
     */
     public get(): Activity | undefined {
        return this.activity;
    }

    /**
     * Sets the current activity.
     */
    public set(activity: Activity | undefined) {
        this.activity = activity;
    }

    public track(): Activity[] {
        if(this.activity === undefined) {
            return [];
        }
        else {
            return [this.activity];
        }
    }    
}