import React, { useEffect, useState } from "react"
import { BooleanSchemaId } from "../boolean/BooleanSchemaId"
import { CancelTask } from "../cancel-task/CancelTask"
import { markCanceled } from "../task/markCanceled"
import { Payload } from "../payload/Payload"
import { Prompt } from "../prompt/Prompt"
import { PromptSchemaId } from "../prompt/PromptSchemaId"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export const CancelTaskCardView: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;
    const reopen = props.model?.payload?.data as CancelTask;
    const taskPayload = reopen.payload;

    // Track whether the user has confirmed the cancellation
    const [confirmed, setConfirmed] = useState(reopen?.confirmed ?? false);

    useEffect(() => {

        let mounted = true;

        if (confirmed) {

            markCanceled(taskPayload.data);

            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload: taskPayload,
                    confirmed: true
                }
            }
            
            if (dispatch && mounted) {
                dispatch(savePayload);
            }
        }

        return () => { mounted = false };

    }, [dispatch, confirmed, taskPayload]);

    /**
     * Builds a prompt to ask the user to confirm the cancellation.
     */
    function makePrompt(): Prompt {
        return {
            title: "Cancel",
            payload: taskPayload,
            options: [
                {
                    schema: BooleanSchemaId,
                    data: false,
                    key: "exit",
                    title: "Do not cancel"
                },
                {
                    schema: BooleanSchemaId,
                    data: true,
                    key: "cancelTask",
                    title: "Cancel task permanently"
                }
            ]
        }
    }

    function onConfirm(selectedPayload: Payload) {
        if (selectedPayload.data === true) {
            setConfirmed(true);
        }
        else {
            if (dispatch) {
                dispatch(taskPayload);
            }
        }
    }

    function selectView() {
        if (confirmed) {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={undefined}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Canceling..."
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
        else {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={p => onConfirm(p)}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: PromptSchemaId,
                        data: makePrompt()
                    }}}
                ></ReactViewFactory>
            )
        }
    }

    return selectView()    
}