import React from "react"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"

export const MoveStreamView: React.FC<ReactViewProps> = (props) => {

    /**
     * Called when the move card dispatches a payload with the
     * updated paret ID. Per the convetions of the move card, the
     * payload has not been saved.
     */
    function onMove(payload: Payload) {

        if(payload) {

            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload
                }
            }

            if (props.dispatch) {
                props.dispatch(savePayload);
            }
        }
    }

    return (
        <ReactViewFactory
            layout="card"
            dispatch={p => onMove(p)}
            matcher={props.matcher}
            model={props.model}>
        </ReactViewFactory>
    );
}