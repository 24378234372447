import React from "react"
import { IonButton } from "@ionic/react"
import { openUrl } from "./openUrl"

export interface UrlButtonProps {
    url: string;
}

export const UrlButton: React.FC<UrlButtonProps> = (props) => {

    if (!props.url) {
        return <></>
    }

    const url = new URL(props.url);
    const urlSafe = url.protocol === "https:";
    const urlColor = urlSafe ? "" : "danger";

    function onClick() {
        openUrl(props.url);
    }

    return (
        <IonButton color={urlColor} fill="outline" shape="round" onClick={() => onClick()}>
            {urlSafe ? "Open" : "Open insecure URL"}
        </IonButton>            
    )
}