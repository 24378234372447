import { EarthquakeFeed } from "../usgs-earthquake-feed/EarthquakeFeed"
import { EarthquakeFeedSchema } from "../usgs-earthquake-feed/EarthquakeFeedSchema"
import { Payload } from "../payload/Payload"

/**
 * The catalog of earthquake feeds available from the USGS.
 */
export const EarthquakeFeedCatalog: Payload<EarthquakeFeed>[] = [
    {
        key: "usgs/Earthquakes/4.5",
        schema: EarthquakeFeedSchema,
        title: "USGS Magnitude 4.5+ Earthquakes, Past Day",
        data: {
            url: "https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/4.5_day.geojson",
        }
    },
    {
        key: "usgs/Earthquakes/2.5",
        schema: EarthquakeFeedSchema,
        title: "USGS Magnitude 2.5+ Earthquakes, Past Day",
        data: {
            url: "https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/2.5_day.geojson",
        }
    },
    {
        key: "usgs/Earthquakes/1.0",
        schema: EarthquakeFeedSchema,
        title: "USGS Magnitude 1.0+ Earthquakes, Past Day",
        data: {
            url: "https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/1.0_day.geojson",
        }
    },    
];