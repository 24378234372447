import React from "react"
import { connect } from "react-redux"
import { goPush, goRefresh } from "../plugins/redux/actions"
import { Model } from "../plugins/state/Model"
import { ReactViewFactory } from "../plugins/react-view/ReactViewFactory"

// HACK
import { runtime } from "../plugins/plugins"
import { ReactViewMatcher } from "../plugins/react-view/ReactViewMatcher"
import { ReactViewMatcherContextKey } from "../plugins/react-view/ReactViewMatcherContextKey"
import { Payload } from "../plugins/payload/Payload"

// HACK
const matcher = runtime.get<ReactViewMatcher>(ReactViewMatcherContextKey);

/**
 * The properties of the home page component.
 */
interface HomePageProps {

    /**
     * The payload to show on the page.
     */
    model: Model,

    dispatch: any
};

class HomePageComponent extends React.Component<HomePageProps> {

    componentDidMount() {
        this.props.dispatch(goRefresh());
    }
     
    render() {

        const dispatch = this.props.dispatch;

        function onDispatch(p: Payload) {
            if (p && dispatch) {
                dispatch(goPush(p))
            }
        }
        
        return (
            <ReactViewFactory
                layout="page"
                dispatch={p => onDispatch(p)}
                matcher={matcher}
                model={this.props.model}></ReactViewFactory> 
        );
    }
}

/**
 * Sets properties of the component from application state.
 */
const mapStateToProps = (state: any) => {
    return { model: state.model };
};

const HomePage = connect(mapStateToProps)(HomePageComponent);
export default HomePage;
