import { Manifest } from "../../runtime/Manifest"
import { ServicePluginKey } from "./ServicePluginKey"

export const ServiceManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ServicePluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Service"
}