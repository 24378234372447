import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { RssFeed } from "../rss-feed/RssFeed"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class RssFeedIndexer implements Indexer<RssFeed> {

    public async index(payload: Payload<RssFeed>): Promise<Payload[]> {

        if ((payload?.schema === RssFeedSchemaId) && payload.data?.url) {

            return [
                {
                    schema: UrlSchemaId,
                    data: payload.data.url,
                    key: payload.data.url,
                    title: "RSS Feed",
                }
            ]
        }
        else {
            return [];
        }
    }
}