import { NasaApodFeedPoller } from "./NasaApodFeedPoller"
import { NasaApodFeed } from "../nasa-apod-feed/NasaApodFeed"
import { NasaApodFeedSchemaId } from "../nasa-apod-feed/NasaApodFeedSchemaId"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { PollerCreator } from "../poller-creator/PollerCreator"

export class NasaApodFeedPollerCreator implements PollerCreator<NasaApodFeed> {

    public async create(params: Payload<NasaApodFeed>): Promise<Poller | undefined> {

        if(params?.schema !== NasaApodFeedSchemaId) {
            return undefined;
        }

        const settings = params.data;
        if (!settings) {
            return undefined;
        }

        if (!settings.apiKey) {
            console.warn("missing API key");
            return undefined;
        }

        return new NasaApodFeedPoller(settings.apiKey);
    }
}