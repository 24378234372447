import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Cue } from "../cue/Cue"
import "./CueCardView.css"

const DEFAULT_CUE = "you got this";

export const CueCardView: React.FC<ReactViewProps<Cue>> = (props) => {

    const cue = props?.model?.payload?.data

    return (
        <div className="cue-card selectable">
            <div className="cue-card-title">{cue?.title ?? DEFAULT_CUE}</div>
        </div>
    )
}