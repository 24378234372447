import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NewsFeedPluginKey } from "../newsapi-feed/NewsFeedPluginKey"
import { NewsFeedPollerCreator } from "./NewsFeedPollerCreator"
import { NewsFeedSchemaId } from "../newsapi-feed/NewsFeedSchemaId"
import { NewsPluginKey } from "../newsapi/NewsPluginKey"
import { PollerCreatorPluginKey } from "../poller-creator/PollerCreatorPluginKey"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryEntry } from "../poller-creator-registry/PollerCreatorRegistryEntry"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"

export const NewsApiFeedPollerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */ 
    key: "newsapi-feed-poller",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NewsPluginKey,
        NewsFeedPluginKey,
        PollerPluginKey,
        PollerCreatorPluginKey,
        PollerCreatorRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "News articles from newsapi.com",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NewsFeedPollerCreator",
            registerWith: PollerCreatorRegistryContextKey,
            title: "News API Feed Poller Creator",
            value: {
                schema: NewsFeedSchemaId,
                creator: new NewsFeedPollerCreator()
            }
        } as ManifestEntry<PollerCreatorRegistryEntry<NewsFeedPollerCreator>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "News API Feed Poller"
};