import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { LinkAnnotator } from "./LinkAnnotator"
import { LinkAnnotatorContextKey } from "./LinkAnnotatorContextKey"
import { LinkPluginKey } from "../link/LinkPluginKey"
import { LinkSchemaId } from "../link/LinkSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const LinkAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "link-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        LinkPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: LinkAnnotatorContextKey,
            value: new LinkAnnotator()
        } as ManifestEntry<LinkAnnotator>,

        // registration
        {
            key: "LinkAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [LinkAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: LinkSchemaId,
                annotator: context[LinkAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<LinkAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Link Annotator"
}