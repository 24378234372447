export function parseTags(tags: string | string[] | undefined): string[] {

    if(!tags) {
        return [];
    }

    if (Array.isArray(tags)) {
        return [...tags];
    }

    return tags.split(" ");
}
