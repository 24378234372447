import { Manifest } from "../../runtime/Manifest"
import { PollerCreatorPluginKey } from "./PollerCreatorPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"

export const PollerCreatorManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: PollerCreatorPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PollerPluginKey
    ],

    /**
     * The display title of the manifest.
     */
    title: "Poller Creator"
}