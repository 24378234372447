import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { WhatsNew } from "../whats-new/WhatsNew"
import { WhatsNewLog } from "./WhatsNewLog"
import { WhatsNewLogContextKey } from "./WhatsNewLogContextKey"
import { WhatsNewLogPluginKey } from "./WhatsNewLogPluginKey"
import { WhatsNewPluginKey } from "../whats-new/WhatsNewPluginKey"

export const WhatsNewLogManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: WhatsNewLogPluginKey,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        WhatsNewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: WhatsNewLogContextKey,
            value: WhatsNewLog
        } as ManifestEntry<WhatsNew[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "What's New Log"
}