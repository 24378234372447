import { BingWallpaperFeedPluginKey } from "../bing-wallpaper-feed/BingWallpaperFeedPluginKey"
import { BingWallpaperFeedPollerCreator } from "./BingWallpaperFeedPollerCreator"
import { BingWallpaperFeedSchemaId } from "../bing-wallpaper-feed/BingWallpaperFeedSchemaId"
import { BingWallpaperPluginKey } from "../bing-wallpaper/BingWallpaperPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryEntry } from "../poller-creator-registry/PollerCreatorRegistryEntry"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"

export const BingWallpaperFeedPollerManifest: Manifest = {

    /** 
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-feed-poller",

    /**
     * Indicates whether the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BingWallpaperPluginKey,
        BingWallpaperFeedPluginKey,
        PollerPluginKey,
        PollerCreatorRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "A daily high-resolution from Bing",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "BingWallpaperFeedPollerCreator",
            registerWith: PollerCreatorRegistryContextKey,
            value: {
                schema: BingWallpaperFeedSchemaId,
                creator: new BingWallpaperFeedPollerCreator()
            }
        } as ManifestEntry<PollerCreatorRegistryEntry<BingWallpaperFeedPollerCreator>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Feed Poller",
};
