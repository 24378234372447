import { Payload } from "../payload/Payload"

export class CatalogRegistry {

    private payloads: Payload[];

    constructor(payloads: Payload[]) {
        if (!Array.isArray(payloads)) {
            throw new Error("payloads must be specified as an array");
        }

        this.payloads = payloads;
    }

    public register(item: Payload | Payload[]) {

        // Normalize to an array
        const arr = Array.isArray(item) ? item : [item];
        this.payloads.push(...arr);
    }

    public unregister(item: Payload | Payload[]) {

        // Normalize to an array
        const arr = Array.isArray(item) ? item : [item];
        for(let p of arr) {
            const index = this.payloads.indexOf(p);
            if (index !== -1) {
                this.payloads.splice(index, 1);
            }    
        }
    }
}