import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { ConfirmPluginKey } from "../confirm/ConfirmPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReopenTaskPluginKey } from "../reopen-task/ReopenTaskPluginKey"
import { ReopenTaskSchemaId } from "../reopen-task/ReopenTaskSchemaId"
import { ReopenTaskCardView } from "./ReopenTaskCardView"
import { SavePluginKey } from "../save/SavePluginKey"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"

export const ReopenTaskCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "reopen-task-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BooleanPluginKey,
        ConfirmPluginKey,
        ReactViewPluginKey,
        ReopenTaskPluginKey,
        SavePluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ReopenTaskCardView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: ReopenTaskSchemaId,
                layout: "stream",
                fc: ReopenTaskCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Reopen Task Card View"
}