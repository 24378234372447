import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Snippet } from "../snippet/Snippet"
import { SnippetSchemaId } from "../snippet/SnippetSchemaId"

const DEFAULT_TITLE = "Snippet";

export class SnippetAnnotator implements Annotator<Snippet> {

    public async annotate(payload: Payload<Snippet>): Promise<void> {

        if (payload?.schema !== SnippetSchemaId) {
            return;
        }

        const tags = payload.data?.tags;
        if (Array.isArray(tags)) {
            payload.title = tags.join(" or ");
        }
        else if (typeof(tags) === "string") {
            payload.title = tags;
        }
        else {
            payload.title = DEFAULT_TITLE;
        }
    }
}