import { ReactViewRegistryEntry } from "./ReactViewRegistryEntry"

/**
 * Compares two controls and returns a negative value if the first control should be
 * sorted before the other control, 0 if both controls are ranked equally, and a
 * positive value if the second control should be sorted before the first.
 */
export function compareReactViews(a: ReactViewRegistryEntry, b: ReactViewRegistryEntry): number {

    // Sort out undefined parameters
    if (!a) {
        if(b) {
            return 1;
        }
        else {
            return 0;
        }
    }
    else {
        if (!b) {
            return -1;
        }
    }

    switch(a.order) {
        case "first":
            if (b.order === "first") {
                return 0;
            }
            else {
                return -1;
            }

        case "last":
            if (b.order === "last") {
                return 0;
            }
            else {
                return 1;
            }
    }

    switch(b.order) {
        case "first":
            return 1;

        case "last":
            return -1;
    }

    const an = a.order ?? 0;
    const bn = b.order ?? 0;

    return an - bn;
}