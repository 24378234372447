import { ConverterPluginKey } from "../converter/ConverterPluginKey"
import { ConverterRegistry } from "./ConverterRegistry"
import { ConverterRegistryContextKey } from "./ConverterRegistryContextKey"
import { ConverterRegistryPluginKey } from "./ConverterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ConverterRegistryManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: ConverterRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConverterPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ConverterRegistryContextKey,
            value: new ConverterRegistry()
        } as ManifestEntry<ConverterRegistry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Converter Registry"
}