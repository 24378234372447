import React from "react"
import { IonCard, IonCardContent, IonChip, IonIcon, IonText } from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { UrlSchemaId } from "../url/UrlSchemaId"
import { WikipediaSummary } from "../wikipedia-summary/WikipediaSummary"
import "./WikipediaSummaryCardView.css"

export const WikipediaSummaryCardView: React.FC<ReactViewProps<WikipediaSummary>> = (props) => {

    const dispatch = props.dispatch;
    const summary = props?.model?.payload?.data as WikipediaSummary

    function onclick() {

        const payload: Payload = {
            schema: UrlSchemaId,
            data: summary.content_urls.desktop.page,
            title: summary.title
        }

        if (dispatch) {
            dispatch(payload);
        }
    }

    return (
        <>
            {summary &&
                <IonCard>
                    <IonCardContent>
                        <div className="WikipediaSummaryCard">
                            {summary.thumbnail &&
                                <img
                                    alt=""
                                    className="WikipediaSummaryThumbnail"
                                    src={summary.thumbnail.source} />
                            }
                            <div className="WikipediaSummaryContent">
                                <h1>{summary.title}</h1>
                                <p>
                                    {summary.extract}
                                </p>
                                <IonChip color="primary" outline={true} onClick={onclick}>
                                    <IonIcon src="/assets/Wikipedias_W.svg" slot=""></IonIcon>
                                    <IonText>{summary.title}</IonText>
                                </IonChip>
                            </div>
                        </div>
                    </IonCardContent>
                </IonCard>
            }
        </>
    );
}