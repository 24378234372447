import React, { useState } from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { RealityCheck } from "../reality-check/RealityCheck"
import "./RealityCheckCardView.css"

export const RealityCheckCardView: React.FC<ReactViewProps> = (props) => {

    const [ready, setReady] = useState(false);

    const realityCheck: RealityCheck | undefined = props?.model?.payload?.data;
    if (!realityCheck) {
        return <></>
    }
    
    if (!ready) {
        return (
            <div className="realitycheck">
                <button
                    className="realitycheck-button"
                    onClick={() => setReady(true)}>Are you dreaming?</button>
            </div>
        );
    }
    else {
        return (
            <div className="realitycheck">
                <span className="realitycheck-text">{realityCheck.title}</span>
            </div>
        );    
    }
};