import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { StringChipView } from "./StringChipView"
import { StringPluginKey } from "../string/StringPluginKey"
import { StringSchemaId } from "../string/StringSchemaId"

export const StringChipViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the string payload plugin.
     */
    key: "string-chip-view",

    /**
     * Indicates the string payload plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        ReactViewPluginKey,
        StringPluginKey
    ],

    /**
     * Returns the objects supplied by the plugin.
     */
    entries: [
        {
            key: "StringChipView",
            registerWith: ReactViewRegistryContextKey,
            title: "String Chip View",
            value: {
                layout: "chip",
                match: StringSchemaId,
                fc: StringChipView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "String Chip View"
}