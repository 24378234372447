import { Goal } from "../goal/Goal";
import { GoalSchemaId } from "../goal/GoalSchemaId";
import { Payload } from "../payload/Payload"
import { PayloadPredicates } from "../payload/PayloadPredicates"
import { Streamer } from "../streamer/Streamer"

const INTERVAL = 1000 * 60 * 60 * 6;

/**
 * A streamer that returns random active goals at an interval.
 */
export class GoalStreamer implements Streamer<Goal> {

    private interval: number;
    private lastSent: number | undefined;
    private payload: Payload<Goal>;

    /**
     * Initializes the goal streamer.
     */
    constructor(payload: Payload, interval: number = INTERVAL) {
        if (!(this.payload = payload)) {
            throw new Error("payload must be specified");
        }

        if (payload.schema !== GoalSchemaId) {
            throw new Error(`payload schema must be ${GoalSchemaId}`);
        }

        if (!(this.interval = interval)) {
            throw new Error(`interval must be a positive integer`)
        }
    }

    /**
     * Returns the next goal.
     */
    public async next(): Promise<Payload<Goal> | undefined> {

        const now = Date.now();
        if (this.lastSent !== undefined) {
            if (now - this.lastSent < this.interval) {
                return undefined;
            }
        }

        if (!PayloadPredicates.Active(this.payload)) {
            return undefined;
        }

        this.lastSent = now;
        return this.payload
    }
}