import React, { useState } from "react"
import { clone } from "../../library/clone"
import { ReactInput } from "../react-input/ReactInput"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"
import "./ObjectReactInput.css"

export const ObjectReactInput: React.FC<ReactInputProps> = (props) => {

    const [value, setValue] = useState(clone(props.value ?? props.schema.default) ?? {});

    // Validate the matcher prop
    const matcher = props.inputMatcher;
    if (typeof(matcher) !== "object") {
        return (
            <ReactInputFailure>
                matcher must be an object
            </ReactInputFailure>
        )
    }

    // Validate the schema prop
    const schema = props?.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema missing or not an object
            </ReactInputFailure>
        )
    }

    if (schema.type !== "object") {
        return (
            <ReactInputFailure>
                Schema type is unsupported: {schema.type}
            </ReactInputFailure>
        )
    }    

    // Validate the value prop
    if (typeof(value) !== "object") {
        return (
            <ReactInputFailure>
                Value must be an object but is type {typeof(props.value)}
            </ReactInputFailure>
        )
    }

    function onChange(property: string, propValue: any) {
        value[property] = propValue;
        setValue(value);
        if (props.onChange) {
            props.onChange(value);
        }
    }

    const controls = [];
    if (typeof(schema.properties) === "object") {

        for(const key in schema.properties) {

            // Get the schema of this property
            const propSchema = schema.properties[key];
            if (typeof(propSchema) !== "object") {
                continue;
            }

            let value: any = undefined;
            if (typeof(props.value) === "object") {
                value = props.value[key];
            }

            controls.push(
                <div className="ObjectEditorRow">
                    <div className="ObjectEditorLabel">{propSchema.title}</div>
                    <div className="ObjectEditorField">
                        <ReactInput
                            onChange={value => onChange(key, value)}
                            inputMatcher={matcher}
                            schema={propSchema}
                            value={value}></ReactInput>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="ObjectEditor">
            {controls}
        </div>
    )
}