import { Annotator } from "../annotator/Annotator"
import { NewsFeed } from "../newsapi-feed/NewsFeed"
import { NewsFeedSchemaId } from "../newsapi-feed/NewsFeedSchemaId"
import { Payload } from "../payload/Payload"

export class NewsFeedAnnotator implements Annotator<NewsFeed> {

    public async annotate(payload: Payload<NewsFeed>): Promise<void> {

        if (payload?.schema !== NewsFeedSchemaId) {
            return;
        }

        if (typeof(payload.data) !== "object") {
            return;
        }

        const country = (payload.data.country ?? "US").toUpperCase();

        payload.title = `News Headlines (${country})`;
    }

}