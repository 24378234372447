import React from "react"
import { folderOutline as icon } from "ionicons/icons"
import { IonIcon } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Folder } from "../folder/Folder"
import "./FolderPreviewView.css"

export const FolderPreviewView: React.FC<ReactViewProps> = (props) => {

    const folder = props?.model?.payload?.data as Folder;

    return (
        <div className="folder-preview">
            <IonIcon style={{
                width:"64px",
                height: "64px"
            }} icon={icon} />
            <h1>{folder?.name}</h1>
        </div>
    )
}