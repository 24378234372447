import { ActivityTrackerContextKey } from "../activity-tracker/ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "../activity-tracker/ActivityTrackerPluginKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { TaskStreamer } from "./TaskStreamer"
import { TaskStreamerContextKey } from "./TaskStreamerContextKey"
import { TaskStreamerPluginKey } from "./TaskStreamerPluginKey"

export const TaskStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: TaskStreamerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityTrackerPluginKey,
        DefaultStorePluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey,
        TaskPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Streams tasks that need to be finished",

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // streamer
        {
            key: TaskStreamerContextKey,
            requires: [ActivityTrackerContextKey, DefaultStoreContextKey],
            value: (context: ManifestContext) =>
                new TaskStreamer(
                    context[DefaultStoreContextKey],
                    context[ActivityTrackerContextKey])
        } as ManifestEntry<TaskStreamer>,

        // registration
        {
            key: "TaskStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: [TaskStreamerContextKey],
            value: (context: ManifestContext) => ({
                streamer: context[TaskStreamerContextKey]
            })
        } as ManifestEntry<StreamerRegistryEntry<TaskStreamer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Task Streamer"
}