import { MathProblem } from "../math-problem/MathProblem"
import { MathProblemSchemaId } from "../math-problem/MathProblemSchemaId";
import { Payload } from "../payload/Payload"
import { Streamer } from "../streamer/Streamer"


/**
 * A streamer that returns math problems.
 */
export class MathProblemStreamer implements Streamer<MathProblem> {

    /**
     * Returns the next math problem.
     */
    public async next(): Promise<Payload<MathProblem> | undefined> {

        function randomNumber() {
            return Math.floor(Math.random() * (99 - 10 + 1) + 10);
        };

        const operand1: number = randomNumber();
        const operand2: number = randomNumber();
        const operator = "+";

        return {
            schema:MathProblemSchemaId,
            data: {
                operator,
                operands: [operand1, operand2],
            }
        };
    }
}