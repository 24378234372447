import { ActivityTrackerContextKey } from "../activity-tracker/ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "../activity-tracker/ActivityTrackerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RandomRegistryContextKey } from "../random-registry/RandomRegistryContextKey"
import { RandomRegistryPluginKey} from "../random-registry/RandomRegistryPluginKey"
import { SplashArrayContextKey } from "../splash-array/SplashArrayContextKey"
import { SplashArrayPluginKey } from "../splash-array/SplashArrayPluginKey"
import { SplashPluginKey } from "../splash/SplashPluginKey"
import { SplashStreamer } from "./SplashStreamer"
import { SplashStreamerContextKey } from "./SplashStreamerContextKey"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"

export const SplashStreamerManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: "splash-streamer",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityTrackerPluginKey,
        RandomRegistryPluginKey,
        SplashArrayPluginKey,
        SplashPluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // streamer
        {
            key: SplashStreamerContextKey,
            requires: [
                SplashArrayContextKey,
                RandomRegistryContextKey,
                ActivityTrackerContextKey
            ],
            value: (context: ManifestContext) =>
                new SplashStreamer(
                    context[SplashArrayContextKey],
                    context[RandomRegistryContextKey],
                    context[ActivityTrackerContextKey])
        } as ManifestEntry<SplashStreamer>,

        // registration
        {
            key: "SplashStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: [SplashStreamerContextKey],
            value: (context: ManifestContext) => ({
                streamer: context[SplashStreamerContextKey]
            })
        } as ManifestEntry<StreamerRegistryEntry<SplashStreamer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Splash Streamer"
}