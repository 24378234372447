import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NoticeAttachmentNotifier } from "./NoticeAttachmentNotifier"
import { NoticePluginKey } from "../notice/NoticePluginKey"
import { NotifierArrayContextKey } from "../notifier-registry/NotifierArrayContextKey"
import { NotifierPluginKey } from "../notifier/NotifierPluginKey"
import { NotifierRegistryPluginKey } from "../notifier-registry/NotifierRegistryPluginKey"

export const NoticeAttachmentNotifierManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "notice-attachment-notifier",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NotifierPluginKey,
        NotifierRegistryPluginKey,
        NoticePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "NoticeAttachmentNotifier",
            registerWith: NotifierArrayContextKey,
            value: new NoticeAttachmentNotifier()
        } as ManifestEntry<NoticeAttachmentNotifier>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Notice Attachment Notifier"
}