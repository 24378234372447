import { Annotator } from "../annotator/Annotator"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Payload } from "../payload/Payload"

const DEFAULT_TITLE = "Confirm";

export class ConfirmAnnotator implements Annotator<Confirm> {

    public async annotate(payload: Payload<Confirm>): Promise<void> {
        if (payload?.schema === ConfirmSchemaId) {
            payload.title = payload?.data?.title ?? payload.title ?? DEFAULT_TITLE;
        }
    }
}