import React from "react"
import { IonInput } from "@ionic/react";

export interface TextFilterProps {
    /**
     * The current text criteria.
     */
    value: string;

    /**
     * The function to call when the value changes.
     */
    onChange: (s: string) => void;
}

export const TextFilter: React.FC<TextFilterProps> = (props) => {

    function onChange(s: string) {
        if (props.onChange) {
            props.onChange(s);
        }
    }

    return (
        <IonInput
            onIonChange={e => onChange(e.detail.value ?? "")}
            placeholder="enter search text"
            value={props.value}></IonInput>
    );   
}