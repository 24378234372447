import { SelectorState } from "./SelectorState"

export abstract class Selector<T = any> {

    /**
     * Selects the next item from an array of items.
     * 
     * @param items An array of items. The selector will return one or none of these.
     * @param state Optional state used by the selector to track state across multiple calls.
     */
    public abstract select(items: T[], state?: SelectorState): Promise<T | undefined>;
}