import React, { useEffect, useState } from "react"
import { Confirm } from "../confirm/Confirm"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { Store } from "../store/Store"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

/**
 * The extended properties of the card.
 */
interface SaveCardProps extends ReactViewProps {
    store: Store;
}

export const SaveCardView: React.FC<SaveCardProps> = (props) => {

    const dispatch = props.dispatch;
    const save = props?.model?.payload?.data as Save;
    const store = props.store;

    // Track whether the user has confirmed the operation
    const [confirmed, setConfirmed] = useState(save?.confirmed ?? false);

    useEffect(() => {

        let mounted = true;

        if (confirmed && save?.payload && store) {
            store.put(save.payload).then(p => {
                if (dispatch && mounted && p) {
                    dispatch(p);
                }
            });
        }

        return () => { mounted = false };

    }, [confirmed, dispatch, save, store]);

    /**
     * Executed when the user confirms or declines.
     */
     function onConfirm(selectedPayload: Payload) {
        if (selectedPayload.data === true) {
            setConfirmed(true);
        }
        else {
            if (dispatch) {
                dispatch(save.payload);
            }
        }
    }

    /**
     * Selects a view based on the state of the view.
     */
     function selectView() {
        if (confirmed) {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={undefined}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Saving..."
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
        else {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={p => onConfirm(p)}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: ConfirmSchemaId,
                        data: {
                            payload: save.payload,
                            title: "Save"
                        } as Confirm
                    }}}
                ></ReactViewFactory>
            )
        }
    }

    return selectView()
}