import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NumberIndexer } from "./NumberIndexer"
import { NumberIndexerContextKey } from "./NumberIndexerContextKey"
import { NumberPluginKey } from "../number/NumberPluginKey"
import { NumberSchemaId } from "../number/NumberSchemaId"

export const NumberIndexerManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: "number-indexer",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        NumberPluginKey,
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        
        // indexer
        {
            key: NumberIndexerContextKey,
            value: new NumberIndexer()
        } as ManifestEntry<NumberIndexer>,

        // registration
        {
            key: "NumberIndexerRegistryEntry",
            registerWith: IndexerRegistryContextKey,
            requires: [NumberIndexerContextKey],
            value: (context: ManifestContext) => ({
                schema: NumberSchemaId,
                indexer: context[NumberIndexerContextKey]
            })
        } as ManifestEntry<IndexerRegistryEntry<NumberIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Number Indexer"
}