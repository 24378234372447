import { NoticeSchemaId } from "./NoticeSchemaId"
import { Schema } from "../schema/Schema"

export const NoticeSchema: Schema = {

    "$id": NoticeSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "A notice or alert",
    title: "Notice",

    properties: {

        "title": {
            type: "string",
            description: "The heading of the notice.",
            title: "Title"
        },

        "level": {
            type: "string",
            description: "The level of severity of the notice.",
            enum: ["debug", "info", "warn"],
            title: "Level"
        }
    }
}