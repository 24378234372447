import { Annotator } from "../annotator/Annotator"
import { BingWallpaper } from "../bing-wallpaper/BingWallpaper"
import { BingWallpaperSchemaId } from "../bing-wallpaper/BingWallpaperSchemaId"
import { Payload } from "../payload/Payload"

export class BingWallpaperAnnotator implements Annotator {

    public async annotate(payload: Payload): Promise<void> {

        if (!payload || payload?.schema !== BingWallpaperSchemaId) {
            return;
        }

        const image = payload.data as BingWallpaper;
        if (!image) {
            return;
        }

        payload.key = image.hsh;
        payload.title = image.title;
    }
}