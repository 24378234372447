import { Annotator } from "../annotator/Annotator"
import { FormatterRegistry } from "../formatter-registry/FormatterRegistry"
import { Payload } from "../payload/Payload";

export class FormatterAnnotator implements Annotator {

    private registry: FormatterRegistry;

    constructor(registry: FormatterRegistry) {
        if (!(this.registry = registry)) {
            throw new Error("formatter registry must be specified");
        }
    }
    
    public async annotate(payload: Payload): Promise<void> {

        const schema = payload?.schema;
        if (!schema) {
            return;
        }

        const entry = this.registry.get(schema);
        if (!entry) {
            return;
        }

        const title = entry.formatter.format(schema, payload?.data);
        if (title) {
            payload.title = title;
        }
    }
}