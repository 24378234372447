import { Annotator } from "../annotator/Annotator"
import { Button } from "../button/Button"
import { ButtonSchemaId } from "../button/ButtonSchemaId"
import { Payload } from "../payload/Payload"

const DEFAULT_TITLE = "Button";

export class ButtonAnnotator implements Annotator<Button> {

    public async annotate(payload: Payload<Button>): Promise<void> {

        if (payload?.schema !== ButtonSchemaId) {
            return;
        }

        if (payload.data) {
            payload.title = payload.data.title ?? DEFAULT_TITLE;
        }
    }
}