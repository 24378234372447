import React from "react"
import { IonInput } from "@ionic/react"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"
import { TextFieldTypes } from "@ionic/core"

export const StringReactInput: React.FC<ReactInputProps> = (props) => {

    const schema = props.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema is missing or not an object
            </ReactInputFailure>
        )
    }

    if (schema.type !== "string") {
        return (
            <ReactInputFailure>
                Schema type is unsupported: {schema.type}
            </ReactInputFailure>
        )
    }

    const valueStr = props.value ?? schema.default ?? "";
    if (typeof(valueStr) !== "string") {
        return (
            <ReactInputFailure>
                Value must be a string instead of {typeof(props.value)}
            </ReactInputFailure>
        )
    }

    let inputType: TextFieldTypes | undefined;
    switch(schema.format) {
        case "email":
            inputType = "email"
            break;
        case "uri":
            inputType = "url";
            break;
    }

    function onChange(v: string | undefined) {
        if (props.onChange) {
            props.onChange(v);
        }
    }

    return (
        <IonInput
            onIonChange={e => onChange(e.detail.value ?? undefined)}
            placeholder={schema.description}
            type={inputType}
            value={valueStr}></IonInput> 
    )
}