import React, { useEffect, useState } from "react"
import { IonButton } from "@ionic/react"
import { isExcluded } from "./isExcluded"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { TreeItemProps } from "./TreeItemProps"
import { Waiting } from "../waiting/Waiting"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"
import "./TreeItem.css"



export const TreeItem: React.FC<TreeItemProps> = (props) => {

    const item = props?.model?.payload;
    const excluded = isExcluded(item, props.excluded);
    const onChildren = props.onChildren;
    const [expanded, setExpanded] = useState(props.expanded ?? false);
    const [childItems, setChildItems] = useState<Payload[]>();

    useEffect(() => {

        let mounted = true;

        if (!excluded && item && onChildren) {
            onChildren(item).then(payloads => {
                if (mounted) {
                    setChildItems(payloads);
                }
            });
        }

        return () => { mounted = false };

    }, [excluded, item, onChildren]);

    /**
     * Returns the header content
     */
    function Header() {
        return (
            <div className="tree-item-header">
                <HeaderEmblem />
                <HeaderTitle />
                <HeaderSelect />
            </div>
        );
    }

    /**
     * Returns an emblem representing the item.
     */
     function HeaderEmblem() {
        return (
            <div className="tree-item-header-emblem">
                <ReactViewFactory
                    dispatch={undefined}
                    matcher={props.matcher}
                    layout="emblem"
                    multi={false}
                    model={{ payload: item }}
                />
            </div>
        );
    }

    /**
     * Returns a button for selecting the item.
     */
    function HeaderSelect() {

        function onClick() {
            if (props.dispatch && item) {
                props.dispatch(item);
            }
        }

        if (excluded) {

            // This item is in the exclusion list. Do not allow selection.
            return <></>
        }
        else {
            return (
                <div className="tree-item-header-select">
                    <IonButton onClick={() => onClick()}>Select</IonButton>
                </div>
            )    
        }
    }

    /**
     * Returns the title of the item. Tapping the title highlights the item.
     */
    function HeaderTitle() {

        function onClick() {
            setExpanded(prev => !prev);
        }

        return (
            <div
                    className="tree-item-header-title"
                    onClick={() => onClick()}
                    role="button"
                    tabIndex={0}>
                {item?.title ?? "<top>"}
            </div>
        );
    }

    /**
     * Returns a view containing the inner content of the tree (e.g, list of children).
     */
    function Panel() {
        return (
            <>
                {expanded &&
                    <div className="tree-item-panel">
                        <PanelList />
                    </div>
                }
            </>
        );
    }

    /**
     * Returns the list of child payloads.
     */
    function PanelList() {

        return (
            <div className="tree-item-panel-list">
                {Array.isArray(childItems) && childItems.map(p =>
                    <TreeItem
                        dispatch={props.dispatch}
                        excluded={props.excluded}
                        expanded={false}
                        matcher={props.matcher}
                        onChildren={props.onChildren}
                        model={{ payload: p }}
                    ></TreeItem>
                )}
                {!Array.isArray(childItems) &&
                    <ReactViewFactory
                        layout="card"
                        dispatch={undefined}
                        matcher={props.matcher}
                        model={{
                            payload: {
                                schema: WaitingSchemaId,
                                data: {
                                    title: "Loading..."
                                }
                            } as Payload<Waiting>
                        }}>
                    </ReactViewFactory>
                }
            </div>
        );     
    }

    return (
        <div className="tree-item">
            <Header />
            <Panel />
        </div>
    );
}