import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { CueAnnotator } from "./CueAnnotator"
import { CueAnnotatorContextKey } from "./CueAnnotatorContextKey"
import { CuePluginKey } from "../cue/CuePluginKey"
import { CueSchemaId } from "../cue/CueSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const CueAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifie the plugin.
     */
    key: "cue-annotator",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        CuePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: CueAnnotatorContextKey,
            value: new CueAnnotator()
        } as ManifestEntry<CueAnnotator>,

        // registration
        {
            key: "CueAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [CueAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: CueSchemaId,
                annotator: context[CueAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<CueAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Cue Annotator"
}