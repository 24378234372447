import { BingWallpaperFeedSchemaId } from "./BingWallpaperFeedSchemaId"
import { Schema } from "../schema/Schema"

export const BingWallpaperFeedSchema: Schema = {

    "$id": BingWallpaperFeedSchemaId,
    title: "Bing Wallpaper Feed",
    type: "object",
    properties: {
        "locale": {
            default: "en-us",
            title: "Locale",
            type: "string"
        }
    }
}