import { MovePluginKey } from "./MovePluginKey"
import { Manifest } from "../../runtime/Manifest"

export const MoveManifest: Manifest = {

    /**
     * The key that uniquely identifies the search box plugin.
     */
    key: MovePluginKey,

    /**
     * Indicates whether the plugin should be installed at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Move"
}