import React, { useEffect, useState } from "react"
import { CompleteTask } from "../complete-task/CompleteTask"
import { ConfirmSchemaId } from "../confirm/ConfirmSchemaId"
import { markComplete } from "../task/markComplete"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps  } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export const CompleteTaskCardView: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;
    const complete = props.model?.payload?.data as CompleteTask;
    const taskPayload = complete.payload;

    // Track whether the user has confirmed the completion
    const [confirmed, setConfirmed] = useState(complete?.confirmed ?? false);

    useEffect(() => {

        let mounted = true;
        if (confirmed) {

            markComplete(taskPayload.data);

            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload: taskPayload,
                    confirmed: true
                }
            }
            
            if (dispatch && mounted) {
                dispatch(savePayload);
            }
        }

        return () => { mounted = false };

    }, [dispatch, confirmed, taskPayload]);

    /**
     * Executed when the user confirms or declines.
     */
    function onConfirm(selectedPayload: Payload) {
        if (selectedPayload.data === true) {
            setConfirmed(true);
        }
        else {
            if (dispatch) {
                dispatch(taskPayload);
            }
        }
    }

    /**
     * Selects a view based on the state of the view.
     */
    function selectView() {
        if (confirmed) {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={undefined}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: WaitingSchemaId,
                        data: {
                            title: "Completing..."
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
        else {
            return (
                <ReactViewFactory
                    layout="stream"
                    dispatch={p => onConfirm(p)}
                    matcher={props.matcher}
                    model={{ payload: {
                        schema: ConfirmSchemaId,
                        data: {
                            payload: taskPayload,
                            title: "Completed?"
                        }
                    }}}
                ></ReactViewFactory>
            )
        }
    }

    return selectView()
}