import { fetchJson } from "../../library/fetchJson"
import { isToday } from "../../library/isToday"
import { SunriseSunsetFeed } from "./SunriseSunsetFeed"
import { Payload } from "../payload/Payload"
import { Streamer } from "../streamer/Streamer"
import { SunriseSunsetResponse } from "./SunriseSunsetResponse"
import { SunriseSunsetSchemaId } from "./SunriseSunsetSchemaId"

/**
 * A streamer that returns information about the current sunrise or sunset.
 */
export class SunriseSunsetStreamer implements Streamer {

    /** Indicates whether the service is being called */
    private calling?: Promise<SunriseSunsetResponse | undefined>;

    /** The location from which to determine the sunrise and sunset */
    private feed: SunriseSunsetFeed;

    /** The date the sunrise/sunset was last supplied */
    private lastSupplied?: number;

    /** The last response from the sunrise/sunset API */
    private response?: SunriseSunsetResponse;

    /**
     * Initializes the sunrise/sunset streamer with the specified location.
     */
    constructor(feed: SunriseSunsetFeed) {
        if (!feed) {
            this.feed = {
                latitude: 0,
                longitude: 0
            }
        }
        else {
            this.feed = feed;
        }
    }

    /**
     * Converts a API service response to a payload.
     */
    public convert(data: SunriseSunsetResponse): Payload {
        return {
            schema: SunriseSunsetSchemaId,
            data,
            key: new Date().toDateString(),
            title: "Sunrise and Sunset"
        };
    }

    /**
     * Returns true if the current image is missing or expired
     */
    public get expired(): boolean {

        if (this.response === undefined) {
            return true;
        }

        if (this.response.status !== "OK") {
            return true;
        }

        
        // The date is specified as a string like "2020-09-01T13:29:44+00:00"
        const sunrise = Date.parse(this.response.results.sunrise);
        return !isToday(sunrise);
    }

    /**
     * Fetches the daily image (even if already loaded).
     */
    public async fetch(): Promise<SunriseSunsetResponse | undefined> {

        // See if a fetch is already in progress
        if (this.calling !== undefined) {
            return this.calling;
        }

        // Get the web service URL
        const url = this.url(this.feed.latitude, this.feed.longitude);

        // Call the bing JSON service, which does require CORS as of November 2020.
        return this.calling = fetchJson<SunriseSunsetResponse>(url, false).then(result => {

            // The URL is constructed to return exactly one image (today's).
            this.calling = undefined;
            return this.response = result;
        });
    }

    /**
     * Returns the next payload from the streamer, or undefined if one is not available.
     */
    public async next(): Promise<Payload | undefined> {

        // See if a service call is in progress
        if (this.calling !== undefined) {
            return undefined;
        }

        // Dispatch a service call if needed.
        if (this.expired) {
            this.fetch();
            return undefined;
        }

        // Only return once per day.
        if (isToday(this.lastSupplied)) {
            return undefined;
        }

        // The response hasn't expired, and it hasn't been returned today.
        this.lastSupplied = Date.now();
        return this.convert(this.response!);
    }

    /**
     * Returns the URL of the web service that returns sunrise/sunset information.
     */
    public url(latitude: number, longitude: number): string {

        // Get today in YYYY-MM-DD format
        const today = new Date().toISOString().split("T")[0];

        return `https://api.sunrise-sunset.org/json?lat=${latitude}&lng=${longitude}&formatted=0&date=${today}`;
    }
}