import { Manifest } from "../../runtime/Manifest"
import { NewsPluginKey } from "./NewsPluginKey"

export const NewsApiManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: NewsPluginKey,

    /**
     * The display title of the plugin.
     */
    title: "News API"
}