import { Payload } from "../payload/Payload"
import { SubredditFeed } from "../subreddit-feed/SubredditFeed"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId";

/**
 * The catalog of reddit feeds that can be added by the user.
 */
export const SubredditFeedCatalog: Payload<SubredditFeed>[] = [
    {
        key: "r/bestof",
        schema: SubredditFeedSchemaId,
        title: "r/bestof",
        data: {
            subreddit: "bestof"
        },
    },
    {
        key: "r/coolguides",
        schema: SubredditFeedSchemaId,
        title: "r/coolguides",
        data: {
            subreddit: "coolguides"
        },
    },
    {
        key: "r/EarthPorn",
        schema: SubredditFeedSchemaId,
        title: "r/EarthPorn",
        data: {
            subreddit: "EarthPorn"
        },
    },
    {
        key: "r/UpliftingNews",
        schema: SubredditFeedSchemaId,
        title: "r/UpliftingNews",
        data: {
            subreddit: "UpliftingNews"
        },
    }
];