import React from "react"
import {
    IonChip,
    IonIcon,
    IonLabel
} from "@ionic/react"
import { logoReddit } from "ionicons/icons"
import { ReactViewProps } from "../react-view/ReactViewProps"

/**
 * The maximum length of the chip title. If a posting has a longer title,
 * then the title is clipped to the maximum length.
 */
const MAX_TITLE_LENGTH = 40;

/**
 * Clips the specified string to the maximum length.
 */
function clip(value: string | undefined, length: number): string | undefined {
    if (!value) {
        return value;
    }
    else {
        return value.substr(0, length) + "...";
    }
};

/**
 * Renders a payload link into a button.
 */
export const RedditThingChipView: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;

    const payload = props.model?.payload;
    if (!payload) {
        return <></>
    }

    // Clip the title in order to make it suitable as a chip caption.
    const title = clip(payload.title, MAX_TITLE_LENGTH);

    const onClick = () => {
        if (dispatch) {
            dispatch(payload);
        }
    };

    return (
        <IonChip onClick={() => onClick()}>
            <IonIcon icon={logoReddit} style={{color: "white"}}></IonIcon>
            <IonLabel>{title}</IonLabel>
        </IonChip>
    );
}