import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { PollerService } from "../poller-service/PollerService"
import { Store } from "../store/Store"
import { StoreStreamer } from "../store-streamer/StoreStreamer"
import { Streamer } from "../streamer/Streamer"

/**
 * Base class for implementing poll-oriented streamer with local storage
 */
export class PollerStreamer implements Streamer {

    /** The service that polls the poller and stores payloads in a folder */
    private service: PollerService;

    /** The inner streamer that returns payloads from a folder */
    private streamer: StoreStreamer;
        
    /**
     * Initializes the poller streamer with the specified parameters.
     */
    constructor(
            poller: Poller,
            store: Store,
            parent: Payload | undefined,
            interval: number,
            autostart: boolean = false) {

        if (!poller) {
            throw new Error("poller must be specified");
        }

        if (!store) {
            throw new Error("store must be specified")
        }

        this.service = new PollerService(poller, store, parent, interval);
        this.streamer = new StoreStreamer(store, parent?.id);

        if (autostart) {
            this.service.start();
        }
    }

    /**
     * Returns the next thing from the subreddit.
     */
    public async next(): Promise<Payload | undefined> {
        return this.streamer.next();
    }   
}