/**
 * The map styles supported in a Bing Map URL. The style
 * is specified with the query string parameter "style".
 */
export enum BingMapStyle {

    /**
     * Aerial view of the map.
     */
    Aerial = "a",

    /**
     * Aerial view with labels.
     */
    AerialWithLabels = "h",

    /**
     * Birds eye (oblique) view.
     */
    BirdsEye = "o",

    /**
     * Birds eye (oblique) view with labels.
     */
    BirdsEyeWithLabels = "b",

    /**
     * Road view of the map.
     */
    Road = "r"
}
