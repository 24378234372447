import { Payload } from "../payload/Payload"
import { PollerCreator } from "../poller-creator/PollerCreator"
import { SubredditFeedPoller } from "./SubredditFeedPoller"
import { SubredditFeed } from "../subreddit-feed/SubredditFeed"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId"

export class SubredditFeedPollerCreator implements PollerCreator<SubredditFeed> {

    /**
     * Creates an instance of the subreddit poller.
     */
    public async create(payload: Payload<SubredditFeed>): Promise<SubredditFeedPoller | undefined> {

        if (payload?.schema !== SubredditFeedSchemaId) {
            return undefined;
        }

        return new SubredditFeedPoller(payload.data.subreddit);
    }
}
 