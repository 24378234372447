import { BingWallpaper } from "../bing-wallpaper/BingWallpaper"
import { BingWallpaperSchemaId } from "../bing-wallpaper/BingWallpaperSchemaId"
import { Copyright } from "../copyright/Copyright"
import { CopyrightSchemaId } from "../copyright/CopyrightSchemaId"
import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class BingWallpaperIndexer implements Indexer {

    public async index(payload: Payload): Promise<Payload[]> {

        if (!payload) {
            return [];
        }

        if (payload.schema !== BingWallpaperSchemaId) {
            return [];
        }

        const hpimage = payload.data as BingWallpaper;
        if (!hpimage) {
            return [];
        }

        const copyrightPayload: Payload<Copyright> = {
            schema: CopyrightSchemaId,
            key: hpimage.hsh,
            data: {
                notice: hpimage.copyright
            }
        }

        const linkPayload: Payload<string> = {
            schema: UrlSchemaId,
            key: hpimage.copyrightlink,
            data: hpimage.copyrightlink,
            title: hpimage.title
        }

        return [copyrightPayload, linkPayload];
    }
}