import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ISO4217CodeArray } from "../iso-4217-code/ISO4217CodeArray"
import { ISO4217CodeArrayPluginKey } from "./ISO4217CodeArrayPluginKey"
import { ISO4217CodeArrayContextKey } from "./ISO4217CodeArrayContextKey"
import { ISO4217CodePluginKey } from "../iso-4217-code/ISO4217CodePluginKey"

export const Iso4217CodeArrayManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ISO4217CodeArrayPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ISO4217CodePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ISO4217CodeArrayContextKey,
            value: ISO4217CodeArray
        } as ManifestEntry<string[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "ISO 4127 Code Array"
}