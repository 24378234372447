import { Annotator } from "../annotator/Annotator"
import { BingWallpaperFeed } from "../bing-wallpaper-feed/BingWallpaperFeed"
import { BingWallpaperFeedSchemaId } from "../bing-wallpaper-feed/BingWallpaperFeedSchemaId"
import { Payload } from "../payload/Payload"

export class BingWallpaperFeedAnnotator implements Annotator<BingWallpaperFeed>{

    public async annotate(payload: Payload<BingWallpaperFeed>): Promise<void> {
        if (payload?.schema === BingWallpaperFeedSchemaId) {
            payload.title = "Bing Wallpaper Feed";
            if (payload?.data?.locale) {
                payload.title += ` (${payload.data.locale})`
            }
        }
    }

}