import { ActivityTrackerContextKey } from "../activity-tracker/ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "../activity-tracker/ActivityTrackerPluginKey"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { DefaultTasks } from "./DefaultTasks"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"
import { SuggestedTasksCard } from "./SuggestedTasksCard"
import { SuggestedTasksPluginKey } from "./SuggestedTasksPluginKey"
import { SuggestedTasksSchemaId } from "./SuggestedTasksSchemaId"
import { SuggestedTasksStreamer } from "./SuggestedTasksStreamer"
import { TaskPluginKey } from "../task/TaskPluginKey"

export const TaskSuggestionsManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SuggestedTasksPluginKey,

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityTrackerPluginKey,
        DefaultStorePluginKey,
        ReactViewPluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey,
        TaskPluginKey,
    ],

    /**
     * The display description of the plugin.
     */
    description: "Suggests tasks",

    /**.
     * The objects provided by the plugin.
     */
    entries: [

        // streamer
        {
            key: "SuggestedTasksStreamer",
            requires: [ActivityTrackerContextKey, DefaultStoreContextKey],
            value: (context: ManifestContext) =>
                new SuggestedTasksStreamer(
                    DefaultTasks,
                    context[ActivityTrackerContextKey],
                    context[DefaultStoreContextKey])
        },

        // registration
        {
            key: "SuggestedTasksStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: ["SuggestedTasksStreamer"],
            value: (context: ManifestContext) => ({
                streamer: context["SuggestedTasksStreamer"]
            })
        } as ManifestEntry<StreamerRegistryEntry<SuggestedTasksStreamer>>,

        {
            key: "SuggestedTasksCard",
            registerWith: ReactViewRegistryContextKey,
            requires: [DefaultStoreContextKey],
            title: "Suggested Tasks Card",
            value: (context: ManifestContext) => ({
                layout: "card",
                match: SuggestedTasksSchemaId,
                fc: (props: ReactViewProps) =>
                    SuggestedTasksCard({...props, store: context[DefaultStoreContextKey]})
            })
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],    

    /**
     * The display title of the plugin.
     */
    title: "Suggested Tasks",
}