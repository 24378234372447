import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { ConvertCommander } from "./ConvertCommander"
import { ConverterRegistryContextKey } from "../converter-registry/ConverterRegistryContextKey"
import { ConverterRegistryPluginKey } from "../converter-registry/ConverterRegistryPluginKey"
import { ConvertPluginKey } from "../convert/ConvertPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ConvertCommanderManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "convert-commander",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderRegistryPluginKey,
        ConvertPluginKey,
        ConverterRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ConvertCommander",
            registerWith: CommanderArrayContextKey,
            requires: [ConverterRegistryContextKey],
            value: (context: ManifestContext) => 
                new ConvertCommander(context[ConverterRegistryContextKey])
        } as ManifestEntry<ConvertCommander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Convert Commander"
}