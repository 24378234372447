import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NewsArticleCardView } from "./NewsArticleCardView"
import { NewsArticleSchemaId } from "../newsapi/NewsArticleSchemaId"
import { NewsPluginKey } from "../newsapi/NewsPluginKey"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const NewsApiArticleCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */ 
    key: "newsapi-article-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NewsPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "News articles from newsapi.com",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NewsArticleCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "NewsAPI Card View",
            value: {
                layout: "card",
                match: NewsArticleSchemaId,
                fc: NewsArticleCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NewsAPI Article Card View"
};