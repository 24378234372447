import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./DateNumberEmblemView.css"

export const DateNumberEmblemView: React.FC<ReactViewProps> = (props) => {

    // Get the numeric value of the date
    const n = props?.model?.payload?.data as number;

    // Convert the number to a date object
    const d = typeof(n) === "number" ? new Date(n) : undefined;

    const month = d?.toLocaleDateString("default", { month: "short"});
    const day = d?.getDate();

    return (
        <div className="date-number-emblem-view">
            <div className="date-number-emblem-view-month">{month}</div>
            <div className="date-number-emblem-view-day">{day}</div>
        </div>
    )
}