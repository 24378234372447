import { NWSAlertFeed } from "../nws-alert-feed/NWSAlertFeed"
import { NWSAlertFeedPoller } from "./NWSAlertFeedPoller"
import { NWSAlertFeedSchemaId } from "../nws-alert-feed/NWSAlertFeedSchemaId"
import { Payload } from "../payload/Payload"
import { PollerCreator } from "../poller-creator/PollerCreator"

export class NWSAlertFeedPollerCreator implements
        PollerCreator<NWSAlertFeed, NWSAlertFeedPoller> {

    public async create(params: Payload<NWSAlertFeed>): Promise<NWSAlertFeedPoller | undefined> {

        if (params?.schema !== NWSAlertFeedSchemaId) {
            return undefined;
        }

        const settings = params.data;
        if (!settings) {
            return undefined;
        }

        return new NWSAlertFeedPoller(settings);
    }
}