import { EmblemView } from "./EmblemView"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const EmblemViewManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: "emblem-view",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "EmblemView",
            registerWith: ReactViewRegistryContextKey,
            title: "Emblem View",
            value: {
                layout: "emblem",
                match: "*",
                fc: EmblemView,
                order: "last"
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Emblem View"
}