import { BingWallpaperIndexer } from "./BingWallpaperIndexer"
import { BingWallpaperPluginKey } from "../bing-wallpaper/BingWallpaperPluginKey"
import { BingWallpaperSchemaId } from "../bing-wallpaper/BingWallpaperSchemaId"
import { CopyrightPluginKey } from "../copyright/CopyrightPluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const BingWallpaperIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bing-wallpaper-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BingWallpaperPluginKey,
        CopyrightPluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "BingWallpaperIndexer",
            registerWith: IndexerRegistryContextKey,
            value: {
                schema: BingWallpaperSchemaId,
                indexer: new BingWallpaperIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<BingWallpaperIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bing Wallpaper Indexer"
}