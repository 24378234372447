import { GoalPluginKey } from "./GoalPluginKey"
import { GoalSchema } from "./GoalSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Schema } from "../schema/Schema"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"

export const GoalManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: GoalPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin key.
     */
    entries: [
        {
            key: "GoalSchema",
            registerWith: SchemaRegistryContextKey,
            value: GoalSchema
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Goal"
}