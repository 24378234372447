import React from "react"
import { Model } from "../state/Model"
import { ReactViewLayout } from "./ReactViewLayout"
import { ReactViewMatcher } from "./ReactViewMatcher"
import { ReactViewProps } from "./ReactViewProps"
import { ReactViewRegistry } from "./ReactViewRegistry"

export class ReactViewRegistryMatcher implements ReactViewMatcher {

    private registry: ReactViewRegistry;

    constructor(registry: ReactViewRegistry) {
        if (!(this.registry = registry)) {
            throw new Error("registry must be specified")
        }
    }

    public match(layout: ReactViewLayout, model: Model | undefined): React.FC<ReactViewProps>[] {
        return this.registry.match(layout, model).map(e => e.fc);
    }    
}