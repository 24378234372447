import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SubredditFeedEmblemView } from "./SubredditFeedEmblemView"
import { SubredditFeedPluginKey } from "../subreddit-feed/SubredditFeedPluginKey"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId"

export const SubredditFeedEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "subreddit-feed-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        SubredditFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "SubredditFeedEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: SubredditFeedSchemaId,
                layout: "emblem",
                fc: SubredditFeedEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Subreddit Feed Emblem View"
}