import { Manifest } from "../../runtime/Manifest"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerCreatorPluginKey } from "./StreamerCreatorPluginKey"

export const StreamerCreatorManifest: Manifest = {
 
    /**
     * The key that uniquely identifies the plugin.
     */
    key: StreamerCreatorPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        StreamerPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Streamer Creator"
}