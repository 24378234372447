import { Installation } from "./Installation"
import { InstallationEntry } from "./InstallationEntry";
import { Manifest } from "./Manifest"
import { Registration } from "./Registration"

export class Runtime {

    /**
     * The installed manifests.
     */
    private _installations: Map<string, Installation>;

    /**
     * The manifests registered with the runtime.
     */
    private _manifests: Map<string, Manifest>;
    
    /**
     * The values of installed manifest entries.
     */
    private _values: Map<string, any>;

    /**
     * Initializes the runtime.
     */
    constructor() {
        this._installations = new Map<string, Installation>();
        this._manifests = new Map<string, Manifest>();
        this._values = new Map<string, any>();
    }

    /**
     * Adds a value to a target manifest entry, e.g., inserts a value into an array.
     * 
     * @param key The target manifest entry that can receive a value, e.g., an array.
     * @param value The value to insert into the target entry.
     * @param mapKey The key to use when inserting into a Map (ignored for other targets).
     */
    public addTo<T = any>(key: string, value: T, mapKey?: string) {

        const target = this.get(key);

        // Array target
        if (Array.isArray(target)) {
            if (Array.isArray(value)) {
                target.push(...value);
            }
            else {
                target.push(value);
            }
            return;
        }

        // Map target
        if (target instanceof Map) {
            if (!mapKey) {
                throw new Error(`A map key must be specified when adding to Map object ${key}`)
            }

            if (target.has(mapKey)) {
                throw new Error(`The target map already has an entry with key ${mapKey}`)
            }

            target.set(mapKey, value);
            return;
        }

        // Set target
        if (target instanceof Set) {
            target.add(value);
            return;
        }

        // Registration function
        if (target.register && typeof(target.register) === "function") {
            target.register(value);
            return;
        }

        throw new Error(`target ${key} does not support registration`);
    }

    /**
     * Returns the manifests dependent on the specified manifest.
     */
    public dependents(key: string): string[] {

        const keys: string[] = [];

        for(let manifest of this._manifests.values()) {
            if (Array.isArray(manifest.dependsOn)) {
                if (manifest.dependsOn.includes(key)) {
                    keys.push(manifest.key);
                }
            }
        }

        return keys;
    }

    /**
     * Gets the runtime value with the specified key.
     */
    public get<T = any>(key: string): T {
        if (!this._values.has(key)) {
            throw new Error(`No value has key "${key}"`);
        }
        else {
            return this._values.get(key);
        }
    }

    /**
     * Indicates a runtime value with the specified key has been installed.
     */
    public has(key: string): boolean {
        return this._values.has(key);
    }

    /**
     * Installs the manifest with the specified key.
     */
    public install(key: string, depth: number = 0) {

        const manifest = this._manifests.get(key);
        if (!manifest) {
            throw new Error(`no manifest with key "${key}" has been registered`);
        }

        if (manifest.debug) {
            debugger;
        }

        let installation = this._installations.get(key);
        if (installation) {
            if (installation.installed) {
                return;
            }
            else {

                // The install function was called against a manifest that is
                // already in process of being installed. This will occur if
                // the dependencies are circular, e.g., A depends on B, and B
                // depends on A.
                throw new Error(`manifest ${key} circular dependency`)
            }
        }
        else {

            // Setup a pending installation (not fully installed yet).
            installation = {
               key,
               entries: new Map<string, InstallationEntry>(),
               installed: false
            } 
           this._installations.set(key, installation);
        }

        if (depth > 0) {
            console.debug(`${"   ".repeat(depth-1)}%c↪`,"color:gray",`Installing ${key}`);
        }
        else {
            console.debug(`Installing ${key}`);
        }

        // Install dependencies
        if (Array.isArray(manifest.dependsOn)) {
            for(let dependsOnKey of manifest.dependsOn) {
                this.install(dependsOnKey, depth + 1);
            }
        }

        // Install entries
        if (Array.isArray(manifest.entries)) {
            for(let entry of manifest.entries) {

                if (!entry) {
                    throw new Error(`manifest ${manifest.key} has empty entry`);
                }

                if (!entry.key) {
                    throw new Error(`manifest ${manifest.key} has entry with no key`);
                }

                if (this._values.has(entry.key)) {
                    throw new Error(`manifest ${manifest.key} has dupe entry ${entry.key}`);
                }

                if(entry.disabled === true) {
                    console.warn(`manifest ${manifest.key} has disabled entry ${entry.key}`);
                    continue;
                }

                // Construct a context object containing the required dependencies
                const context: { [key: string]: any } = {};
                if (Array.isArray(entry.requires)) {
                    for(let requiresKey of entry.requires) {

                        if (!this._values.has(requiresKey)) {
                            throw new Error(`requirement ${requiresKey} not found`);
                        }

                        context[requiresKey] = this._values.get(requiresKey);
                    }
                }

                // Get the entry value, which may be direct or lazy loaded
                let value = entry.value;
                if (typeof(value) === "function") {
                    value = value(context);
                }

                // Add the value to the groups
                if (entry.registerWith) {
                    this.addTo(entry.registerWith, value, entry.registerMapKey);
                }

                const installationEntry: InstallationEntry = {
                    key: entry.key,
                    value,
                    addedTo: entry.registerWith,
                    addedMapKey: entry.registerMapKey
                }

                // Save the entry value
                installation.entries.set(entry.key, installationEntry);
                this._values.set(entry.key, value);
            }
        }

        if (manifest.install) {
            manifest.install(this);
        }

        installation.installed = true;
    }

    /**
     * Installs manifests marked as default install.
     */
    public installDefaults() {
        for(let manifest of this._manifests.values()) {
            if (manifest.defaultInstall) {
                this.install(manifest.key)
            }
        }
    }

    /**
     * Indicates whether the specified manifest is installed.
     */
    public installed(key: string): boolean {
        return this._installations.has(key);
    }

    /**
     * Registers a manifest with the runtime. The manifest is not installed.
     */
    public register(...manifests: Manifest[]) {

        manifests.forEach((manifest, index) => {

            if (!manifest) {
                throw new Error(`manifest at index ${index} is undefined`);
            }

            if (!manifest.key) {
                throw new Error(`manifest at index ${index} is missing a key`);
            }

            if (this._manifests.has(manifest.key)) {
                throw new Error(`manifest "${manifest.key}" is already registered`);
            }

            this._manifests.set(manifest.key, manifest);
        });
    }

    /**
     * Returns a list of all manifest registrations and whether they have been installed.
     */
    public registrations(): Registration[] {

        const regs: Registration[] = [];

        for(let manifest of this._manifests.values()) {

            const reg: Registration = {
                manifest,
                installed: this._installations.has(manifest.key)
            }

            regs.push(reg);
        }

        return regs;
    }

    /**
     * Removes a value from an array or registration object.
     */
    public removeFrom<T = any>(key: string, value: T, mapKey?: string) {

        const target = this.get(key);

        // Array target
        if (Array.isArray(target)) {

            // Get the values as an array
            const values = Array.isArray(value) ? value : [value];
            values.forEach(value => {
                const idx = target.indexOf(value);
                if (idx !== undefined) {
                    target.splice(idx, 1);    
                }    
            })
            return;
        }

        // Map target
        if (target instanceof Map) {
            if (!mapKey) {
                throw new Error("A map key must be specified when removing from a Map object")
            }

            target.delete(mapKey);
            return;
        }

        // Set target
        if (target instanceof Set) {
            target.delete(value);
            return;
        }

        // Object with unregister function
        if (target.unregister && typeof(target.unregister) === "function") {
            target.unregister(value);
            return;
        }

        throw new Error("target type not supported")
    }

    /**
     * Returns the specified runtime value, or undefined if one does not exist.
     */
    public tryGet<T = any>(key: string): T | undefined {
        return this._values.get(key);
    }

    /**
     * Uninstalls the specified manifest.
     */
    public uninstall(key: string) {

        const manifest = this._manifests.get(key);
        if (!manifest) {
            throw new Error(`no manifest with key ${key} has been registered`);
        }

        if (manifest.debug) {
            debugger;
        }
        
        let installation = this._installations.get(key);
        if (installation) {
            installation.installed = false;
        }
        else {
            return;
        }

        // Uninstall manifests dependent on this one.
        const dependents = this.dependents(key);
        for(let dependent of dependents) {
            this.uninstall(dependent);
        }

        // Uninstall entry values in reverse order
        const entries = [...installation.entries.values()].reverse();

        for(let entry of entries) {
            this._values.delete(entry.key);
            if(entry.addedTo) {
                this.removeFrom(entry.addedTo, entry.value, entry.addedMapKey);
            }
        }

        this._installations.delete(key);
    }
}