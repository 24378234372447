import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { ISO4217CodeSchemaId } from "../iso-4217-code/ISO4217CodeSchemaId"
import { PaymentSchemaId } from "./PaymentSchemaId"
import { Schema } from "../schema/Schema"

export const PaymentSchema: Schema = {

    "$id": PaymentSchemaId,
    title: "Payment",
    type: "object",
    properties: {
        "amount": {
            title: "Amount",
            type: "number",
        },
        "currency": {
            "$ref": ISO4217CodeSchemaId,
            title: "Currency"
        },
        "paidOn": {
            "$ref": DateNumberSchemaId,
            title: "Paid On"
        },
        "confirmation": {
            title: "Confirmation Number",
            type: "string"
        },
        "memo": {
            title: "Memo",
            type: "string"
        }
    }
}