import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PromptPluginKey } from "../prompt/PromptPluginKey"
import { PromptSchemaId } from "../prompt/PromptSchemaId"
import { PromptCardView } from "./PromptCardView"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const PromptCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "prompt-card-view",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PromptPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "PromptCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Prompt Card View",
            value: {
                layout: "stream",
                match: PromptSchemaId,
                fc: PromptCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Prompt Card View",
}