import React from "react"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { IonDatetime } from "@ionic/react"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputProps } from "../react-input/ReactInputProps"

export const DateNumberReactInput: React.FC<ReactInputProps> = (props) => {

    const schema = props?.schema;
    if (typeof(schema) !== "object") {
        return (
            <ReactInputFailure>
                Schema missing or not an object
            </ReactInputFailure>
        )
    }

    if (schema.$schema !== DateNumberSchemaId) {
        <ReactInputFailure>
            Expected $schema "{DateNumberSchemaId}" but received "{schema.$schema}"
        </ReactInputFailure>
    }

    // Get the date number (milliseconds from Unix epoch)
    const nvalue = props.value ?? props.schema.default;

    // Convert to a date object
    const dvalue = nvalue !== undefined ? new Date(nvalue) : new Date();

    // Convert to an ISO 8601 datetime string
    const svalue = dvalue.toISOString();

    function onChanged(value: string | undefined | null) {
        if (props.onChange) {
            if (value === undefined || value === null || value === "") {
                props.onChange(undefined);
            }
            else {
                props.onChange(new Date(value).valueOf());                
            }
        }
    }

    return (
        <IonDatetime
            onIonChange={e => onChanged(e.detail.value)}
            placeholder="DNRI"
            value={svalue}></IonDatetime>
    );    
}