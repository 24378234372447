import React  from "react"
import { T3 } from "../reddit/T3"

/**
 * The properties of the component.
 */
interface RedditT3ImageProps {
    t3: T3 
}

export const RedditT3Image: React.FC<RedditT3ImageProps> = (props) => {
    
    const t3 = props?.t3;
    if (!t3) {
        return <></>
    }

    // Return a blank tag if there is no image
    if (!t3?.preview?.enabled) {
        return <></>
    }

    // Height and width of available width
    let screenWidth = window.screen.availWidth;

    /*
     *  Returns the decoded Url of the image with the correct resolution
     *  for the specific screen size.
     */
    function getResolutionImageUrl() {

        let imgResolutions = t3.preview.images[0].resolutions;

        for (let i = 0; i < imgResolutions.length; i++) {
            let resolutionWidth = imgResolutions[i].width;

            // may want to compare screenWidth <= resolutionWidth to
            // screenWidth < resolutionWidth on the phone 
            // to see the difference for user
            if (screenWidth < resolutionWidth) {
                return decodeURIComponent(imgResolutions[i].url);
            }  
        }

        return decodeURIComponent(t3.preview.images[0].source.url);
    }

    // Use the same tooltip format as reddit
    let alt = t3.subreddit_name_prefixed + " - " + t3.title;

    // HACK: Quick and dirty decode. Rewrite this!
    let imgurl = getResolutionImageUrl();

    imgurl = imgurl.replace("amp;", "");
    imgurl = imgurl.replace("amp;", "");
    imgurl = imgurl.replace("amp;", "");

   return <img alt={alt} src={imgurl} className='reddit-t3-image'/>;
};

