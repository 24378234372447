import { EditSchemaId } from './EditSchemaId'
import { PayloadSchemaId } from '../payload/PayloadSchemaId'
import { Schema } from "../schema/Schema"

export const EditSchema: Schema = {

    "$id": EditSchemaId,
    title: "Edit",
    type: "object",
    properties: {
        "payload": {
            "$ref": PayloadSchemaId,
        }
    }
} 