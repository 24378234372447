import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { ChildrenCardView } from "./ChildrenCardView"
import { ChildrenCardViewContextKey } from "./ChildrenCardViewContextKey"
import { ChildrenCardViewPluginKey } from "./ChildrenCardViewPluginKey"
import { ChildrenPluginKey } from "../children/ChildrenPluginKey"
import { ChildrenSchemaId } from "../children/ChildrenSchemaId"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const ChildrenCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ChildrenCardViewPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        ChildrenPluginKey,
        DefaultStorePluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ChildrenCardViewContextKey,
            registerWith: ReactViewRegistryContextKey,
            requires: [AnnotatorRegistryContextKey, DefaultStoreContextKey],
            title: "Children Card View",
            value: (context: ManifestContext) => ({
                layout: ["card"],
                match: ChildrenSchemaId,
                fc: (props) => ChildrenCardView(
                    {
                        ...props,
                        annotator: context[AnnotatorRegistryContextKey],
                        store: context[DefaultStoreContextKey]
                    })
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Children Card View",
}