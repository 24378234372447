import { Manifest } from "../../runtime/Manifest"
import { TreePluginKey } from "./TreePluginKey"

export const TreeManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: TreePluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The display title of the plugin.
     */
    title: "Tree"
}