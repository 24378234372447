import { Activity } from "../activity/Activity"
import { ActivityArrayContextKey } from "./ActivityArrayContextKey"
import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityRegistryPluginKey } from "./ActivityRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ActivityRegistryManifest: Manifest = {

    /**
     * The string that uniquely identifies the plugin.
     */
    key: ActivityRegistryPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: ActivityArrayContextKey,
            title: "Activity Array",
            value: []
        } as ManifestEntry<Activity[]>
    ],

    /**
     * The display title of the plugin
     */
    title: "Activity Registry"
}