import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ShortcutArrayContextKey } from "../shortcut-registry/ShortcutArrayContextKey"
import { ShortcutRegistryPluginKey } from "../shortcut-registry/ShortcutRegistryPluginKey"
import { ShortcutRegistryCommander } from "./ShortcutRegistryCommander"

export const ShortcutRegistryCommanderManifest: Manifest = {

    /**
     * The key tht uniquely identifies the plugin.
     */
    key: "shortcut-registry-commander",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderRegistryPluginKey,
        ShortcutRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ShortcutRegistryCommander",
            registerWith: CommanderArrayContextKey,
            requires: [ShortcutArrayContextKey],
            value: (context: ManifestContext) => 
                new ShortcutRegistryCommander(context[ShortcutArrayContextKey])
        } as ManifestEntry<ShortcutRegistryCommander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Shortcut Commander"
}