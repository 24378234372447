import { ConvertPluginKey } from "../convert/ConvertPluginKey"
import { ConvertStreamView } from "./ConvertStreamView"
import { ConvertSchemaId } from "../convert/ConvertSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SavePluginKey } from "../save/SavePluginKey"

export const ConvertStreamViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "convert-stream-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ConvertPluginKey,
        ReactViewPluginKey,
        SavePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ConvertStreamView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: ConvertSchemaId,
                layout: "stream",
                fc: ConvertStreamView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Convert Stream View"
}