import { Commander } from "../commander/Commander"
import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { DeleteCommander } from "./DeleteCommander"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const DeleteCommanderManifest: Manifest = {

    /**
     * The key that uniquely describes the plugin.
     */
    key: "delete-commander",

    /**
     * Indicates whether the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey,
        CommanderRegistryPluginKey
    ],

    /**
     * Returns the objects supplied by the plugin.
     */
    entries: [
        {
            key: "DeleteCommander",
            registerWith: CommanderArrayContextKey,
            title: "Delete Commander",
            value: new DeleteCommander()
        } as ManifestEntry<Commander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Delete Command",

    /**
     * The display description of the plugin.
     */
    description: "A command for deleting a payload"
}