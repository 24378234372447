import React, { useState } from "react"
import { Command } from "../commander/Command"
import { CommandsReducerKey } from "../commands-reducer/CommandsReducerKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    ActionSheetButton,
    IonActionSheet,
    IonButton,
    IonIcon,
} from "@ionic/react"
import {
    close as cancelIcon,
    menu as menuIcon
} from "ionicons/icons"

/**
 * Renders a payload link into a button.
 */
export const ShortcutToolbarButton: React.FC<ReactViewProps> = (props) => {

    const [showActionSheet, setShowActionSheet] = useState(false);

    // Get the commands supported against the model
    const model = props?.model;
    if (!model) {
        return <></>
    }

    const commands = model[CommandsReducerKey] as Command[];
    if (!commands) {
        return <></>
    }

    // Filter for shortcut commands
    const shortcuts = commands.filter(c => c.layout === "shortcut");
    if (shortcuts.length === 0) {
        return <></>
    }

    shortcuts.sort((a,b) => {
        return a.title.localeCompare(b.title);
    });

    // Map commands to buttons
    const buttons: ActionSheetButton[] = shortcuts.map(command => ({
        text: command.title,
        handler: () => {
            if (command.value && props.dispatch) {
                props.dispatch(command.value);
            }
        }
    }));

    buttons.push({
        text: "Cancel",
        icon: cancelIcon,
        role: "cancel"
    });

    return (
        <>
            {/* Popup menu */}
            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => { setShowActionSheet(false)}}
                buttons={buttons}>
            </IonActionSheet>

            <IonButton onClick={() => setShowActionSheet(true)} size="large">
                <IonIcon icon={menuIcon} size="large"></IonIcon>
            </IonButton>
        </>
    );
}