import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class GoogleEarthIndexer implements Indexer {
    
    public googleEarthUrl(latitude: number, longitude: number): string {

        // https://support.google.com/earth/thread/12301934?hl=en
    
        return `https://earth.google.com/web/search/${latitude},${longitude}/`;
    }
    
    public async index(payload: Payload | undefined): Promise<Payload[]> {

        const location = payload?.location;
        if (!location) {
            return [];
        }

        const url = this.googleEarthUrl(
            location.latitude,
            location.longitude);

        const urlPayload: Payload = {
            schema: UrlSchemaId,
            key: url,
            data: url,
            title: "Google Earth"
        }

        return [urlPayload];
    }
    
}