import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Schema } from "../schema/Schema"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"
import { WaitingPluginKey } from "./WaitingPluginKey"
import { WaitingSchema } from "./WaitingSchema"

export const WaitingManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: WaitingPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SchemaPluginKey,
        SchemaRegistryPluginKey
    ],

    entries: [
        {
            key: "WaitingSchema",
            registerWith: SchemaRegistryContextKey,
            value: WaitingSchema
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Waiting"
}