
/**
 * The signature of a heartbeat callback function.
 */
export type HeartbeatCallback = () => Promise<void>;

/**
 * Executes callbacks at a defined interval.
 */
export class Heartbeat {

    /** The function to execute at the defined interval */
    private callback: HeartbeatCallback;

    /** The interval between heartbeats in milliseconds */
    private interval: number;

    /** The handle of the active timer */
    private timer: number = 0;

    /**
     * Initializes the heartbeat.
     * 
     * @param callback The function to execute at the defined interval.
     * @param interval The interval between heartbeats in milliseconds.
     * @param autostart Indicates whether to start the heartbeat immediately.
     * 
     */
    constructor(callback: HeartbeatCallback, interval: number, autostart: boolean = false) {

        if (!callback) {
            throw new Error("callback must be specified");
        }

        if (interval < 0) {
            throw new Error("interval cannot be negative");
        }

        this.callback = callback;
        this.interval = interval;

        if (autostart) {
            this.start();
        }
    }

    /**
     * Indicates whether the heartbeat is running.
     */
    public get running(): boolean {
        return this.timer > 0;
    }

    /**
     * Starts the heartbeat and return true if started.
     */
    public start(): boolean {
        if (this.timer === 0) {
            this.timer = window.setTimeout(() => { this.loop() }, 0);
        }

        return this.timer !== 0;
    }

    /**
     * Stops the heartbeat and returns true if stopped.
     */
    public stop(): boolean {
        if (this.timer) {
            window.clearTimeout(this.timer);
            this.timer = 0;
        }

        return this.timer === 0;
    }

    /**
     * Implemets the heartbeat loop.
     */
    private loop() {

        if (this.timer === 0) {

            // The timer field will be 0 if stop() was called.
            return;
        }

        // Trigger the heartbeat
        this.callback().then(() => {

            // Setup the next heartbeat. However, if stop() was called by a
            // subscriber during the heartbeat, then the timer value will be zero.
            if (this.timer > 0) {
                this.timer = window.setTimeout(() => { this.loop() }, this.interval)        
            }
        });
    }
}