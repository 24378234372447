import { Splash } from "../splash/Splash"

export const JuneteenthSplashes: Splash[] = [
    {
        activity: "juneteenth",
        imageUrl: "https://images.unsplash.com/photo-1624147798687-17f309188700?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1525&q=80",
        location: "Texas, USA",
        photographer: "Tasha Jolley",
        photographerUrl: "https://unsplash.com/@jolleytasha"
    }    
]