import React from "react"
import { DateNumber } from "../date-number/DateNumber"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { Deadline } from "../deadline/Deadline"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const DeadlineEmblemView: React.FC<ReactViewProps> = (props) => {

    const deadline = props?.model?.payload?.data as Deadline;

    return (
        <ReactViewFactory
            layout="emblem"
            dispatch={props.dispatch}
            matcher={props.matcher}
            model={{
                payload: {
                    schema: DateNumberSchemaId,
                    data: deadline?.deadlineOn
                } as Payload<DateNumber>
            }}>
        </ReactViewFactory>
    )
}