import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Schema } from "../schema/Schema"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"
import { SnippetPluginKey } from "./SnippetPluginKey"
import { SnippetSchema } from "./SnippetSchema"

export const SnippetManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SnippetPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,      

    /**
     * The plugins that must be installed before this one.
     */
     dependsOn: [
        SchemaPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
     entries: [
        {
            key: "SnippetSchema",
            value: SnippetSchema,
            registerWith: SchemaRegistryContextKey
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Snippet"
};