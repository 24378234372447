import { Schema } from "../schema/Schema"
import { TimerSchemaId } from "./TimerSchemaId"

export const TimerSchema: Schema = {
    "$id": TimerSchemaId,
    type: "object",
    title: "Timer",

    properties: {
        "timespan": {
            type: "number",
            description: "The amount of time in milliseconds",
            title: "Timespan"
        },
        "name": {
            type: "string",
            description: "The name of the timer",
            title: "Name"
        },
    }
}

