import { DeadlinePluginKey } from "../deadline/DeadlinePluginKey"
import { DeadlineStreamer } from "./DeadlineStreamer"
import { DeadlineStreamerContextKey } from "./DeadlineStreamerContextKey"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"

export const DeadlineStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "deadline-streamer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DeadlinePluginKey,
        DefaultStorePluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey,
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // streamer
        {
            key: DeadlineStreamerContextKey,
            requires: [DefaultStoreContextKey],
            value: (context: ManifestContext) =>
                new DeadlineStreamer(context[DefaultStoreContextKey])
        } as ManifestEntry<DeadlineStreamer>,

        // registration
        {
            key: "DeadlineStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: [DeadlineStreamerContextKey],
            value: (context: ManifestContext) => ({
                streamer: context[DeadlineStreamerContextKey]
            })
        } as ManifestEntry<StreamerRegistryEntry<DeadlineStreamer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Deadline Streamer"
}