import { CatalogPayloadArrayContextKey } from "./CatalogPayloadArrayContextKey"
import { CatalogPluginKey } from "./CatalogPluginKey"
import { CatalogRegistry } from "./CatalogRegistry"
import { CatalogRegistryContextKey } from "./CatalogRegistryContextKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { Payload } from "../payload/Payload"

export const CatalogManifest: Manifest = {

    /**
     * A string that uniquely identifies the plugin.
     */
    key: CatalogPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: CatalogPayloadArrayContextKey,
            title: "Catalog Payload Array",
            value: []
        } as ManifestEntry<Payload[]>,
        {
            key: CatalogRegistryContextKey,
            requires: [CatalogPayloadArrayContextKey],
            title: "Catalog Registry",
            value: (context: ManifestContext) => 
                new CatalogRegistry(context[CatalogPayloadArrayContextKey])
        } as ManifestEntry<CatalogRegistry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Catalog"
}