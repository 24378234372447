import React from "react"
import { BooleanSchemaId } from "../boolean/BooleanSchemaId"
import { DateNumber } from "../date-number/DateNumber"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { Defer } from "../defer/Defer"
import { Payload } from "../payload/Payload"
import { Prompt } from "../prompt/Prompt"
import { PromptSchemaId } from "../prompt/PromptSchemaId"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"
import { StringSchemaId } from "../string/StringSchemaId"

const MS_PER_SECOND = 1000;
const MS_PER_MINUTE = MS_PER_SECOND * 60;
const MS_PER_HOUR   = MS_PER_MINUTE * 60;
const MS_PER_DAY    = MS_PER_HOUR * 24;

/**
 * The key of the Cancel option of the prompt.
 */
const CANCEL_KEY = "Cancel";

/**
 * The key of the Remove option of the prompt.
 */
const REMOVE_KEY = "Remove";

/**
 * Calculates now deferred by the specified days.
 */
function byDays(days: number): number {
    return Date.now() + days * MS_PER_DAY;
}

/**
 * Calculates now deferred by the specified hours.
 */
function byHours(hours: number): number {
    return Date.now() + hours * MS_PER_HOUR;
}

/**
 * Calculates now deferred by the specified minutes.
 */
function byMinutes(minutes: number): number {
    return Date.now() + minutes * MS_PER_MINUTE;
}

/**
 * Returns the date/time of midnight next monday.
 */
function monday() {

    // Get the current day of the week.
    const day = new Date().getDay();

    // If 0 then +1
    //    1      +7 
    //    2      +6
    //    3      +5
    //    4      +4
    //    5      +3
    //    6      +2
    const daysToAdd = day === 0 ? 1 : 8 - day;

    // Get midnight today
    const midnight = new Date().setHours(0, 0, 0, 0);

    return midnight + daysToAdd * MS_PER_DAY;
}

/**
 * Builds a Prompt object to get the desired deferral time from the user.
 */
function makePrompt(payload: Payload): Prompt {

    const prompt: Prompt = {
        title: "Defer",
        payload,
        options: [
            {
                schema: BooleanSchemaId,
                data: false,
                key: CANCEL_KEY,
                title: "Cancel"
            } as Payload<boolean>,
            {
                schema: DateNumberSchemaId,
                data: byMinutes(5),
                title: "5 minutes"
            } as Payload<DateNumber>,
            {
                schema: DateNumberSchemaId,
                data: byHours(1),
                title: "1 hour"
            } as Payload<DateNumber>,
            {
                schema: DateNumberSchemaId,
                data: tomorrow(),
                title: "Tomorrow"
            } as Payload<DateNumber>,
            {
                schema: DateNumberSchemaId,
                data: monday(),
                title: "Monday"
            } as Payload<DateNumber>,
            {
                schema: DateNumberSchemaId,
                data: byDays(7),
                title: "Next week"
            } as Payload<DateNumber>
        ]
    }

    // If already deferred, provide an option to remove.
    if (payload.deferUntil) {
        prompt.options.push({
            schema: StringSchemaId,
            data: "Remove Deferral",
            key: REMOVE_KEY,
            title: "Remove Deferral"
        })
    }

    return prompt;
}

/**
 * Returns the date/time of midnight tomorrow.
 */
function tomorrow(): number {

    // Get the current date
    const next = new Date();

    // Get the next midnight
    next.setHours(24, 0, 0, 0);

    return next.valueOf();    
}

export const DeferCard: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;
    const defer = props?.model?.payload?.data as Defer;

    /**
     * Executed when a deferral should be applied to the current payload.
     */
    function onDefer(until: number) {

        if (!defer?.payload) {
            return;
        }

        if (dispatch) {

            // Construct a payload to save the deferred payload object
            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload: {...defer.payload, deferUntil: until },
                    confirmed: true
                }
            }

            dispatch(savePayload);
        }
    }

    /**
     * Executed when a deferral should be removed.
     */
    function onRemove() {

        if (!defer?.payload) {
            return;
        }

        if (dispatch) {

            // Construct a payload to save the undeferred payload object
            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload: {...defer.payload, deferUntil: undefined},
                    confirmed: true
                }
            }

            dispatch(savePayload);            
        }
    }

    /**
     * Executed when the user confirms or declines.
     */
    function onSelect(selected: Payload) {

        if (selected.schema === DateNumberSchemaId) {
            onDefer(selected.data);
            return;
        }

        switch(selected.key) {
            case CANCEL_KEY: 
                if (dispatch && defer.payload) {
                    dispatch(defer.payload);
                }
                return;

            case REMOVE_KEY:
                onRemove();
                return;
        }
    }
    
    return (
        <ReactViewFactory
            layout="stream"
            dispatch={p => onSelect(p)}
            matcher={props.matcher}
            model={{ payload: {
                schema: PromptSchemaId,
                data: makePrompt(defer.payload)
            }}}
        ></ReactViewFactory>
    );
}