import { FolderSchemaId } from "./FolderSchemaId"
import { Schema } from "../schema/Schema"

export const FolderSchema: Schema = {

    "$id": FolderSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "A folder containing items",
    title: "Folder",

    properties: {
        "name": {
            type: "string",
            description: "The name of the folder",
            title: "Name"
        },
    }
}