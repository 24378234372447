import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { RootSchemaId } from "../root/RootSchemaId";
import { Store } from "../store/Store"

export class AncestorsIndexer implements Indexer {

    private store: Store;

    constructor(store: Store) {
        if (!(this.store = store)) {
            throw new Error("store must be specified")
        }
    }

    public async index(payload: Payload | undefined): Promise<Payload[]> {

        if (!payload?.id) {
            return [];
        }

        const items: Payload[] = [
            {
                schema: RootSchemaId,
                data: {}
            }
        ];
        
        if (payload.id) {
            const ancestors = await this.store.ancestors(payload.id);
            if (Array.isArray(ancestors)) {

                // Remove the last item (which is this item)
                ancestors.pop();
                items.push(...ancestors);
            }
        }

        return items;
    }
}