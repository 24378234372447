import React from "react"
import { ReactViewFailure } from "./ReactViewFailure"
import { ReactViewLayout } from "./ReactViewLayout";
import { ReactViewProps } from "./ReactViewProps"

export interface ReactViewFactoryProps extends ReactViewProps{
    layout: ReactViewLayout;
    multi?: boolean;
}

export const ReactViewFactory: React.FC<ReactViewFactoryProps> = (props) => {

    const matcher = props.matcher;
    if (!matcher) {
        return(
            <ReactViewFailure>matcher not specified</ReactViewFailure>
        )
    }

    // Get all view functions that match against this model
    const matches = matcher.match(props.layout, props.model);

    if (!props.multi) {
        matches.splice(1);
    }
    
    // Generate the base key for the view
    const baseKey = props?.model?.payload?.id;

    return (
        <>
        {matches.map((v, x) => {
            return React.createElement(v, {
                key: `${baseKey}_${x}`,
                dispatch: props.dispatch,
                matcher: props.matcher,
                model: props.model
            });
        })}
        </>
    );
}