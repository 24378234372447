import { ControllerPluginKey } from "./ControllerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"

export const ControllerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ControllerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PayloadPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Controller"
}