import { Children } from "../children/Children"
import { ChildrenSchemaId } from "../children/ChildrenSchemaId"
import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"

export class ChildrenCommander implements Commander {

    public async issue(model: Model | undefined): Promise<Command[]> {

        if (!model) {
            return [];
        }

        // Payload must exist and have a unique ID
        const payload = model.payload;
        if (!payload?.id) {
            return [];
        }

        const childrenCommand: Command = {
            key: "children",
            layout: "extended",
            order: -1,
            title: "Children",
            value: {
                schema: ChildrenSchemaId,
                data: {
                    payload                    
                }
            } as Payload<Children>
        };

        return [childrenCommand];
    }    
}