import { Manifest } from "../../runtime/Manifest"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"

export const AnnotatorManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: "annotator",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PayloadPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Annotator"
}