import React from "react"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Save } from "../save/Save"
import { SaveSchemaId } from "../save/SaveSchemaId"

export const AttachStreamView: React.FC<ReactViewProps> = (props) => {
   
    /**
     * Called when the attach card dispatches a payload with a new
     * attachment. The parent payload is not saved yet.
     */
    function onAttach(payload: Payload) {

        if(payload) {

            // Wrap the edited payload into a Save payload. The Save
            // payload will be handled by a view that is capable of
            // asking for confirmation and saving to storage.
            const savePayload: Payload<Save> = {
                schema: SaveSchemaId,
                data: {
                    payload
                }
            }

            if (props.dispatch) {
                props.dispatch(savePayload);
            }
        }
    }

    return (
        <ReactViewFactory
            layout="card"
            dispatch={p => onAttach(p)}
            matcher={props.matcher}
            model={props.model}>
        </ReactViewFactory>
    );
}