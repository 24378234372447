import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NgcNumberIndexer } from "./NgcNumberIndexer"
import { NgcNumberIndexerContextKey } from "./NgcNumberIndexerContextKey"
import { NgcNumberPluginKey } from "../ngc-number/NgcNumberPluginKey"
import { NgcNumberIndexerPluginKey } from "./NgcNumberIndexerPluginKey"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"
import { RegExIndexerPluginKey } from "../regex-indexer/RegExIndexerPluginKey"

export const NgcNumberIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: NgcNumberIndexerPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        NgcNumberPluginKey,
        PayloadPluginKey,
        RegExIndexerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: NgcNumberIndexerContextKey,
            value: new NgcNumberIndexer()
        } as ManifestEntry<NgcNumberIndexer>
    ],

    /**
     * The display title of the plugin.
     */
    title: "NGC Number Indexer"
}