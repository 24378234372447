import { Splash } from "../splash/Splash"

export const LoveSplashes: Splash[] = [
    {
        activity: "love",
        imageUrl: "https://images.unsplash.com/photo-1517330283827-2527c57981ad?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
        photographer: "Element5 Digital",
        photographerUrl: "https://unsplash.com/@element5digital"
    },
    {
        activity: "love",
        imageUrl: "https://images.unsplash.com/photo-1516646085441-e1719f13aa3e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
        location: "LoCali, West Hollywood, United States",
        photographer: "Jiroe",
        photographerUrl: "https://unsplash.com/@iamjiroe"
    }
]