import { RssFeedSchemaId } from "./RssFeedSchemaId"
import { Schema } from "../schema/Schema"

export const RssFeedSchema: Schema = {

    "$id": RssFeedSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "A RSS feed",
    title: "RSS Feed",

    properties: {
        "url": {
            type: "string",
            description: "The URL of the RSS feed",
            title: "URL"
        },
        "title": {
            type: "string",
            description: "The display title of the RSS feed",
            title: "Title"
        },
    }
}