import React  from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { RedditT3Card } from "./RedditT3Card"
import { T3 } from "../reddit/T3"

export const RedditThingCardView: React.FC<ReactViewProps> = (props) => {

    let thing = props?.model?.payload?.data;
    if (!thing) {
        return <></>
    }

    switch (thing.kind) {

        case "t3":
            return <RedditT3Card t3={thing.data as T3}></RedditT3Card>

        default:
            return <></>
    }
};