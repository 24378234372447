import { ReducerRegistryEntry } from "./ReducerRegistryEntry"
import { Registry } from "../../runtime/Registry"

export class ReducerRegistry implements Registry<ReducerRegistryEntry> {

    private entries = new Set<ReducerRegistryEntry>();

    public all(): ReducerRegistryEntry[] {
        return [...this.entries.values()];
    }

    public register(value: ReducerRegistryEntry): void {
        if (typeof(value) !== "object") {
            throw new Error("value must be specified as an entry object");
        }
        else {
            this.entries.add(value);
        }
    }

    public unregister(value: ReducerRegistryEntry): void {
        this.entries.delete(value);
    }   
}