import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"

export class ShortcutRegistryCommander implements Commander {

    private shortcuts: Payload[];

    constructor(shortcuts: Payload[]) {
        if (!Array.isArray(this.shortcuts = shortcuts)) {
            throw new Error("shortcuts must be specified as an array")
        }
    }

    public async issue(model: Model): Promise<Command[]> {
        return this.shortcuts.map(payload => this.wrap(payload));
    }

    public wrap(payload: Payload): Command {
        return {
            key: payload.key ?? "",
            layout: "shortcut",
            title: payload.title ?? "",
            value: payload
        };
    }
}