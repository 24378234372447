import { Commander } from "../commander/Commander"
import { CommanderArrayContextKey } from "./CommanderArrayContextKey"
import { CommanderPluginKey } from "../commander/CommanderPluginKey"
import { CommanderRegistryPluginKey } from "./CommanderRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const CommanderRegistryManifest: Manifest = {
 
    /**
     * A string that uniquely identifies the plugin.
     */
    key: CommanderRegistryPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        CommanderPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: CommanderArrayContextKey,
            title: "Commander Array",
            value: []
        } as ManifestEntry<Commander[]>,
    ],
 
    /**
     * The display title of the plugin.
     */
    title: "Commander Registry"
}