import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { PollerCreatorRegistryEntry } from "./PollerCreatorRegistryEntry"
import { Registry } from "../../runtime/Registry"

export class PollerCreatorRegistry implements Registry<PollerCreatorRegistryEntry> {
    
    /**
     * A schema to creator entry mapping.
     */
    private map = new Map<string, PollerCreatorRegistryEntry>();

    /**
     * Creates a poller from the specified payload.
     */
     public async create(payload: Payload): Promise<Poller | undefined> {
        const entry = this.match(payload);
        if (entry) {
            return entry.creator.create(payload);
        }
    }

    /**
     * Returns the entry that can create a poller for the specified payload.
     */
    public match(payload: Payload): PollerCreatorRegistryEntry | undefined {
        if (!payload?.schema) {
            return undefined;
        }
        else {
            return this.map.get(payload.schema);
        }
    }

    /**
     * Adds a poller creator to the registry.
     */
    public register(value: PollerCreatorRegistryEntry): void {

        const schema = value?.schema;
        if (!schema) {
            throw new Error("schema required")
        }

        if (this.map.has(schema)) {
            throw new Error(`schema ${schema} already registered`)
        }

        if (typeof(value.creator) !== "object") {
            throw new Error("creator required");
        }

        this.map.set(schema, value);
    }

    /**
     * Removes a poller creator from the registry.
     */
    public unregister(value: PollerCreatorRegistryEntry): void {
        if (value.schema) {
            this.map.delete(value.schema);
        }
    }
}