import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class UrlAnnotator implements Annotator {

    public async annotate(payload: Payload<string>): Promise<void> {

        if (payload?.schema !== UrlSchemaId) {
            return;
        }

        if (!payload.data) {
            return;
        }

        payload.key = payload.data;
        payload.title = payload.data;
    }
}