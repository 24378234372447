import { Formatter } from "../formatter/Formatter"
import { KilometersSchemaId } from "../kilometers/KilometersSchemaId";

/**
 * Converts kilometers to miles.
 */
 function kmToMiles(km: number): number {
    return km * 0.62137119;
}

export class KilometersFormatter implements Formatter {

    public format<T = any>(schemaId: string, value: T | undefined): string | undefined {

        if (schemaId !== KilometersSchemaId) {
            return undefined;
        }

        if (typeof(value) !== "number") {
            return undefined;
        }

        return `${value} km (${kmToMiles(value).toFixed(1)}) miles`
    }
}