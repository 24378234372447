import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Delete } from "../delete/Delete"
import { DeleteSchemaId } from "../delete/DeleteSchemaId"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload";

export class DeleteCommander implements Commander {

    public async issue(model: Model | undefined): Promise<Command[]> {

        if (!model) {
            return [];
        }

        const payload = model.payload;
        if (!payload) {
            return [];
        }

        if (!payload.id) {
            return [];
        }

        return [
            {
                destructive: true,
                emoji: "❌",
                key: "delete",
                layout: "extended",
                order: "last",
                title: "Delete",
                value: {
                    schema: DeleteSchemaId,
                    data: {
                        payload
                    }
                } as Payload<Delete>,
            },
        ];
    }
}