import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"

/**
 * An annotator that marks a payload as blocked if the payload has an
 * expiration date and the expiration date has elapsed.
 */
export class BlockExpiredAnnotator implements Annotator {

    /**
     * Examines the payload and marks it blocked if the expiration date has elapsed.
     */
    public async annotate(payload: Payload | undefined): Promise<void> {

        if (!payload) {
            return;
        }

        if ((payload.expiresOn !== undefined) && (payload.expiresOn < Date.now())) {
            payload.blocked = true;
        }
    }
}