import { AttachPluginKey } from "../attach/AttachPluginKey"
import { AttachSchemaId } from "../attach/AttachSchemaId"
import { AttachStreamView } from "./AttachStreamView"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SavePluginKey } from "../save/SavePluginKey"

export const AttachStreamViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "attach-stream-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AttachPluginKey,
        ReactViewPluginKey,
        SavePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "AttachStreamView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: AttachSchemaId,
                layout: "stream",
                fc: AttachStreamView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Attach Stream View"
}