import { Activity } from "../activity/Activity";
import { ActivityTracker } from "./ActivityTracker";

export class ActivityTrackerRegistry extends ActivityTracker{

    private trackers: ActivityTracker[] = [];

    public register(tracker: ActivityTracker) {

        if (!tracker) {
            throw new Error("tracker must be specified");
        }

        this.trackers.push(tracker);
    }

    public track(): Activity[] {

        const combined = new Set<Activity>();
        for(let tracker of this.trackers) {
            const activities = tracker.track();
            for (let activity of activities) {
                if (activity) {
                    combined.add(activity);
                }
            }
        }
        return [...combined];
    }

    public unregister(tracker: ActivityTracker) {
        const index = this.trackers.indexOf(tracker);
        if (index !== -1) {
            this.trackers.splice(index, 1);
        }
    }
}