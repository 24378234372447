import { Activity } from "../activity/Activity"
import { ActivityKey } from "../activity-key/ActivityKey"
import { ActivityKeySchemaId } from "../activity-key/ActivityKeySchemaId"
import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload";

export class ActivityKeyAnnotator implements Annotator<ActivityKey> {

    constructor(public activities?: Activity[]) {
    }

    public async annotate(payload: Payload<ActivityKey>): Promise<void> {

        if (payload?.schema !== ActivityKeySchemaId) {
            return;
        }

        if (typeof(payload.data) !== "string") {
            return;
        }

        if (Array.isArray(this.activities)) {
            const act = this.activities.find(a => a.key === payload.data);
            if (act) {
                payload.title = act.title ?? payload.data;
            }
        }
        else {
            payload.title = payload.data;
        }
    }
}