import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"
import { PersonPluginKey } from "./PersonPluginKey"
import { PersonSchema } from "./PersonSchema"
import { SchemaPluginKey } from "../schema/SchemaPluginKey"
import { SchemaRegistryContextKey } from "../schema-registry/SchemaRegistryContextKey"
import { SchemaRegistryPluginKey } from "../schema-registry/SchemaRegistryPluginKey"
import { Schema } from "../schema/Schema"

export const PersonManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: PersonPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PayloadPluginKey,
        SchemaPluginKey,
        SchemaRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "PersonSchema",
            disabled: true, // bug in schema editor causes crash
            registerWith: SchemaRegistryContextKey,
            value: PersonSchema
        } as ManifestEntry<Schema>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Person"
}