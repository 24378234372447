import { RandomValue } from "./RandomValue"

export abstract class RandomGenerator {

    /**
     * Generates a random floating point value between 0.0 (inclusive) to 1.0 (exclusive).
     */
    abstract float(): RandomValue<number>

    /**
     * Generates a random integer between the two values, inclusively.
     */
    abstract int(min: number, max: number): RandomValue<number>;
}