import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { MessierNumberAnnotator } from "./MessierNumberAnnotator"
import { MessierNumberAnnotatorContextKey } from "./MessierNumberAnnotatorContextKey"
import { MessierNumberPluginKey } from "../messier-number/MessierNumberPluginKey"
import { MessierNumberSchemaId } from "../messier-number/MessierNumberSchemaId"

export const MessierNumberAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "messier-number-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        MessierNumberPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: MessierNumberAnnotatorContextKey,
            value: new MessierNumberAnnotator()
        } as ManifestEntry<MessierNumberAnnotator>,

        // registration
        {
            key: "MessierNumberAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [MessierNumberAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: MessierNumberSchemaId,
                annotator: context[MessierNumberAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<MessierNumberAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Messier Number Annotator"
}
