import React from "react"
import { ImageSchema } from "./ImageSchema"
import {
    IonAvatar,
    IonChip,
    IonLabel,
} from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Thumbnail } from "./Thumbnail"

export const ThumbnailChip: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;    

    const payload = props?.model?.payload;
    if (!payload) {
        return <></>
    }

    // Grab the thumbnail data
    const thumbnail = payload.data as Thumbnail;
    if (!thumbnail) {
        return (
            <></>
        );
    }

    // If the chip is clicked, show the image
    const onClick = () => {
        
        // Make a shallow copy of the payload containing the thumbnail
        const p: Payload = {...payload};

        // Repackage the payload as an image
        p.schema = ImageSchema;
        p.data = thumbnail.source;

        if (dispatch) {
            dispatch(p);
        }
    }

    return (
        <IonChip onClick={() => onClick()} color="">
            <IonAvatar>
                <img alt="Thumbnail" src={thumbnail.thumb.url}/>
            </IonAvatar>
            <IonLabel>
                {thumbnail?.source?.width}x{thumbnail?.source?.height}                    
            </IonLabel>
        </IonChip>    
    );    
}