import { DateNumberFormatter } from "./DateNumberFormatter"
import { DateNumberFormatterContextKey } from "./DateNumberFormatterContextKey"
import { DateNumberFormatterPluginKey } from "./DateNumberFormatterPluginKey"
import { DateNumberPluginKey } from "../date-number/DateNumberPluginKey"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryEntry } from "../formatter-registry/FormatterRegistryEntry"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const DateNumberFormatterManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: DateNumberFormatterPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DateNumberPluginKey,
        FormatterPluginKey,
        FormatterRegistryPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // formatter
        {
            key: DateNumberFormatterContextKey,
            title: "Date Number Formatter",
            value: new DateNumberFormatter()
        } as ManifestEntry<DateNumberFormatter>,

        // registration
        {
            key: "DateNumberFormatterRegistryEntry",
            registerWith: FormatterRegistryContextKey,
            requires: [DateNumberFormatterContextKey],
            value: (context: ManifestContext) => ({
                schemaId: DateNumberSchemaId,
                formatter: context[DateNumberFormatterContextKey]
            })
        } as ManifestEntry<FormatterRegistryEntry<DateNumberFormatter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Date Number Formatter"
}