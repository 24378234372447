import { ActivityTracker } from "../activity-tracker/ActivityTracker"
import { Payload } from "../payload/Payload"
import { Store } from "../store/Store"
import { Streamer } from "../streamer/Streamer"
import { SuggestedTask } from "./SuggestedTask"
import { SuggestedTasks } from "./SuggestedTasks"
import { SuggestedTasksSchemaId } from "./SuggestedTasksSchemaId"
import { TaskSchemaId } from "../task/TaskSchemaId"

/**
 * The minimum interval between suggestions (ms).
 */
const MINIMUM_INTERVAL = 1000 * 60 * 60 * 24;

/**
 * The storage key for tracking persistent state.
 */
const STORAGE_KEY = "SuggestedTasks.History";

/**
 * The timestamps of when suggestions were shown for each activity.
 */
interface History {
    [key: string]: number;
}

export class SuggestedTasksStreamer implements Streamer {

    /** The store containing tasks */
    private store: Store;

    /** The suggested tasks */
    private suggestedTasks: SuggestedTask[];

    /** The current activity  */
    private tracker: ActivityTracker;

    /**
     * Initializes a new instance of the suggested tasks streamer.
     */
    constructor(suggestedTasks: SuggestedTask[], tracker: ActivityTracker, store: Store) {

        if (!Array.isArray(this.suggestedTasks = suggestedTasks)) {
            throw new Error("suggested tasks must be specified as an array");
        }

        if (!(this.tracker = tracker)) {
            throw new Error("activity tracker must be specified");
        }

        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }
    }

    public async next(): Promise<Payload | undefined> {

        function loadHistory(): History {
            const str = localStorage.getItem(STORAGE_KEY);
            if (str) {
                return JSON.parse(str);
            }
            else {
                return {};
            }    
        }

        function saveHistory(history: History) {
            const str = JSON.stringify(history);
            localStorage.setItem(STORAGE_KEY, str);
        }

        // Get the history of times when suggestions were made for each activity
        const history = loadHistory();

        // Get the current activity key
        const activityKey = this.tracker.track()[0]?.key;

        // Get the key of the history object that holds a timestamp for the activity.
        const historyKey = activityKey ?? "<none>";

        // Get the timestamp when suggestions were last made for the activity.
        const timestamp = history[historyKey];

        // Exit if the minimum interval hasn't passed.
        if (timestamp) {
            if (timestamp + MINIMUM_INTERVAL > Date.now()) {
                return undefined;
            }
        }

        // Save the updated timestamp
        history[historyKey] = Date.now();
        saveHistory(history);

        // Get the suggested tasks
        const suggested = await this.suggest(activityKey);        
        return this.wrap(suggested);
    }

    /**
     * Returns an array of suggested schedules based on the supplied query.
     */
    public async suggest(activityKey: string | undefined): Promise<SuggestedTask[]> {

        // Get all tasks that originated from a suggestion
        const existingPayloads: Payload<SuggestedTask>[] = (await this.store.schema(TaskSchemaId)).filter(payload => {

            if(payload?.schema !== TaskSchemaId) {
                return false;
            }

            const task = payload.data as SuggestedTask;
            if (!task) {
                return false;
            }

            return task.suggestionKey !== undefined;
        });

        // Build a set of the existing suggestion keys for faster checking
        const existingKeys = new Set<string>(
            existingPayloads.map(p => p.data.suggestionKey)
        );

        return this.suggestedTasks.filter(s => {

            // Don't suggest a task that has already been created
            if (existingKeys.has(s.suggestionKey)) {
                return false;
            }

            return s.activities === activityKey;
        });
    }

    /**
     * Packages an array of suggested schedules into a single payload.
     */
    public wrap(tasks: SuggestedTask[]): Payload | undefined {

        if (!tasks || tasks.length === 0) {
            return undefined;
        }

        const suggested: SuggestedTasks = {
            tasks
        }

        return {
            schema: SuggestedTasksSchemaId,
            data: suggested,
            title: "Suggested Tasks"
        }
    }
}