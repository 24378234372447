import { EmailAddress } from "./EmailAddress"

export function getEmailAddress(value: string | EmailAddress | undefined): string | undefined {

    if (!value) {
        return undefined;
    }

    switch(typeof(value)) {
        case "string":
            return value;

        case "object":
            return value.mailto;

        default:
            return undefined;
    }
}