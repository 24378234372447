import { Action } from "./Action"
import { Controller } from "./Controller"
import { ControllerVerb } from "./ControllerVerb";
import { Payload } from "../payload/Payload"

export class RoundRobinController extends Controller {

    /** The controllers */
    private controllers: Controller[];

    /** The index of the current controller. */
    private index: number | undefined;

    constructor(controllers: Controller[]) {
        super();
        if (!Array.isArray(controllers)) {
            throw new Error("controllers must be specified as an array")
        }
        this.controllers = controllers;
    }

    public async dispatch<TParam = any>(action: Action<TParam>): Promise<Payload | undefined> {

        if (this.controllers.length === 0) {
            return undefined;
        }

        if (action?.verb === ControllerVerb.NEXT) {

            // Go to the next index
            if (this.index === undefined) {
                this.index = 0;
            }
            else {
                this.index++;
                if (this.index === this.controllers.length) {
                    this.index = 0;
                }
            }

        }

        const controller = this.controllers[this.index ?? 0];
        if (!controller) {
            return undefined;
        }
        else {
            return controller.dispatch(action);
        }
    }
}