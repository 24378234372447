import { Manifest } from "../../runtime/Manifest"
import { ServiceCreatorPluginKey } from "./ServiceCreatorPluginKey"
import { ServicePluginKey } from "../service/ServicePluginKey"

export const ServiceCreatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ServiceCreatorPluginKey,

    /**
     * Indicates whether to install the plugin at first run by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ServicePluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Service Creator"
}