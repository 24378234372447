import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { UrlCardView } from "./UrlCardView"
import { UrlPluginKey } from "../url/UrlPluginKey"
import { UrlSchemaId } from "../url/UrlSchemaId"

export const UrlCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the URL payload plugin.
     */
    key: "url-card-view",

    /**
     * Ensures the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        ReactViewPluginKey,
        UrlPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Displays URLs",

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "UrlCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "URL Card View",
            value: {
                layout: "card",
                match: UrlSchemaId,
                fc: UrlCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "URL Card View",
}