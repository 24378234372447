import { Payload } from "../payload/Payload"
import { Registry } from "../../runtime/Registry"
import { Streamer } from "../streamer/Streamer";
import { StreamerCreatorRegistryEntry } from "./StreamerCreatorRegistryEntry"
import { StreamerCreator } from "../streamer-creator/StreamerCreator"

export class StreamerCreatorRegistry
    implements Registry<StreamerCreatorRegistryEntry>, StreamerCreator {

    /**
     * The creators mapped by schema value.
     */
    private creators = new Map<string, Set<StreamerCreatorRegistryEntry>>();

    /**
     * Indicates whether the payload appears to be a creatable stream.
     */
    public creatable(payload: Payload): boolean {
        return this.match(payload) !== undefined;
    }

    /**
     * Attempts to create a streamer from creators in the registry.
     */
    public async create(payload: Payload): Promise<Streamer | undefined> {

        const matches = this.match(payload);

        for(const entry of matches) {
            const result = await entry.creator.create(payload);
            if (result) {
                return result;
            }
        }

        return undefined;
    }

    /**
     * Returns the registry entry best matching the specified payload.
     */
    public match(payload: Payload): StreamerCreatorRegistryEntry[] {
        
        if (!payload?.schema) {
            return [];
        }

        const matches: StreamerCreatorRegistryEntry[] = [];

        // See if there is an exact schema match
        const schemaSet = this.creators.get(payload.schema);
        if (schemaSet && schemaSet.size > 0) {
            matches.push(...schemaSet.values());
        }

        // Add global matches last
        const globalSet = this.creators.get("*");
        if (globalSet && globalSet.size > 0) {
            matches.push(...globalSet.values());
        }

        return matches;
    }

    /**
     * Adds a streamer creator to the registry.
     */
    public register(value: StreamerCreatorRegistryEntry): void {

        if (!value) {
            throw new Error("value must be specified");
        }

        if (!value.schema) {
            throw new Error("value must have a schema property");
        }

        let set = this.creators.get(value.schema);
        if (!set) {
            set = new Set<StreamerCreatorRegistryEntry>();
            this.creators.set(value.schema, set);
        }

        set.add(value);
    }

    /**
     * Removes a streamer creator from the registry.
     */
    public unregister(value: StreamerCreatorRegistryEntry): void {
        if (!value) {
            throw new Error("value must be specified");
        }

        if (!value.schema) {
            throw new Error("value must have a schema property");
        }

        const set = this.creators.get(value.schema);
        if (set) {
            set.delete(value);
            if (set.size === 0) {
                this.creators.delete(value.schema);
            }
        }
    }
}