import { JsonPluginKey } from "./JsonPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const JsonManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: JsonPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "JSON"
}