import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SearchStringCardView } from "./SearchStringCardView"
import { SearchStringPluginKey } from "../search-string/SearchStringPluginKey"
import { SearchStringSchemaId } from "../search-string/SearchStringSchemaId"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const SearchStringCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the search box plugin.
     */
    key: "search-string-card-view",

    /**
     * Indicates whether the plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        SearchStringPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "SearchStringCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Search String Card View",
            value: {
                layout: "card",
                match: SearchStringSchemaId,
                fc: SearchStringCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Search String Card View"
}