import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { RssItemCardView } from "./RssItemCardView"
import { RssItemCardViewPluginKey } from "./RssItemCardViewPluginKey"
import { RssItemSchemaId } from "../rss/RssItemSchemaId"
import { RssPluginKey } from "../rss/RssPluginKey"

export const RssItemCardViewManifest: Manifest = {

    /**
     * A key that uniquely identifies the plugin.
     */
    key: RssItemCardViewPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey,
        RssPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "RssItemCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "RSS Item Card View",
            value: {
                layout: "card",
                match: RssItemSchemaId,
                fc: RssItemCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "RSS Item Card View"
};
