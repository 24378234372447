import { BooleanCardView } from "./BooleanCardView"
import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { BooleanSchemaId } from "../boolean/BooleanSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { StringPluginKey } from "../string/StringPluginKey"

export const BooleanCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the string payload plugin.
     */
    key: "boolean-card-view",

    /**
     * Indicates the string payload plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        BooleanPluginKey,
        ReactViewPluginKey,
        StringPluginKey
    ],

    /**
     * Returns the objects supplied by the plugin.
     */
    entries: [
        {
            key: "BooleanCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Boolean Card View",
            value: {
                layout: ["card", "preview"],
                match: BooleanSchemaId,
                fc: BooleanCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Boolean Card View"
}