import { Manifest } from "../../runtime/Manifest"
import { MathProblemPluginKey } from "./MathProblemPluginKey"

export const MathProblemManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: MathProblemPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The display description of the plugin.
     */
    description: "Defines a math problem",

    /**
     * The objects provided by the plugin.
     */
    entries: [

    ],

    /**
     * The display title of the plugin.
     */
    title: "Math Problem"
}