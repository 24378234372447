import React, { useEffect } from "react"
import { Action } from "../controller/Action"
import { controllerDispatch } from "../controller-redux/actions.service"
import { ControllerVerb } from "../controller/ControllerVerb"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { useDispatch } from "react-redux"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"
import "./NextCardView.css"

export const NextCard: React.FC<ReactViewProps> = (props) => {

    const dispatch = useDispatch();
    const { model } = {...props};

    useEffect(() => {

        // Create a NEXT action to send to the controller
        const action: Action = {
            verb: ControllerVerb.NEXT,            
        }

        dispatch(controllerDispatch(action));

    }, [dispatch, model])

    return (
        <ReactViewFactory
            dispatch={props.dispatch}
            layout="card"
            matcher={props.matcher}
            model={{
                payload: {
                    schema: WaitingSchemaId,
                    data: {
                        title: "Loading..."
                    }
                }
            }}>

        </ReactViewFactory>
    )
}