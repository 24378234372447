import { Schema } from "../schema/Schema"
import { DefinitionSchemaId } from "./DefinitionSchemaId"

export const DefinitionSchema: Schema = {
    $id: DefinitionSchemaId,
    title: "Definition",
    type: "object",
    properties: {
        "term": {
            title: "Term",
            type: "string"
        },
        "meaning": {
            title: "Meaning",
            type: "string"
        }
    }
}