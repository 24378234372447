import { Copyright } from "../copyright/Copyright"
import { CopyrightSchemaId } from "../copyright/CopyrightSchemaId"
import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { Splash } from "../splash/Splash"
import { SplashSchemaId } from "../splash/SplashSchemaId"

export class SplashIndexer implements Indexer {

    public async index(payload: Payload): Promise<Payload[]> {

        if (!payload) {
            return [];
        }

        if (payload.schema !== SplashSchemaId) {
            return [];
        }

        const splash = payload.data as Splash;
        if (!splash) {
            return [];
        }

        const copyrightPayload: Payload<Copyright> = {
            schema: CopyrightSchemaId,
            data: {
                notice: `© ${splash.photographer}`
            }
        }

        return [copyrightPayload];
    }
}