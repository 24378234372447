import { ActivityArrayContextKey } from "../activity-registry/ActivityArrayContextKey"
import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityRegistryPluginKey } from "../activity-registry/ActivityRegistryPluginKey"
import { ActivityScheduleTracker } from "./ActivityScheduleTracker"
import { ActivityTrackerContextKey } from "../activity-tracker/ActivityTrackerContextKey"
import { ActivityTrackerPluginKey } from "../activity-tracker/ActivityTrackerPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SchedulePluginKey } from "../schedule/SchedulePluginKey"

export const ActivityScheduleTrackerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "activity-schedule-tracker",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        ActivityRegistryPluginKey,
        ActivityTrackerPluginKey,
        SchedulePluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "ActivityScheduleTracker",
            registerWith: ActivityTrackerContextKey,
            requires: [ActivityArrayContextKey],
            value: (context: ManifestContext) => 
                new ActivityScheduleTracker(context[ActivityArrayContextKey])
        } as ManifestEntry<ActivityScheduleTracker>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Schedule Tracker"
}