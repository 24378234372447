import { DefaultPayloadCard } from "./DefaultPayloadCard"
import { DefaultPayloadPluginKey } from "../default-payload/DefaultPayloadPluginKey"
import { DefaultPayloadSchemaId } from "../default-payload/DefaultPayloadSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const DefaultPayloadCardManifest: Manifest = {

    /** 
     * The key that uniquely identifies the plugin.
     */
    key: "default-payload-card",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DefaultPayloadPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Displays a message when no payloads are available",

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DefaultPayloadCard",
            registerWith: ReactViewRegistryContextKey,
            title: "Default Payload Card",
            value: {
                layout: "card",
                match: DefaultPayloadSchemaId,
                fc: DefaultPayloadCard    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Default Payload Card"
};
