import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle
} from "@ionic/react"
import { RssItem } from "../rss/RssItem"
import "./RssItemCardView.css"

/**
 * Renders an RSS item payload.
 */
export const RssItemCardView: React.FC<ReactViewProps> = (props) => {

    function decodeHtml(text: string | undefined): string {
        if (!text) {
            return "";
        }

        let decode = text.indexOf("&gt;") > 0;

        if (decode) {
            let doc = new DOMParser().parseFromString(text, "text/html");
            return doc.documentElement.textContent ?? "";    
        }
        else {
            return text;
        }
    }

    let item: RssItem = props?.model?.payload?.data;
    if (!item) {
        return <></>
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{item.title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <div className="rss-item selectable" dangerouslySetInnerHTML={{__html: decodeHtml(item.description)}}>

                </div>
            </IonCardContent>
        </IonCard>
    )
} 