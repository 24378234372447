import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { RedditThingSchemaId } from "../reddit/RedditThingSchemaId"
import { T3 } from "../reddit/T3"
import { Thing } from "../reddit/Thing"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class RedditThingIndexer implements Indexer {

    public fix(url: string | undefined): string | undefined {
        if (!url) {
            return url;
        }
        else {
            return url
                .replace("amp;", "")
                .replace("amp;", "")
                .replace("amp;", "");
        }
    }

    public async index(payload: Payload | undefined): Promise<Payload[]> {

        if (!payload) {
            return [];
        }

        if (payload.schema !== RedditThingSchemaId) {
            return [];
        }

        // Get the raw reddit Thing object
        const thing = payload.data as Thing;
        if (!thing) {
            return [];
        }

        // The thing type needs to be t3
        if (thing.kind !== "t3") {
            return [];
        }

        // Get the T3 object
        const t3 = thing.data as T3;
        if (!t3) {
            return [];
        }

        const payloads: Payload[] = [];
        payloads.push(
            {
                schema: UrlSchemaId,
                data: `https://www.reddit.com${t3.permalink}`,
                title: "Reddit Post"
            },
            {
                schema: UrlSchemaId,
                data: `https://www.reddit.com/r/${t3.subreddit}`,
                title: `/r/${t3.subreddit}`,
            },
            {
                schema: UrlSchemaId,
                data: `https://www.reddit.com/u/${t3.author}`,
                title: `/u/${t3.author}`,
            }
        )

        // Index the posting URL. This field can be blank.
        if (t3.url) {
            payloads.push({
                schema: UrlSchemaId,
                data: t3.url
            });
        }

        return payloads;
    }
}