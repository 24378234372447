import React  from "react"
import { CAP12 } from "../geojson/CAP12"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonText
} from "@ionic/react"
import "./NWSAlertDetailView.css"

export const NWSAlertDetailView: React.FC<ReactViewProps> = (props) => {

    const feature = props?.model?.payload?.data;
    if (!feature) {
        return <></>
    }

    // Get the underlying properties of the feature.
    const fprops = feature.properties as CAP12;
    
    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{fprops.headline}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonText className={"nws-alert-detail selectable"}>{fprops.description}</IonText>
            </IonCardContent>
        </IonCard>
    )
};