import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Convert } from "../convert/Convert"
import { ConvertSchemaId } from "../convert/ConvertSchemaId"
import { Converter } from "../converter/Converter"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload"

export class ConvertCommander implements Commander {

    private converter: Converter;

    constructor(converter: Converter) {
        if (!(this.converter = converter)) {
            throw new Error("converter must be specified");
        }
    }

    public async issue(model: Model): Promise<Command[]> {

        const payload = model?.payload;
        if (!payload) {
            return [];
        }

        const inspections = await this.converter.inspect(payload);
        if (inspections.length === 0) {
            return [];
        }
        else {
            return [
                {
                    destructive: false,
                    key: "convert",
                    layout: "extended",
                    title: "Convert",
                    value: {
                        schema: ConvertSchemaId,
                        data: {
                            payload
                        }
                    } as Payload<Convert>
                }
            ];
        }
    }
}