import { ISO4217CodeArray } from "./ISO4217CodeArray"
import { ISO4217CodeSchemaId } from "./ISO4217CodeSchemaId"
import { Schema } from "../schema/Schema"

export const ISO4217CodeSchema: Schema = {

    "$id": ISO4217CodeSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    /**
     * An ISO 4217 code is a string
     */
    type: "string",

    description: "The ISO 4217 currency code",
    
    /**
     * An array of valid codes
     */
    enum: ISO4217CodeArray,

    /**
     * The ISO 4217 code always has three characters.
     */
    minLength: 3,

    /**
     * The ISO 4217 code always has three characters.
     */
    maxLength: 3,

    /**
     * The title of the field.
     */
    title: "ISO 4217 Currency Code"
}