import { ActivityTracker } from "../activity-tracker/ActivityTracker"
import { Payload } from "../payload/Payload"
import { RandomGenerator } from "../random/RandomGenerator"
import { Splash } from "../splash/Splash"
import { SplashSchemaId } from "../splash/SplashSchemaId"
import { Streamer } from "../streamer/Streamer"

export class SplashStreamer implements Streamer {

    private processedKeys = new Set<string>();
    private rng: RandomGenerator;
    private splashes: Splash[];
    private tracker: ActivityTracker;

    constructor(splashes: Splash[], rng: RandomGenerator, tracker: ActivityTracker) {
        if (!Array.isArray(this.splashes = splashes)) {
            throw new Error("splashes must be specified as an array")
        }
        if (!(this.rng = rng)) {
            throw new Error("rng must be specified");
        }
        if (!(this.tracker = tracker)) {
            throw new Error("tracker must be specified");
        }
    }

    public async next(): Promise<Payload | undefined> {

        // Get the current activities
        const tracked = this.tracker.track();

        // Handle activities that were previously sent but no longer current.
        for(let processedKey of this.processedKeys) {
            const index = tracked.findIndex(a => a.key === processedKey);
            if (index === -1) {
                this.processedKeys.delete(processedKey);
                // TODO: issue a payload to indicate the activity is finished
            }
        }

        // Get the next processed activity
        const unprocessed = tracked.find(a => !this.processedKeys.has(a.key));
        if (!unprocessed) {
            return;
        }

        // Mark this activity as processed
        this.processedKeys.add(unprocessed.key);

        // Find all matching splashes
        const matches = this.splashes.filter(p => p.activity === unprocessed.key);
        if (!matches.length) {
            return;
        }

        // Select a random index from the matches array
        const rvalue = this.rng.int(0, matches.length - 1);
        if (!rvalue || rvalue.value === undefined) {
            return;
        }

        // Get the splash at that index
        const rsplash = matches[rvalue.value]

        // Package into a payload
        const payload: Payload<Splash> = {
            schema: SplashSchemaId,
            data: rsplash
        }

        return payload;
    }
}