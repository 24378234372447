import { AttachCommander } from "./AttachCommander"
import { AttachPluginKey } from "../attach/AttachPluginKey"
import { CommanderArrayContextKey } from "../commander-registry/CommanderArrayContextKey"
import { CommanderRegistryPluginKey } from "../commander-registry/CommanderRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const AttachCommanderManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "attach-commander",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AttachPluginKey,
        CommanderRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "AttachCommander",
            registerWith: CommanderArrayContextKey,
            value: new AttachCommander()
        } as ManifestEntry<AttachCommander>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Attach Commander"
}