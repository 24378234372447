import React, { useState } from "react"
import {
    ActionSheetButton,
    IonActionSheet,
    IonButton,
} from "@ionic/react"
import { Activity } from "../activity/Activity"
import { ActivityToggleTracker } from "../activity-toggle-tracker/ActivityToggleTracker"
import { ActivityTracker } from "../activity-tracker/ActivityTracker"
import { ReactViewProps } from "../react-view/ReactViewProps"

export interface ActivityToolbarButtonProps extends ReactViewProps {

    /**
     * The array of available activities.
     */
    activities: Activity[],

    /**
     * The object that tracks the selected activity.
     */
    setter: ActivityToggleTracker,

    /**
     * The object that tracks all current activities.
     */
    tracker: ActivityTracker
}

export const ActivityToolbarButton: React.FC<ActivityToolbarButtonProps> = (props) => {

    const [showActionSheet, setShowActionSheet] = useState(false);

    if (!props.activities) {
        return <></>
    }

    if (!props.setter) {
        return <></>
    }

    // Get all active/current activities
    const activeActivities = props.tracker?.track() ?? [];

    // Get the actively selected by this toggle
    const selectedActivity = props.setter.get();

    // Define the color of the toolbar button depending on whether the activity is set
    const color = selectedActivity === undefined ? "warning" : "success";

    let caption = selectedActivity?.title ?? selectedActivity?.key;
    if (!caption) {
        caption = activeActivities[0]?.title ?? "(unfocused)";
    }
    if (activeActivities.length > 1) {
        caption += "+";
    }

    function buildButtons(): ActionSheetButton[] {

        const buttons: ActionSheetButton[] = [{
            text: "(unfocus)",
            handler: () => setActivity(undefined)
        }];

        if (Array.isArray(props.activities)) {
            for(let a of props.activities) {
                const button: ActionSheetButton = {
                    text: a.title,
                    handler: () => {
                        setActivity(a)            
                    }                    
                }

                if (a === selectedActivity) {
                    button.role = "selected"
                }
                else if (activeActivities.includes(a)) {
                    button.role = "selected"
                }
                
                buttons.push(button);
            }  
        }

        return buttons;
    }

    function setActivity(value: Activity | undefined) {
        if (!props.setter) {
            return;
        }
        else {
            props.setter.set(value);
        }
    }

    return (
        <>
            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => { setShowActionSheet(false)}}
                buttons={buildButtons()}>
            </IonActionSheet>
            <IonButton color={color} fill="solid" onClick={() => setShowActionSheet(true)} size="large">
                {caption}
            </IonButton>
        </>
    );
}
