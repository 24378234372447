export enum TaskStatus {

    /**
     * The task is open/active.
     */
    Open = 0,

    /**
     * The task has been completed.
     */
    Completed = 1,

    /**
     * The task has been canceled.
     */
    Canceled = 2
}