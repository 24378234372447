/**
 * Parses the hostname from a URL string, e.g., youtube.com.
 */
export function parseHostname(url: string): string {

    // Adapted from: https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string
    let hostname: string;

    // Find and remove protocol
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }
    
    // Remove port number
    hostname = hostname.split(':')[0];

    // Remove ? query string
    hostname = hostname.split('?')[0];
    
    return hostname;  
}
