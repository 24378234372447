import { DeadlineEmblemView } from "./DeadlineEmblemView"
import { DeadlinePluginKey } from "../deadline/DeadlinePluginKey"
import { DeadlineSchemaId } from "../deadline/DeadlineSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const DeadlineEmblemViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "deadline-emblem-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DeadlinePluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DeadlineEmblemView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: DeadlineSchemaId,
                layout: "emblem",
                fc: DeadlineEmblemView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Deadline Emblem View"
}