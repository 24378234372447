import { Payload } from "../payload/Payload"
import { RootSchemaId } from "../root/RootSchemaId"

/**
 * Determines whether a payload is in the exclusion list.
 */
 export function isExcluded(payload: Payload | undefined, exclusions: number[] | undefined): boolean {

    if (!payload) {
        throw new Error("payload must be specified");
    }

    if (payload.schema === RootSchemaId) {
        return false;
    }

    if (!payload.id) {
        throw new Error("payload must have an id")
    }

    if (!Array.isArray(exclusions)) {
        return false;
    }

    return exclusions.includes(payload.id);
}