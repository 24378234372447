import { JuneteenthSplashes } from "./JuneteenthSplashes"
import { JuneteenthSplashesPluginKey } from "./JuneteenthSplashesPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { Splash } from "../splash/Splash"
import { SplashArrayContextKey } from "../splash-array/SplashArrayContextKey"
import { SplashArrayPluginKey } from "../splash-array/SplashArrayPluginKey"
import { SplashPluginKey } from "../splash/SplashPluginKey"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const JuneteenthSplashesManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: JuneteenthSplashesPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SplashPluginKey,
        SplashArrayPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "JuneteenthSplashes",
            registerWith: SplashArrayContextKey,
            value: JuneteenthSplashes
        } as ManifestEntry<Splash[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Juneteenth Splashes"
}