import { EarthquakeDetailView } from "./EarthquakeDetailView"
import { EarthquakePluginKey } from "../usgs-earthquake/EarthquakePluginKey"
import { EarthquakeSchemaId } from "../usgs-earthquake/EarthquakeSchemaId"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { KilometersFormatterPluginKey } from "../kilometers-formatter/KilometersFormatterPluginKey"
import { KilometersPluginKey } from "../kilometers/KilometersPluginKey"
import { LatitudeFormatterPluginKey } from "../latitude-formatter/LatitudeFormatterPluginKey"
import { LatitudePluginKey } from "../latitude/LatitudePluginKey"
import { LongitudeFormatterPluginKey } from "../longitude-formatter/LongitudeFormatterPluginKey"
import { LongitudePluginKey } from "../longitude/LongitudePluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const USGSEarthquakeDetailViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "usgs-earthquake-detail-view",

    /** 
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        EarthquakePluginKey,
        FormatterRegistryPluginKey,
        KilometersPluginKey,
        KilometersFormatterPluginKey,
        LatitudePluginKey,
        LatitudeFormatterPluginKey,
        LongitudePluginKey,
        LongitudeFormatterPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "EarthquakeDetailView",
            requires: [FormatterRegistryContextKey],
            registerWith: ReactViewRegistryContextKey,
            title: "Earthquake Detail View",
            value: (context: ManifestContext) => ({
                layout: "detail",
                order: "first",
                match: EarthquakeSchemaId,
                fc: (props: ReactViewProps) =>
                    EarthquakeDetailView({...props, formatter: context[FormatterRegistryContextKey]})
            })
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "USGS Earthquake Detail View"
};
