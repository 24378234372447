import { Annotator } from "../annotator/Annotator"
import { MessierNumberSchemaId } from "../messier-number/MessierNumberSchemaId";
import { Payload } from "../payload/Payload";

export class MessierNumberAnnotator implements Annotator<number> {

    public async annotate(payload: Payload<number>): Promise<void> {

        if (payload?.schema !== MessierNumberSchemaId) {
            return;
        }

        const n = payload.data;
        if (typeof(n) !== "number") {
            return;
        }

        if ((n < 1) || (n > 110)) {
            return;
        }

        payload.title = `Messier ${n}`;
    }
}