import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { DeadlineSchemaId } from "./DeadlineSchemaId"
import { Schema } from "../schema/Schema"

export const DeadlineSchema: Schema = {
    $id: DeadlineSchemaId,
    title: "Deadline",
    type: "object",
    properties: {
        "title": {
            title: "Title",
            type: "string"
        },
        "deadlineOn": {
            title: "Deadline Date",
            $ref: DateNumberSchemaId
        }
    }
}