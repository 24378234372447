import { FolderSchemaId } from "../folder/FolderSchemaId"
import { Payload } from "../payload/Payload"
import { ServiceCreator } from "../service-creator/ServiceCreator"
import { ServiceRegistry } from "../service-registry/ServiceRegistry"
import { ServiceRegistryEntry } from "../service-registry/ServiceRegistryEntry"
import { Store } from "../store/Store"
import { SyncService } from "../service/SyncService"
import { Service } from "../service/Service"

/**
 * The default interval between checks for new or updated services.
 */
const DEFAULT_INTERVAL_MS = 1000 * 60;

export class ServiceLoaderService extends SyncService<Payload, number | string, ServiceRegistryEntry> {
 
    /** The registry of objects for creating services from payloads */
    private creator: ServiceCreator;

    /** The parent payload containing child service payloads */
    private parentId: number | undefined;

    /** The store containing streamer settings */
    private store: Store;

    /** The registry that receives instantiated streamer objects */
    private registry: ServiceRegistry;

    constructor(
            registry: ServiceRegistry,
            creator: ServiceCreator,
            store: Store,
            parentId: number | undefined) {

        super(DEFAULT_INTERVAL_MS);

        if (!(this.registry = registry)) {
            throw new Error("services must be specified");
        }

        if (!(this.creator = creator)) {
            throw new Error("creators must be specified");
        }

        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }

        this.parentId = parentId;
    }

    protected async onAdd(payload: Payload): Promise<ServiceRegistryEntry | undefined> {

        let service: Service | undefined;

        if (payload?.id && (payload.schema === FolderSchemaId)) {

            service = new ServiceLoaderService(
                this.registry,
                this.creator,
                this.store,
                payload.id
            );
        }
        else {
            service = await this.creator.create(payload);
        }

        if (!service) {
            console.warn(`service ${payload.id} (${payload.schema}) could not be created`);
            return undefined;
        }

        // Construct a registry entry
        const entry: ServiceRegistryEntry = {
            service,
            startup: "auto"
        };

        // Register the service
        this.registry.register(entry);
        return entry;
    }

    protected async onFetch(): Promise<Payload[]> {
        return this.store.children(this.parentId);
    }

    protected onKey(payload: Payload): number | string | undefined {
        return payload.id ?? payload.key;
    }

    protected async onRemove(entry: ServiceRegistryEntry): Promise<void> {
        this.registry.unregister(entry);
    }
}