import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"
import { WhatsNewLogContextKey } from "../whats-new-log/WhatsNewLogContextKey"
import { WhatsNewLogPluginKey } from "../whats-new-log/WhatsNewLogPluginKey"
import { WhatsNewPluginKey } from "../whats-new/WhatsNewPluginKey"
import { WhatsNewStreamer } from "./WhatsNewStreamer"
import { WhatsNewStreamerContextKey } from "./WhatsNewStreamerContextKey"

export const WhatsNewStreamerManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: "whats-new-streamer",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        StreamerPluginKey,
        StreamerRegistryPluginKey,
        WhatsNewPluginKey,
        WhatsNewLogPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // streamer
        {
            key: WhatsNewStreamerContextKey,
            requires: [WhatsNewLogContextKey],
            value: (context: ManifestContext) =>
                new WhatsNewStreamer(context[WhatsNewLogContextKey])
        } as ManifestEntry<WhatsNewStreamer>,

        // registration
        {
            key: "whats-new-streamer",
            requires: [WhatsNewStreamerContextKey],
            registerWith: StreamerRegistryContextKey,
            value: (context: ManifestContext) => ({
                streamer: context[WhatsNewStreamerContextKey]
            })
        } as ManifestEntry<StreamerRegistryEntry<WhatsNewStreamer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "What's New Streamer"
}