import { ImageCard } from "./ImageCard"
import { ImageSchema } from "./ImageSchema"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ThumbnailSchema } from "./ThumbnailSchema"
import { ThumbnailChip } from "./ThumbnailChip"

export const ImagesManifest: Manifest = {

    /**
     * The key value that uniquely identifies the plugin.
     */
    key: "images",

    /**
     * Indicates the plugin should be installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        ReactViewPluginKey
    ],

    /**
     * Returns the objects provided by the plugin.
     */
    entries: [
        {
            key: "ImageCard",
            registerWith: ReactViewRegistryContextKey,
            title: "Image Card",
            value: {
                layout: "card",
                match: ImageSchema,
                fc: ImageCard    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
        {
            key: "ThumbnailChip",
            registerWith: ReactViewRegistryContextKey,
            title: "Thumbnail Chip",
            value: {
                layout: "chip",
                match: ThumbnailSchema,
                fc: ThumbnailChip    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Images"
}