import { SuggestedTask } from "./SuggestedTask"

/**
 * The list of suggested tasks, ordered by activity.
 */
export const DefaultTasks: SuggestedTask[] = [

    // Bedtime
    {
        title: "Brush teeth in evening",
        activities: "bedtime",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "brush-teeth-evening",
        tags: ["bedtime", "dental"]
    },
    {
        title: "Charge phone",
        activities: "bedtime",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "charge-phone"
    },
    {
        title: "Check your meetings tomorrow",
        activities: "bedtime",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "check-meetings-tomorrow",
        tags: ["bedtime", "meeting"]
    },
    {
        title: "Lock all doors and windows",
        activities: "bedtime",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "lock-doors",
        tags: ["bedtime", "home", "security"]
    },
    {
        title: "Set alarm for morning",
        activities: "bedtime",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "set-alarm",
        tags: ["bedtime", "work"]
    },
    {
        title: "Set coffee maker timer for morning",
        activities: "bedtime",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "set-coffee-timer",
        tags: ["bedtime", "coffee"]
    },
    {
        title: "Wash face in evening",
        activities: "bedtime",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "wash-face-evening",
        tags: ["bedtime", "grooming"]
    },

    // Chores
    {
        title: "Add water to ice tray",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "add-water-to-ice-tray",
        tags: ["chores"]
    },
    {
        title: "Clean coffee maker",
        activities: "chores",
        schedule: {
            repeat: "weekly"
        },
        suggestionKey: "clean-coffee-maker",
        tags: ["chores", "coffee"]
    },
    {
        title: "Declutter for a few minutes",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "declutter",
        tags: ["chores"]
    },
    {
        title: "Empty bathroom garbage",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "empty-bathroom-garbage",
        tags: ["chores"]
    },
    {
        title: "Empty kitchen garbage",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "empty-kitchen-garbage",
        tags: ["chores"]
    },
    {
        title: "Empty kitchen recycling",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "empty-kitchen-recycling"
    },
    {
        title: "Load dishwasher",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "load-dishwasher"
    },
    {
        title: "Make bed",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "make-bed"
    },
    {
        title: "Mop kitchen floor",
        activities: "chores",
        schedule: {
            repeat: "weekly"
        },
        suggestionKey: "mop-kitchen-floor"
    },
    {
        title: "Put dirty laundry in hamper",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "put-laundry-in-hamper"
    },
    {
        title: "Refill coffee maker with water",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "refill-coffee-maker-water"
    },
    {
        title: "Scrub shower",
        activities: "chores",
        schedule: {
            repeat: "weekly"
        },
        suggestionKey: "scrub-shower"
    },
    {
        title: "Unload dishwasher",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "unload-dishwasher"
    },
    {
        title: "Wash dishes",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "wash-dishes"
    },
    {
        title: "Water plants",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "water-plants"
    },
    {
        title: "Wipe base of electric tooth brush",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clean-electric-toothbrush"
    },
    {
        title: "Wipe bathroom mirror",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clean-bathroom-mirror"
    },
    {
        title: "Wipe bathroom sink",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clean-bathroom-sink"
    },
    {
        title: "Wipe toilet tank",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clean-toilet-tank"
    },
    {
        title: "Wipe kitchen counters",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clean-kitchen-counters"
    },
    {
        title: "Wipe kitchen garbage can",
        activities: "chores",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clean-kitchen-garbage"
    },

    // Exercise and diet
    {
        title: "Do 15 situps",
        activities: "exercise",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "exercise-situps-15"
    },
    {
        title: "Do 35 pushups",
        activities: "exercise",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "exercise-pushups-35"
    },
    {
        title: "Plank for one minute",
        activities: "exercise",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "plank-1-minute"
    },
    {
        title: "Stretch back",
        activities: "exercise",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "stretch-back"
    },
    {
        title: "Take your vitamins",
        activities: "exercise",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "take-vitamins"
    },
    {
        title: "Walk or jog in the morning",
        activities: "exercise",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "walk-morning"
    },

    // Financial
    {
        title: "Check bank account",
        activities: "finances",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "check-bank-account",
        tags: [ "finances" ],
    },
    {
        title: "Check postal mail",
        activities: "finances",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "check-postal-mail",
    },
    {
        title: "Check upcoming bills",
        activities: "finances",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "check-upcoming-bills"
    },
    { 
        title: "Archive postal mail",
        activities: "finances",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "archive-postal-mail"
    },

    // Gardening
    {
        title: "Change water in bird bath",
        activities: "gardening",
        schedule: {
            daysOfWeek: "wed,sat"
        },
        suggestionKey: "change-water-bird-bath"
        // https://birdingoutdoors.com/how-often-you-should-change-bird-bath-water-a-useful-guide
    },

    // Grooming
    {
        title: "Brush teeth in morning",
        activities: "grooming",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "brush-teeth-morning"
    },
    {
        title: "Check and clip fingernails",
        activities: "grooming",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clip-fingernails"
    },
    {
        title: "Check and clip toenails",
        activities: "grooming",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clip-toenails"
    },
    {
        title: "Wash face in morning",
        activities: "grooming",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "wash-face-morning"
    },

    // Social
    {
        title: "Call parents",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "call-parents"
    },

    // Spiritual
    {
        title: "Find a quiet spot and pray to God",
        activities: "spirituality",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "pray"
    },
    {
        title: "Get rid of one material possession per month",
        activities: "spirituality",
        schedule: {
            daysOfMonth: "1"
        },
        suggestionKey: "get-rid-of-possession"
    },

    // Wakeup
    {
        title: "Write down your dreams",
        activities: "wakeup",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "write-down-dreams"
    },

    // Work
    {
        title: "Update timesheet",
        activities: "work",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "timesheet-update"
    },
    {
        title: "Clean your workspace",
        activities: "work",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "clean-workspace"
    },
    {
        title: "For the next moment, let go of all stress",
        activities: "work",
        schedule: {
            repeat: "hourly",
            daysOfWeek: "weekdays"
        },
        suggestionKey: "release-stress"
    },

    // Meta
    {
        title: "#this affects the structure of your reality",
        activities: "#this",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "#this-affects-structure-of-reality"
    },
    {
        title: "Recursively get stronger - #this",
        activities: "#this",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "#this-recursively-get-stronger"
    },
    {
        title: "Make #this work for you",
        activities: "#this",
        schedule: {
            daysOfWeek: "daily"
        },
        suggestionKey: "make-#this-work"
    }
];
