import React from "react"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const StreamView: React.FC<ReactViewProps> = (props) => {

    // This is the default stream view that will get selected if 
    // a better match is not found. For the default, fallback to a card.
    return (
        <ReactViewFactory
            dispatch={props.dispatch}
            layout="card"
            matcher={props.matcher}
            model={props.model}>
        </ReactViewFactory>
    )
}