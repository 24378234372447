import { Annotator } from "../annotator/Annotator"
import { Link } from "../link/Link"
import { LinkSchemaId } from "../link/LinkSchemaId"
import { Payload } from "../payload/Payload"

export class LinkAnnotator implements Annotator<Link> {

    public async annotate(payload: Payload<Link>): Promise<void> {
        if (payload?.data && (payload.schema === LinkSchemaId)) {
            payload.key = payload.data.url ?? payload.key;
            payload.title = payload.data.title ?? payload.data.url ?? payload.title;
        }
    }
}