import { DefaultStoreContextKey } from "./DefaultStoreContextKey"
import { DefaultStorePluginKey } from "./DefaultStorePluginKey"
import { IDBStore } from "../store/IDBStore"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { StorePluginKey } from "../store/StorePluginKey"

export const DefaultStoreManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: DefaultStorePluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        StorePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: DefaultStoreContextKey,
            title: "Default Store",
            value: new IDBStore("motley.stream", "Payloads")
        } as ManifestEntry<IDBStore>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Default Store"
}