import React from "react"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { List } from "../list/List"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import "./ListStreamView.css"

export const ListStreamView: React.FC<ReactViewProps> = (props) => {

    const payload = props?.model?.payload;
    if (!payload) {
        return (
            <ReactViewFailure>
                payload missing
            </ReactViewFailure>
        )
    }

    const list = payload.data as List;
    if (!list) {
        return (
            <ReactViewFailure>
                list object missing
            </ReactViewFailure>
        )
    }

    return (
        <IonCard>
            <IonCardHeader>
                <div className="list-stream-view-header">
                    <div className="list-stream-view-emblem">
                        <ReactViewFactory
                            dispatch={props.dispatch}
                            matcher={props.matcher}
                            layout="emblem"
                            model={props.model}>
                        </ReactViewFactory>
                    </div>
                    <IonCardTitle className="list-stream-view-title">{list.title ?? "List"}</IonCardTitle>
                </div>
            </IonCardHeader>
            <IonCardContent>
                <ReactViewFactory
                    layout="card"
                    dispatch={props.dispatch}
                    matcher={props.matcher}
                    model={props.model}>
                </ReactViewFactory>
            </IonCardContent>
        </IonCard>
    );
}