import { Payload } from "../payload/Payload"
import { SunriseSunsetFeed } from "./SunriseSunsetFeed"
import { SunriseSunsetFeedSchema } from "./SunriseSunsetFeedSchema"

export const SunriseSunsetCatalog: Payload<SunriseSunsetFeed>[] = [
    {
        key: "sunrise-sunset/Seattle",
        schema: SunriseSunsetFeedSchema,
        data: {
            latitude: 47.609722,
            longitude: -122.333056,
            title: "Seattle"    
        }
    }
]
