import React from "react"
import { IonButton } from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { RootSchemaId } from "../root/RootSchemaId"
import { ReactViewFactory } from "../react-view/ReactViewFactory"

export const RootToolbarButton: React.FC<ReactViewProps> = (props) => {

    const rootPayload: Payload = {
        schema: RootSchemaId,
        data: {}
    };

    function onClick() {
        if (props.dispatch) {
            props.dispatch(rootPayload);
        }
    }

    return (
        <IonButton onClick={() => onClick()} size="large">
            <ReactViewFactory
                layout="emblem"
                dispatch={props.dispatch}
                matcher={props.matcher}
                model={{
                    payload: rootPayload
                }}>
            </ReactViewFactory>
        </IonButton>
    );    
}