import { SequenceInstance } from "../sequence/SequenceInstance"
import { SequenceCreator } from "../sequence/SequenceCreator"
import { Sequence } from "../sequence/Sequence"

export class SequenceMapFactory {

    /**
     * The creator responsible for assembling a sequence object.
     */
    private creator: SequenceCreator;

    /**
     * The sequences that can be created by the factory.
     */
    private sequences: Map<string, Sequence>;

    constructor(creator: SequenceCreator, sequences: Map<string, Sequence>) {
        if (!(this.creator = creator)) {
            throw new Error("creator must be specified");
        }

        if (!(this.sequences = sequences)) {
            throw new Error("sequences map must be specified");
        }
    }

    /**
     * Creates an instance of a sequence.
     */
    public async create(value: string | Sequence): Promise<SequenceInstance | undefined> {

        if (!value) {
            return undefined;
        }

        switch(typeof(value)) {

            case "string":
                {
                    const settings = this.sequences?.get(value)
                    if (settings) {
                        return this.creator.create(settings);
                    }
                    else {
                        return undefined;
                    }        
                }

            case "object":
                return this.creator.create(value);

            default:
                return undefined;
        }
    }
}