import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonImg
} from "@ionic/react"
import { NewsArticle } from "../newsapi/NewsArticle"

export const NewsArticleCardView: React.FC<ReactViewProps> = (props) => {

    let article: NewsArticle = props?.model?.payload?.data;
    if (!article) {
        return <></>
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{article.title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {article.description}
                {article.urlToImage && 
                    <IonImg src={article.urlToImage}></IonImg>
                }
            </IonCardContent>
        </IonCard>
    );
};