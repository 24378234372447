import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SnippetIndexer } from "./SnippetIndexer"
import { SnippetIndexerPluginKey } from "./SnippetIndexerPluginKey"
import { SnippetPluginKey } from "../snippet/SnippetPluginKey"

/**
 * The settings of the plugin.
 */
export const SnippetIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SnippetIndexerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DefaultStorePluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        SnippetPluginKey,
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "SnippetIndexer",
            registerWith: IndexerRegistryContextKey,
            requires: [DefaultStoreContextKey],
            title: "Snippet Indexer",
            value: (context: ManifestContext) => ({
                schema: "*",
                indexer: new SnippetIndexer(context[DefaultStoreContextKey])
            })
        } as ManifestEntry<IndexerRegistryEntry<SnippetIndexer>>
    ],        

    /**
     * The display title of the plugin.
     */
    title: "Snippet Indexer"
};