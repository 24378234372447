import React  from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { IonText } from "@ionic/react"
import { Task } from "../task/Task"
import "./TaskCardView.css"

export const TaskCardView: React.FC<ReactViewProps> = (props) => {

    let task: Task = props?.model?.payload?.data;
    if (!task) {
        return <></>
    }

    return (
        <div className="note-card">
            <div className="note-paper yellow selectable">
                <IonText>{task.title}</IonText>
            </div>
        </div>
    );
};