import React  from "react"
import { ComCatProperties } from "../usgs-earthquake/ComCatProperties"
import { Formatter } from "../formatter/Formatter"
import { KilometersSchemaId } from "../kilometers/KilometersSchemaId"
import { LatitudeSchemaId } from "../latitude/LatitudeSchemaId"
import { LongitudeSchemaId } from "../longitude/LongitudeSchemaId"
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonItem,
    IonLabel,
    IonList,
    IonText
} from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./EarthquakeDetailView.css"

/**
 * Translates an alert level into an Ionic theme color.
 */
function alertColor(alertLevel?: string | undefined | null) {

    switch(alertLevel) {
        case "green":
            return "success";

        case "yellow":
        case "orange":
            return "warning";

        case "red":
            return "danger";

        default:
            return "";
    }

}

export interface EarthquakeDetailViewProps extends ReactViewProps {

    /**
     * The formatter to use for displaying latitude, longitude, etc., in human-friendly format.
     */
    formatter: Formatter
}

export const EarthquakeDetailView: React.FC<EarthquakeDetailViewProps> = (props) => {

    // Get the underlying properties of the feature. This contains earthquake data.
    const feature = props?.model?.payload?.data;
    if (!feature) {
        return <></>
    }
    
    const fprops = feature.properties as ComCatProperties;

    return (
        <IonCard className="detail">
            <IonCardHeader>
                <h1>
                    <img
                        alt=""
                        className="earthquake-icon"
                        style={{fill: "white"}}
                        src={`/assets/weather-icons/svg/wi-earthquake.svg`}/>  
                    Details
                </h1>
            </IonCardHeader>
            <IonCardContent>
                <IonList>
                    <IonItem>
                        <IonLabel slot="start">Alert level</IonLabel>
                        <IonText color={alertColor(fprops.alert)}>{fprops.alert ?? "None"}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel slot="start">Depth</IonLabel>
                        <IonText>
                            {props.formatter.format(KilometersSchemaId, feature.geometry?.coordinates[2])}
                        </IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel slot="start">Felt Reports</IonLabel>
                        <IonText>{fprops.felt ?? "0"}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel slot="start">Latitude</IonLabel>
                        <IonText>
                            {props.formatter.format(LatitudeSchemaId, feature.geometry?.coordinates[0])}
                        </IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel slot="start">Longitude</IonLabel>
                        <IonText>
                            {props.formatter.format(LongitudeSchemaId, feature.geometry?.coordinates[1])}
                        </IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel slot="start">Magnitude</IonLabel>
                        <IonText>{fprops.mag}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel slot="start">Seismic Event</IonLabel>
                        <IonText>{fprops.type}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel slot="start">Time</IonLabel>
                        <IonText>{new Date(fprops.time).toString()}</IonText>
                    </IonItem>
                </IonList>
            </IonCardContent>
        </IonCard>
    )
};