import { DateNumberPluginKey } from "../date-number/DateNumberPluginKey"
import { DateNumberReactInput } from "./DateNumberReactInput"
import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactInputRegistryContextKey } from "../react-input/ReactInputRegistryContextKey"
import { ReactInputPluginKey } from "../react-input/ReactInputPluginKey"
import { ReactInputRegistryEntry } from "../react-input/ReactInputRegistryEntry"

export const DateNumberReactInputManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "date-number-react-input",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DateNumberPluginKey,
        ReactInputPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DateNumberReactInput",
            registerWith: ReactInputRegistryContextKey,
            value: {
                match: (s) => s.$id === DateNumberSchemaId,
                fc: DateNumberReactInput
            }
        } as ManifestEntry<ReactInputRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Date Number Input Field"
}