import { ComCatProperties } from "../usgs-earthquake/ComCatProperties"
import { EarthquakeSchemaId } from "../usgs-earthquake/EarthquakeSchemaId"
import { Feature } from "../geojson/Feature"
import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId"
import { wrapSearchString } from "../search-string/wrapSearchString"

export class EarthquakeIndexer implements Indexer {

    /**
     * Returns payloads derived from the earthquake data, such as geolocation.
     */
    public async index(payload: Payload | undefined): Promise<Payload[]> {

        if (!payload) {
            return [];
        }

        if (payload.schema !== EarthquakeSchemaId) {
            return [];
        }

        const feature = payload.data as Feature;
        if (!feature) {
            return [];
        }

        // In GeoJSON, each feature has a "properties" object. The
        // USGS earthquake service fills the properties with information
        // about the earthquake such as magnitude.
        const props = feature.properties as ComCatProperties;
        if (!props) {
            return [];
        }

        const payloads: Payload[] = [
            {
                schema: UrlSchemaId,
                key: props.url,
                data: props.url,
                title: "USGS Summary"
            },
            {
                schema: UrlSchemaId,
                key: String(feature.id) ?? "FeltItReport",
                data: `https://earthquake.usgs.gov/earthquakes/eventpage/${feature.id}/tellus`,
                title: "Felt it? Report it" 
            }
        ];

        // Add search terms
        const titleLocation = this.parseTitleLocation(props.title);
        if (titleLocation) {
            payloads.push(wrapSearchString(titleLocation));
        }

        if (props.place && props.place !== titleLocation) {
            payloads.push(wrapSearchString(props.place));
        }
        
        return payloads;
    }

    public parseTitleLocation(title: string | undefined | null): string | undefined {

        if (!title) {
            return undefined;
        }

        const index = title.indexOf(" of ");
        if (index === undefined || index === -1) {
            return undefined;
        }

        return title.substr(index + 4);
    }
}