import { FormatterPluginKey } from "../formatter/FormatterPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryEntry } from "../formatter-registry/FormatterRegistryEntry"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { LatitudeFormatter } from "./LatitudeFormatter"
import { LatitudeFormatterContextKey } from "./LatitudeFormatterContextKey"
import { LatitudeFormatterPluginKey } from "./LatitudeFormatterPluginKey"
import { LatitudePluginKey } from "../latitude/LatitudePluginKey"
import { LatitudeSchemaId } from "../latitude/LatitudeSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const LatitudeFormatterManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: LatitudeFormatterPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        FormatterPluginKey,
        FormatterRegistryPluginKey,
        LatitudePluginKey,
    ],

    entries: [

        // formatter
        {
            key: LatitudeFormatterContextKey,
            value: new LatitudeFormatter()
        } as ManifestEntry<LatitudeFormatter>,

        // registration
        {
            key: "LatitudeFormatterRegistryEntry",
            registerWith: FormatterRegistryContextKey,
            requires: [LatitudeFormatterContextKey],
            value: (context: ManifestContext) => ({
                schemaId: LatitudeSchemaId,
                formatter: context[LatitudeFormatterContextKey]
            })
        } as ManifestEntry<FormatterRegistryEntry<LatitudeFormatter>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Latitude Formatter"
}