import React  from "react"
import { SunriseSunsetResponse } from "./SunriseSunsetResponse"
import "./SunriseSunsetCard.css"
//import '/public/assets/icon/Sunrise_icon_iOS.png';
//import '/public/assets/icon/Sunset_icon_iOS.png';

import {
    IonIcon
} from '@ionic/react';

import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { ReactViewProps } from '../react-view/ReactViewProps';

export const SunriseSunsetCard: React.FC<ReactViewProps> = (props) => {
    
    function getLocalTime(date: string) {
        let returnDate: Date = new Date(date);
        return returnDate.toLocaleTimeString();
    }

    let response: SunriseSunsetResponse | undefined = props?.model?.payload?.data;
    if (!response) {
        return <></>
    }

    /**
     * <img src="/assets/icon/Sunrise_icon_iOS.png" className="sun-action-icon"></img>
     * <img src="/assets/icon/Sunset_icon_iOS.png" className="sun-action-icon"></img>
     */
    return (
        <div className="content">
            <div className="sunrise">
                <p className="time">{getLocalTime(response.results.sunrise)}</p>
                <h2 className="sun-action">Sunrise</h2>
                <IonIcon className="arrow-icon" icon={arrowUpOutline}></IonIcon>
            </div>
            <div className="sun-action-container">
                <img alt="The Sun" src="/assets/sun2.svg" className="sun-action-icon"></img>
            </div>
            <div className="sunset">
                <IonIcon className="arrow-icon" icon={arrowDownOutline}></IonIcon>
                <h2 className="sun-action">Sunset</h2>
                <p className="time">{getLocalTime(response.results.sunset)}</p>
            </div>
        </div>
    )
};