import { BillCardView } from "./BillCardView"
import { BillPluginKey } from "../bill/BillPluginKey"
import { BillSchemaId } from "../bill/BillSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"

export const BillCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "bill-card-view",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BillPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "BillCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Bill Card View",
            value: {
                layout: ["card", "preview"],
                match: BillSchemaId,
                fc: BillCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Bill Card View",
}