import { BingWallpaperFeed } from "../bing-wallpaper-feed/BingWallpaperFeed"
import { BingWallpaperFeedSchemaId } from "../bing-wallpaper-feed/BingWallpaperFeedSchemaId"
import { Payload } from "../payload/Payload"

export const BingWallpaperFeedCatalog: Payload<BingWallpaperFeed>[] = [
    {
        key: "bing-daily-image",
        schema: BingWallpaperFeedSchemaId,
        title: "Bing Image of the Day",
        data: {
            locale: "en-us"
        }
    }
]