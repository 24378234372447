import { DeadlineAnnotator } from "../deadline-annotator/DeadlineAnnotator"
import { Deadline } from "../deadline/Deadline"
import { DeadlinePredicates } from "../deadline/DeadlinePredicates"
import { DeadlineSchemaId } from "../deadline/DeadlineSchemaId"
import { List } from "../list/List"
import { ListSchemaId } from "../list/ListSchemaId"
import { Payload } from "../payload/Payload"
import { Store } from "../store/Store"
import { Streamer } from "../streamer/Streamer"

/**
 * The amount of time (in ms) when a deadline is considered upcoming.
 */
const UPCOMING_THRESHOLD = 1000 * 60 * 60 * 24 * 30;

export class DeadlineStreamer implements Streamer {

    private sent: boolean = false;
    private store: Store;

    constructor(store: Store) {
        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }
    }

    public async next(): Promise<Payload | undefined> {

        if (this.sent) {
            return undefined;
        }

        // Get current deadlines from the store
        const deadlines: Payload<Deadline>[] = await this.store.schema(DeadlineSchemaId);
        if (deadlines.length === 0) {
            return undefined;
        }

        // Filter out inactive deadlines
        const filtered = deadlines.filter(DeadlinePredicates.Upcoming(UPCOMING_THRESHOLD));
        if (filtered.length === 0) {
            return undefined;
        }

        // Sort by date
        filtered.sort((a,b) => {

            const ad = a.data.deadlineOn!; // the predicate ensures non-null dates
            const bd = b.data.deadlineOn!;

            return ad - bd;
        });

        // HACK: until annotation is centralized
        const annotator = new DeadlineAnnotator();
        for(const p of filtered) {
            await annotator.annotate(p);
        }

        const listPayload: Payload<List> = {
            schema: ListSchemaId,
            data: {
                items: filtered,
                title: "Upcoming Deadlines"
            }
        }

        this.sent = true;
        return listPayload;
    }
}