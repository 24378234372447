import { IonCard, IonCardContent } from "@ionic/react";
import React, { useState } from "react"
import { Flashcard } from "../flashcard/Flashcard";
import { ReactViewProps } from "../react-view/ReactViewProps"
import "./FlashcardCardView.css"

export const FlashcardCardView: React.FC<ReactViewProps> = (props) => {

    const [shown, setShown] = useState(false);
    
    const flashcard: Flashcard = props?.model?.payload?.data;
    if (!flashcard) {
        return <></>
    }

    function onClick() {
        setShown(prev => !prev);
    }

    return (
        <IonCard className="flashcard-card" button={true} onClick={(e) => onClick()}>
            <IonCardContent className="flashcard-card-title">
                {shown ? flashcard.back : flashcard.front}
            </IonCardContent>
        </IonCard>
    )
}