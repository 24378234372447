import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { MathProblemCardView } from "./MathProblemCardView"
import { MathProblemPluginKey } from "../math-problem/MathProblemPluginKey"
import { MathProblemSchemaId } from "../math-problem/MathProblemSchemaId"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const MathProblemCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "math-problem-card-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        MathProblemPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "MathProblemCardView",
            registerWith: ReactViewRegistryContextKey,
            title: "Math Problem Card View",
            value: {
                layout: ["card", "preview"],
                match: MathProblemSchemaId,
                fc: MathProblemCardView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],    

    /**
     * The display title of the plugin.
     */
    title: "Math Problem Card View"
};
