import React from "react"
import { bug, informationCircle, warning} from "ionicons/icons"
import { IonIcon } from "@ionic/react"
import { Notice } from "../notice/Notice"
import { ReactViewProps } from "../react-view/ReactViewProps"

function icon(notice: Notice): string {

    switch(notice?.level) {

        case "warn":
            return warning;

        case "debug":
            return bug;

        default:
            return informationCircle;

    }
}

export const NoticeEmblemView: React.FC<ReactViewProps> = (props) => {

    return (
        <IonIcon style={{
            width:"32px",
            height: "32px"
        }} icon={icon(props.model?.payload?.data)} />
    )
}