import { Annotator } from "../annotator/Annotator"
import { Notice } from "../notice/Notice"
import { NoticeSchemaId } from "../notice/NoticeSchemaId"
import { Payload } from "../payload/Payload"

export class NoticeAnnotator implements Annotator<Notice> {
    public async annotate(payload: Payload<Notice>): Promise<void> {
        if ((payload?.schema === NoticeSchemaId) && (payload.data)) {
            payload.title = payload.data.title ?? payload.title ?? "Notice"
        }
    }
}