import { IDBPDatabase, openDB } from "idb"
import { migrateBeta } from "./migrateBeta"
import { Payload } from "../payload/Payload"
import { Store } from "./Store"

/**
 * The name of the index of parent IDs.
 */
const PARENT_ID_INDEX = "parentId";

/**
 * The name of the index of payload paths.
 */
 const PATH_INDEX = "path";

/**
 * The name of the index of payload schemas.
 */
const SCHEMA_INDEX = "schema";

/**
 * The name of the index of payload tags.
 */
const TAGS_INDEX = "tags";

export class IDBStore implements Store {

    private _edb: Promise<IDBPDatabase>;

    constructor(public databaseName: string, public tableName: string) {

        this._edb = openDB(databaseName, 4, {

            // Code example on https://www.npmjs.com/package/idb
            upgrade(db, oldVersion, newVersion, transaction) {

                if (oldVersion < 1) {
                    const store = db.createObjectStore(tableName, {
                        keyPath: 'id',
                        autoIncrement: true,
                    });
                    store.createIndex(PATH_INDEX, 'path', { unique: false });
                }

                if (oldVersion < 2) {
                    const store = transaction.objectStore(tableName);
                    store.createIndex(TAGS_INDEX, 'tags', { multiEntry: true });
                }

                if (oldVersion < 3) {
                    const store = transaction.objectStore(tableName);
                    store.createIndex(SCHEMA_INDEX, 'schema', { unique: false });
                }

                if (oldVersion < 4) {
                    const store = transaction.objectStore(tableName);
                    store.createIndex(PARENT_ID_INDEX, 'parentId', { unique: false });
                }
            },

        }).then(db => migrateBeta(db))
    }

    public async add(payload: Payload): Promise<Payload | undefined> {

        if (!payload) {
            return undefined;
        }

        return this._edb
            .then(db => {
                return db.add(this.tableName, payload).then(key => db.get(this.tableName, key));
            })
    }

    public async addRange(payloads: Payload[]): Promise<number[]> {

        // Get a connection to the database
        const db = await this._edb;

        // Open a transaction to add the items
        const tx = db.transaction(this.tableName, "readwrite");

        // Request to add the items
        const keys = await Promise.all(payloads.map(p => tx.store.add(p)));

        // Return the ID values
        return keys as number[];
    }

    public async all(): Promise<Payload[]> {
        return this._edb.then(db => db.getAll(this.tableName));
    }

    public async ancestors(id?: number): Promise<Payload[]> {

        const payloads: Payload[] = [];
        const ids = new Set<number>();

        let nextId = id;
        while (nextId !== undefined) {

            if (ids.has(nextId)) {
                // This payload is already in the list.
                // This probably means a circular tree.
                console.error(`payload ${nextId} has circular parent reference`);
                return payloads;
            } 
            else {
                ids.add(nextId);
            }

            const payload = await this.get(nextId);
            if (payload) {
                payloads.unshift(payload);
                nextId = payload.parentId;
            }
            else {
                nextId = undefined;
            }
        }

        return payloads;
    }

    public async children(id?: number): Promise<Payload[]> {
        const db = await this._edb;
        if (id) {
            return db.getAllFromIndex(this.tableName, PARENT_ID_INDEX, id);
        }
        else {
            return db.getAll(this.tableName).then(payloads => payloads.filter(p => p.parentId === undefined));
        }        
    }

    public async count(parentId?: number | undefined): Promise<number> {

        // TODO: handle root folder
        return this._edb.then(db => db.countFromIndex(this.tableName, PARENT_ID_INDEX, parentId));
    }

    public async delete(id: number): Promise<Payload | undefined> {

        // Open the database
        const db = await this._edb;

        // Get the original object
        const deleted: Payload | undefined = await db.get(this.tableName, id);

        if (deleted) {
            await db.delete(this.tableName, id);
        }

        // TODO: do something like deleted.deleted = true;
        return deleted;
    }

    public async deleteRange(ids: number[]) {

        // Get a connection to the database
        const db = await this._edb;

        // Delete teach item
        await Promise.all(ids.map(id => db.delete(this.tableName, id)));
    }

    public get(id: number): Promise<Payload | undefined> {
        return this._edb.then(db => db.get(this.tableName, id));
    }

    public async getRange(ids: number[]): Promise<(Payload | undefined)[]> {
        // Get a connection to the database
        const db = await this._edb;

        // Open a transaction to add the items
        const tx = db.transaction(this.tableName, "readonly");

        // Request to get the items
        const payloads = await Promise.all(ids.map(p => tx.store.get(p)));

        // Return non-empty results
        return payloads;
    }

    public async put(payload: Payload): Promise<Payload | undefined> {
        // Open the database
        const db = await this._edb;

        // Save the payload
        const key = await db.put(this.tableName, payload);

        // Return the saved object
        return db.get(this.tableName, key);
    }

    public async putRange(payloads: Payload[]): Promise<(number | undefined)[]> { 
        // Get a connection to the database
        const db = await this._edb;

        // Open a transaction to add the items
        const tx = db.transaction(this.tableName, "readwrite");

        // Request to put the items
        const keys = await Promise.all(payloads.map(p => tx.store.put(p)));

        // Return the ID values
        return keys as (number | undefined)[];
    }

    public async schema(schemaId: string): Promise<Payload[]> {
        // Open the database
        const db = await this._edb;

        // Use the schema index to return all payloads of the given schema
        return db.getAllFromIndex(this.tableName, SCHEMA_INDEX, schemaId)
    }

    public async tagged(path: string): Promise<Payload[]> {
        // Open the database
        const db = await this._edb;

        // Return all payloads in the path using the tags index
        return db.getAllFromIndex(this.tableName, TAGS_INDEX, path)
    }
}