const MS_SECOND = 1000;
const MS_MINUTE = MS_SECOND * 60;
const MS_HOUR   = MS_MINUTE * 60;
const MS_DAY    = MS_HOUR   * 24;

/**
 * Formats a timespan into common language.
 */
export function formatTimespan(ms: number): string {

    const lookups: [number, string][] = [
        [MS_SECOND,       "a second"],
        [MS_SECOND * 10,  "a few seconds"],
        [MS_SECOND * 50,  `${(ms / MS_SECOND) >> 0} seconds`],
        [MS_SECOND * 70,  "a minute"],
        [MS_SECOND * 100,  `${(ms / MS_SECOND) >> 0} seconds`],
        [MS_SECOND * 130, "2 minutes ago"],
        [MS_MINUTE * 55,  `${(ms / MS_MINUTE) >> 0} minutes`],
        [MS_MINUTE * 65,  "an hour"],
        [MS_MINUTE * 95,  `${(ms / MS_MINUTE) >> 0} minutes`],
        [MS_HOUR   * 23,  `about ${Math.round(ms / MS_HOUR)} hours`],
        [MS_HOUR   * 36,  "about a day"],
        [MS_HOUR   * 47,  `about ${Math.round(ms / MS_HOUR)} hours`],
        [MS_HOUR   * 49,  `2 days`],
        [Number.MAX_SAFE_INTEGER, `about ${Math.round(ms / MS_DAY)} days`]
    ];

    for(let lookup of lookups) {
        if(ms < lookup[0]) {
            return lookup[1];
        }
    }

    return "";
}