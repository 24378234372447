import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SubredditFeedPluginKey } from "../subreddit-feed/SubredditFeedPluginKey"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId"
import { SubredditFeedIndexer } from "./SubredditFeedIndexer"
import { UrlPluginKey } from "../url/UrlPluginKey"

export const SubredditFeedIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "subreddit-feed-indexer",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        SubredditFeedPluginKey,
        UrlPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "SubredditFeedIndexer",
            registerWith: IndexerRegistryContextKey,
            value: {
                schema: SubredditFeedSchemaId,
                indexer: new SubredditFeedIndexer()
            }
        } as ManifestEntry<IndexerRegistryEntry<SubredditFeedIndexer>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Subreddit Feed Indexer",
};
