import React from "react"
import { IonIcon } from "@ionic/react"
import { linkOutline as icon } from "ionicons/icons"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const UrlEmblemView: React.FC<ReactViewProps> = (props) => {

    const url = props?.model?.payload?.data;

    // Color the URL if insecure
    let color: string | undefined;
    if ((typeof(url) === "string") && (url.substr(0, 7) === "http://")) {
        color = "danger";
    }

    return (
        <IonIcon color={color} style={{
            width:"32px",
            height: "32px"
        }} icon={icon} />
    )
}