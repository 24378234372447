import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewMatcherContextKey } from "./ReactViewMatcherContextKey"
import { ReactViewPluginKey } from "./ReactViewPluginKey"
import { ReactViewRegistry } from "./ReactViewRegistry"
import { ReactViewRegistryContextKey } from "./ReactViewRegistryContextKey"
import { ReactViewRegistryMatcher } from "./ReactViewRegistryMatcher"

export const ReactViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ReactViewPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: false,

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // registry
        {
            key: ReactViewRegistryContextKey,
            value: new ReactViewRegistry()
        } as ManifestEntry<ReactViewRegistry>,

        // matcher
        {
            key: ReactViewMatcherContextKey,
            requires: [ReactViewRegistryContextKey],
            value: (context: ManifestContext) =>
                new ReactViewRegistryMatcher(context[ReactViewRegistryContextKey])
        }
    ],

    /**
     * The display title of the plugin.
     */
    title: "React View"
};
