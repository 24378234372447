import React from "react"
import {
    ellipseOutline as openIcon,
    checkmarkCircleOutline as completedIcon,
    closeCircleOutline as canceledIcon
} from "ionicons/icons"
import { IonIcon } from "@ionic/react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Task } from "../task/Task"
import { TaskStatus } from "../task/TaskStatus"

export const TaskEmblemView: React.FC<ReactViewProps> = (props) => {

    const task = props?.model?.payload?.data as Task;

    switch(task?.status) {

        case TaskStatus.Completed:
            return (
                <IonIcon color="success" style={{
                    width:"32px",
                    height: "32px"
                }} icon={completedIcon} />
            );

        case TaskStatus.Canceled:
            return (
                <IonIcon color="warning" style={{
                    width:"32px",
                    height: "32px"
                }} icon={canceledIcon} />
            );

        default:
            return (
                <IonIcon style={{
                    width:"32px",
                    height: "32px"
                }} icon={openIcon} />
            );
    }
}