import { DateNumberSchemaId } from "../date-number/DateNumberSchemaId";
import { Formatter } from "../formatter/Formatter"

export class DateNumberFormatter implements Formatter {

    public format(schemaId: string, value: any): string | undefined {
        if ((schemaId === DateNumberSchemaId) && typeof(value) === "number") {
            return new Date(value).toLocaleString();
        }
    }
}