import { FormatterPluginKey } from "./FormatterPluginKey"
import { Manifest } from "../../runtime/Manifest"

export const FormatterManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: FormatterPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The dislay title of the plugin.
     */
    title: "Formatter"
}