import React, { useEffect, useState } from "react"
import { getActivity } from "./getActivity"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle  } from "@ionic/react"
import { List } from "../list/List"
import { Payload } from "../payload/Payload"
import { PayloadPredicate } from "../payload/PayloadPredicate"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Store } from "../store/Store"
import { Task } from "../task/Task"
import { TaskPredicates } from "../task/TaskPredicates"
import { TaskSchemaId } from "../task/TaskSchemaId"
import { ListSchemaId } from "../list/ListSchemaId"

interface ActivityTasksDetailViewProps extends ReactViewProps {
    store: Store
}

export const ActivityTasksDetailView: React.FC<ActivityTasksDetailViewProps> = (props) => {

    const activity = getActivity(props.model?.payload);
    const store = props?.store;
    const [taskPayloads, setTaskPayloads] = useState<Payload[]>();
    
    useEffect(() => {

        let mounted = true;

        const predicate: PayloadPredicate<Task> = (p: Payload<Task>) => {
            return TaskPredicates.active(p) && (p.data.activities === activity);
        }
    
        if (activity && store) {
            store.schema(TaskSchemaId).then(payloads => {
                if (mounted && Array.isArray(payloads)) {
                    setTaskPayloads(payloads.filter(predicate));
                }
            });
        }

        return () => { mounted = false };

    }, [activity, store]);

    return (
        <>
        {taskPayloads && taskPayloads.length > 0 &&
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Tasks</IonCardTitle>
                </IonCardHeader>
                <IonCardContent> 
                    <ReactViewFactory
                        dispatch={props.dispatch}
                        layout="card"
                        matcher={props.matcher}
                        model={{
                            payload: {
                                schema: ListSchemaId,
                                data: {
                                    items: taskPayloads
                                }
                            } as Payload<List>
                        }}>

                    </ReactViewFactory>
                </IonCardContent>
            </IonCard>
        }
        </>
    )
}