import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NasaApodFeedPluginKey } from "../nasa-apod-feed/NasaApodFeedPluginKey"
import { NasaApodFeedPollerCreator } from "./NasaApodFeedPollerCreator"
import { NasaApodFeedPollerPluginKey } from "./NasaApodFeedPollerPluginKey"
import { NasaApodFeedSchemaId } from "../nasa-apod-feed/NasaApodFeedSchemaId"
import { NasaApodPluginKey } from "../nasa-apod/NasaApodPluginKey"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryEntry } from "../poller-creator-registry/PollerCreatorRegistryEntry"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"
import { PollerPluginKey } from "../poller/PollerPluginKey"

export const NasaApodFeedPollerManifest: Manifest = {

    /**
     * The key that uniquely identifies this plugin.
     */
    key: NasaApodFeedPollerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        NasaApodPluginKey,
        NasaApodFeedPluginKey,
        PollerPluginKey,
        PollerCreatorRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Provides a poller to show the daily Astronomy Picture of the Day from NASA.",

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "NasaApodFeedPollerCreator",
            registerWith: PollerCreatorRegistryContextKey,
            value: {
                schema: NasaApodFeedSchemaId,
                creator: new NasaApodFeedPollerCreator()
            }
        } as ManifestEntry<PollerCreatorRegistryEntry<NasaApodFeedPollerCreator>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "NASA Astronomy Picture of the Day Poller"
};
