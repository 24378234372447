import { Annotator } from "../annotator/Annotator"
import { StringSchemaId } from "../string/StringSchemaId"
import { Payload } from "../payload/Payload";

const MAX_LENGTH = 80;

export class StringAnnotator implements Annotator {

    public async annotate(payload: Payload<string>): Promise<void> {

        if (payload?.schema !== StringSchemaId) {
            return;
        }

        if (typeof(payload.data) !== "string") {
            return;
        }

        if (payload.data.length > MAX_LENGTH) {
            payload.title = payload.data.substr(0, MAX_LENGTH) + "...";
        }
        else {
            payload.title = payload.data;
        }
    }
}