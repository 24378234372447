import { fetchJson } from "../../library/fetchJson"
import { Listing } from "../reddit/Listing"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { RedditThingSchemaId } from "../reddit/RedditThingSchemaId"
import { T3 } from "../reddit/T3"
import { Thing } from "../reddit/Thing"

/**
 * A poller that returns payloads from a reddit forum, e.g., https://reddit.com/r/EarthPorn.
 */
export class SubredditFeedPoller implements Poller {

    /**
     * The name of the subreddit.
     */
    private name: string;

    /**
     * Initializes the poller against the specified subreddit.
     */
    constructor(name: string) {
        if (!(this.name = name)) {
            throw new Error("name must be specified");
        }
    }

    /**
     * Converts the JSON results to payloads.
     */
    public convertJson(rootThing: Thing | undefined): Payload[] {

        if (!rootThing) {
            return [];
        }

        let listing = rootThing.data as Listing;

        let payloads: Payload[] = listing.children.map( (child) => {

            let kind: string = child.kind;
            if (kind !== "t3") {
                console.warn(`unrecognized kind: ${kind}`);
                debugger;                
            }

            // Each child object is a thing.
            let t:T3 = child.data as T3;
    
            return {
                schema: RedditThingSchemaId,
                data: child,
                key: t.id,
                title: t.title
            }
        });

        return payloads;
    }

    /**
     * Fetches postings from the subreddit.
     */
    public async poll(): Promise<Payload[]> {
        return fetchJson<Thing>(this.getUrl(), true)
            .then(thing => this.convertJson(thing))
            .catch(ex => {
                console.error(ex);
                return [];
            });
    }

    /**
     * Returns the URL of the JSON API for the subreddit.
     */
    public getUrl(): string {

        // As of July 2020, the JSON for any reddit forum can be fetched by
        // appending ".json" to the URL. No authentication is required.
        return `https://www.reddit.com/r/${this.name}.json`;
    }
}