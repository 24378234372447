import { HeartbeatService } from "../service/HeartbeatService"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"
import { Store } from "../store/Store"

/** The default polling interval in milliseconds */
const DEFAULT_INTERVAL_MS = 1000 * 60 * 30;

/**
 * Feeds payloads into a store.
 */
export class PollerService extends HeartbeatService {

    /**
     * The parent item of polled payloads that are saved to the store.
     */
    private parent: Payload | undefined;

    /** The poller object that is polling for payloads */
    private poller: Poller;

    /** The destination store for payloads from the poller */
    private store: Store;

    /**
     * Feeds payloads into a folder.
     */
    constructor(
            poller: Poller,
            store: Store,
            parent: Payload | undefined = undefined,
            interval: number = DEFAULT_INTERVAL_MS) {

        // Set the heartbeat interval
        super(interval);

        if (!(this.poller = poller)) {
            throw new Error("poller must be specified");
        }

        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }

        this.parent = parent;
    }

    /**
     * Polls and merges items into the folder.
     */
    protected async onHeartbeat(): Promise<void> {

        // Fetch incoming payloads...
        const polled = await this.poller.poll();

        console.debug(
            `received ${polled.length} ` +
            `payload(s) from ${this.poller.constructor.name}`);

        if(polled.length === 0) {
            return;
        }

        // Fetch current items to merge against
        const against = await this.store.children(this.parent?.id);

        // Map keys for faster matching
        const againstKeys = new Map<string, Payload>(
            against.filter(p => p.key !== undefined).map(p => [p.key!, p])
        );

        // Track payloads to update or add to the store
        const putted: Payload[] = [];

        polled.forEach(p => {

            // See if this matches against an existing payload
            const existing = p.key ? againstKeys.get(p.key) : undefined;
            if (existing) {
                existing.data = p.data;
                putted.push(existing);
            }
            else {
                p.parentId = this.parent?.id;
                putted.push(p);
            }
        });

        if (putted.length > 0) {
            console.debug(`Adding or updating ${putted.length} payload(s)`)
            await this.store.putRange(putted);
        }
    }
}