import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ButtonAnnotator } from "./ButtonAnnotator"
import { ButtonAnnotatorContextKey } from "./ButtonAnnotatorContextKey"
import { ButtonPluginKey } from "../button/ButtonPluginKey"
import { ButtonSchemaId } from "../button/ButtonSchemaId"

export const ButtonAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "button-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        ButtonPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: ButtonAnnotatorContextKey,
            value: new ButtonAnnotator()
        } as ManifestEntry<ButtonAnnotator>,

        // registration
        {
            key: "ButtonAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [ButtonAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: ButtonSchemaId,
                annotator: context[ButtonAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<ButtonAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Button Annotator"
}
