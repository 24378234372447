import React from "react"
import { IndexesReducerKey } from "../indexes-reducer/IndexesReducerKey"
import { IonCard, IonCardContent } from "@ionic/react"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const IndexesDetail: React.FC<ReactViewProps> = (props) => {

    const model = props?.model;
    if (!model) {
        return <></>
    }

    const indexes = model[IndexesReducerKey] as Payload[];
    if (!indexes || !Array.isArray(indexes) || indexes.length === 0) {
        return <></>
    }
 
    const chips = indexes.map((p,x) => 
        <ReactViewFactory
            dispatch={props.dispatch}
            matcher={props.matcher}
            key={p.id ?? p.key ?? "i" + x}
            layout="chip"
            model={{payload:p}}></ReactViewFactory>);

    if (chips.length) {
        return (
            <IonCard>
                <IonCardContent>
                    {chips}
                </IonCardContent>
            </IonCard>
        )    
    }
    else {
        return <></>
    }
}