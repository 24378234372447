import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { Splash } from "../splash/Splash"
import { SplashSchemaId } from "../splash/SplashSchemaId";

export class SplashAnnotator implements Annotator {

    public async annotate(payload: Payload<Splash>): Promise<void> {

        if (payload?.schema !== SplashSchemaId) {
            return;
        }

        if (payload.data) {
            payload.title = payload.data.title;
        }
    }
}