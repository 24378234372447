import { EmailAddressSchemaId } from "../email-address/EmailAddressSchemaId";
import { Payload } from "../payload/Payload"
import { RegExIndexer } from "../regex-indexer/RegExIndexer";
import { UrlSchemaId } from "../url/UrlSchemaId";

/**
 * A regular expression to match Instagram usernames in a title.
 * Based on https://blog.jstassen.com/2016/03/code-regex-for-instagram-username-and-hashtags/
 */
const REGEX = /(?:@|\((?:IG|ig):\s?)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/g;
    // 1. Starts with "@"" or "(IG:" or "(ig: ", etc., in a non-captured group.
    // 2. First character is a letter, number or underscore.
    // 3. Followed by up to 28 letters, numbers, underscores, or single periods (not "..")
    // 4. Followed by a letter, number or underscore

/**
 * An indexer that examines a payload title and generates links for each Instagram
 * username reference, e.g., @username. Each generated link has the full URL
 * format of https://www.instagram.com/username/ (with the "https", "wwww", and trailing slash).
 */
export class InstagramUsernameIndexer extends RegExIndexer {

    constructor() {
        super(REGEX);
    }

    public async index(payload: Payload | undefined): Promise<Payload<any>[]> {

        // HACK: instead make the regex smarter
        if (payload?.schema === EmailAddressSchemaId) {
            return []
        }
        else {
            return super.index(payload);
        }
    }

    protected onmatch(match: RegExpMatchArray): Payload<any> | undefined {
            
        // match[0] is the username with @
        // match[1] is the username without @
        const url = `https://www.instagram.com/${match[1]}/`;

        return {
            schema: UrlSchemaId,
            key: url,
            data: url,
            title: match[1]
        }
     }
}