import React from "react"
import { clone } from "../../library/clone"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { Move } from "../move/Move"
import { MoveSchemaId } from "../move/MoveSchemaId"
import { Payload } from "../payload/Payload"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { RootSchemaId } from "../root/RootSchemaId"
import { Tree } from "../tree/Tree"
import { TreeSchemaId } from "../tree/TreeSchemaId"

export const MoveCardView: React.FC<ReactViewProps> = (props) => {

    let payloadToMove = props?.model?.payload;
    if (payloadToMove?.schema === MoveSchemaId) {
        payloadToMove = (payloadToMove.data as Move)?.payload;
    }

    if (!payloadToMove) {
        return (
            <ReactViewFailure>payload to move is not specified</ReactViewFailure>
        )
    }

    if (!payloadToMove.id) {
        return (
            <ReactViewFailure>payload to move must have an id</ReactViewFailure>
        )
    }

    const treePayload: Payload<Tree> = {
        schema: TreeSchemaId,
        data: {
            root: {
                schema: RootSchemaId,
                data: undefined
            },
            excluded: [payloadToMove.id]
        }
    };

    function onMove(parent: Payload) {

        if (!parent) {
            throw new Error("parent must be specified");
        }

        if (!parent.id) {

            // This is OK if the parent is the root
            if (parent.schema !== RootSchemaId) {
                throw new Error("no ID is specified");
            }
        }

        const updatedPayload = clone(payloadToMove);
        if (!updatedPayload) {
            throw new Error("no payload to move")
        }

        updatedPayload.parentId = parent?.id;

        if (props.dispatch) {
            props.dispatch(updatedPayload);
        }
    }

    return (
        <IonCard>
            <IonCardHeader>
                <div className="children-card-view-header">
                    <div className="children-card-view-emblem">
                        <ReactViewFactory
                            dispatch={props.dispatch}
                            matcher={props.matcher}
                            layout="emblem"
                            model={{ payload: payloadToMove }}>
                        </ReactViewFactory>
                    </div>
                    <IonCardTitle className="children-card-view-title">Move to...</IonCardTitle>
                </div>
            </IonCardHeader>
            <IonCardContent>
                <ReactViewFactory
                    layout="card"
                    dispatch={p => onMove(p)}
                    matcher={props.matcher}
                    model={{ payload: treePayload }}>
                </ReactViewFactory>
            </IonCardContent>
        </IonCard>
    );
}