import { Schema } from "../schema/Schema"
import { SubredditFeedSchemaId } from "./SubredditFeedSchemaId"

export const SubredditFeedSchema: Schema = {

    "$id": SubredditFeedSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "A subreddit feed",
    title: "Subreddit",

    properties: {
        "subreddit": {
            type: "string",
            description: "A subreddit name without the r/ prefix",
            title: "Subreddit"
        },
    }
}