import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SelectorMapContextKey } from "../selector-map/SelectorMapContextKey"
import { SelectorMapPluginKey } from "../selector-map/SelectorMapPluginKey"
import { SequenceCreator } from "../sequence/SequenceCreator"
import { SequenceCreatorContextKey } from "./SequenceCreatorContextKey"
import { SequenceMapFactory } from "./SequenceMapFactory"
import { SequenceMapFactoryContextKey } from "./SequenceMapFactoryContextKey"
import { SequenceMapFactoryPluginKey } from "./SequenceMapFactoryPluginKey"
import { SequenceMapContextKey } from "../sequence-map/SequenceMapContextKey"
import { SequenceMapPluginKey } from "../sequence-map/SequenceMapPluginKey"
import { SequencePluginKey } from "../sequence/SequencePluginKey"
import { Sequence } from "../sequence/Sequence"

export const SequenceMapFactoryManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SequenceMapFactoryPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        SelectorMapPluginKey,
        SequencePluginKey,
        SequenceMapPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        
        {
            key: SequenceCreatorContextKey,
            description: "Instantiates a sequence instance",
            requires: [SelectorMapContextKey],
            value: (context: ManifestContext) =>
                new SequenceCreator(context[SelectorMapContextKey])
        } as ManifestEntry<SequenceCreator>,

        {
            key: SequenceMapFactoryContextKey,
            description: "Instantiates a sequence instance based on a key from the sequence map",
            requires: [SequenceCreatorContextKey, SequenceMapContextKey],
            value: (context: ManifestContext) => {

                // Get the creator responsible for assembling a sequence instance
                const creator: SequenceCreator = context[SequenceCreatorContextKey];

                // Get the map of keys to sequences
                const sequences: Map<string, Sequence> = context[SequenceMapContextKey];

                return new SequenceMapFactory(creator, sequences)
            }
        } as ManifestEntry<SequenceMapFactory>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Sequence Map Factory"
}