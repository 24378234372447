import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { NasaApodAnnotator } from "./NasaApodAnnotator"
import { NasaApodAnnotatorContextKey } from "./NasaApodAnnotatorContextKey"
import { NasaApodPluginKey } from "../nasa-apod/NasaApodPluginKey"
import { NasaApodSchemaId } from "../nasa-apod/NasaApodSchemaId"

export const NasaApodAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "nasa-apod-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        NasaApodPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: NasaApodAnnotatorContextKey,
            value: new NasaApodAnnotator()
        } as ManifestEntry<NasaApodAnnotator>,

        // registration
        {
            key: "NasaApodAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [NasaApodAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: NasaApodSchemaId,
                annotator: context[NasaApodAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<NasaApodAnnotator>>
    ],

    /**
     * The dislay title of the plugin.
     */
    title: "NASA APOD Annotator"
}