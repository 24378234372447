import { RealityCheck } from "../reality-check/RealityCheck"

export const DefaultRealityChecks: RealityCheck[] = [
    {
        title: "Count the fingers on your hand",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG",
        links: [
            {
                title: "Lucid Dream",
                url: "https://en.wikipedia.org/wiki/Lucid_dream"
            },
            {
                title: "Image: Waldemar Smolarek",
                url: "https://commons.wikimedia.org/wiki/File:Abstract_120.JPG",
            }
        ]
    },
    {
        title: "Look at a clock",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG",
        links: [
            {
                title: "Lucid Dream",
                url: "https://en.wikipedia.org/wiki/Lucid_dream"
            },
            {
                title: "Image: Waldemar Smolarek",
                url: "https://commons.wikimedia.org/wiki/File:Abstract_120.JPG",
            }
        ]
    },
    {
        title: 'Ask yourself, "Am I dreaming?"',
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG",
        links: [
            {
                title: "Lucid Dream",
                url: "https://en.wikipedia.org/wiki/Lucid_dream"
            },
            {
                title: "Image: Waldemar Smolarek",
                url: "https://commons.wikimedia.org/wiki/File:Abstract_120.JPG",
            }
        ]
    },
    {
        title: "Push your fingers into your palm",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG",
        links: [
            {
                title: "Lucid Dream",
                url: "https://en.wikipedia.org/wiki/Lucid_dream"
            },
            {
                title: "Image: Waldemar Smolarek",
                url: "https://commons.wikimedia.org/wiki/File:Abstract_120.JPG",
            }
        ]
    },
    {
        title: "Are your tattoos changing?",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG",
        links: [
            {
                title: "Lucid Dream",
                url: "https://en.wikipedia.org/wiki/Lucid_dream"
            },
            {
                title: "Image: Waldemar Smolarek",
                url: "https://commons.wikimedia.org/wiki/File:Abstract_120.JPG",
            }
        ]
    },
    {
        title: "Look in a mirror",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG",
        links: [
            {
                title: "Lucid Dream",
                url: "https://en.wikipedia.org/wiki/Lucid_dream"
            },
            {
                title: "Image: Waldemar Smolarek",
                url: "https://commons.wikimedia.org/wiki/File:Abstract_120.JPG",
            }
        ]
    },
    {
        title: "Look at your hands",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG",
        links: [
            {
                title: "Lucid Dream",
                url: "https://en.wikipedia.org/wiki/Lucid_dream"
            },
            {
                title: "Image: Waldemar Smolarek",
                url: "https://commons.wikimedia.org/wiki/File:Abstract_120.JPG",
            }
        ]
    }
];
