import { Manifest } from "../../runtime/Manifest"
import { QuotePluginKey } from "./QuotePluginKey"

export const QuoteManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: QuotePluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Quote"
}