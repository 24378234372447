import { EarthquakePluginKey } from "../usgs-earthquake/EarthquakePluginKey"
import { EarthquakeFeedPollerCreator } from "./EarthquakeFeedPollerCreator"
import { EarthquakeFeedSchema } from "../usgs-earthquake-feed/EarthquakeFeedSchema"
import { GeoJSONPluginKey } from "../geojson/GeoJSONPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PollerPluginKey } from "../poller/PollerPluginKey"
import { PollerCreatorPluginKey } from "../poller-creator/PollerCreatorPluginKey"
import { PollerCreatorRegistryContextKey } from "../poller-creator-registry/PollerCreatorRegistryContextKey"
import { PollerCreatorRegistryEntry } from "../poller-creator-registry/PollerCreatorRegistryEntry"
import { PollerCreatorRegistryPluginKey } from "../poller-creator-registry/PollerCreatorRegistryPluginKey"

export const UsgsEarthquakeFeedPollerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "usgs-earthquake-feed-poller",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        EarthquakePluginKey,
        GeoJSONPluginKey,
        PollerPluginKey,
        PollerCreatorPluginKey,
        PollerCreatorRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "USGS earthquake alerts",

    /**
     * The objects provided by the plugin
     */
    entries: [
        {
            key: "EarthquakePollerCreator",
            registerWith: PollerCreatorRegistryContextKey,
            title: "Earthquake Alert Poller Creator",
            value: {
                schema: EarthquakeFeedSchema,
                creator: new EarthquakeFeedPollerCreator()
            }
        } as ManifestEntry<PollerCreatorRegistryEntry<EarthquakeFeedPollerCreator>>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "USGS Earthquake Poller",
};
