import { GoalSchemaId } from "./GoalSchemaId"
import { Schema } from "../schema/Schema"

export const GoalSchema: Schema = {

    "$id": GoalSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "A goal to accomplish",
    title: "Goal",

    properties: {
        "title": {
            type: "string",
            description: "The title of the goal",
            title: "Title"
        },
    }
}