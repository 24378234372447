import { Indexer } from "../indexer/Indexer"
import { NewsArticle } from "../newsapi/NewsArticle"
import { NewsArticleSchemaId } from "../newsapi/NewsArticleSchemaId"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId"

export class NewsArticleIndexer implements Indexer<NewsArticle> {

    public async index(payload: Payload<NewsArticle>): Promise<Payload[]> {

        if (payload?.schema !== NewsArticleSchemaId) {
            return [];
        }

        if (typeof(payload.data) !== "object") {
            return [];
        }
 
        const urlPayload: Payload<string> = {
            key: payload.data.url,
            data: payload.data.url,
            schema: UrlSchemaId,
            title: payload.data.title
        }

        return [urlPayload];
    }
}