import React from "react"
import { ReactViewProps } from "../react-view/ReactViewProps"
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle
} from "@ionic/react"
import "./JsonCardView.css"

export const JsonCardView: React.FC<ReactViewProps> = (props) => {

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>JSON</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <div className='json selectable'>{JSON.stringify(props.model?.payload?.data, null, 4)}</div>
            </IonCardContent>
        </IonCard>
    )
}