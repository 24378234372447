import { ActivityTracker } from "../activity-tracker/ActivityTracker"
import { Activity } from "../activity/Activity"
import { Payload } from "../payload/Payload"
import { Store } from "../store/Store"
import { Streamer } from "../streamer/Streamer"
import { Task } from "../task/Task"
import { TaskPredicates } from "../task/TaskPredicates"
import { TaskSchemaId } from "../task/TaskSchemaId"

export class TaskStreamer implements Streamer {

    /**
     * The current activity (or a function to get the activity)
     */
    private activity: ActivityTracker
    
    /**
     * The folder containing tasks.
     */
    private store: Store;

    /**
     * Initializes a new instance of the task streamer.
     */
    constructor(store: Store, activity: ActivityTracker) {

        if (!(this.store = store)) {
            throw new Error("store must be specified");
        }

        if (!(this.activity = activity)) {
            throw new Error("activity tracker must be specified");
        }
    }

    public choose(
            p1: Payload<Task> | undefined,
            p2: Payload<Task> | undefined,
            currentActivities: Activity[]): Payload | undefined {

        // Make sure both are eligible. If either is not, select the other or none.
        if (!this.eligible(p1, currentActivities)) {
            if (this.eligible(p2, currentActivities)) {
                return p2;
            }
            else {
                return undefined;
            }
        }
        else {

            if(!this.eligible(p2, currentActivities)) {
                return p1;
            }
        }

        // If either payload has been deferred, select the earlier one.
        if (p1?.deferUntil) {
            if (p2?.deferUntil) {
                return p1.deferUntil < p2.deferUntil ? p1 : p2;
            }
            else {
                return p1;
            }
        }
        else if (p2?.deferUntil) {
            return p2;
        }

        // Prefer higher priority
        const p1pri = p1?.data?.priority ?? 1;
        const p2pri = p2?.data?.priority ?? 1;
        if (p1pri < p2pri) {
            return p1;
        }
        else if (p1pri > p2pri) {
            return p2;
        }

        // Prefer unopened payloads. If both opened, prefer the older one.
        if (p1?.openedOn) {
            if (p2?.openedOn) {
                return p1.openedOn < p2.openedOn ? p1 : p2;
            }
            else {
                // p2 has never been opened
                return p2;
            }
        }
        else {
            // p1 has never been opened.
            if (p2?.openedOn) {
                return p1;
            }
        }

        // All else being equal, p1 was encountered first
        return p1;
    }

    /**
     * Indicates whether the specified payload is eligible to be returned.
     */
    public eligible(
            payload: Payload | undefined,
            currentActivities: Activity[]): boolean {

        if (!TaskPredicates.active(payload)) {
            return false;
        }
        
        // If this request has an activity, the task must have a matching activity.
        if (Array.isArray(currentActivities) && currentActivities.length > 0) {

            const task = payload!.data as Task;

            if (!task.activities) {
                return false;
            }

            // Build a set of activity keys for this task
            let taskActivityKeys = new Set<string>();
            if (Array.isArray(task.activities)) {
                for(let k of task.activities) {
                    if (typeof(k) === "string") {
                        taskActivityKeys.add(k);
                    }
                }
            }
            else if (typeof(task.activities) === "string") {
                taskActivityKeys.add(task.activities);
            }

            // See if any current activities are in the task activities.
            let found = false;
            for(let activity of currentActivities) {
                if (taskActivityKeys.has(activity.key)) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                return false;
            }
        }

        // All eligility tests pass
        return true;
    }

    /**
     * Returns the best task needing a reminder.
     */
    public async best(currentActivities: Activity[]): Promise<Payload | undefined> {

        // Get all task payloads
        const payloads = await this.store.schema(TaskSchemaId);

        // Track the best task found in the list...
        let best: Payload | undefined;

        for(let payload of payloads) {
            best = this.choose(best, payload, currentActivities);
        }

        return best;
    }

    /**
     * Returns the next task reminder.
     */
    public async next(): Promise<Payload | undefined> {
        
        // Get the current activities
        const activities = this.activity.track();

        // Find the best task to show right now
        const best = await this.best(activities);

        if (best) {

            // Mark the payload as opened and remove the deferral
            return this.store.put({
                ...best,
                deferUntil: undefined,
                openedOn: Date.now()})
        }
        else {
            return undefined;
        }
    }
}