import React from "react"
import { IonIcon } from "@ionic/react"
import { logoMicrosoft as icon } from "ionicons/icons"
import { ReactViewProps } from "../react-view/ReactViewProps"

export const BingWallpaperFeedEmblemView: React.FC<ReactViewProps> = (props) => {
    return (
        <IonIcon style={{
            width:"32px",
            height: "32px"
        }} icon={icon} />
    )
}