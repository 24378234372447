import React from "react"
import {
    IonCard,
    IonCardContent,
    IonText
} from "@ionic/react"

export const ReactViewFailure: React.FC = (props) => {
    return (
        <IonCard>
            <IonCardContent>
                <IonText color="danger">{props.children}</IonText>
            </IonCardContent>
        </IonCard>
    );
}