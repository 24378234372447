import { DefaultPayload } from "./DefaultPayload"
import { DefaultPayloadContextKey } from "./DefaultPayloadContextKey"
import { DefaultPayloadPluginKey } from "./DefaultPayloadPluginKey"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const DefaultPayloadManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: DefaultPayloadPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PayloadPluginKey
    ],
 
    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: DefaultPayloadContextKey,
            value: DefaultPayload
        } as ManifestEntry
    ],

    /**
     * The display title of the plugin.
     */
    title: "Default Payload"
}