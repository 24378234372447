import { DeleteSchemaId } from './DeleteSchemaId'
import { Schema } from "../schema/Schema"

export const DeleteSchema: Schema = {

    "$id": DeleteSchemaId,
    title: "Delete",
    type: "object",
    properties: {
        "payload": {
            "$ref": "https://motley.stream/schema/payload/1.0",
        },
        "confirmed": {
            title: "Confirmed",
            type: "boolean"
        }
    }
} 