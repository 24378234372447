import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { SubredditFeedAnnotator } from "./SubredditFeedAnnotator"
import { SubredditFeedAnnotatorContextKey } from "./SubredditFeedAnnotatorContextKey"
import { SubredditFeedPluginKey } from "../subreddit-feed/SubredditFeedPluginKey"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId"

export const SubredditFeedAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "subreddit-feed-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        SubredditFeedPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: SubredditFeedAnnotatorContextKey,
            value: new SubredditFeedAnnotator()
        } as ManifestEntry<SubredditFeedAnnotator>,

        // registration
        {
            key: "SubredditFeedAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [SubredditFeedAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: SubredditFeedSchemaId,
                annotator: context[SubredditFeedAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<SubredditFeedAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Subreddit Feed Annotator"
}