import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityTasksDetailView } from "./ActivityTasksDetailView"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { ListPluginKey } from "../list/ListPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { SplashSchemaId } from "../splash/SplashSchemaId"
import { SplashPluginKey } from "../splash/SplashPluginKey"

export const ActivityTasksDetailViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "activity-tasks-detail-view",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        DefaultStorePluginKey,
        ListPluginKey,
        SplashPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "ActivityTasksDetailView",
            requires: [DefaultStoreContextKey],
            registerWith: ReactViewRegistryContextKey,
            value: (context: ManifestContext) => {
                return {
                    match: SplashSchemaId,
                    layout: "detail",
                    fc: (props: ReactViewProps) =>
                        ActivityTasksDetailView({...props, store: context[DefaultStoreContextKey]}),
                }
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Activity Tasks Detail View"
}