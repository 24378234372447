import React from "react"
import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { ReactViewFailure } from "../react-view/ReactViewFailure"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Root } from "../root/Root"
import { RootSchemaId } from "../root/RootSchemaId"
import { Store } from "../store/Store"
import { Tree } from "../tree/Tree"
import { TreeItem } from "./TreeItem"

export interface TreeCardViewProps extends ReactViewProps {
    annotator: Annotator;
    store: Store
}

export const TreeCardView: React.FC<TreeCardViewProps> = (props) => {

    // Get the tree payload
    const treePayload = props?.model?.payload;
    if (!treePayload) {
        return (
            <ReactViewFailure>tree payload not specified</ReactViewFailure>
        )
    }

    // Get the tree object
    const tree = treePayload.data as Tree;
    if (!tree) {
        return (
            <ReactViewFailure>tree object not specified</ReactViewFailure>
        )
    }

    // Get the root payload.
    let rootPayload = tree.root;
    if (!rootPayload) {
        rootPayload = {
            schema: RootSchemaId,
            data: {}
        } as Payload<Root>
    }

    async function onChildren(parent: Payload): Promise<Payload[]> {

        if (!props.store) {
            return [];
        }

        // HACK: annotate here, needs to move to central location eventually
        const payloads = await props.store.children(parent?.id);
        for(const p of payloads) {
            await props.annotator.annotate(p);
        }

        payloads.sort((a,b) => {
            const as = a?.title ?? "";
            const bs = b?.title ?? "";
            return as.localeCompare(bs);
        });

        return payloads;
    }

    return (
        <TreeItem
            dispatch={props.dispatch}
            expanded={true}
            excluded={tree.excluded}
            matcher={props.matcher}
            onChildren={p => onChildren(p)}
            model={{ payload: rootPayload }}
            >
        </TreeItem>
    );
}