import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Blocker } from "./Blocker"
import { BlockingAnnotator } from "./BlockingAnnotator"
import { BlockingAnnotatorContextKey } from "./BlockingAnnotatorContextKey"
import { BlockingLocalStore } from "./BlockingLocalStore"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const BlockContentManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "block-content",

    /** 
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey
    ],

    /**
     * The display description of the plugin.
     */
    description: "Enables content to be blocked",

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // annotator
        {
            key: BlockingAnnotatorContextKey,
            title: "Blocking Annotator",
            value: new BlockingAnnotator(new Blocker(new BlockingLocalStore()))
        } as ManifestEntry<BlockingAnnotator>,

        // registration
        {
            key: "BlockingAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [BlockingAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: "*",
                annotator: context[BlockingAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<BlockingAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Content Blocker"
}