import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { MathProblemPluginKey } from "../math-problem/MathProblemPluginKey"
import { MathProblemStreamer } from "./MathProblemStreamer"
import { MathProblemStreamerContextKey } from "./MathProblemStreamerContextKey"
import { MathProblemStreamerPluginKey } from "./MathProblemStreamerPluginKey"
import { StreamerPluginKey } from "../streamer/StreamerPluginKey"
import { StreamerRegistryContextKey } from "../streamer-registry/StreamerRegistryContextKey"
import { StreamerRegistryEntry } from "../streamer-registry/StreamerRegistryEntry"
import { StreamerRegistryPluginKey } from "../streamer-registry/StreamerRegistryPluginKey"

export const MathProblemStreamerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: MathProblemStreamerPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        MathProblemPluginKey,
        StreamerPluginKey,
        StreamerRegistryPluginKey,
    ],

    /**
     * The display description of the plugin.
     */
    description: "Supplies math problems",

    /**
     * The objects provided by the plugin.
     */
    entries: [

        // streamer
        {
            key: MathProblemStreamerContextKey,
            value: new MathProblemStreamer(),
        } as ManifestEntry<MathProblemStreamer>,

        // registration
        {
            key: "MathProblemStreamerRegistryEntry",
            registerWith: StreamerRegistryContextKey,
            requires: [MathProblemStreamerContextKey],
            value: (context: ManifestContext) => ({
                streamer: context[MathProblemStreamerContextKey]
            })
        } as ManifestEntry<StreamerRegistryEntry<MathProblemStreamer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Math Problem Streamer"
}