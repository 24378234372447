import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { RssFeed } from "../rss-feed/RssFeed"
import { RssFeedSchemaId } from "../rss-feed/RssFeedSchemaId"

export class RssFeedAnnotator implements Annotator<RssFeed> {

    public async annotate(payload: Payload<RssFeed>): Promise<void> {

        if (payload.schema !== RssFeedSchemaId) {
            return;
        }

        const feed = payload.data;
        if (!feed) {
            return;
        }

        payload.title = feed.title ?? feed.url;
    }
}