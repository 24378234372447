import { parseHostname } from "../../library/parseHostname"

const STORAGE_KEY = "Whitelist";

export class Whitelist {

    private hostnames = new Set<string>();

    constructor() {
        this.load();
    }

    public add(domain: string) {
        this.hostnames.add(domain);
        this.save();
    }

    public checkUrl(value: string): boolean {
        const hostname = parseHostname(value);
        return this.has(hostname);
    }

    public has(hostname: string) {
        return this.hostnames.has(hostname);
    }

    public remove(hostname: string) {
        this.hostnames.delete(hostname);
        this.save();
    }

    private load() {
        const str = localStorage.getItem(STORAGE_KEY);
        if (str) {
            const items = JSON.parse(str);
            for(let domain of items) {
                this.hostnames.add(domain);
            }
        }
    }

    private save() {
        const array = [...this.hostnames.values()];
        localStorage.setItem(STORAGE_KEY, JSON.stringify(array));
    }
}