import { Command } from "../commander/Command"
import { Commander } from "../commander/Commander"
import { Move } from "../move/Move"
import { MoveSchemaId } from "../move/MoveSchemaId"
import { Model } from "../state/Model"
import { Payload } from "../payload/Payload";

export class MoveCommander implements Commander {

    public async issue(model: Model | undefined): Promise<Command[]> {

        if (!model) {
            return [];
        }

        const payload = model.payload;
        if (!payload) {
            return [];
        }

        if (!payload.id) {
            return [];
        }

        return [
            {
                key: "move",
                layout: "extended",
                order: "last",
                title: "Move",
                value: {
                    schema: MoveSchemaId,
                    data: {
                        payload
                    }
                } as Payload<Move>,
            },
        ];
    }
}