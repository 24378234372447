import { Model } from "../state/Model"
import { Notice } from "../notice/Notice"
import { Notifier } from "../notifier/Notifier";
import { Reducer } from "../reducer/Reducer";

export class NoticesReducer extends Reducer{

    private notifiers: Notifier[];
    
    constructor(notifiers: Notifier[]) {
        super();
        if (!Array.isArray(this.notifiers = notifiers)) {
            throw new Error("notifies must be specified as an array");
        }
    }

    public async reduce(context: Model): Promise<Notice[]> {

        const payload = context.payload;
        if (!payload) {
            return [];
        }

        const notices: Notice[] = [];

        for(let notifier of this.notifiers) {
            const n = await notifier.notify(context);
            if (Array.isArray(n)) {
                notices.push(...n);
            }
        }

        return notices;
    }
}