import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PreviewView } from "./PreviewView"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const PreviewViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "preview-view",

    /**
     * Ensure the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "PreviewView",
            description: "Default view for showing a preview of a payload",
            registerWith: ReactViewRegistryContextKey,
            title: "Preview View",
            value: {
                layout: "preview",
                match: "*",
                fc: PreviewView,
                order: "last"
            }
        } as ManifestEntry<ReactViewRegistryEntry>,
    ],

    /**
     * The display title of the plugin.
     */
    title: "Preview View",
}