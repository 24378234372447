import { BooleanReactInput } from "./BooleanReactInput"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactInputPluginKey } from "../react-input/ReactInputPluginKey"
import { ReactInputRegistryContextKey } from "../react-input/ReactInputRegistryContextKey"
import { ReactInputRegistryEntry } from "../react-input/ReactInputRegistryEntry"
import { Schema } from "../schema/Schema"

export const BooleanReactInputManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "boolean-react-input",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ReactInputPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "BooleanReactInput",
            registerWith: ReactInputRegistryContextKey,
            value: {

                /**
                 * Matches against string schemas
                 */
                match: (s: Schema) => s?.type === "boolean",

                /**
                 * The functional component that renders the input control.
                 */
                fc: BooleanReactInput,

                /**
                 * Prioritize this last so that more schema-specific inputs can take priority.
                 */
                order: "last"
            },
        } as ManifestEntry<ReactInputRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Boolean React Input"
}