import { Annotator } from "../annotator/Annotator"
import { Goal } from "../goal/Goal"
import { GoalSchemaId } from "../goal/GoalSchemaId"
import { Payload } from "../payload/Payload";

export class GoalAnnotator implements Annotator {

    public async annotate(payload: Payload<Goal>): Promise<void> {
        if (payload?.schema === GoalSchemaId) {
            payload.title = payload.data.title ?? payload.title;
        }
    }
}