import { Activity } from "../activity/Activity"
import { ActivityArrayContextKey } from "../activity-registry/ActivityArrayContextKey"
import { ActivityPluginKey } from "../activity/ActivityPluginKey"
import { ActivityRegistryPluginKey } from "../activity-registry/ActivityRegistryPluginKey"
import { JuneteenthActivity } from "./JuneteenthActivity"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const JuneteenthActivityManifest: Manifest = {

    /**
     * The key that uniquely identifies
     */
    key: "juneteenth-activity",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ActivityPluginKey,
        ActivityRegistryPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "juneteenth-activity",
            registerWith: ActivityArrayContextKey,
            value: JuneteenthActivity
        } as ManifestEntry<Activity>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Juneteenth Activity"
}