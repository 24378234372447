import { fetchJson } from "../../library/fetchJson"
import { NasaApod } from "../nasa-apod/NasaApod"
import { NasaApodSchemaId } from "../nasa-apod/NasaApodSchemaId"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller";

/**
 * https://api.nasa.gov/index.html
 */
export class NasaApodFeedPoller implements Poller {

    /** The API key needed to call the service */
    private apiKey: string;

    /**
     * Initializes a new instance of the poller.
     */
    constructor(apiKey: string) {
        if (!(this.apiKey = apiKey)) {
            throw new Error("apiKey must be specified");
        }
    }

    /**
     * Converts the JSON response of the web service into payloads.
     */
    public convertJson(response: NasaApod | undefined): Payload<NasaApod>[] {

        if (!response) {
            return [];
        }

        return [
            {
                schema: NasaApodSchemaId,
                key: response.date,
                data: response
            }
        ];
    }

    public getUrl(): string {
        return `https://api.nasa.gov/planetary/apod?api_key=${this.apiKey}`;
    }

    /**
     * Polls for the current picture.
     */
    public async poll(): Promise<Payload<NasaApod>[]> {
        return fetchJson<NasaApod>(this.getUrl(), false).then(
            response => this.convertJson(response)
        );
    }
}