import { Schema } from "../schema/Schema"
import { WaitingSchemaId } from "./WaitingSchemaId"

export const WaitingSchema: Schema = {
    $id: WaitingSchemaId,
    title: "Waiting",
    type: "object",
    properties: {
        "title": {
            type: "string"
        }
    }
}