import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { EmailAddressAnnotator } from "./EmailAddressAnnotator"
import { EmailAddressAnnotatorContextKey } from "./EmailAddressAnnotatorContextKey"
import { EmailAddressPluginKey } from "../email-address/EmailAddressPluginKey"
import { EmailAddressSchemaId } from "../email-address/EmailAddressSchemaId"

export const EmailAddressAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "email-address-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        EmailAddressPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: EmailAddressAnnotatorContextKey,
            value: new EmailAddressAnnotator()
        } as ManifestEntry<EmailAddressAnnotator>,

        // registration
        {
            key: "EmailAddressAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [EmailAddressAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: EmailAddressSchemaId,
                annotator: context[EmailAddressAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<EmailAddressAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Email Address Annotator"
}
