import { fetchJson } from "../../library/fetchJson"
import { NewsFeed } from "../newsapi-feed/NewsFeed";
import { NewsArticleSchemaId } from "../newsapi/NewsArticleSchemaId"
import { NewsResults } from "../newsapi/NewsResults"
import { Payload } from "../payload/Payload"
import { Poller } from "../poller/Poller"

// ** WARN: fetching images may cause cookies to be set

export class NewsFeedPoller implements Poller {

    private feed: NewsFeed;

    /**
     * Initializes a new instance of the news poller using the specified API key.
     */
    constructor(feed: NewsFeed) {
        
        if (!(this.feed = feed)) {
            throw new Error("feed must be specified");
        }

        if (!this.feed.apiKey) {
            console.warn("missing API key");
        }
    }

    /**
     * Converts the news results JSON into an article.
     */
    public convertJson(results: NewsResults | undefined): Payload[] {

        if (!results) {
            return [];
        }

        return results.articles.map(article => {
            return {
                schema: NewsArticleSchemaId,
                data: article,
                key: article.url,
                title: article.title
            }
        });
    }

    /**
     * Constructs the URL to the web service based on current parameters.
     */
    public getUrl(): string | undefined {

        if (!this.feed.apiKey) {
            return undefined;
        }

        return `https://newsapi.org/v2/top-headlines?country=${this.feed.country ?? "us"}&apiKey=${this.feed.apiKey}`;
    }

    /**
     * Polls the service for news articles.
     */
    public async poll(): Promise<Payload[]> {

        // Construct the URL based on the poller settings
        const url = this.getUrl();
        if (!url) {
            return [];
        }

        return fetchJson<NewsResults>(url, false).then(
            results => this.convertJson(results)
        );
    }
}