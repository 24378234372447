import { Model } from "../state/Model"
import { Notice } from "../notice/Notice"
import { NoticeSchemaId } from "../notice/NoticeSchemaId"
import { Notifier } from "../notifier/Notifier"

export class NoticeAttachmentNotifier implements Notifier {

    public async notify(model: Model): Promise<Notice[]> {

        const attachments = model?.payload?.attachments;
        if (!Array.isArray(attachments)) {
            return [];
        }

        return attachments.filter(p => p.schema === NoticeSchemaId).map(p => p.data);
    }
}