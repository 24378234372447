import { EmailAddressChipView } from "./EmailAddressChipView"
import { EmailAddressPluginKey } from "../email-address/EmailAddressPluginKey"
import { EmailAddressSchemaId } from "../email-address/EmailAddressSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const EmailAddressChipViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the URL payload plugin.
     */
    key: "email-address-chip-view",

    /**
     * Ensures the plugin is installed at first run.
     */
    defaultInstall: true,

    /**
     * The list of plugin dependencies.
     */
    dependsOn: [
        EmailAddressPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "EmailAddressChipView",
            registerWith: ReactViewRegistryContextKey,
            title: "Email Address Chip View",
            value: {
                layout: "chip",
                match: EmailAddressSchemaId,
                fc: EmailAddressChipView    
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Email Address Chip View",
}