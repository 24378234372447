import { Quote } from "../quote/Quote"

export const defaultQuotes: Quote[] = [
    {
        key: "alexander-the-great-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Alexander_cuts_the_Gordian_Knot.jpg",
        links: [
            {
                title: "Alexander the Great",
                url: "https://en.wikipedia.org/wiki/Alexander_the_Great"
            }
        ],
        tags: [
            "achieve",
            "ancient",
            "goals",
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivation",
            "success",
        ],
        text: "There is nothing impossible to him who will try.",
        who: "Alexander the Great",
    },    
    /** Another Alexander the Great picture */
    /** https://upload.wikimedia.org/wikipedia/commons/e/e1/Alexander_the_Great_mosaic.jpg */

/*

                    .+~                :xx++::
                   :`. -          .!!X!~"?!`~!~!. :-:.
                  <             .!!!H":.~ ::+!~~!!!~ `%X.
                  '             ~~!M!!>!!X?!!!!!!!!!!...!~.
                              <!:!MM!~:XM!!!!!!.:!..~ !.  `<
                  <: `   :~ .:<~!!M!XXHM!!!X!XXHtMMHHHX!  ~ ~
                ~~~~<' ~!!!:!!!!!XM!!M!!!XHMMMRMSXXX!!!!!!:  <`
                  `<  <::!!!!!X!X?M!!M!!XMMMMXXMMMM??!!!!!?!:~<
               : '~~~<!!!XMMH!!XMXMXHHXXXXM!!!!MMMMSXXXX!!!!!!!~
            :    ::`~!!!MMMMXXXtMMMMMMMMMMMHX!!!!!!HMMMMMX!!!!!: ~
               '~:~!!!!!MMMMMMMMMMMMMMMMMMMMMMXXX!!!M??MMMM!!X!!i:
               <~<!!!!!XMMMMMMMMMMMM8M8MMMMM8MMMMMXX!!!!!!!!X!?t?!:
               ~:~~!!!!?MMMMMM@M@RMRRR$@@MMRMRMMMMMMXSX!!!XMMMX<?X!
             :XX <!!XHMMMM88MM88BR$M$$$$8@8RN88MMMMMMMMHXX?MMMMMX!!!
           .:X! <XMSM8M@@$$$$$$$$$$$$$$$$$$$B8R$8MMMMMMMMMMMMMMMMX!X
          :!?! !?XMMMMM8$$$$8$$$$$$$$$$$$$$BBR$$MMM@MMMMMMMMMMMMMM!!X
        ~<!!~ <!!XMMMB$$$$$$$$$$$$$$$$$$$$$$$$MMR$8MR$MMMMMMMMMMMMM!?!:
        :~~~ !:X!XMM8$$$$$$$$$$$$$$$$$$$$$$$RR$$MMMMR8NMMMMMMMMMMMMM<!`-
    ~:<!:~`~':!:HMM8N$$$$$$$$$$$$$$$$$$$$$$$$$8MRMM8R$MRMMMMMMMMRMMMX!
  !X!``~~   :~XM?SMM$B$$$$$$$$$$$$$$$$$$$$$$BR$$MMM$@R$M$MMMMMM$MMMMX?L
 X~.      : `!!!MM#$RR$$$$$$$$$$$$$$$$$R$$$$$R$M$MMRRRM8MMMMMMM$$MMMM!?:
 ! ~ <~  !! !!~`` :!!MR$$$$$$$$$$RMM!?!??RR?#R8$M$MMMRM$RMMMM8MM$MMM!M!:>
: ' >!~ '!!  !   .!XMM8$$$$$@$$$R888HMM!!XXHWX$8$RM$MR5$8MMMMR$$@MMM!!!< ~
!  ' !  ~!! :!:XXHXMMMR$$$$$$$$$$$$$$$$8$$$$8$$$MMR$M$$$MMMMMM$$$MMM!!!!
 ~<!!!  !!! !!HMMMMMMMM$$$$$$$$$$$$$$$$$$$$$$$$$$MMM$M$$MM8MMMR$$MMXX!!!!/:`
  ~!!!  !!! !XMMMMMMMMMMR$$$$$$$$$$$$R$RRR$$$$$$$MMMM$RM$MM8MM$$$M8MMMX!!!!:
  !~ ~  !!~ XMMM%!!!XMMX?M$$$$$$$$B$MMSXXXH?MR$$8MMMM$$@$8$M$B$$$$B$MMMX!!!!
  ~!    !! 'XMM?~~!!!MMMX!M$$$$$$MRMMM?!%MMMH!R$MMMMMM$$$MM$8$$$$$$MR@M!!!!!
  <>    !!  !Mf x@#"~!t?M~!$$$$$RMMM?Xb@!~`??MS$M@MMM@RMRMMM$$$$$$RMMMMM!!!!
  !    '!~ <!!:!?M   !@!M<XM$$R5M$8MMM$! -XXXMMRMBMMM$RMMM@$R$BR$MMMMX??!X!!
  !    '!  !!X!!!?::xH!HM:MM$RM8M$RHMMMX...XMMMMM$RMMRRMMMMMMM8MMMMMMMMX!!X!
  !     ~  !!?:::!!!MXMR~!MMMRMM8MMMMMS!!M?XXMMMMM$$M$M$RMMMM8$RMMMMMMMM%X!!
  ~     ~  !~~X!!XHMMM?~ XM$MMMMRMMMMMM@MMMMMMMMMM$8@MMMMMMMMRMMMMM?!MMM%HX!
           !!!!XSMMXXMM .MMMMMMMM$$$BB8MMM@MMMMMMMR$RMMMMMMMMMMMMMMMXX!?H!XX
           XHXMMMMMMMM!.XMMMMMMMMMR$$$8M$$$$$M@88MMMMMMMMMMMMMMM!XMMMXX!!!XM
      ~   <!MMMMMMMMRM:XMMMMMMMMMM8R$$$$$$$$$$$$$$$NMMMMMMMM?!MM!M8MXX!!/t!M
      '   ~HMMMMMMMMM~!MM8@8MMM!MM$$8$$$$$$$$$$$$$$8MMMMMMM!!XMMMM$8MR!MX!MM
          'MMMMMMMMMM'MM$$$$$MMXMXM$$$$$$$$$$$$$$$$RMMMMMMM!!MMM$$$$MMMMM<!M
          'MMMMMMMMM!'MM$$$$$RMMMMMM$$$$$$$$$$$$$$$MMM!MMMX!!MM$$$$$M$$M$M!M
           !MMMMMM$M! !MR$$$RMM8$8MXM8$$$$$$$$$$$$NMMM!MMM!!!?MRR$$RXM$$MR!M
           !M?XMM$$M.< !MMMMMMSUSRMXM$8R$$$$$$$$$$#$MM!MMM!X!t8$M$MMMHMRMMX$
    ,-,   '!!!MM$RMSMX:.?!XMHRR$RM88$$$8M$$$$$R$$$$8MM!MMXMH!M$$RMMMMRNMMX!$
   -'`    '!!!MMMMMMMMMM8$RMM8MBMRRMR8RMMM$$$$8$8$$$MMXMMMMM!MR$MM!M?MMMMMM$
          'XX!MMMMMMM@RMM$MM@$$BM$$$M8MMMMR$$$$@$$$$MM!MMMMXX$MRM!XH!!??XMMM
          `!!!M?MHMMM$RMMMR@$$$$MR@MMMM8MMMM$$$$$$$WMM!MMMM!M$RMM!!.MM!%M?~!
           !!!!!!MMMMBMM$$RRMMMR8MMMMMRMMMMM8$$$$$$$MM?MMMM!f#RM~    `~!!!~!
           ~!!HX!!~!?MM?MMM??MM?MMMMMMMMMRMMMM$$$$$MMM!MMMM!!
           '!!!MX!:`~~`~~!~~!!!!XM!!!?!?MMMM8$$$$$MMMMXMMM!!
            !!~M@MX.. <!!X!!!!XHMHX!!``!XMMMB$MM$$B$M!MMM!!
            !!!?MRMM!:!XHMHMMMMMMMM!  X!SMMX$$MM$$$RMXMMM~
             !M!MMMM>!XMMMMMMMMXMM!!:!MM$MMMBRM$$$$8MMMM~
             `?H!M$R>'MMMM?MMM!MM6!X!XM$$$MM$MM$$$$MX$f
              `MXM$8X MMMMMMM!!MM!!!!XM$$$MM$MM$$$RX@"
               ~M?$MM !MMMMXM!!MM!!!XMMM$$$8$XM$$RM!`
                !XMMM !MMMMXX!XM!!!HMMMM$$$$RH$$M!~
                'M?MM `?MMXMM!XM!XMMMMM$$$$$RM$$#
                 `>MMk ~MMHM!XM!XMMM$$$$$$BRM$M"
                  ~`?M. !M?MXM!X$$@M$$$$$$RMM#
                    `!M  !!MM!X8$$$RM$$$$MM#`
                      !% `~~~X8$$$$8M$$RR#`
                       !!x:xH$$$$$$$R$R*`
                        ~!?MMMMRRRM@M#`       -Sushil-
                         `~???MMM?M"`
                             ``~~

------------------------------------------------
Thank you for visiting https://asciiart.website/
This ASCII pic can be found at
https://asciiart.website/index.php?art=people/famous/albert%20einstein

*/
    {
        key: "albert-einstein-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Albert_Einstein_met_Paul_en_Galinka_Ehrenfest.jpg",
        links: [{
            title: "Albert Einstein",
            url: "https://en.wikipedia.org/wiki/Albert_Einstein"
        }],
        tags: [
            "curiosity",
            "existence",
            "life"
        ],
        text: "The important thing is not to stop questioning. Curiosity has its own reason for existing.",
        who: "Albert Einstein",
    },
    {
        key: "albert-einstein-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/0/05/Albert_einstein.jpg",
        links: [{
            title: "Albert Einstein",
            url: "https://en.wikipedia.org/wiki/Albert_Einstein"
        }],
        tags: [
            "learning",
        ],
        text: "If you can't explain it simply, you don't understand it well enough.",
        who: "Albert Einstein",
    },
    {
        key: "albert-einstein-3",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/5/5b/A._%28Albert%29_Einstein_LCCN2014712247.jpg",
        links: [{
            title: "Albert Einstein",
            url: "https://en.wikipedia.org/wiki/Albert_Einstein"
        }],
        tags: [
            "fear",
            "growth",
            "inspirational",
            "mindset",
            "opportunity",
            "motivate",
            "motivational",
        ],
        text: "In the middle of difficulty lies opportunity.",
        who: "Albert Einstein",
    },
    {
        key: "albert-einstein-4",
        links: [
            {
                title: "Albert Einstein",
                url: "https://en.wikipedia.org/wiki/Albert_Einstein"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2017/03/23/same/"
            }
        ],
        misattributed: true,
        tags: [
            "expectation",
            "insanity",
            "mind",
            "mindset",
            "philosophy",
            "thinking",
            "thought",
        ],
        text: "Insanity: doing the same thing over and over again and expecting different results.",
        who: "Albert Einstein",
    },
    {
        key: "albert-einstein-5",
        links: [{
            title: "Albert Einstein",
            url: "https://en.wikipedia.org/wiki/Albert_Einstein"
        }],
        tags: [
            "future",
            "goals",
            "growth",
            "life",
            "mindset",
            "success",
        ],
        text: "If you want to live a happy life, tie it to a goal. Not to people or things.",
        who: "Albert Einstein",
    },
    {
        key: "albert-einstein-6",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/1/11/Albert_Einstein_photo_1921.jpg",
        links: [{
            title: "Albert Einstein",
            url: "https://en.wikipedia.org/wiki/Albert_Einstein"
        }],
        tags: [
            "creativity",
        ],
        text: "Creativity is contagious, pass it on.",
        who: "Albert Einstein",
    },
    {
        key: "alexandria-ocasio-cortez-1",
        links: [
            {
                title: "Representative Alexandria Ocasio-Cortez",
                url: "https://ocasio-cortez.house.gov/"
            },
            {
                title: "Alexandria Ocasio-Cortez",
                url: "https://en.wikipedia.org/wiki/Alexandria_Ocasio-Cortez"
            }
        ],
        text: "If you’re calling for an end to unrest, but not calling out police brutality, not calling for health care as a human right, not calling for an end to housing discrimination, all you’re asking for is the continuation of quiet oppression",
        who: "Rep. Alexandria Ocasio-Cortez"
    },
    {
        key: "anne-frank-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Anne_Frank_lacht_naar_de_schoolfotograaf.jpg",
        links: [{
            title: "Anne Frank",
            url: "https://en.wikipedia.org/wiki/Anne_Frank"
        }],
        tags: [
            "achieve",
            "work",
        ],
        text: "Laziness may appear attractive, but work gives satisfaction.",
        who: "Anne Frank",
    },
    {
        key: "aristotle-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Aristotle_Altemps_Inv8575.jpg",
        layout: "overlay",
        links: [{
            title: "Aristotle",
            url: "https://en.wikipedia.org/wiki/Aristotle"
        }],
        tags: [
            "ancient",
            "excellence",
            "greatness",
            "historical",
            "inspirational",
            "philosophy",
            "philisophical",
            "success"
        ],
        text: "We are what we repeatedly do. Excellence, then, is not an act, but a habit.",
        who: "Aristotle",
    },
    {
        key: "aristotle-oanassis-1",
        //backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Aristotle_by_Raphael.jpg",
        links: [
            {
                title: "Aristotle",
                url: "https://en.wikipedia.org/wiki/Aristotle"
            },
            {
                title: "Fake Buddha Quotes",
                url: "https://fakebuddhaquotes.com/it-is-during-our-darkest-moments-that-we-must-focus-to-see-the-light-2/"
            }
        ],
        tags: [ 
            "courage",
            "growth",
            "inspirational",
            "mindset",
            "positivity",
        ],
        text: "It is during our darkest moments that we must focus to see the light.",
        who: "Aristotle Oanassis",
    },
    {
        key: "ayn-rand-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Ayn_Rand_%281943_Talbot_portrait%29.jpg",
        links: [
            {
                title: "Ayn Rand",
                url: "https://en.wikipedia.org/wiki/Ayn_Rand"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2013/10/11/let-me/"
            },
            {
                title: "The Fountainhead",
                url: "https://en.wikipedia.org/wiki/The_Fountainhead"
            }
        ],
        tags: [
            "empowering",
            "growth",
            "inspirational",
            "motivational",
            "strong",
            "mindset",
            "question",
        ],
        text: "The question isn't who is going to let me; it's who is going to stop me.",
        who: "Ayn Rand",
    },
    {
        key: "bertrand-russell-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Bertrand_Russell_1957.jpg",
        layout: "overlay",
        links: [{
            title: "Bertrand Russell",
            url: "https://en.wikipedia.org/wiki/Bertrand_Russell"
        }],
        tags: [
            "belief",
            "beliefs",
            "philosophical",
            "spiritual",
        ],
        text: "I would never die for my beliefs because I might be wrong.",
        who: "Bertrand Russell",
    },
    {
        key: "bertrand-russell-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Russell1907-2.jpg",
        links: [
            {
                title: "Bertrand Russell",
                url: "https://en.wikipedia.org/wiki/Bertrand_Russell"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2015/03/04/self-doubt/"
            }
        ],
        tags: [
            "philisophical",
            "wisdom",
        ],
        text: "The whole problem with the world is that fools and fanatics are always so certain of themselves, but wiser people so full of doubts.",
        who: "Bertrand Russell",
    },
    {
        key: "beverly-sills-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Portrait_of_Beverly_Sills_LCCN2004663564.jpg",
        links: [{
            title: "Beverly Sills",
            url: "https://en.wikipedia.org/wiki/Beverly_Sills"
        }],
        tags: [
            "doomed",
            "failure",
            "inspirational",
            "motivation",
            "motivational",
            "persevere",
            "perseverance",
        ],
        text: "You may be disappointed if you fail, but you are doomed if you don't try.",
        who: "Beverly Sills",
    },
    {
        key: "bill-gates-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Bill_Gates%2C_WEF_2009_Davos.jpg",
        links: [{
            title: "Bill Gates",
            url: "https://en.wikipedia.org/wiki/Bill_Gates"
        }],
        tags: [
            "intuition",
            "wisdom",
        ],
        text: "Often you have to rely on intuition.",
        who: "Bill Gates",
    },
    {
        key: "bill-gates-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Bill_Gates_2013.jpg",
        layout: "no-overlay",
        links: [{
            title: "Bill Gates",
            url: "https://en.wikipedia.org/wiki/Bill_Gates"
        }],
        tags: [
            "empowering",
            "future",
            "wisdom",
        ],
        text: "As we look ahead into the next century, leaders will be those who empower others.",
        who: "Bill Gates",
    },
    {
        key: "bob-marley-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Bob-Marley.jpg",
        layout: "overlay",
        links: [{
            title: "Bob Marley",
            url: "https://en.wikipedia.org/wiki/Bob_Marley"
        }],
        tags: [
            "growth",
            "inspirational",
            "life",
            "mindset",
            "wisdom",
        ],
        text: "Love the life you live. Live the life you love.",
        who: "Bob Marley",
    },
    /** Another Bob Marley picture */
    /** https://upload.wikimedia.org/wikipedia/commons/6/68/Bob-Marley_3.jpg */
    /**  https://upload.wikimedia.org/wikipedia/commons/c/c7/Bob_Marley.JPG */
    { 
        key: "buddha-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/d/da/Shakyamuni_Buddha_with_Avadana_Legend_Scenes_-_Google_Art_Project.jpg",
        links: [{
            title: "Buddha",
            url: "https://en.wikipedia.org/wiki/Buddha"
        }],
        tags: [
            "ancient",
            "historical",
            "mindset",
            "philosophical",
            "think",
            "thought",
            "wisdom",
        ],
        text: "All that we are is the result of what we have thought.",
        who: "Buddha",
    },
    {
        key: "buddha-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/75/Buddha_Shakyamuni_as_Lord_of_the_Munis.jpg",
        links: [{
            title: "Buddha",
            url: "https://en.wikipedia.org/wiki/Buddha"
        }],
        tags: [
            "ancient",
            "historical",
            "inspirational",
            "philosophical",
            "wisdom",
        ],
        text: "The root of suffering is attachment.",
        who: "Buddha",
    },
    /** Another Buddha picture */
    /** https://upload.wikimedia.org/wikipedia/commons/3/30/Large_Gautama_Buddha_statue_in_Buddha_Park_of_Ravangla%2C_Sikkim.jpg */
    /** https://upload.wikimedia.org/wikipedia/commons/b/ba/Goutam_Buddha_unique_Painting.jpg */
    {
        key: "bruce-lee-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/76/Bruce_Lee_Stencil.jpg",
        links: [{
            title: "Bruce Lee",
            url: "https://en.wikipedia.org/wiki/Bruce_Lee"
        }],
        tags: [
            "growth",
            "inspirational",
            "mindset",
        ],
        text: "I fear not the man who has practiced 10,000 kicks once, but I fear the man who has practiced one kick 10,000 times.",
        who: "Bruce Lee",
    },
    {
        key: "bruce-lee-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Bruce_Lee_1973.jpg",
        layout: "overlay",
        links: [{
            title: "Bruce Lee",
            url: "https://en.wikipedia.org/wiki/Bruce_Lee"
        }],
        tags: [
            "philosophical",
            "success",
            "wisdom",
        ],
        text: "A wise man can learn more from a foolish question than a fool can learn from a wise answer.",
        who: "Bruce Lee",
    },
    {
        key: "charlie-chaplin-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/0/00/Charlie_Chaplin.jpg",
        links: [{
            title: "Charlie Chaplin",
            url: "https://en.wikipedia.org/wiki/Charlie_Chaplin"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "philosophical",
            "wisdom",
        ],
        text: "You’ll never find a rainbow if you’re looking down.",
        who: "Charlie Chaplin",
    },
    /** Another Charlie Chaplin picture */
    /** https://upload.wikimedia.org/wikipedia/commons/e/ea/Chaplin_The_Kid_edit.jpg */
    {
        key: "cicero-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/4f/The_Young_Cicero_Reading.jpg",
        links: [{
            title: "Cicero",
            url: "https://en.wikipedia.org/wiki/Cicero"
        }],
        tags: [
            "ancient",
            "historical",
            "inspirational",
            "motivational",
        ],
        text: "Whatever you do, do with all your might.",
        who: "Cicero",
    },
    {
        key: "coco-chanel-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/en/3/30/Coco_Chanel%2C_1920.jpg",
        links: [{
            title: "Coco Chanel",
            url: "https://en.wikipedia.org/wiki/Coco_Chanel"
        }],
        tags: [
            "empowering",
            "life",
            "wisdom",
        ],
        text: "The most courageous act is still to think for yourself. Aloud.",
        who: "Coco Chanel",
    },
    {
        key: "coco-chanel-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/9/90/Gabrielle_Chanel_en_marini%C3%A8re.jpg",
        links: [{
            title: "Coco Chanel",
            url: "https://en.wikipedia.org/wiki/Coco_Chanel"
        }],
        tags: [
            "success",
            "wisdom",
        ],
        text: "Simplicity is the keynote of all true elegance.",
        who: "Coco Chanel",
    },
    {
        key: "colin-davis-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Colin_Davis_%281967%29.jpg",
        layout: "overlay",
        links: [{
            title: "Colin Davis",
            url: "https://en.wikipedia.org/wiki/Colin_Davis"
        }],
        tags: [
            "failure",
            "inspirational",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "The road to success and the road to failure are almost exactly the same.",
        who: "Colin Davis",
    },
    {
        key: "colin-powell-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/e/e9/GEN_Colin_Powell.JPG",
        layout: "overlay",
        links: [{
            title: "Colin Powell",
            url: "https://en.wikipedia.org/wiki/Colin_Powell"
        }],
        tags: [
            "failure",
            "success",
            "wisdom",
        ],
        text: "There are no secrets to success. It is the result of preparation, hard work, and learning from failure.",
        who: "Colin Powell",
    },
    {
        key: "confucius-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/8/86/HAV_Confucius_wall_mural.jpg",
        layout: "overlay",
        links: [{
            title: "Confucius",
            url: "https://en.wikipedia.org/wiki/Confucius"
        }],
        tags: [
            "ancient",
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
            "wisdom",
        ],
        text: "It does not matter how slowly you go as long as you do not stop.",
        who: "Confucius",
    },
    {
        key: "confucius-2",
        links: [{
            title: "Confucius",
            url: "https://en.wikipedia.org/wiki/Confucius"
        }],
        tags: [
            "ancient",
            "historical",
            "philosophical",
            "wisdom",
        ],
        text: "Real knowledge is to know the extent of one’s ignorance.",
        who: "Confucius",
    },
    {
        key: "confucius-3",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Confucius1.jpg",
        layout: "overlay",
        links: [
            {
                title: "Confucius",
                url: "https://en.wikipedia.org/wiki/Confucius"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2014/05/27/rising/"
            }
        ],
        tags: [
            "ancient",
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "wisdom",
        ],
        text: "Our greatest glory is not in never falling, but in rising every time we fall.",
        who: "Confucius",
    },
    {
        key: "eleanor-roosevelt-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/77/Eleanor_Roosevelt_in_Tivoli%2C_New_York_-_NARA_-_196162.jpg",
        links: [{
            title: "Eleanor Roosevelt",
            url: "https://en.wikipedia.org/wiki/Eleanor_Roosevelt"
        }],
        tags: [
            "historical",
            "inspirational",
        ],
        text: "With the new day comes new strength and new thoughts",
        who: "Eleanor Roosevelt",
    },
    {
        key: "elinor-smith-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Plato-raphael.jpg",
        layout: "overlay",
        links: [{
            title: "Elinor Smith",
            url: "https://en.wikipedia.org/wiki/Elinor_Smith"
        }],
        tags: [
            "growth",
            "inspirational",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "It had long since come to my attention that people of accomplishment rarely sat back and let things happen to them. They went out and happened to things.",
        who: "Elinor Smith",
    },
    {
        key: "elvis-presley-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/9/99/Elvis_Presley_promoting_Jailhouse_Rock.jpg",
        layout: "overlay",
        links: [{
            title: "Elvis Presley",
            url: "https://en.wikipedia.org/wiki/Elvis_Presley"
        }],
        tags: [
            "historical",
            "motivational",
        ],
        text: "Do something worth remembering.",
        who: "Elvis Presley",
    },
    {
        key: "emile-zola-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Vincent_van_Gogh_-_Dr_Paul_Gachet_-_Google_Art_Project.jpg",
        links: [
            {
                title: "Émile Zola",
                url: "https://en.wikipedia.org/wiki/%C3%89mile_Zola"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2016/06/18/passionate/"
            }
        ],
        text: "I would rather die of passion than of boredom.",
        who: "Émile Zola",
    },    
    {
        key: "frank-sinatra-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/9/97/Frank_Sinatra_by_Gottlieb_c1947.jpg",
        layout: "no-overlay",
        links: [{
            title: "Frank Sinatra",
            url: "https://en.wikipedia.org/wiki/Frank_Sinatra"
        }],
        tags: [
            "historical",
            "inspirational",
            "motivational",
            "success",
        ],
        text: "The best revenge is massive success.",
        who: "Frank Sinatra",
    },
    {
        key: "franklin-roosevelt-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/0/03/Fdr_car.jpg",
        layout: "overlay",
        links: [{
            title: "Franklin D. Roosevelt",
            url: "https://en.wikipedia.org/wiki/Franklin_D._Roosevelt"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
        ],
        text: "When you reach the end of your rope, tie a knot in it and hang on.",
        who: "Franklin D. Roosevelt",
    },
    {
        key: "franklin-roosevelt-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/7b/FDRfiresidechat2.jpg",
        layout: "overlay",
        links: [{
            title: "Franklin D. Roosevelt",
            url: "https://en.wikipedia.org/wiki/Franklin_D._Roosevelt"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
            "wisdom",
        ],
        text: "The only limit to our realization of tomorrow will be our doubts of today.",
        who: "Franklin D. Roosevelt",
    },
    {
        key: "frederick-douglass-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Frederick_Douglass_%28circa_1879%29.jpg",
        layout: "overlay",
        links: [{
            title: "Frederick Douglass",
            url: "https://en.wikipedia.org/wiki/Frederick_Douglass"
        }],
        tags: [
            "historical",
            "inspirational",
            "motivational",
            "wisdom",
            "success",
        ],
        text: "If there is no struggle, there is no progress.",
        who: "Frederick Douglass",
    },
    /** Another Frederick Douglass picture */
    /** https://upload.wikimedia.org/wikipedia/commons/8/8b/Frederick_Douglass_as_a_younger_man.jpg */
    {
        key: "friedrich-nietzsche-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Nietzsche187c.jpg",
        layout: "overlay",
        links: [
            {
                title: "Friedrich Nietzsche",
                url: "https://en.wikipedia.org/wiki/Friedrich_Nietzsche"
            },
            {
                title: "What does not kill me makes me stronger",
                url: "https://en.wikipedia.org/wiki/What_does_not_kill_me_makes_me_stronger"
            },
            {
                title: "Twilight of the Idols",
                url: "https://en.wikipedia.org/wiki/Twilight_of_the_Idols"
            }
        ],
        tags: [
            "historical",
            "inspirational",
            "motivational",
        ],
        text: "That which does not kill us makes us stronger.",
        who: "Friedrich Nietzsche",
    },
    {
        key: "gloria-steinem-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/8/80/Gloria_Steinem_1975.jpg",
        links: [{
            title: "Gloria Steinem",
            url: "https://en.wikipedia.org/wiki/Gloria_Steinem"
        }],
        tags: [
            "philosophical",
            "wisdom",
        ],
        text: "Dreaming, after all, is a form of planning.",
        who: "Gloria Steinem",
    },
    {
        key: "helen-keller-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Helen_KellerA.jpg",
        links: [{
            title: "Helen Keller",
            url: "https://en.wikipedia.org/wiki/Helen_Keller"
        }],
        tags: [
            "blind",
            "deaf",
            "historical",
            "life",
            "wisdom",
        ],
        text: "Life is either a daring adventure, or nothing.",
        who: "Helen Keller",
    },
    {
        key: "henry-david-thoreau-1",
        links: [{
            title: "Henry David Thoreau",
            url: "https://en.wikipedia.org/wiki/Henry_David_Thoreau"
        }],
        tags: [
            "historical",
            "life",
            "success",
            "wisdom",
        ],
        text: "Our life is frittered away by detail… simplify, simplify.",
        who: "Henry David Thoreau",
    },
    {
        key: "henry-ford-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Ford_1921.jpg",
        links: [{
            title: "Henry Ford",
            url: "https://en.wikipedia.org/wiki/Henry_Ford"
        }],
        tags: [
            "historical",
            "inspirational",
            "motivational",
            "success",
        ],
        text: "You can't build a reputation on what you are going to do.",
        who: "Henry Ford",
    },
    { 
        key: "henry-ford-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/5/52/ModelTFord1913.jpg",
        layout: "no-overlay",
        links: [{
            title: "Henry Ford",
            url: "https://en.wikipedia.org/wiki/Henry_Ford"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "Whether you think you can or you think you can't, you're right.",
        who: "Henry Ford",
    },
    /** Other Henry Ford picture */
    /** https://upload.wikimedia.org/wikipedia/commons/1/18/Henry_ford_1919.jpg */
    {
        key: "herman-melville-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Herman_Melville.jpg",
        layout: "overlay",
        links: [{
            title: "Herman Melville",
            url: "https://en.wikipedia.org/wiki/Herman_Melville"
        }],
        tags: [
            "historical",
            "inspirational",
            "success",
            "wisdom",
        ],
        text: "It is better to fail in originality than to succeed in imitation.",
        who: "Herman Melville",
    },
    {
        key: "isaac-newton-1",
        //backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/1/19/The_story_of_the_comets_-_Sir_Isaac_Newton.jpg",
        links: [{
            title: "Isaac Newton",
            url: "https://en.wikipedia.org/wiki/Isaac_Newton"
        }],
        tags: [
            "historical",
            "wisdom",
        ],
        text: "Truth is ever to be found in simplicity and not in the multiplicity and confusion of things",
        who: "Isaac Newton",
    },
    {
        key: "james-brown-1",
        links: [
            {
                title: "James Brown",
                url: "https://en.wikipedia.org/wiki/James_Brown"
            }
        ],
        text: "Say it loud. I’m black and I’m proud!",
        who: "James Brown"
    },
    /** Other Isaac Newton picture */
    /** https://upload.wikimedia.org/wikipedia/commons/3/39/GodfreyKneller-IsaacNewton-1689.jpg */
    {
        key: "jean-jacques-rousseau-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Jean-Jacques_Rousseau.jpg",
        links: [{
            title: "Bertrand Russell",
            url: "https://en.wikipedia.org/wiki/Jean-Jacques_Rousseau"
        }],
        tags: [
            "equality",
            "historical",
            "philosophical",
            "wisdom",
        ],
        text: "Man is born free, but is everywhere in chains.",
        who: "Jean-Jacques Rousseau",
    },
    {
        key: "jim-rohn-1",
        links: [{
            title: "Jim Rohn",
            url: "https://en.wikipedia.org/wiki/Jim_Rohn"
        }],
        tags: [
            "growth",
            "mindset",
            "philosophical",
            "wisdom",
        ],
        text: "Either you run the day or the day runs you.",
        who: "Jim Rohn",
    },
    {
        key: "john-locke-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/f/f0/John_Locke_by_Sir_Godfrey_Kneller%2C_Bt.jpg",
        links: [{
            title: "John Locke",
            url: "https://en.wikipedia.org/wiki/John_Locke"
        }],
        tags: [
            "growth",
            "historical",
            "mindset",
            "philosophical",
            "wisdom",
        ],
        text: "What worries you, masters you.",
        who: "John Locke",
    },
    {
        key: "john-locke-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/b8/John_Locke.jpg",
        links: [{
            title: "John Locke",
            url: "https://en.wikipedia.org/wiki/John_Locke"
        }],
        tags: [
            "historical",
            "philosophical",
            "wisdom",
        ],
        text: "The mind is furnished with ideas by experience alone.",
        who: "John Locke",
    },
    {
        key: "joseph-campbell-1",
        links: [{
            title: "Joseph Campbell",
            url: "https://en.wikipedia.org/wiki/Joseph_Campbell"
        }],
        text: "The cave you fear to enter holds the treasure you seek.",
        who: "Joseph Campbell",
    },
    {
        key: "julius-caesar-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/78/1944.20_-_Caesar_in_the_Gallic_Wars_from_The_Story_of_Caesar.jpg",
        links: [
            {
                title: "Julius Caesar",
                url: "https://en.wikipedia.org/wiki/Julius_Caesar"
            },
            {
                title: "Veni, vidi, vici",
                url: "https://en.wikipedia.org/wiki/Veni,_vidi,_vici"
            }
        ],
        tags: [
            "ancient",
            "philosophical",
        ],
        text: "I came, I saw, I conquered.",
        who: "Julius Caesar",
    },
    {
        key: "kwame-nkrumah-1",
        links: [
            {
                title: "Kwame Nkrumah",
                url: "https://en.wikipedia.org/wiki/Kwame_Nkrumah"
            }
        ],
        text: "Freedom is not something that one people can bestow on another as a gift. They claim it as their own and none can keep it from them",
        who: "Kwame Nkrumah"
    },
    /** Other Julius Ceasar picture */
    /** https://upload.wikimedia.org/wikipedia/commons/4/47/Caesar.jpg */
    /** https://upload.wikimedia.org/wikipedia/commons/9/93/Siege-alesia-vercingetorix-jules-cesar.jpg */
    /**  */
    {
        key: "lao-tzu-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Lao_Tzu_-_Project_Gutenberg_eText_15250.jpg",
        layout: "overlay",
        links: [{
            title: "Lao Tzu",
            url: "https://en.wikipedia.org/wiki/Lao_Tzu"
        }],
        tags: [
            "ancient",
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
            "philosophical",
            "wisdom",
        ],
        text: "The journey of a thousand miles begins with one step.",
        who: "Lao Tzu",
    },
    
    {
        key: "ludwig-wittgenstein-1",
        links: [{
            title: "Ludwig Wittgenstein",
            url: "https://en.wikipedia.org/wiki/Ludwig_Wittgenstein"
        }],
        text: "We are asleep. Our Life is a dream. But we wake up sometimes, just enough to know that we are dreaming.",
        who: "Ludwig Wittgenstein",
    },
    /* Other Lao Tzu picture */
    /* https://upload.wikimedia.org/wikipedia/commons/0/04/Shanghai_Museum_2006_17-36.jpg */

    {
        key: "mahatma-gandhi-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Mahatma-Gandhi%2C_studio%2C_1931.jpg",
        links: [{
            title: "Mahatma Gandhi",
            url: "https://en.wikipedia.org/wiki/Mahatma_Gandhi"
        }],
        tags: [
            "historical",
            "wisdom",
        ],
        text: "Nonviolence is a weapon of the strong.",
        who: "Mahatma Gandhi",
    },
    {
        key: "malcom-x-1",
        backgroundImage: "https://commons.wikimedia.org/wiki/Category:Photographs_taken_on_1964-03-26#/media/File:MLK_and_Malcolm_X_USNWR_cropped.jpg",
        links: [{
            title: "Malcom X",
            url: "https://en.wikipedia.org/wiki/Malcom_X"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "Stumbling is not falling.",
        who: "Malcom X",
    },
    {
        key: "malcom-x-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/4/49/Malcolm-x.jpg",
        layout: "overlay",
        links: [{
            title: "Malcom X",
            url: "https://en.wikipedia.org/wiki/Malcom_X"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "The future belongs to those who prepare for it today.",
        who: "Malcom X",
    },
    {
        key: "malcom-x-3",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Malcolm_X_NYWTS_4.jpg",
        layout: "overlay",
        links: [{
            title: "Malcom X",
            url: "https://en.wikipedia.org/wiki/Malcom_X"
        }],
        tags: [
            "empowering",
            "historical",
            "mindset",
            "wisdom",
        ],
        text: "A man who stands for nothing will fall for anything.",
        who: "Malcom X",
    },
    {
        key: "malcom-x-4",
        links: [
            {
                title: "Malcom X",
                url: "https://en.wikipedia.org/wiki/Malcom_X"
            }
        ],
        text: "We declare our right on this earth to be a human being, to be respected as a human being, to be given the rights of a human being in this society, on this earth, in this day, which we intend to bring into existence by any means necessary.",
        who: "Malcom X"
    },

    /** Other Malcom X picture */
    /** https://upload.wikimedia.org/wikipedia/commons/d/d7/Malcolm_X_NYWTS_4.jpg */
    {
        key: "marcus-aurelius-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Marc_Aurele.jpg",
        layout: "overlay",
        links: [{
            title: "Marcus Aurelius",
            url: "https://en.wikipedia.org/wiki/Marcus_Aurelius"
        }],
        tags: [
            "ancient",
            "growth",
            "historical",
            "inspirational",
            "mind",
            "mindset",
            "motivational",
            "wisdom",
        ],
        text: "You have power over your mind – not outside events. Realize this, and you will find strength.",
        who: "Marcus Aurelius",
    },
    /** Other Marcus Aurelius picture */
    /** https://upload.wikimedia.org/wikipedia/commons/2/24/DSC04500l_Istanbul_-_Museo_archeol._-_Marco_Aurelio_-_Foto_G._Dall%27Orto_28-5-2006.jpg */
    {
        key: "margaret-mead-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Margaret_Mead_%281901-1978%29_%286891482481%29.jpg",
        links: [
            {
                title: "Margaret Mead",
                url: "https://en.wikipedia.org/wiki/Margaret_Mead"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2014/11/10/you-unique/"
            }
        ],
        misattributed: true,
        text: "Always remember that you are absolutely unique. Just like everyone else.",
        who: "Margaret Mead",
    },
    {
        key: "marilyn-monroe-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Ed_Feingersh_Marilyn_Monroe_1955_no8.webp",
        layout: "overlay",
        links: [{
            title: "Marilyn Monroe",
            url: "https://en.wikipedia.org/wiki/Marilyn_Monroe"
        }],
        tags: [
            "empowering",
            "historical",
            "motivational",
        ],
        text: "Imperfection is beauty, madness is genius and it’s better to be absolutely ridiculous than absolutely boring.",
        who: "Marilyn Monroe",
    },
    {
        key: "marilyn-monroe-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Monroe_1953_publicity.jpg",
        links: [{
            title: "Marilyn Monroe",
            url: "https://en.wikipedia.org/wiki/Marilyn_Monroe"
        }],
        tags: [
            "historical",
            "wisdom",
        ],
        text: "Fear is stupid. So are regrets.",
        who: "Marilyn Monroe",
    },
    /** Other Marilyn Monroe picture */
    /**  https://upload.wikimedia.org/wikipedia/commons/4/45/Ed_Feingersh_Marilyn_Monroe_1955_no6.webp*/
    /** https://upload.wikimedia.org/wikipedia/commons/0/0a/Marilyn_Monroe_in_1952.jpg */
    /** https://upload.wikimedia.org/wikipedia/commons/f/f8/Marilyn_Monroe%2C_The_Prince_and_the_Showgirl_%28small%29.jpg */
    /** https://upload.wikimedia.org/wikipedia/commons/3/3d/Barris_Marilyn_Monroe.jpg */
    { 
        key: "mark-twain-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Mark_Twain2.jpg",
        links: [
            {
                title: "Mark Twain",
                url: "https://en.wikipedia.org/wiki/Mark_Twain"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2018/02/03/start/"
            }
        ],
        misattributed: true,
        text: "The secret of getting ahead is getting started. The secret of getting started is breaking your complex overwhelming tasks into small manageable tasks, and starting on the first one.",
        who: "Mark Twain",
    },
    {
        key: "mark-twain-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Mark_Twain_by_AF_Bradley.jpg",
        layout: "overlay",
        links: [
            {
                title: "Mark Twain",
                url: "https://en.wikipedia.org/wiki/Mark_Twain"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2011/09/29/you-did/"
            }
        ],
        misattributed: true,
        text: "Twenty years from now you will be more disappointed by the things that you didn't do than by the ones you did do. So, throw off the bowlines, sail away from safe harbor, catch the trade winds in your sails. Explore, Dream, Discover.",
        who: "Mark Twain",
    },
    {
        key: "martin-luther-king-1",
        links: [
            {
                title: "Martin Luther King Jr.",
                url: "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr."
            }
        ],
        text: "Freedom is never voluntarily given by the oppressor; it must be demanded by the oppressed.",
        who: "Martin Luther King Jr."
    },
    {
        key: "martin-luther-king-2",
        source: "The Measures of Man (1959)",
        text: "Man is man because he is free to operate within the framework of his destiny. He is free to deliberate, to make decisions, and to choose between alternatives. He is distinguished from animals by his freedom to do evil or to do good and to walk the high road of beauty or tread the low road of ugly degeneracy.",
        who: "Martin Luther King Jr."
    },
    {
        key: "martin-luther-king-3",
        source: '"The Birth of a New Nation," Sermon Delivered at Dexter Avenue Baptist Church, Montgomery, Alabama (7 April 1957)',
        text: "Freedom only comes through persistent revolt, through persistent agitation, through persistently rising up against the system of evil.",
        who: "Martin Luther King Jr."
    },
    {
        key: "maya-angelou-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/d/da/Angelou_Obama.jpg",
        links: [{
            title: "Maya Angelou",
            url: "https://en.wikipedia.org/wiki/Maya_Angelou"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
        ],
        text: "You will face many defeats in life, but never let yourself be defeated.",
        who: "Maya Angelou",
    },
    {
        key: "maya-angelou-2",
        links: [{
            title: "Maya Angelou",
            url: "https://en.wikipedia.org/wiki/Maya_Angelou"
        }],        
        text: "You may kill me with your hatefulness. But still, like air, I’ll rise.",
        who: "Maya Angelou"
    },
    {
        key: "michael-jordan-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Dereck_Hard_Michael_Jordan_-_Little_Reality_2016.jpg",
        links: [{
            title: "Michael Jordan",
            url: "https://en.wikipedia.org/wiki/Michael_Jordan"
        }],
        tags: [
            "growth",
            "inspirational",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "I’ve failed over and over and over again in my life and that is why I succeed.",
        who: "Michael Jordan",
    },
    /** Other Michael Jordan picture */
    /**  https://upload.wikimedia.org/wikipedia/commons/b/b3/Jordan_Lipofsky.jpg */
    {
        key: "muhammad-ali-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/9/93/Bokser_Cassius_Clay_%28USA_%281966%29_%28Mohammed_Ali%29%2C_Bestanddeelnr_924-3060.jpg",
        links: [{
            title: "Muhammad Ali",
            url: "https://en.wikipedia.org/wiki/Muhammad_Ali"
        }],
        tags: [
            "growth",
            "inspirational",
            "mindset",
            "motivational",
        ],
        text: "I am the greatest, I said that even before I knew I was.",
        who: "Muhammad Ali",
    },
    {
        key: "muhammad-ali-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Muhammad_Ali_fights_Brian_London_on_August_6%2C_1966.jpg",
        text: "Float like a butterfly, sting like a bee.",
        links: [{
            title: "Muhammad Ali",
            url: "https://en.wikipedia.org/wiki/Muhammad_Ali"
        }],
        tags: [
            "motivational",
        ],
        who: "Muhammad Ali",
    },
    {
        key: "muhammad-ali-3",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Muhammad-Ali-Sri-Chinmoy.jpg",
        links: [{
            title: "Muhammad Ali",
            url: "https://en.wikipedia.org/wiki/Muhammad_Ali"
        }],
        tags: [
            "inspirational",
            "motivational",
            "success",
        ],
        text: "Don’t count the days, make the days count.",
        who: "Muhammad Ali",
    },
    {
        key: "muhammad-ali-4",
        links: [
            {
                title: "Muhammad Ali",
                url: "https://en.wikipedia.org/wiki/Muhammad_Ali"
            },
            {
                title: "Black people (Wikiquote)",
                url: "https://en.wikiquote.org/wiki/Black_people"
            }
        ],        
        text: "I am America. I am the part you won't recognize. But get used to me. Black, confident, cocky; my name, not yours; my religion, not yours; my goals, my own; get used to me.",
        who: "Muhammad Ali"
    },
    {
        key: "neil-degrasse-tyson-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/78/Neil_deGrasse_Tyson_at_Howard_University_September_28%2C_2010.jpg",
        links: [{
            title: "Neil deGrasse Tyson",
            url: "https://en.wikipedia.org/wiki/Neil_deGrasse_Tyson"
        }],
        tags: [
            "wisdom",
            "success",
        ],
        text: "There is no greater education than one that is self-driven.",
        who: "Neil deGrasse Tyson",
    },
    {
        key: "nelson-mandela-1",
        links: [
            {
                title: "Nelson Mandela",
                url: "https://en.wikipedia.org/wiki/Nelson_Mandela"
            }            
        ],
        text: "No one is born hating another person because of the color of his skin, or his background, or his religion. People must learn to hate, and if they can learn to hate, they can be taught to love, for love comes more naturally to the human heart than its opposite.",
        who: "Nelson Mandela"
    },
    {
        key: "nicolaus-copernicus-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/8/88/Jan_Matejko-Astronomer_Copernicus-Conversation_with_God.jpg",
        links: [{
            title: "Nicolaus Copernicus",
            url: "https://en.wikipedia.org/wiki/Nicolaus_Copernicus"
        }],
        tags: [
            "historical",
            "philosophical",
            "wisdom",
        ],
        text: "To know that we know what we know, and to know that we do not know what we do not know, that is true knowledge.",
        who: "Nicolaus Copernicus",
    },
    /** Other Nicolaus Copernicus picture */
    /** https://upload.wikimedia.org/wikipedia/commons/2/28/Copernicus.jpg */
    {
        key: "norman-vaughan-1",
        links: [{
            title: "Norman Vaughan",
            url: "https://en.wikipedia.org/wiki/Norman_Vaughan"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
        ],
        text: "Dream big and dare to fail.",
        who: "Norman Vaughan",
    },
    {
        key: "paulo-coelho-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Paulo_Coelho_2007-04-09_001.jpg",
        layout: "overlay",
        links: [{
            title: "Paulo Coelho",
            url: "https://en.wikipedia.org/wiki/Paulo_Coelho"
        }],
        tags: [
            "growth",
            "inspirational",
            "mindset",
            "motivational",
            "wisdom",
            "success",
        ],
        text: "You are what you believe yourself to be.",
        who: "Paulo Coelho",
    },
    {
        key: "pablo-picasso-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Portrait_de_Picasso%2C_1908.jpg",
        layout: "overlay",
        links: [{
            title: "Pablo Picasso",
            url: "https://en.wikipedia.org/wiki/Pablo_Picasso"
        }],
        tags: [
            "historical",
            "motivational",
        ],
        text: "Learn the rules like a pro, so you can break them like an artist.",
        who: "Pablo Picasso",
    },
    {
        key: "peace-pilgrim-1",
        links: [{
            title: "Peace Pilgrim",
            url: "https://en.wikipedia.org/wiki/Peace_Pilgrim"
        }],
        tags: [
            "growth",
            "mindset",
            "think",
            "thought",
            "success",
            "wisdom",
        ],
        text: "If you realized how powerful your thoughts are, you would never think a negative thought.",
        who: "Peace Pilgrim",
    },
    {
        key: "ralph-waldo-emerson-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Emerson_seated.jpg",
        layout: "overlay",
        links: [
            {
                title: "Ralph Waldo Emerson",
                url: "https://en.wikipedia.org/wiki/Ralph_Waldo_Emerson"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2020/12/08/destined/"
            }
        ],
        tags: [
            "growth",
            "inspirational",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "The only person you are destined to become is the person you decide to be.",
        who: "Ralph Waldo Emerson",
    },
    {
        key: "robin-diangelo-1",
        links: [
            {
                title: "https://en.wikipedia.org/wiki/Robin_DiAngelo",
                url: "https://en.wikipedia.org/wiki/Robin_DiAngelo"
            }
        ],
        text: "It is white people’s responsibility to be less fragile; people of color don’t need to twist themselves into knots trying to navigate us as painlessly as possible.",
        who: "Robin DiAngelo"
    },
    {
        key: "rosa-parks-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Rosa_Parks_being_fingerprinted_by_Deputy_Sheriff_D.H._Lackey_after_being_arrested_on_February_22%2C_1956%2C_during_the_Montgomery_bus_boycott.jpg",
        links: [{
            title: "Rosa Parks",
            url: "https://en.wikipedia.org/wiki/Rosa_Parks"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "I have learned over the years that when one's mind is made up, this diminishes fear.",
        who: "Rosa Parks",
    },
    {
        key: "rosa-parks-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Rosaparks.jpg",
        layout: "overlay",
        links: [{
            title: "Rosa Parks",
            url: "https://en.wikipedia.org/wiki/Rosa_Parks"
        }],
        tags: [
            "empowering",
            "historical",
            "philosophical",
            "wisdom",
        ],
        text: "Stand for something or you will fall for anything. Today’s mighty oak is yesterday’s nut that held its ground.",
        who: "Rosa Parks",
    },
    {
        key: "rosa-parks-3",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Rosa_Parks_%2813270402093%29.jpg",
        links: [{
            title: "Rosa Parks",
            url: "https://en.wikipedia.org/wiki/Rosa_Parks"
        }],
        tags: [
            "empowering",
            "historical",
            "inspirational",
        ],
        text: "You must never be fearful about what you are doing when it is right.",
        who: "Rosa Parks",
    },
    {
        key: "rosa-parks-4",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Rosa_parks_human_rights_museum_memphis_2.jpg",
        links: [{
            title: "Rosa Parks",
            url: "https://en.wikipedia.org/wiki/Rosa_Parks"
        }],
        tags: [
            "empowering",
            "equality",
            "historical",
            "inspirational",
            "motivational",
        ],
        text: "No.",
        who: "Rosa Parks",
    },
    {
        key: "rene-descartes-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/7/73/Frans_Hals_-_Portret_van_Ren%C3%A9_Descartes.jpg",
        links: [{
            title: "René Descartes",
            url: "https://en.wikipedia.org/wiki/René_Descartes"
        }],
        tags: [
            "growth",
            "historical",
            "mindset",
            "philosophical",
        ],
        text: "I think, therefore I am.",
        who: "René Descartes",
    },
    {
        key: "salvador-dali-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/2/27/Salvador_Dali_NYWTS.jpg",
        links: [{
            title: "Salvador Dali",
            url: "https://en.wikipedia.org/wiki/Salvador_Dali"
        }],
        tags: [
            "historical",
            "wisdom",
        ],
        text: "Have no fear of perfection, you'll never reach it.",
        who: "Salvador Dali",
    },
    {
        key: "socrates-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Socrates.jpg",
        links: [
            {
                title: "Socrates",
                url: "https://en.wikipedia.org/wiki/Socrates"
            },
            {
                title: "The unexamined life is not worth living",
                url: "https://en.wikipedia.org/wiki/The_unexamined_life_is_not_worth_living"
            }
        ],
        tags: [
            "ancient",
            "growth",
            "historical",
            "philosophical",
            "mindset",
            "wisdom",
        ],
        text: "The unexamined life is not worth living.",
        who: "Socrates",
    },
    {
        key: "socrates-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/8/8d/%CE%A3%CF%89%CE%BA%CF%81%CE%AC%CF%84%CE%B7%CF%82%2C_%CE%91%CE%BA%CE%B1%CE%B4%CE%B7%CE%BC%CE%AF%CE%B1_%CE%91%CE%B8%CE%B7%CE%BD%CF%8E%CE%BD_6616.jpg",
        layout: "overlay",
        links: [{
            title: "Socrates",
            url: "https://en.wikipedia.org/wiki/Socrates"
        }],
        tags: [
            "ancient",
            "historical",
            "philosophical",
            "wisdom",
        ],
        text: "One thing only I know, and that is that I know nothing.",
        who: "Socrates",
    },
    {
        key: "socrates-3",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Socrates.jpg",
        links: [{
            title: "Socrates",
            url: "https://en.wikipedia.org/wiki/Socrates"
        }],
        tags: [
            "ancient",
            "growth",
            "historical",
            "mindset",
            "philosophical",
            "wisdom",
        ],
        text: "To find yourself, think for yourself.",
        who: "Socrates",
    },
    {
        key: "stephen-king-1",
        links: [{
            title: "Stephen King",
            url: "https://en.wikipedia.org/wiki/Stephen_King"
        }],
        tags: [
            "motivational",
            "wisdom",
        ],
        text: "Get busy living, or get busy dying.",
        who: "Stephen King",
    },
    {
        key: "steve-biko-1",
        links: [
            {
                title: "Steve Biko",
                url: "https://en.wikipedia.org/wiki/Steve_Biko"
            }
        ],
        source: "Statement as witness (3 May 1976)",
        text: "The basic tenet of black consciousness is that the black man must reject all value systems that seek to make him a foreigner in the country of his birth and reduce his basic human dignity.",
        who: "Steve Biko"
    },
    {
        key: "steve-biko-2",
        links: [
            {
                title: "Steve Biko",
                url: "https://en.wikipedia.org/wiki/Steve_Biko"
            }
        ],        
        source: "The Quest for a True Humanity",
        text: "The system concedes nothing without demand, for it formulates its very method of operation on the basis that the ignorant will learn to know, the child will grow into an adult and therefore demands will begin to be made. It gears itself to resist demands in whatever way it sees fit.",
        who: "Steve Biko"
    },
    {
        key: "theodore-roosevelt-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Theodore_Roosevelt_laughing.jpg",
        layout: "overlay",
        links: [{
            title: "Theodore Roosevelt",
            url: "https://en.wikipedia.org/wiki/Theodore_Roosevelt"
        }],
        tags: [
            "growth",
            "historical",
            "mindset",
            "wisdom",
        ],
        text: "Complaining about a problem without posing a solution is called whining.",
        who: "Theodore Roosevelt",
    },
    {
        key: "thomas-edison-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Thomas_Alva_Edison._Photograph._Wellcome_V0026323.jpg",
        links: [{
            title: "Thomas Edison",
            url: "https://en.wikipedia.org/wiki/Thomas_Edison"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "mindset",
            "motivational",
        ],
        text: "I failed my way to success.",
        who: "Thomas Edison",
    },
    {
        key: "thomas-fuller-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/6/64/Thomas_Fuller_1841.jpg",
        links: [
            {
                title: "Thomas Fuller",
                url: "https://en.wikipedia.org/wiki/Thomas_Fuller"
            },
            {
                title: "Full Text of Gnomologia: Adages and Proverbs (PDF)",
                url: "https://books.google.com/books/download/Gnomologia.pdf?id=3y8JAAAAQAAJ&output=pdf&sig=ACfU3U2H8sLwgwP9tkatcap_L5GBxuysKw"
            }
        ],
        tags: [
            "historical",
            "growth",
            "mindset",
            "wisdom",
        ],
        text: "All things are difficult before they are easy.",
        who: "Thomas Fuller",
    },
    {
        key: "vince-lombardi-1",
        links: [{
            title: "Vince Lombardi",
            url: "https://en.wikipedia.org/wiki/Vince_Lombardi"
        }],
        tags: [
            "growth",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "Perfection is not attainable, but if we chase perfection we can catch excellence.",
        who: "Vince Lombardi",
    },
    {
        key: "vince-lombardi-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Vince_Lombardi_%2811282503285%29.jpg",
        links: [{
            title: "Vince Lombardi",
            url: "https://en.wikipedia.org/wiki/Vince_Lombardi"
        }],
        tags: [
            "growth",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "Winners never quit, and quitters never win.",
        who: "Vince Lombardi",
    },
    {
        key: "vince-lombardi-3",
        links: [{
            title: "Vince Lombardi",
            url: "https://en.wikipedia.org/wiki/Vince_Lombardi"
        }],
        tags: [
            "growth",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "It’s not whether you get knocked down, it’s whether you get up.",
        who: "Vince Lombardi",
    },
    {
        key: "voltaire-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Voltaire-Baquoy.gif",
        links: [{
            title: "Voltaire",
            url: "https://en.wikipedia.org/wiki/Voltaire"
        }],
        tags: [
            "historical",
            "life",
            "success",
            "wisdom",
        ],
        text: "Don’t think money does everything or you are going to end up doing everything for money.",
        who: "Voltaire",
    },
    {
        key: "voltaire-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/c/c2/D%27apr%C3%A8s_Maurice_Quentin_de_La_Tour%2C_Portrait_de_Voltaire%2C_d%C3%A9tail_du_visage_%28ch%C3%A2teau_de_Ferney%29.jpg",
        links: [{
            title: "Voltaire",
            url: "https://en.wikipedia.org/wiki/Voltaire"
        }],
        tags: [
            "growth",
            "historical",
            "life",
            "mindset",
            "success",
            "wisdom",
        ],
        text: "Doubt is not a pleasant condition, but certainty is absurd.",
        who: "Voltaire",
    },
    /** Other Voltaire picture */
    /** https://upload.wikimedia.org/wikipedia/commons/f/f2/Atelier_de_Nicolas_de_Largilli%C3%A8re%2C_portrait_de_Voltaire%2C_d%C3%A9tail_%28mus%C3%A9e_Carnavalet%29_-002.jpg*/
    {
        key: "warren-buffett-1",
        links: [{
            title: "Warren Buffett",
            url: "https://en.wikipedia.org/wiki/Warren_Buffett"
        }],
        tags: [
            "wisdom",
        ],
        text: "Someone’s sitting in the shade today because someone planted a tree a long time ago.",
        who: "Warren Buffett",
    },
    {
        key: "wayne-gretzky-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/0/03/Wayne_Gretzky_2006-02-18_Turin_001.jpg",
        links: [{
            title: "Wayne Gretzky",
            url: "https://en.wikipedia.org/wiki/Wayne_Gretzky"
        }],
        tags: [
            "growth",
            "inspirational",
            "life",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "You miss 100% of the shots you don't take.",
        who: "Wayne Gretzky",
    },
    {
        key: "william-shakespeare-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Shakespeare.jpg",
        layout: "overlay",
        links: [{
            title: "William Shakespeare",
            url: "https://en.wikipedia.org/wiki/William_Shakespeare"
        }],
        tags: [
            "growth",
            "historical",
            "life",
            "mindset",
            "wisdom",
        ],
        text: "A fool thinks himself to be wise, but a wise man knows himself to be a fool.",
        who: "William Shakespeare",
    },
    {
        key: "william-makepeace-thackeray-1",
        links: [
            {
                title: "William Makepeace Thackeray",
                url: "https://en.wikipedia.org/wiki/William_Makepeace_Thackeray"
            },
            {
                title: "Quote Investigator",
                url: "https://quoteinvestigator.com/2014/10/03/be-good/"
            }
        ],
        text: "Whatever you are, try be a good one.",
        who: "William Makepeace Thackeray",
    },
    {
        key: "winston-churchill-1",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Churchill_on_the_east_bank_of_the_Rhine.jpg",
        links: [{
            title: "Winston Churchill",
            url: "https://en.wikipedia.org/wiki/Winston_Churchill"
        }],
        tags: [
            "growth",
            "historical",
            "life",
            "mindset",
            "success",
            "wisdom",
        ],
        text: "Success is walking from failure to failure with no loss of enthusiasm.",
        who: "Winston Churchill",
    },
    {
        key: "winston-churchill-2",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Winston_Churchill_during_the_Second_World_War_H12752.jpg",
        links: [{
            title: "Winston Churchill",
            url: "https://en.wikipedia.org/wiki/Winston_Churchill"
        }],
        tags: [
            "growth",
            "historical",
            "life",
            "mindset",
            "wisdom",
        ],
        text: "Keep calm and carry on.",
        who: "Winston Churchill",
    },
    {
        key: "winston-churchill-3",
        backgroundImage: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Sir_Winston_Churchill_-_19086236948.jpg",
        layout: "overlay",
        links: [{
            title: "Winston Churchill",
            url: "https://en.wikipedia.org/wiki/Winston_Churchill"
        }],
        tags: [
            "growth",
            "historical",
            "inspirational",
            "life",
            "mindset",
            "motivational",
            "success",
            "wisdom",
        ],
        text: "Success is not final; failure is not fatal: It is the courage to continue that counts.",
        who: "Winston Churchill",
    },
    {
        who: "Unknown",
        key: "QUOTE35",
        tags: [
            "growth",
            "inspirational",
            "motivational",
            "mindset",
        ],
        text: "When the plan doesn't work, change the plan - never the goal",
    },

    /** Other Winston Churchill picture */
    /** https://upload.wikimedia.org/wikipedia/commons/5/59/Winston_Churchill_verl_sst_das_Geb_ude_der_Admiralt_t_%281912%29.jpg */
    /** https://upload.wikimedia.org/wikipedia/commons/7/70/Winston_Churchill_watching_Allied_vehicles_crossing_the_Rhine.jpg */
];
