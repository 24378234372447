import { Manifest } from "../../runtime/Manifest"
import { ReopenTaskPluginKey } from "./ReopenTaskPluginKey"

export const ReopenTaskManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ReopenTaskPluginKey,

    /**
     * The display title of the plugin.
     */
    title: "Reopen Task"
}