import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { WaitingAnnotator } from "./WaitingAnnotator"
import { WaitingAnnotatorContextKey } from "./WaitingAnnotatorContextKey"
import { WaitingPluginKey } from "../waiting/WaitingPluginKey"
import { WaitingSchemaId } from "../waiting/WaitingSchemaId"

export const WaitingAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "waiting-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        WaitingPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: WaitingAnnotatorContextKey,
            value: new WaitingAnnotator()
        } as ManifestEntry<WaitingAnnotator>,

        // registration
        {
            key: "WaitingAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [WaitingAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: WaitingSchemaId,
                annotator: context[WaitingAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<WaitingAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Waiting Annotator"
}
