
/**
 * Normalizes the input into an array.
 */
function asArray(values: number | number[] | string | string[]): (string | number)[] {

    if (Array.isArray(values)) {
        return values;
    }

    switch(typeof(values)) {

        case "string":
            return values.split(",");

        case "number":
            return [values];

        default:
            return [];
    }
}

export function parseMonths(values: number | number[] | string | string[]): number[] {

    // Track the 0-based month numbers to return
    const results = new Set<number>();

    // Normalize the input into an array
    const array = asArray(values);
 
    // Examine each item and convert to a 0-based month number
    for(let item of array) {

        switch(typeof(item)) {

            case "number":
                if (item >=0 && item <= 11) {
                    results.add(item);
                }
                else {
                    console.warn(`parseMonths() skipping out-of-range 0-based month: ${item}`);
                }
                break;

            case "string":
                switch(item.trim().toLowerCase()) {
                    case "jan": results.add(0); break;
                    case "feb": results.add(1); break;
                    case "mar": results.add(2); break;
                    case "apr": results.add(3); break;
                    case "may": results.add(4); break;
                    case "jun": results.add(5); break;
                    case "jul": results.add(6); break;
                    case "aug": results.add(7); break;
                    case "sep": results.add(8); break;
                    case "oct": results.add(9); break;
                    case "nov": results.add(10); break;
                    case "dec": results.add(11); break;
                    default:
                        console.warn(`parseMonths invalid month name ${item}`);
                        break;
                }
                break;

            default:
                console.warn(`parseMonths invalid type ${item}`);
                break;
        }
    }

    return [...results.values()].sort();
}