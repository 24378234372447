import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { TimerAnnotator } from "./TimerAnnotator"
import { TimerAnnotatorContextKey } from "./TimerAnnotatorContextKey"
import { TimerPluginKey } from "../timer/TimerPluginKey"
import { TimerSchemaId } from "../timer/TimerSchemaId"

export const TimerAnnotatorManifest: Manifest = {

    /**
     * The key that uniquely identifie the plugin.
     */
    key: "timer-annotator",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorRegistryPluginKey,
        TimerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [

        // annotator
        {
            key: TimerAnnotatorContextKey,
            value: new TimerAnnotator()
        } as ManifestEntry<TimerAnnotator>,

        // registration
        {
            key: "TimerAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [TimerAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: TimerSchemaId,
                annotator: context[TimerAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<TimerAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Timer Annotator"
}