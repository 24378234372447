export function sinceMidnight(when?: number): number {

    // Convert the timestamp into a date; use the current date if the timestamp is empty
    const onDate = (when ? new Date(when) : new Date());

    // Get midnight of the date
    const midnight = new Date(
        onDate.getFullYear(),
        onDate.getMonth(),
        onDate.getDate());

    return onDate.valueOf() - midnight.valueOf();
}