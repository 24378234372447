import { AnnotatorPluginKey } from "../annotator/AnnotatorPluginKey"
import { AnnotatorRegistryContextKey } from "../annotator-registry/AnnotatorRegistryContextKey"
import { AnnotatorRegistryEntry } from "../annotator-registry/AnnotatorRegistryEntry"
import { AnnotatorRegistryPluginKey } from "../annotator-registry/AnnotatorRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PaymentAnnotator } from "./PaymentAnnotator"
import { PaymentAnnotatorContextKey } from "./PaymentAnnotatorContextKey"
import { PaymentPluginKey } from "../payment/PaymentPluginKey"
import { PaymentSchemaId } from "../payment/PaymentSchemaId"

export const PaymentAnnotatorManifest: Manifest = {
    /**
     * The key that uniquely identifies the plugin.
     */
    key: "payment-annotator",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        AnnotatorPluginKey,
        AnnotatorRegistryPluginKey,
        PaymentPluginKey
    ],

    /**
     * The plugins that must be installed before this one.
     */
    entries: [

        // annotator
        {
            key: PaymentAnnotatorContextKey,
            value: new PaymentAnnotator()
        } as ManifestEntry<PaymentAnnotator>,

        // registration
        {
            key: "PaymentAnnotatorRegistryEntry",
            registerWith: AnnotatorRegistryContextKey,
            requires: [PaymentAnnotatorContextKey],
            value: (context: ManifestContext) => ({
                schema: PaymentSchemaId,
                annotator: context[PaymentAnnotatorContextKey]
            })
        } as ManifestEntry<AnnotatorRegistryEntry<PaymentAnnotator>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Payment Annotator"
}