import { AncestorsIndexer } from "./AncestorsIndexer"
import { DefaultStoreContextKey } from "../default-store/DefaultStoreContextKey"
import { DefaultStorePluginKey } from "../default-store/DefaultStorePluginKey"
import { IndexerPluginKey } from "../indexer/IndexerPluginKey"
import { IndexerRegistryContextKey } from "../indexer-registry/IndexerRegistryContextKey"
import { IndexerRegistryEntry } from "../indexer-registry/IndexerRegistryEntry"
import { IndexerRegistryPluginKey } from "../indexer-registry/IndexerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { RootPluginKey } from "../root/RootPluginKey"

export const AncestorsIndexerManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "ancestors-indexer",

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DefaultStorePluginKey,
        IndexerPluginKey,
        IndexerRegistryPluginKey,
        RootPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "AncestorsIndexer",
            registerWith: IndexerRegistryContextKey,
            requires: [DefaultStoreContextKey],
            title: "Ancestors Indexer",
            value: (context: ManifestContext) => ({
                schema: "*",
                indexer: new AncestorsIndexer(context[DefaultStoreContextKey])
            })
        } as ManifestEntry<IndexerRegistryEntry<AncestorsIndexer>>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Ancestors Indexer"
}