import React from "react"
import { clone } from "../../library/clone"
import { Edit } from "../edit/Edit"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import { ReactInput } from "../react-input/ReactInput"
import { ReactInputFailure } from "../react-input/ReactInputFailure"
import { ReactInputMatcher } from "../react-input/ReactInputMatcher"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { SchemaRegistry } from "../schema-registry/SchemaRegistry"

export interface EditCardViewProps extends ReactViewProps {

    /**
     * A matcher object that returns an input control for a given schema.
     */
    inputMatcher: ReactInputMatcher;

    /**
     * A registry of all available schemas.
     */
    schemas: SchemaRegistry;
}

export const EditCardView: React.FC<EditCardViewProps> = (props) => {

    if (typeof(props.inputMatcher) !== "object") {
        return (
            <ReactInputFailure>
                matcher must be specified as an object
            </ReactInputFailure>
        )
    }

    if (typeof(props.schemas) !== "object") {
        return (
            <ReactInputFailure>
                schemas must be specified as an object
            </ReactInputFailure>
        )
    }

    const edit = props?.model?.payload?.data as Edit
    if (!edit) {
        return (
            <ReactInputFailure>
                payload does not contain an edit object
            </ReactInputFailure>
        )
    }

    if (!edit.payload) {
        return (
            <ReactInputFailure>
                edit does not specifiy a payload
            </ReactInputFailure>
        )
    }

    if (!edit.payload.schema) {
        return (
            <ReactInputFailure>
                edit does not specify a payload with a schema
            </ReactInputFailure>
        )
    }

    const schema = props.schemas.get(edit.payload.schema);
    if (!schema) {
        return (
            <ReactInputFailure>
                schema {edit.payload.schema} cannot be found
            </ReactInputFailure>
        )
    }

    let value = clone(edit.payload?.data);

    function onChange(v: any) {
        value = v;
    }

    async function onsubmit(e: React.FormEvent) {
        e.preventDefault();
        if (props.dispatch) {
            // Package the new payload with the edited value
            const copy = {...edit.payload, data: value}

            props.dispatch(copy);
        }
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{schema.title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <form onSubmit={(e) => onsubmit(e)}>
                    <ReactInput
                        inputMatcher={props.inputMatcher}
                        onChange={value => onChange(value)}
                        schema={schema}
                        value={value}></ReactInput>
                    <IonButton type="submit">Save</IonButton>
                </form>
            </IonCardContent>
        </IonCard>
    )
}