import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { PayloadPredicates } from "../payload/PayloadPredicates"
import { Predicate } from "../predicate/Predicate"
import { PredicateArrayContextKey } from "../predicate/PredicateArrayContextKey"
import { PredicatePluginKey } from "../predicate/PredicatePluginKey"

export const DeferPredicatesManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "defer-predicates",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PredicatePluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DeferredToFuturePredicate",
            registerWith: PredicateArrayContextKey,
            value: {
                key: "DeferredToFuturePredicate",
                predicate: PayloadPredicates.DeferredToFuture,
                title: "Deferred to Future"
            }
        } as ManifestEntry<Predicate>,        
    ],

    /**
     * The display title of the plugin.
     */
    title: "Defer Predicates"
}