import { Registry } from "../../runtime/Registry"
import { ServiceRegistryEntry } from "./ServiceRegistryEntry";

export class ServiceRegistry implements Registry<ServiceRegistryEntry> {

    private services = new Set<ServiceRegistryEntry>();

    /**
     * Adds a service to the registry.
     */
    public register(value: ServiceRegistryEntry): void {

        if (!value) {
            throw new Error("service settings must be specified");
        }

        this.services.add(value);
        if (value.startup === "auto") {
            console.debug(`▶ Auto-starting service ${value.service.constructor.name}`);
            value.service.start();
        }
    }

    /**
     * Removes a service from the registry.
     */
    public unregister(value: ServiceRegistryEntry): void {

        if (!value) {
            throw new Error("service settings must be specified");
        }

        // TODO: is this the right way to do this?
        if (value.startup === "auto") {
            value.service.stop();
        }

        this.services.delete(value);
    }    
}