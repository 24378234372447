import { Controller } from "../controller/Controller"
import { ControllerArrayContextKey } from "./ControllerArrayContextKey"
import { ControllerPluginKey } from "../controller/ControllerPluginKey"
import { ControllerRegistryPluginKey } from "./ControllerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"

export const ControllerRegistryManifest: Manifest = {

    /**
     * The unique key of the plugin.
     */
    key: ControllerRegistryPluginKey,

    /**
     * Indicates whether to install the plugin by default.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ControllerPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: ControllerArrayContextKey,
            value: []
        } as ManifestEntry<Controller[]>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Controller Registry"
}