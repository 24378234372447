import React from "react"
import { Command } from "../commander/Command"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { IonButtons } from "@ionic/react"
import { ToolbarCommandButton } from "./ToolbarCommandButton"

export const ToolbarCommandBar: React.FC<ReactViewProps> = (props) => {

    const commands: Command[] = props?.model?.commands;
    if (!Array.isArray(commands)) {
        return <></>
    }

    const filtered = commands.filter(c => c.layout === "toolbar");

    return (
        <IonButtons slot="start">
            {filtered.map(command =>
                <ToolbarCommandButton
                    key={command.key}
                    command={command}
                    dispatch={props.dispatch}></ToolbarCommandButton>)}
        </IonButtons>
    )
}