import React from "react"
import { CompleteTask } from "../complete-task/CompleteTask"
import { CompleteTaskSchemaId } from "../complete-task/CompleteTaskSchemaId"
import { NextSchemaId } from "../next/NextSchemaId"
import { Payload } from "../payload/Payload"
import { Prompt } from "../prompt/Prompt"
import { PromptSchemaId } from "../prompt/PromptSchemaId"
import { ReactViewFactory } from "../react-view/ReactViewFactory"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { Task } from "../task/Task"
import { TaskSchemaId } from "../task/TaskSchemaId"
import { TaskStatus } from "../task/TaskStatus"

/**
 * Builds a Prompt object that will prompt the user to complete the specified task.
 */
function makePrompt(payload: Payload | undefined): Prompt | undefined {
    
    if (payload?.schema !== TaskSchemaId) {
        return undefined;
    }

    return {
        title: "Have you completed this task?",
        payload,
        options: [
            {
                schema: CompleteTaskSchemaId,
                data: {
                    payload,
                    confirmed: true
                },
                title: "Yes"
            } as Payload<CompleteTask>,
            {
                schema: NextSchemaId,
                data: undefined,
                title: "No"
            }
        ]
    }
}

export const TaskStreamView: React.FC<ReactViewProps> = (props) => {

    const dispatch = props.dispatch;
    const payload = props?.model?.payload;
    const task = payload?.data as Task;
    const showPrompt = payload?.id && (task.status === undefined || task.status === TaskStatus.Open);

    function onSelect(p: Payload) {
        if (dispatch && p) {
            dispatch(p);
        }
    }

    if (!showPrompt) {
        return (
            <ReactViewFactory
                layout="card"
                dispatch={props.dispatch}
                matcher={props.matcher}
                model={props.model}>
            </ReactViewFactory>
        )
    }
    else 
    {
        return (
            <ReactViewFactory
                layout="stream"
                dispatch={p => onSelect(p)}
                matcher={props.matcher}
                model={{
                    payload: {
                        schema: PromptSchemaId,
                        data: makePrompt(props?.model?.payload)
                    }
                }}>
            </ReactViewFactory>
        )    
    }
}