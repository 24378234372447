import { DefinitionCardView } from "./DefinitionCardView"
import { DefinitionPluginKey } from "../definition/DefinitionPluginKey"
import { DefinitionSchemaId } from "../definition/DefinitionSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const DefinitionCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "definition-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DefinitionPluginKey,
        ReactViewPluginKey,
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DefinitionCardView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: DefinitionSchemaId,
                layout: ["card", "preview"],
                fc: DefinitionCardView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Definition Card View"
}