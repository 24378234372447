import { CommanderPluginKey } from "./CommanderPluginKey"
import { ControllerPluginKey } from "../controller/ControllerPluginKey"
import { ControllerRegistryPluginKey } from "../controller-registry/ControllerRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"

export const CommanderManifest: Manifest = {
 
    /**
     * A string that uniquely identifies the plugin.
     */
    key: CommanderPluginKey,

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ControllerPluginKey,
        ControllerRegistryPluginKey,
        PayloadPluginKey
    ],
 
    /**
     * The display title of the plugin.
     */
    title: "Commander"
}