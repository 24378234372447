import { AttachmentsDetailView } from "./AttachmentsDetailView"
import { ListPluginKey } from "../list/ListPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const AttachmentsDetailViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "attachments-detail-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        ListPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "AttachmentsDetailView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                layout: "detail",
                match: "*",
                order: "first",
                fc: AttachmentsDetailView
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Attachments Detail View"
};
