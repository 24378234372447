import { BooleanPluginKey } from "../boolean/BooleanPluginKey"
import { DateNumberPluginKey } from "../date-number/DateNumberPluginKey"
import { DeferCard } from "./DeferCardView"
import { DeferPluginKey } from "../defer/DeferPluginKey"
import { DeferSchemaId } from "../defer/DeferSchemaId"
import { Manifest } from "../../runtime/Manifest"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"

export const DeferCardViewManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: "defer-card-view",

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        BooleanPluginKey,
        DateNumberPluginKey,
        DeferPluginKey,
        ReactViewPluginKey
    ],

    /**
     * The objects supplied by the plugin.
     */
    entries: [
        {
            key: "DeferCardView",
            registerWith: ReactViewRegistryContextKey,
            value: {
                match: DeferSchemaId,
                layout: "card",
                fc: DeferCard
            }
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Defer Card View"
}