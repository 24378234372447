import { Formatter } from "../formatter/Formatter"
import { formatTimespan } from "./formatTimespan"
import { TimespanSchemaId } from "../timespan/TimespanSchemaId"

export class TimespanFormatter implements Formatter {

    public format(schemaId: string, value: any): string | undefined {

        if (schemaId !== TimespanSchemaId) {
            return undefined;
        }

        if (typeof(value) !== "number") {
            return undefined;
        }

        return formatTimespan(value);
    }
}
