import { ConvertPluginKey } from "./ConvertPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { PayloadPluginKey } from "../payload/PayloadPluginKey"

export const ConvertManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: ConvertPluginKey,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        PayloadPluginKey
    ],

    /**
     * The display title of the plugin.
     */
    title: "Convert"
}