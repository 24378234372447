import { Manifest } from "../../runtime/Manifest"
import { SplashPluginKey } from "./SplashPluginKey"

export const SplashManifest: Manifest = {

    /**
     * The key that uniquely identifies the plugin.
     */
    key: SplashPluginKey,

    /**
     * Indicates whether to install the plugin by default at first run.
     */
    defaultInstall: false,

    /**
     * The display title of the plugin.
     */
    title: "Splash"
}