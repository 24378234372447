import { Annotator } from "../annotator/Annotator"
import { Payload } from "../payload/Payload"
import { SubredditFeed } from "../subreddit-feed/SubredditFeed"
import { SubredditFeedSchemaId } from "../subreddit-feed/SubredditFeedSchemaId"

export class SubredditFeedAnnotator implements Annotator<SubredditFeed> {
    public async annotate(payload: Payload<SubredditFeed>): Promise<void> {
        if ((payload?.schema === SubredditFeedSchemaId) && (payload.data?.subreddit)) {
            payload.key = `https://reddit.com/r/${payload.data.subreddit}`;
            payload.title = `r/${payload.data.subreddit}`;
        }
    }
}