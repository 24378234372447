import { DateNumberPluginKey } from "../date-number/DateNumberPluginKey"
import { FormatterRegistryContextKey } from "../formatter-registry/FormatterRegistryContextKey"
import { FormatterRegistryPluginKey } from "../formatter-registry/FormatterRegistryPluginKey"
import { Manifest } from "../../runtime/Manifest"
import { ManifestContext } from "../../runtime/ManifestContext"
import { ManifestEntry } from "../../runtime/ManifestEntry"
import { ReactViewPluginKey } from "../react-view/ReactViewPluginKey"
import { ReactViewProps } from "../react-view/ReactViewProps"
import { ReactViewRegistryContextKey } from "../react-view/ReactViewRegistryContextKey"
import { ReactViewRegistryEntry } from "../react-view/ReactViewRegistryEntry"
import { TaskHistoryDetailView } from "./TaskHistoryDetailView"
import { TaskPluginKey } from "../task/TaskPluginKey"
import { TaskSchemaId } from "../task/TaskSchemaId"
import { TimespanPluginKey } from "../timespan/TimespanPluginKey"

export const TaskHistoryDetailViewManifest: Manifest = {

    /**
     * A string that uniquely identifies the plugin.
     */
    key: "task-history-detail-view",

    /**
     * Indicates whether to install the plugin at first run.
     */
    defaultInstall: true,

    /**
     * The plugins that must be installed before this one.
     */
    dependsOn: [
        DateNumberPluginKey,
        FormatterRegistryPluginKey,
        ReactViewPluginKey,
        TaskPluginKey,
        TimespanPluginKey
    ],

    /**
     * The objects provided by the plugin.
     */
    entries: [
        {
            key: "TaskHistoryDetailView",
            requires: [FormatterRegistryContextKey],
            registerWith: ReactViewRegistryContextKey,
            title: "Task History Detail View",
            value: (context: ManifestContext) => ({
                layout: "detail",
                order: "last",
                match: TaskSchemaId,
                fc: (props: ReactViewProps) =>
                    TaskHistoryDetailView({...props, formatter: context[FormatterRegistryContextKey]})                       
            })
        } as ManifestEntry<ReactViewRegistryEntry>
    ],

    /**
     * The display title of the plugin.
     */
    title: "Task History Detail View"
}