import { Indexer } from "../indexer/Indexer"
import { Payload } from "../payload/Payload"
import { UrlSchemaId } from "../url/UrlSchemaId";

export class GoogleMapIndexer implements Indexer {
    
    public mapUrl(latitude: number, longitude: number): string {
        // https://developers.google.com/maps/documentation/urls/get-started
        return `https://www.google.com/maps/@?api=1` +
            `&map_action=map` + 
            `&center=${latitude},${longitude}` + 
            `&basemap=terrain`;
    }
    
    public async index(payload: Payload | undefined): Promise<Payload[]> {

        const location = payload?.location;
        if (!location) {
            return [];
        }

        const url = this.mapUrl(
            location.latitude,
            location.longitude);

        const urlPayload: Payload = {
            schema: UrlSchemaId,
            key: url,
            data: url,
            title: "Google Map"
        }

        return [urlPayload];
    }
}