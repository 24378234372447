import { NasaApodFeedSchemaId } from "./NasaApodFeedSchemaId"
import { Schema } from "../schema/Schema"

export const NasaApodFeedSchema: Schema = {

    "$id": NasaApodFeedSchemaId,
    "$schema": "https://json-schema.org/draft/2020-12/schema",

    type: "object",
    description: "NASA Astronomy Picture of the Day (APOD)",
    title: "NASA Astronomy Picture of the Day",

    properties: {

        "apiKey": {
            type: "string",
            description: "The API key for fetching data from NASA",
            title: "API Key"
        },
    }
}