import { Payload } from "../payload/Payload"
import { Sequence } from "../sequence/Sequence"
import { SequenceMapFactory } from "../sequence-map-factory/SequenceMapFactory"
import { SequenceSchemaId } from "../sequence/SequenceSchemaId"
import { SequenceStreamer } from "./SequenceStreamer"
import { Streamer } from "../streamer/Streamer"
import { StreamerCreator } from "../streamer-creator/StreamerCreator"

export class SequenceStreamerCreator implements StreamerCreator {

    private factory: SequenceMapFactory;

    constructor(factory: SequenceMapFactory) {
        if (!(this.factory = factory)) {
            throw new Error("factory must be specified");
        }
    }

    public async create(parameters: Payload<Sequence | string>): Promise<Streamer | undefined> {

        if (parameters?.schema !== SequenceSchemaId) {
            return undefined;
        }

        const sequence = parameters.data as string | Sequence;
        if (!sequence) {
            return undefined;
        }

        // Load the sequence
        const instance = await this.factory.create(sequence);
        if (!instance) {
            return undefined;
        }
        
        return new SequenceStreamer(instance);
    }
}